import React, { useEffect, useState, useMemo } from 'react'
import { Alert, Button, Col, Divider, Row, Space } from 'antd'
import PropTypes from 'prop-types'
import { FormSubTitle } from '../../forms'
import { useTranslation } from 'react-i18next'
import _, { uniqueId } from 'lodash'
import { IconButton, Table, Tooltip, Whisper } from 'rsuite'
import moment from 'moment/moment'
import { TbReload } from 'react-icons/tb'
import { MdClose, MdOutlineDone, MdOutlineEdit } from 'react-icons/md'
import { useRequestsEvents } from '../../../hooks/useRequestsEvents'
import { useParams } from 'react-router-dom'
import { ErrorResult, LoaderBackDrop } from '../../feedbacks'
import { PromotionLiquidationGenericEnergyTable } from '../tables/PromotionLiquidationGenericEnergyTable'
import { useTable } from '../../../hooks/useTable'
import { HeaderTextWithEditionIcons, DataPickerCell, InputNumberCell, InputTextCell } from '../../tables'
import { FaPlus } from 'react-icons/fa6'
import { v4 } from 'uuid'
import { CloseOutlined, SaveOutlined } from '@ant-design/icons'
import {
  setCompensationsInterfaceData,
  setCompensationsSummaryInterfaceData,
  setInterfaceDataToCalculatedPeriod,
  setInterfaceDataToTotalCalculatedPeriod
} from '../../../helpers/liquidations'

const { getCompensationData, getCalculatedPeriods } = window.helpersLiquidations
const { Column, HeaderCell, Cell } = Table
const CompactCell = props => <Cell {...props} style={{ padding: 4 }} />
const CompactHeaderCell = props => <HeaderCell {...props} style={{ padding: 4 }} />

const styleIconsHeaders = { marginTop: '-1px' }
const styleDivider = { marginTop: '10px', marginBottom: '10px' }

const EditCell = ({ rowData, dataKey, onChange, ...props }) => {
  // default component
  let component = <CompactCell {...props} ><span className="table-content-edit-span">{rowData[dataKey]}</span></CompactCell>

  if (_.get(rowData, 'period_is_estimated', false) && dataKey === 'period_consumption') {
    component = <CompactCell {...props}>
                  <InputNumberCell min={0} onChange={onChange} name={`${props.name}${props.rowIndex}`}
                                   value={rowData.period_consumption_day * rowData.period_days} align={'right'}
                                   defaultValue={rowData.period_consumption_day * rowData.period_days} />
                </CompactCell>
  } else if (dataKey === 'other_concepts_value') {
    component = <CompactCell {...props}>
                  <InputNumberCell min={Number.MIN_SAFE_INTEGER} onChange={onChange} name={`${props.name}${props.rowIndex}`}
                                   dataId={rowData.other_concepts_uuid} value={rowData.other_concepts_value} defaultValue={rowData.other_concepts_value} align={'right'} />
                </CompactCell>
  } else if (dataKey === 'other_concepts_concept') {
    component = <CompactCell {...props}>
                  <InputTextCell onChange={onChange} name={`${props.name}${props.rowIndex}`} dataId={rowData.other_concepts_uuid}
                         value={rowData.other_concepts_concept} defaultValue={rowData.other_concepts_concept} align={'right'} />
                </CompactCell>
  // for source efficiency
  } else if (dataKey === 'liquidation_source_efficiency') {
    component = <CompactCell {...props}>
      <InputNumberCell min={0} onChange={onChange} name={`${props.name}`}
                       value={rowData.liquidation_source_efficiency} defaultValue={rowData.liquidation_source_efficiency} align={'right'} />
    </CompactCell>
  }
  // return component to render
  return (<>{component}</>)
}

EditCell.propTypes = {
  name: PropTypes.string.isRequired,
  rowIndex: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  rowData: PropTypes.object,
  dataKey: PropTypes.string
}

const GeneraInformationSection = (props) => {
  // for translation
  const { t } = useTranslation(['dashboard', 'fields'])
  // get initial configuration component
  const { workingData, onEdit, onSave, onCancel, onValueChange } = props
  // get data
  const { data, isEditing, isEditable } = workingData
  // get liquidation periods
  // for track editing and field
  const nameLiquidationDate = 'liquidation_date'
  const nameSourceEfficiency = 'liquidation_source_efficiency'

  // init liquidation data
  // 1. is undefined set now()
  // 2. is not undefined set a date from data object
  data[nameLiquidationDate] = useMemo(() => {
    return !data[nameLiquidationDate] ? moment().format('YYYY-MM-DD') : moment(data[nameLiquidationDate], 'YYYYMMDD').format('YYYY-MM-DD')
  }, [data[nameLiquidationDate]])
  const minValueLiquidationDate = moment(data.liquidation_date_end, 'YYYYMMDD').format('YYYY-MM-DD')

  // data for table with period and days
  // 1. recalculate when change a liquidation_date_start and liquidation_date_end
  const firstTableStaticDate = useMemo(() => {
    const liquidationStartDate = moment(data.liquidation_date_start, 'YYYYMMDD')
    const liquidationEndDate = moment(data.liquidation_date_end, 'YYYYMMDD')
    const efficiencyValue = _.get(data, nameSourceEfficiency, 1)
    return [{
      liquidation_is_first: data.liquidation_is_first ? <MdOutlineDone /> : <MdClose/>,
      days: moment(liquidationEndDate).add(1, 'days').diff(liquidationStartDate, 'days'),
      period: `${liquidationStartDate.format('YYYY-MM-DD')} - ${liquidationEndDate.format('YYYY-MM-DD')}`,
      liquidation_source_efficiency_string: `${efficiencyValue * 100}%`,
      [nameSourceEfficiency]: efficiencyValue
    }]
  }, [data.liquidation_date_start, data.liquidation_date_end, data[nameSourceEfficiency]])

  // data for tale with liquidation date
  // 1. recalculate when change a liquidation_date on data
  const secondTableEditableData = useMemo(() => {
    return [{ [nameLiquidationDate]: data[nameLiquidationDate] }]
  }, [data[nameLiquidationDate]])

  return (
    <>
      <Row gutter={16}>
        <Col span={11}>
          <Table loading={false} autoHeight={true} hover={false} fillHeight={false} showHeader={true} data={firstTableStaticDate} bordered={true} cellBordered={true} headerHeight={30} rowHeight={29}>
            <Column width={50} align={'center'}>
              <CompactHeaderCell>
                <Whisper speaker={<Tooltip>{t('dashboard.is_fist_liquidation')}</Tooltip>}><span>{t('dashboard.is_fist_liquidation_abbr')}*</span></Whisper>
              </CompactHeaderCell>
              <CompactCell dataKey={'liquidation_is_first'} />
            </Column>
            <Column width={200} align={'center'}>
              <CompactHeaderCell>{t('dashboard.period')}</CompactHeaderCell>
              <CompactCell dataKey={'period'} />
            </Column>
            <Column width={60} align={'center'}>
              <CompactHeaderCell>{t('dashboard.days')}</CompactHeaderCell>
              <CompactCell dataKey={'days'} />
            </Column>
            <Column flexGrow={1} align={'center'}>
              { isEditable
                ? <CompactHeaderCell>
                  <HeaderTextWithEditionIcons isEditing={isEditing} text={'dashboard.efficiency'} onEdit={onEdit} onSave={onSave} onCancel={onCancel} />
                </CompactHeaderCell>
                : <CompactHeaderCell>{t('dashboard.efficiency')}</CompactHeaderCell>
              }
              {!isEditing || !isEditable
                ? <CompactCell dataKey={'liquidation_source_efficiency_string'} />
                : <EditCell dataKey={nameSourceEfficiency} onChange={onValueChange} name={nameSourceEfficiency} />
              }
            </Column>
          </Table>
        </Col>
        <Col span={5}>
          <Table loading={false} autoHeight={true} hover={false} fillHeight={false} showHeader={true} data={secondTableEditableData} bordered={true} cellBordered={true} headerHeight={30} rowHeight={29}>
            <Column flexGrow={1} align={'center'}>
              { isEditable
                ? <CompactHeaderCell>
                    <HeaderTextWithEditionIcons isEditing={isEditing} text={'dashboard.liquidation_date'} onEdit={onEdit} onSave={onSave} onCancel={onCancel} />
                  </CompactHeaderCell>
                : <CompactHeaderCell>{t('dashboard.liquidation_date')}</CompactHeaderCell>
              }
              {!isEditing || !isEditable
                ? <CompactCell dataKey={nameLiquidationDate} />
                : <CompactCell>
                    <DataPickerCell minDate={minValueLiquidationDate} onChange={onValueChange} name={nameLiquidationDate} value={data[nameLiquidationDate]} />
                  </CompactCell>
              }
            </Column>
          </Table>
        </Col>
      </Row>
    </>
  )
}

GeneraInformationSection.propTypes = {
  workingData: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  periodsDataCalculated: PropTypes.array.isRequired
}

export const LiquidationSection = (props) => {
  // for translation
  const { t } = useTranslation(['dashboard', 'fields'])
  // get initial configuration component
  const { workingData, periodsDataCalculated, onEdit, onSave, onCancel, onValueChange } = props
  // get data
  const { data, isEditing, isEditable } = workingData
  // get liquidation periods
  const { liquidation_periods: liquidationPeriods } = data
  // for values change name
  const namePeriodConsumption = 'period_consumption_'
  // get last period not estimated
  const isSomePeriodEstimated = _.findLast(liquidationPeriods, { period_is_estimated: true })

  return (<>
    <Table className={'compensations-table'} loading={false} autoHeight={true} hover={false} fillHeight={false} showHeader={true} data={periodsDataCalculated.slice(0, -1)} bordered={true} cellBordered={true} headerHeight={30} rowHeight={29}>
      <Column width={50} align={'center'}>
        <CompactHeaderCell>
          <Whisper speaker={<Tooltip>{t('dashboard.is_estimated_m')}</Tooltip>}><span>E.*</span></Whisper>
        </CompactHeaderCell>
        <CompactCell dataKey={'period_is_estimated_icon'} />
      </Column>
      <Column width={60} align={'center'}>
        <CompactHeaderCell>
          <Whisper speaker={<Tooltip>{t('dashboard.invoice')}</Tooltip>}><span>{t('dashboard.invoice_abbr')} Id*</span></Whisper>
        </CompactHeaderCell>
        <CompactCell dataKey={'period_invoice_id'} />
      </Column>
      <Column width={180} align={'center'}>
        <CompactHeaderCell>{t('dashboard.period')}</CompactHeaderCell>
        <CompactCell dataKey={'period_full_date'} />
      </Column>
      <Column width={45} align={'center'}>
        <CompactHeaderCell>{t('dashboard.days')}</CompactHeaderCell>
        <CompactCell dataKey={'period_days'} />
      </Column>
      <Column width={150} flexGrow={1} align={'right'}>
        { isSomePeriodEstimated && isEditable
          ? <CompactHeaderCell>
            <HeaderTextWithEditionIcons isEditing={isEditing} text={'dashboard.consumption'} onEdit={onEdit} onSave={onSave} onCancel={onCancel} />
          </CompactHeaderCell>
          : <CompactHeaderCell>{t('fields:fields.labels.consumption')}</CompactHeaderCell>
        }
        {!isEditing || !isSomePeriodEstimated || !isEditable
          ? <CompactCell dataKey={'period_consumption'} />
          : <EditCell dataKey={'period_consumption'} onChange={onValueChange} name={namePeriodConsumption} />
        }
      </Column>
      <Column width={100} align={'right'}>
        <CompactHeaderCell>{t('fields:fields.labels.cost_variable')}</CompactHeaderCell>
        <CompactCell dataKey={'period_cost_variable'} />
      </Column>
      <Column width={100} align={'right'}>
        <CompactHeaderCell>{t('fields:fields.labels.cost_fix')}</CompactHeaderCell>
        <CompactCell dataKey={'period_cost_fix'} />
      </Column>
      <Column width={100} align={'right'}>
        <CompactHeaderCell>{t('fields:fields.labels.tax')} %</CompactHeaderCell>
        <CompactCell dataKey={'period_cost_tax_percent'} />
      </Column>
      <Column width={100} align={'right'}>
        <CompactHeaderCell>{t('fields:fields.labels.tax')} €</CompactHeaderCell>
        <CompactCell dataKey={'period_cost_tax_eur'} />
      </Column>
      <Column width={150} flexGrow={1} align={'right'}>
        <CompactHeaderCell>{t('fields:fields.labels.total')}</CompactHeaderCell>
        <CompactCell dataKey={'period_total'} />
      </Column>
    </Table>
    { /* summary for all compensations table */ }
    <div className={'rs-table-last-as-total-top-bottom'}>
      <PromotionLiquidationGenericEnergyTable showHeader={false} style={{ marginTop: '15px' }} data={periodsDataCalculated.slice(-1)} summary={true} />
    </div>
  </>)
}

LiquidationSection.propTypes = {
  workingData: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  periodsDataCalculated: PropTypes.array.isRequired
}

export const CompensationSection = (props) => {
  // get props data
  const { compensationsDataCalculated, compensationDataCalculatedSummary } = props

  // create a source tables
  const compensationsTablesArray = useMemo(() => {
    // if is empty compensations
    if (_.isEmpty(compensationsDataCalculated)) return []
    // parse and return table for each compensation
    return compensationsDataCalculated.map((compensation) => {
      // set data to show
      const compensationDataTable = compensation.compensableDataBalance.concat(compensation.invoiceDataBalance, compensation.compensationDataBalance)
      // return each table for each compensation
      return (
        <div key={_.uniqueId('container-compensations-tables-')} style={{ marginTop: '15px' }} className={'rs-table-last-as-total-top'}>
          <PromotionLiquidationGenericEnergyTable data={compensationDataTable} />
        </div>)
    })
  }, [compensationsDataCalculated])

  // return components
  return (<>
    { /* all compensations tables */ }
    { compensationsTablesArray }
    { /* summary for all compensations table */ }
    <div className={'rs-table-last-as-total-top-bottom'}>
      <PromotionLiquidationGenericEnergyTable showHeader={false} style={{ marginTop: '15px' }} data={[compensationDataCalculatedSummary]} summary={true} />
    </div>
  </>)
}

CompensationSection.propTypes = {
  compensationsDataCalculated: PropTypes.array.isRequired,
  compensationDataCalculatedSummary: PropTypes.object.isRequired
}

export const OtherConceptsSection = (props) => {
  // get props data
  const { otherConceptsCfg, handlerChange, handlerDelete, summary } = props
  // get props data
  const { isEditing, isEditable, concepts } = otherConceptsCfg
  // for translation
  const { t } = useTranslation(['dashboard', 'fields'])
  // get actions from use table
  const { DeleteAction } = useTable()
  // for render summary totals
  const summaryTotal = {
    title: <span style={{ float: 'right', marginRight: '6px', color: 'grey' }}>{t('dashboard.total')}:</span>,
    value: summary,
    value_string: `${summary} €`
  }
  // create a source tables
  const conceptsTableArray = concepts.map((conceptOrg) => {
    // new objet
    const conceptCopy = {}
    // set original values
    conceptCopy.other_concepts_concept = _.get(conceptOrg, 'concept', ' - ')
    // set value data
    const value = _.get(conceptOrg, 'value', 0)
    conceptCopy.other_concepts_value = value
    conceptCopy.other_concepts_value_string = `${value.toFixed(2)} €`
    conceptCopy.other_concepts_uuid = conceptOrg.uuid
    // set actions
    conceptCopy.delete_action = <DeleteAction data={conceptOrg} disabled={isEditing} handler={handlerDelete} />
    // conceptCopy.edit_action = <EditAction data={conceptOrg} handler={handlerEdit} />
    // conceptCopy.cancel_action = <CancelAction data={conceptOrg} handler={handlerCancel} />
    // conceptCopy.save_action = <SaveAction data={conceptOrg} handler={handlerSave} />
    // return application
    return conceptCopy
  })

  // return components
  return (<>
    <Table className={'compensations-table'} loading={false} autoHeight={true} hover={false} fillHeight={false} showHeader={true} data={conceptsTableArray} bordered={true} cellBordered={true}
           headerHeight={30} rowHeight={29}>
      { /* ACTIONS FOR THIS ROWS */}
      {isEditable && <Column width={25} align="center">
          <CompactHeaderCell></CompactHeaderCell>
          <CompactCell dataKey="delete_action"/>
        </Column>
      }
      { /* VALUES */}
      <Column width={422} align={'right'}>
        <CompactHeaderCell>{t('fields:fields.labels.concept')}</CompactHeaderCell>
        {!isEditing || !isEditable
          ? <CompactCell dataKey={'other_concepts_concept'}/>
          : <EditCell dataKey={'other_concepts_concept'} onChange={handlerChange} name={'other_concepts-concept-'}/>
        }
      </Column>
      <Column width={150} flexGrow={1} align={'right'}>
        <CompactHeaderCell>{t('fields:fields.labels.total')}</CompactHeaderCell>
        {!isEditing || !isEditable
          ? <CompactCell dataKey={'other_concepts_value_string'}/>
          : <EditCell dataKey={'other_concepts_value'} onChange={handlerChange} name={'other_concepts-value-'}/>
        }
      </Column>
    </Table>
    <div className={'rs-table-last-as-total-top-bottom'}>
      <Table style={{ marginTop: '15px' }} loading={false} autoHeight={true} hover={false} fillHeight={false} showHeader={false}
             data={[summaryTotal]} bordered={true} cellBordered={true} headerHeight={0} rowHeight={29}>
        <Column width={873} align={'right'}>
          <CompactHeaderCell>{t('fields:fields.labels.total')}</CompactHeaderCell>
          <CompactCell dataKey={'title'}/>
        </Column>
        <Column width={150} flexGrow={1} align={'right'}>
          <CompactHeaderCell>{t('fields:fields.labels.total')}</CompactHeaderCell>
          <CompactCell dataKey={'value_string'}/>
        </Column>
      </Table>
    </div>
  </>)
}

OtherConceptsSection.propTypes = {
  otherConceptsCfg: PropTypes.object.isRequired,
  handlerChange: PropTypes.func.isRequired,
  handlerDelete: PropTypes.func.isRequired,
  summary: PropTypes.number.isRequired
}

export const SummarySection = (props) => {
  // for translation
  const { t } = useTranslation(['dashboard', 'fields'])
  // get needed props
  const { periodsDataCalculatedSummary, compensationDataCalculatedSummary, otherConceptsSummaryValue } = props
  // get data for tables
  const { dataTable, summaryDataTable } = useMemo(() => {
    // init array on set data to show
    const dataTable = []
    // init total to show on summary
    let total = 0
    // set liquidations calculations summary
    dataTable.push({ ...periodsDataCalculatedSummary, period_full_date: <span style={{ float: 'right', marginRight: '6px', color: 'grey' }}>{t('dashboard.liquidation')}:</span> })
    // update total with liquidations data
    total += periodsDataCalculatedSummary.total
    // fi exists compensations
    if (!_.isEmpty(compensationDataCalculatedSummary)) {
      // set compensations calculations summary
      dataTable.push({
        ...compensationDataCalculatedSummary,
        period_full_date: <span style={{ float: 'right', marginRight: '6px', color: 'grey' }}>{t('dashboard.compensations')}:</span>
      }) // update total with compensations data
      total += compensationDataCalculatedSummary.total
    }

    // set other concepts data values summary
    if (otherConceptsSummaryValue || otherConceptsSummaryValue === 0) {
      // set compensations calculations summary
      dataTable.push({
        period_total: `${otherConceptsSummaryValue.toFixed(2)} €`,
        period_cost_tax_eur: '-',
        period_cost_tax_percent: '-',
        period_cost_fix: '-',
        period_cost_variable: '-',
        period_consumption: '-',
        period_days: '-',
        period_full_date: <span style={{ float: 'right', marginRight: '6px', color: 'grey' }}>{t('dashboard.others')}:</span>
      }) // update total with compensations data
      total += otherConceptsSummaryValue
    }// set to data table
    return {
      dataTable,
      summaryDataTable: {
        period_total_title: <span style={{ float: 'right', marginRight: '6px', color: 'grey' }}>{t('dashboard.total')}:</span>,
        period_total: `${total.toFixed(2)} €`
      }
    }
  }, [periodsDataCalculatedSummary, compensationDataCalculatedSummary, otherConceptsSummaryValue])

  // return component
  return (<>
    <PromotionLiquidationGenericEnergyTable style={{ marginTop: '15px' }} data={dataTable} summary={true} />
    <div className={'rs-table-last-as-total-top-bottom'}>
      <Table style={{ marginTop: '15px' }} loading={false} autoHeight={true} hover={false} fillHeight={false} showHeader={false} data={[summaryDataTable]} bordered={true} cellBordered={true} headerHeight={30} rowHeight={29}>
        <Column width={873} align={'right'}>
          <CompactHeaderCell>{t('fields:fields.labels.total')}</CompactHeaderCell>
          <CompactCell dataKey={'period_total_title'} />
        </Column>
        <Column width={150} flexGrow={1} align={'right'}>
          <CompactHeaderCell>{t('fields:fields.labels.total')}</CompactHeaderCell>
          <CompactCell dataKey={'period_total'} />
        </Column>
      </Table>
    </div>
  </>)
}

SummarySection.propTypes = {
  periodsDataCalculatedSummary: PropTypes.object.isRequired,
  compensationDataCalculatedSummary: PropTypes.object.isRequired,
  otherConceptsSummaryValue: PropTypes.number.isRequired
}

export const PromotionEnergySupplyLiquidateForm = (props) => {
  // for translation
  const { t } = useTranslation(['dashboard', 'fields'])
  // get current promotion id
  const { promotionId } = useParams()
  // get actions from use table
  const { DeleteAction } = useTable()
  // get methods to send data
  const { createEnergySourceLiquidatedPeriod, getPromotionEnergySourceLiquidationCompensablePeriods } = useRequestsEvents()
  // get data for form
  const { data: originalData, onLiquidate, action } = props
  // for compensations table loader on edit
  const [showCompensationsLoader, setShowCompensationsLoader] = useState(false)
  // for compensations table loader on edit
  const [showDeductionsLoader, setShowDeductionsLoader] = useState(false)
  // for working data
  const [workingData, setWorkingData] = useState({
    isEditable: action !== 'liquidation.view',
    // modified data
    data: _.cloneDeep(originalData),
    dataBeforeEdit: _.cloneDeep(originalData),
    dataOriginal: _.cloneDeep(originalData),
    isLoaded: false,
    isEditing: false,
    isError: false,
    compensations: [],
    compensationsOriginal: []
  })
  // for other concepts configuration
  const [otherConceptsCfg, setOtherConceptsCfg] = useState({
    isEditing: false,
    isEditable: action !== 'liquidation.view',
    concepts: _.get(originalData, 'liquidation_other_concepts', []),
    conceptsDataBeforeEdit: _.get(originalData, 'liquidation_other_concepts', [])
  })

  // ** METHOD LOAD DATA BY AJAX ** //

  // get compensable data
  useEffect(() => {
    // clean up controller --> prevent crash with async function
    let isMounted = true
    // not reload if component is loaded...
    if (workingData.isLoaded) return
    // get source id
    const sourceId = _.get(workingData, 'dataOriginal.liquidation_source_id', -1)
    // if actions not is liquidation view (not is liquidated)
    if (action !== 'liquidation.view') {
      // get compensations data
      getPromotionEnergySourceLiquidationCompensablePeriods(promotionId, sourceId, (response) => {
        // prevent async crash
        if (!isMounted) return null
        // get compensations data
        const liquidationCompensations = response.data || []
        // set to working data object
        setWorkingData({ ...workingData, isLoaded: true, compensations: liquidationCompensations, compensationsOriginal: liquidationCompensations })
      }, () => {
        // prevent async crash
        if (!isMounted) return null
        setWorkingData({ ...workingData, isError: true, isLoaded: true, compensations: [], compensationsOriginal: [] })
      })
    } else { // load from caller
      const compensations = _.get(originalData, 'liquidation_compensations', [])
      setWorkingData({ ...workingData, isLoaded: true, compensations, compensationsOriginal: compensations })
    } // prevent crash with async function
    return () => { isMounted = false }
  }, [workingData.isLoaded])

  // ** METHODS ** //

  // handler for when click on edit buttons
  const periodHandlerOnClickEdit = () => {
    setWorkingData({ ...workingData, isEditing: true, dataBeforeEdit: _.cloneDeep(workingData.data) })
  }

  // handler for cancel edited
  const periodHandlerOnClickCancelEdit = () => {
    // restore data before edit
    setWorkingData({ ...workingData, isEditing: false, data: _.cloneDeep(workingData.dataBeforeEdit) })
  }

  // handler for save edit
  const periodHandlerOnClickSaveEdit = () => {
    // update data on working data
    setWorkingData({ ...workingData, isEditing: false })
  }

  // handler for each value changed on period tables
  const periodHandlerOnValueChange = (name, value) => {
    // fix crash
    if (!value && value !== 0) { return }
    // get data cloned
    const clonedDataObj = _.cloneDeep(workingData.data)
    // force re-rendering when object is clone
    const liquidationPeriods = _.cloneDeep(workingData.data.liquidation_periods)
    // if edit a date of liquidation
    if (name === 'liquidation_date') {
      clonedDataObj[name] = parseInt(value)
    // if changed any value of consumption
    } else if (name.search('period_consumption_') > -1) {
      // get period index
      const periodIndex = name.split('_')[2]
      // update liquidation period
      liquidationPeriods[periodIndex].period_consumption_day = value / liquidationPeriods[periodIndex].period_days
      // add modified to updater
      clonedDataObj.liquidation_periods = liquidationPeriods
    } else if (name === 'liquidation_source_efficiency') {
      clonedDataObj[name] = parseFloat(value)
    } // update data on working data
    setWorkingData({ ...workingData, data: clonedDataObj })
  }

  // handler when delete a compensation table
  const compensationHandlerDelete = (data, action) => {
    // set loader for compensations
    setShowCompensationsLoader(true)
    // get current compensations
    const compensations = _.clone(workingData.compensations)
    // remove period id selected to remove
    _.remove(compensations, { period_id: data.period_id })
    // set to working data
    setWorkingData({ ...workingData, compensations })
    // set loader for compensations
    setShowCompensationsLoader(false)
  }

  // handler when restore default compensation
  const compensationsHandlerReload = () => {
    // set loader for compensations
    setShowCompensationsLoader(true)
    // set to working data
    setWorkingData({ ...workingData, compensations: workingData.compensationsOriginal })
    // set loader for compensations
    setShowCompensationsLoader(false)
  }

  // handler for edit other concepts
  const otherConceptsHandlerOnClickEdit = (data, setIsActionRunning) => {
    setOtherConceptsCfg({ ...otherConceptsCfg, isEditing: true })
  }

  // add other concept
  const othersConceptsHandlerOnAdd = () => {
    setShowDeductionsLoader(true)
    // get data cloned
    const clonedDataObj = _.cloneDeep(otherConceptsCfg.concepts)
    // add the new concept
    clonedDataObj.push({ uuid: v4(), concept: uniqueId('concept-'), value: 0 })
    // update data
    setOtherConceptsCfg({ ...otherConceptsCfg, concepts: clonedDataObj, conceptsDataBeforeEdit: clonedDataObj, isEditing: true })
    // enable
    setShowDeductionsLoader(false)
  }

  // handler for each value changed on others concept data
  const otherConceptsHandlerOnValueChange = (name, value, dataId) => {
    // fix crash
    if (!value && value !== 0) { return }
    // get data cloned
    const clonedDataObj = _.cloneDeep(otherConceptsCfg.concepts)
    // find concept by id
    const concept = _.find(clonedDataObj, ['uuid', dataId])
    // get key to update
    const key = name.split('-')[1]
    // update key object
    concept[key] = value
    // update data
    setOtherConceptsCfg({ ...otherConceptsCfg, concepts: clonedDataObj })
  }

  // handler for edit other concepts
  const otherConceptsHandlerOnClickCancelEdit = () => {
    setOtherConceptsCfg({ ...otherConceptsCfg, isEditing: false, concepts: otherConceptsCfg.conceptsDataBeforeEdit })
  }

  // handler for save edit other concepts
  const otherConceptsHandlerOnClickSaveEdit = () => {
    // update data on working data
    setOtherConceptsCfg({ ...otherConceptsCfg, conceptsDataBeforeEdit: otherConceptsCfg.concepts, isEditing: false })
  }

  // handler when delete a compensation table
  const otherConceptsHandlerHandlerDelete = (data, setIsActionRunning) => {
    // set loader for compensations
    setIsActionRunning(true)
    setShowDeductionsLoader(true)
    // clone other concepts
    const clonedDataObj = _.cloneDeep(otherConceptsCfg.concepts)
    // remove period id selected to remove
    _.remove(clonedDataObj, { uuid: data.uuid })
    // set to working data
    setOtherConceptsCfg({ ...otherConceptsCfg, concepts: clonedDataObj, conceptsDataBeforeEdit: clonedDataObj })
    // set loader for compensations
    setIsActionRunning(false)
    setShowDeductionsLoader(false)
  }

  // submit handler liquidation
  const submitLiquidation = () => {
    // save compensations
    workingData.data.liquidation_compensations = workingData.compensations
    workingData.data.liquidation_other_concepts = otherConceptsCfg.concepts
    // get source id
    const sourceId = _.get(originalData, 'liquidation_source_id', -1)
    // call to create a source liquidation
    createEnergySourceLiquidatedPeriod(promotionId, sourceId, workingData.data, () => { onLiquidate() })
  }

  // ** METHODS WITH DEPENDENCIES ** //

  // get compensations data calculated
  const { compensationsDataCalculated, compensationDataCalculatedSummary } = useMemo(() => {
    // getCompensationData -> data, index, empty result, is component loaded required, deletable config
    return getCompensationData(workingData, 'compensations', true, true, setCompensationsInterfaceData, setCompensationsSummaryInterfaceData, setInterfaceDataToCalculatedPeriod, setInterfaceDataToTotalCalculatedPeriod, workingData.isEditable && { component: DeleteAction, handler: compensationHandlerDelete })
  }, [workingData.isLoaded, workingData.compensations])

  // get periods data calculated
  const { periodsDataCalculated, periodsDataCalculatedSummary } = useMemo(() => {
    // get calculated periods
    const periodsDataCalculated = getCalculatedPeriods(workingData.data.liquidation_periods, setInterfaceDataToCalculatedPeriod, setInterfaceDataToTotalCalculatedPeriod)
    return { periodsDataCalculated, periodsDataCalculatedSummary: periodsDataCalculated[periodsDataCalculated.length - 1] }
  }, [workingData.data.liquidation_periods])

  // calculate summary for other concepts
  const conceptsSummary = useMemo(() => {
    return _.sumBy(otherConceptsCfg.concepts, 'value')
  }, [otherConceptsCfg.concepts])

  return (<>
    {(() => { /* load component */
      if (_.isEmpty(originalData) || _.get(originalData, 'liquidation_periods', []).length < 1) {
        return <Alert key={_.uniqueId('alert-')} type="error" message={t('dashboard.messages.any_period_to_liquidate')} banner />
      } else if (workingData.isError) {
        return <ErrorResult compact={false} />
      } else { /* return components to render */
        return (<>
          <LoaderBackDrop active={!workingData.isLoaded} loader background bordered>
            { /* GENERAL SECTION :: title */ }
            <GeneraInformationSection workingData={workingData} setWorkingData={setWorkingData} periodsDataCalculated={periodsDataCalculated} onValueChange={periodHandlerOnValueChange} onEdit={periodHandlerOnClickEdit} onSave={periodHandlerOnClickSaveEdit} onCancel={periodHandlerOnClickCancelEdit} />
            <Divider style={styleDivider}/>
            { /* liquidation section */ }
            <FormSubTitle text={t('dashboard.periods')} />
            <LiquidationSection workingData={workingData} setWorkingData={setWorkingData} periodsDataCalculated={periodsDataCalculated} onValueChange={periodHandlerOnValueChange} onEdit={periodHandlerOnClickEdit} onSave={periodHandlerOnClickSaveEdit} onCancel={periodHandlerOnClickCancelEdit} />
            <Divider style={styleDivider}/>
            { /* *** */ }
            { /* *** */ }
            { /* COMPENSATIONS SECTION :: title */ }
            <FormSubTitle text={
              <Space>
                <span style={{ fontSize: '14px' }}>{t('dashboard.compensations')}</span>
                { workingData.isEditable && <IconButton appearance="default" disabled={showCompensationsLoader} style={{ ...styleIconsHeaders/*, color: '#2a2a2a' */ }} circle icon={<TbReload />} size="xs" onClick={compensationsHandlerReload} /> }
              </Space>}
            />
            { /* COMPENSATIONS SECTION :: table */ }
            { workingData.compensations.length < 1
              ? <Alert key={_.uniqueId('alert-')} type="info" message={t('dashboard.messages.no_compensations_for_this_period')} banner />
              : <LoaderBackDrop active={showCompensationsLoader} loader bordered background>
                  <CompensationSection compensationsDataCalculated={compensationsDataCalculated} compensationDataCalculatedSummary={compensationDataCalculatedSummary} />
                </LoaderBackDrop>
            }
            <Divider style={styleDivider}/>
            { /* *** */ }
            { /* *** */ }
            { /* OTHERS SECTION :: title */ }
            <FormSubTitle text={
              <Space>
                <span style={{ fontSize: '14px' }}>{t('dashboard.others')}</span>
                { workingData.isEditable && <>
                  <IconButton appearance="default" disabled={showDeductionsLoader} style={{ ...styleIconsHeaders/*, color: '#2a2a2a' */ }} circle icon={<FaPlus />} size="xs" onClick={othersConceptsHandlerOnAdd} />
                  |
                  { !otherConceptsCfg.isEditing && <IconButton appearance="default" disabled={showDeductionsLoader || otherConceptsCfg.concepts.length < 1} style={{ ...styleIconsHeaders/*, color: '#2a2a2a' */ }} circle icon={<MdOutlineEdit />} size="xs" onClick={otherConceptsHandlerOnClickEdit} /> }
                  { otherConceptsCfg.isEditing && <IconButton appearance="default" disabled={showDeductionsLoader || otherConceptsCfg.concepts.length < 1} style={{ ...styleIconsHeaders/*, color: '#2a2a2a' */ }} circle icon={<SaveOutlined />} size="xs" onClick={otherConceptsHandlerOnClickSaveEdit} /> }
                  { otherConceptsCfg.isEditing && <IconButton appearance="default" disabled={showDeductionsLoader || otherConceptsCfg.concepts.length < 1} style={{ ...styleIconsHeaders/*, color: '#2a2a2a' */ }} circle icon={<CloseOutlined />} size="xs" onClick={otherConceptsHandlerOnClickCancelEdit} /> }
                </> }
              </Space>}
            />
            { /* OTHERS SECTION :: editable */ }
            { otherConceptsCfg.concepts.length < 1
              ? <Alert key={_.uniqueId('alert-')} type="info" message={t('dashboard.messages.no_added_others')} banner />
              : <LoaderBackDrop active={showDeductionsLoader} loader bordered background>
                  <OtherConceptsSection otherConceptsCfg={otherConceptsCfg}
                                        handlerChange={otherConceptsHandlerOnValueChange}
                                        handlerDelete={otherConceptsHandlerHandlerDelete} summary={conceptsSummary}
                  />
                </LoaderBackDrop>
            }
            <Divider style={styleDivider}/>
            { /* *** */ }
            { /* *** */ }
            { /* SUMMARY SECTION :: editable */ }
            <FormSubTitle text={t('dashboard.summary')} />
            <SummarySection periodsDataCalculatedSummary={periodsDataCalculatedSummary} compensationDataCalculatedSummary={compensationDataCalculatedSummary}
                            otherConceptsSummaryValue={conceptsSummary} />
            <div style={{ paddingBottom: '10px' }}></div>
            { /* button for liquidation */ }
            { action !== 'liquidation.view' && <Row justify="end">
                <Button style={{ marginBottom: '10px' }} disabled={workingData.isEditing || otherConceptsCfg.isEditing} onClick={submitLiquidation} type="primary">
                  {t('dashboard.liquidate')}
                </Button>
              </Row> }
          </LoaderBackDrop>
        </>)
      }
    })()
    }</>)
}

PromotionEnergySupplyLiquidateForm.propTypes = {
  promotionId: PropTypes.number.isRequired,
  onSuccessSubmit: PropTypes.func,
  data: PropTypes.object,
  action: PropTypes.string,
  onLiquidate: PropTypes.func
}
