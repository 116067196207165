import { useTranslation } from 'react-i18next'
import { IconButton, Table } from 'rsuite'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import _ from 'lodash'
import { MdClose, MdOutlineDone, MdElectricBolt } from 'react-icons/md'
import { Col, Row } from 'antd'
import { TbCalendarEvent, TbSolarElectricity } from 'react-icons/tb'
import { EditOutlined } from '@ant-design/icons'
import { DrawerForm } from '../../forms'
import { useParams } from 'react-router-dom'
import { PromotionEnergySourceForm } from '../forms/PromotionEnergySourceForm'
import { sentenceCase } from 'change-case'
import { EmptyResult, ErrorResult, ForbiddenResult, LoaderBackDrop } from '../../feedbacks'
import { useRequestsEvents } from '../../../hooks/useRequestsEvents'
import { PiSolarPanelBold, PiFlameBold } from 'react-icons/pi'
import { LuTrees } from 'react-icons/lu'
import { useEnvironment } from '../../../hooks/useEnvironment'

const { Column, HeaderCell, Cell } = Table
const CompactCell = props => <Cell {...props} style={{ padding: 6 }} />
const CompactHeaderCell = props => <HeaderCell {...props} style={{ padding: 4 }} />
// define locales for this page
const transFiles = ['dashboard', 'fields']

export const PromotionEnergySourceTable = (props) => {
  // for translation
  const { t } = useTranslation(transFiles)
  // get months for a table
  const locale = window.user.user_language_id
  // get methods for requests
  const { getPromotionEnergySource } = useRequestsEvents()
  // get for check permissions
  const { hasPromotionPermissions } = useEnvironment()
  // get current promotion id
  const { promotionId } = useParams()
  // set locale to moments
  moment.locale(locale)
  // get months in use lang
  const months = moment.months()
  // editing status
  const [componentCfg, setComponentCfg] = useState({
    promotionEnergySource: null,
    promotionEnergySourceData: _.get(props, 'data', []),
    isLoaded: true,
    isReloading: false,
    isError: false,
    showDrawer: false,
    isForbidden: false
  }) // permissions
  const hasReadSourcePermissions = hasPromotionPermissions(promotionId, 'read:promotion:energy:sources') && !componentCfg.isForbidden
  // disable flag for component button
  const editButtonIsDisabled = !hasPromotionPermissions(promotionId, 'update:promotion:energy:sources') || componentCfg.isReloading || componentCfg.showDrawer || componentCfg.isError || !componentCfg.isLoaded

  // call to edit
  const handlerOpenEnergeticProfileForm = () => {
    setComponentCfg({ ...componentCfg, showDrawer: true, isEditing: true })
  }

  // call to save energetic profile
  const handlerCloseEnergeticProfileForm = () => {
    setComponentCfg({ ...componentCfg, showDrawer: false, isEditing: false })
  }

  // call to reload component
  const handlerReloadComponent = () => {
    setComponentCfg({ ...componentCfg, isReloading: true, showDrawer: false, isLoaded: false })
  }

  // for load all promotions data when component is mounted
  useEffect(() => {
    // clean up controller --> prevent crash with async function
    let isMounted = true
    // nothing to do if is loaded
    if (componentCfg.isLoaded) { return }
    // if not set source id
    if (!componentCfg.promotionEnergySource) { // is created case
      setComponentCfg({ ...componentCfg, isLoaded: true, isReloading: false, isError: true })
    } else { // reload element
      getPromotionEnergySource(promotionId, componentCfg.promotionEnergySource, (result) => {
        // prevent async crash
        if (!isMounted) return null
        // update component cfg
        setComponentCfg({ ...componentCfg, isLoaded: true, isReloading: false, isError: false, promotionEnergySourceData: [result.data], isForbidden: false })
      }, (err) => {
        // prevent async crash
        if (!isMounted) return null
        if (err === 403) {
          setComponentCfg({ ...componentCfg, isLoaded: true, isReloading: false, isError: false, isForbidden: true })
        } else {
          setComponentCfg({ ...componentCfg, isLoaded: true, isReloading: false, isError: true, isForbidden: false })
        }
      })
    } // prevent crash with async function
    return () => { isMounted = false }
  }, [componentCfg.isLoaded])

  // format data for table
  const tableData = useMemo(() => {
    // if not data set...
    if (_.isEmpty(componentCfg.promotionEnergySourceData)) { return [] }
    // little rename
    const data = componentCfg.promotionEnergySourceData
    // save curren promotion energy source to config
    setComponentCfg({ ...componentCfg, promotionEnergySource: data[0].promotion_energy_source_id })
    // format data
    return data.map((source) => {
      // get is active flag
      source.is_liquidation_active = source.promotion_energy_source_liquidation_is_active === 0 ? <MdClose /> : <MdOutlineDone/>
      // set timestamps
      source.updated = moment(source.updated_at, 'YYYY-MM-DD').locale(locale).format('YYYY.MM.DD')
      source.created = moment(source.created_at, 'YYYY-MM-DD').locale(locale).format('YYYY.MM.DD')
      // for not print id of source
      source.empty = '-'
      // for each month
      for (let i = 0; i < 12; i++) {
        source[`promotion_energy_source_estimated_consumption_month_${i}_string`] = `${source[`promotion_energy_source_estimated_consumption_month_${i}`]} kWh`
      }
      source.promotion_energy_source_estimated_consumption_price_variable_string = `${source.promotion_energy_source_estimated_consumption_price_unit} €/kWh`
      source.promotion_energy_source_estimated_consumption_price_fix_string = `${source.promotion_energy_source_estimated_consumption_price_fix} €`
      source.promotion_energy_source_estimated_consumption_tax_string = `${source.promotion_energy_source_estimated_consumption_tax} %`
      source.promotion_energy_source_custom_efficiency_string = `${source.promotion_energy_source_custom_efficiency * 100} %`
      // return source data
      return source
    })
  }, [componentCfg.promotionEnergySourceData])

  const sourceIcon = useMemo(() => {
    // if not data set...
    if (_.isEmpty(componentCfg.promotionEnergySourceData)) { return [] }
    let icon = <></>
    if (componentCfg.promotionEnergySourceData[0].energy_source_id === 1) {
      icon = <MdElectricBolt size={'18px'}/>
    } else if (componentCfg.promotionEnergySourceData[0].energy_source_id === 5) {
      icon = <PiSolarPanelBold size={'18px'}/>
    } else if (componentCfg.promotionEnergySourceData[0].energy_source_id === 4) {
      icon = <TbSolarElectricity size={'20px'}/>
    } else if (componentCfg.promotionEnergySourceData[0].energy_source_id === 3 || componentCfg.promotionEnergySourceData[0].energy_source_id === 6) {
      icon = <PiFlameBold size={'18px'}/>
    } else if (componentCfg.promotionEnergySourceData[0].energy_source_id === 2) {
      icon = <LuTrees size={'18px'}/>
    }

    return <Col flex="20px">{icon}</Col>
  }, [componentCfg.promotionEnergySourceData])

  return (<>
    {(() => {
      if (!hasReadSourcePermissions) {
        return <ForbiddenResult compact={false} />
      } else if (componentCfg.isError) {
        return <ErrorResult compact={true}/>
      } else { /* return components to render */
        return (<>
          <div className={'rs-between-tables-row-title'}>
            <Row>
              {sourceIcon}
              <Col flex="auto" className={'rs-table-cell-title-text'}>
                {t('dashboard.promotion.data_title_energy_source')}&nbsp;
                <IconButton style={{ marginTop: '-1px' }} disabled={editButtonIsDisabled} circle icon={<EditOutlined/>} size="xs" onClick={handlerOpenEnergeticProfileForm}/>
              </Col>
            </Row>
          </div>
          <LoaderBackDrop loader={!componentCfg.isError} error={componentCfg.isError} active={!componentCfg.isLoaded || componentCfg.isReloading || componentCfg.isError} background={'white'} top>
            <Table hover={false} autoHeight={true} data={tableData} cellBordered rowHeight={30} headerHeight={30}>
              <Column width={50} align="center" fixed>
                <CompactHeaderCell>E. Id</CompactHeaderCell>
                <CompactCell dataKey="promotion_energy_source_id"/>
              </Column>
              <Column width={220} align="left">
                <CompactHeaderCell>{t('dashboard.name')}</CompactHeaderCell>
                <CompactCell dataKey="promotion_energy_source_name"/>
              </Column>
              <Column minWidth={300} flexGrow={1} align="left">
                <CompactHeaderCell>{t('dashboard.description')}</CompactHeaderCell>
                <CompactCell dataKey="promotion_energy_source_desc"/>
              </Column>
              <Column width={90} align="center">
                <CompactHeaderCell>{t('dashboard.efficiency')}</CompactHeaderCell>
                <CompactCell dataKey="promotion_energy_source_custom_efficiency_string"/>
              </Column>
              <Column width={65} align="center">
                <CompactHeaderCell>{t('dashboard.promotion.liquidation_active_column')}</CompactHeaderCell>
                <CompactCell dataKey="is_liquidation_active"/>
              </Column>
              <Column width={90} align={'center'}>
                <CompactHeaderCell>{t('dashboard.updated_at')}</CompactHeaderCell>
                <CompactCell dataKey="updated"/>
              </Column>
              <Column width={90} align={'center'}>
                <CompactHeaderCell>{t('dashboard.created_at')}</CompactHeaderCell>
                <CompactCell dataKey="created"/>
              </Column>
            </Table>
            <div className={'rs-between-tables-row-title'}>
              <Row>
                <Col flex="20px"><TbCalendarEvent size={'18px'}/></Col>
                <Col flex="auto" className={'rs-table-cell-title-text'}>
                  {t('dashboard.energy_profile')}&nbsp;
                </Col>
              </Row>
            </div>
            <Table hover={false} autoHeight={true} data={tableData} cellBordered rowHeight={30} headerHeight={30}>
              <Column width={50} align="center" fixed>
                <CompactHeaderCell>E. Id</CompactHeaderCell>
                <CompactCell dataKey="promotion_energy_source_id"/>
              </Column>
              <Column minWidth={100} flexGrow={1} align="center">
                <CompactHeaderCell>{sentenceCase(months[0])}</CompactHeaderCell>
                <CompactCell dataKey="promotion_energy_source_estimated_consumption_month_0_string"/>
              </Column>
              <Column minWidth={100} flexGrow={1} align="center">
                <CompactHeaderCell>{sentenceCase(months[1])}</CompactHeaderCell>
                <CompactCell dataKey="promotion_energy_source_estimated_consumption_month_1_string"/>
              </Column>
              <Column minWidth={100} flexGrow={1} align="center">
                <CompactHeaderCell>{sentenceCase(months[2])}</CompactHeaderCell>
                <CompactCell dataKey="promotion_energy_source_estimated_consumption_month_2_string"/>
              </Column>
              <Column minWidth={100} flexGrow={1} align="center">
                <CompactHeaderCell>{sentenceCase(months[3])}</CompactHeaderCell>
                <CompactCell dataKey="promotion_energy_source_estimated_consumption_month_3_string"/>
              </Column>
              <Column minWidth={100} flexGrow={1} align="center">
                <CompactHeaderCell>{sentenceCase(months[4])}</CompactHeaderCell>
                <CompactCell dataKey="promotion_energy_source_estimated_consumption_month_4_string"/>
              </Column>
              <Column minWidth={100} flexGrow={1} align="center">
                <CompactHeaderCell>{sentenceCase(months[5])}</CompactHeaderCell>
                <CompactCell dataKey="promotion_energy_source_estimated_consumption_month_5_string"/>
              </Column>
              <Column minWidth={100} flexGrow={1} align="center">
                <CompactHeaderCell>{sentenceCase(months[6])}</CompactHeaderCell>
                <CompactCell dataKey="promotion_energy_source_estimated_consumption_month_6_string"/>
              </Column>
              <Column minWidth={100} flexGrow={1} align="center">
                <CompactHeaderCell>{sentenceCase(months[7])}</CompactHeaderCell>
                <CompactCell dataKey="promotion_energy_source_estimated_consumption_month_7_string"/>
              </Column>
              <Column minWidth={100} flexGrow={1} align="center">
                <CompactHeaderCell>{sentenceCase(months[8])}</CompactHeaderCell>
                <CompactCell dataKey="promotion_energy_source_estimated_consumption_month_8_string"/>
              </Column>
              <Column minWidth={100} flexGrow={1} align="center">
                <CompactHeaderCell>{sentenceCase(months[9])}</CompactHeaderCell>
                <CompactCell dataKey="promotion_energy_source_estimated_consumption_month_9_string"/>
              </Column>
              <Column minWidth={100} flexGrow={1} align="center">
                <CompactHeaderCell>{sentenceCase(months[10])}</CompactHeaderCell>
                <CompactCell dataKey="promotion_energy_source_estimated_consumption_month_10_string"/>
              </Column>
              <Column minWidth={100} flexGrow={1} align="center">
                <CompactHeaderCell>{sentenceCase(months[11])}</CompactHeaderCell>
                <CompactCell dataKey="promotion_energy_source_estimated_consumption_month_11_string"/>
              </Column>
              <Column minWidth={100} flexGrow={1} align="center">
                <CompactHeaderCell>{t('fields:fields.labels.cost_unit')}</CompactHeaderCell>
                <CompactCell dataKey="promotion_energy_source_estimated_consumption_price_variable_string"/>
              </Column>
              <Column width={70} align="center">
                <CompactHeaderCell>{t('fields:fields.labels.cost_fix')}</CompactHeaderCell>
                <CompactCell dataKey="promotion_energy_source_estimated_consumption_price_fix_string"/>
              </Column>
              <Column width={60} align="center">
                <CompactHeaderCell>{t('fields:fields.labels.tax')}</CompactHeaderCell>
                <CompactCell dataKey="promotion_energy_source_estimated_consumption_tax_string"/>
              </Column>
            </Table>
          </LoaderBackDrop>
          { /* drawer */}
          {componentCfg.showDrawer &&
            <DrawerForm destroyOnClose={true} title={'dashboard.promotion.data_title_edit_energy_source'} open={componentCfg.showDrawer}
                        extra={<TbCalendarEvent size={25}/>} onClose={handlerCloseEnergeticProfileForm}
                        content={<PromotionEnergySourceForm promotionId={parseInt(promotionId)} sourceId={parseInt(componentCfg.promotionEnergySource)} onSuccessSubmit={handlerReloadComponent}/>}
            />
          }
        </>)
      }
      })()}
  </>)
}

PromotionEnergySourceTable.propTypes = {
  data: PropTypes.array.isRequired
}
