import React, { useEffect, useMemo, useState } from 'react'
import { Pagination, Table } from 'rsuite'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useRequestsEvents } from '../../../hooks/useRequestsEvents'
import _ from 'lodash'
import { ErrorResult, ForbiddenResult, LoaderBackDrop } from '../../feedbacks'
import { useEnvironment } from '../../../hooks/useEnvironment'
import { useParams } from 'react-router-dom'
import { Button } from 'antd'
import { IoMdRefresh } from 'react-icons/io'
import { getPaginationText } from '../../../helpers/paginations'
import { setCompensationsInterfaceData, setCompensationsSummaryInterfaceData, setInterfaceDataToCalculatedPeriod, setInterfaceDataToTotalCalculatedPeriod } from '../../../helpers/liquidations'

const { getCompensationData } = window.helpersLiquidations
const { Column, HeaderCell, Cell } = Table
const CompactCell = props => <Cell {...props} style={{ padding: 6 }} />
const CompactHeaderCell = props => <HeaderCell {...props} style={{ padding: 4 }} />

// define locales for this page
const transFiles = ['dashboard', 'fields']

export const PromotionEnergySupplyLiquidationCompensationPeriodsTable = (props) => {
  // for translation
  const { t } = useTranslation(transFiles)
  // get current promotion id
  const { promotionId } = useParams()
  // get methods to send data
  const { getPromotionEnergySourceLiquidationPendingCompensationPeriods } = useRequestsEvents()
  // get for check permissions
  const { hasPromotionPermissions } = useEnvironment()
  // get data from props
  const { sourceId, isCalledToReload, propagateIsLoaded } = props
  // component config
  const [componentCfg, setComponentCfg] = useState({
    compensable: [],
    isLoaded: false,
    isChangingPage: false,
    isReloading: false,
    isError: false,
    isForbidden: false,
    // pagination config
    page: 1,
    total: 0,
    offset: 0,
    limit: 25
  }) // get permissions
  const hasReadEnergySupplyLiquidationPeriodsPromotionPermissions = hasPromotionPermissions(promotionId, 'read:promotion:energy:liquidation:periods') && !componentCfg.isForbidden

  // reload component data
  const handlerReloadComponent = () => {
    // call to reload from segmentation
    setComponentCfg({ ...componentCfg, isError: false, isLoaded: false, isReloading: true })
  }

  // on change page
  const handlerChangePage = (page, skipIsLoading = false) => {
    // not change if component is not loaded....
    if (!componentCfg.isLoaded && !skipIsLoading) { return }
    // set to reload a component
    setComponentCfg({ ...componentCfg, page, offset: (componentCfg.limit * page) - componentCfg.limit, isError: false, isLoaded: false, isChangingPage: true })
  }

  const handlerPageReload = (skipIsLoading = false) => {
    // not change if component is not loaded....
    if (!componentCfg.isLoaded && !skipIsLoading) { return }
    // set to reload a component
    setComponentCfg({ ...componentCfg, isError: false, isLoaded: false, isChangingPage: true })
  }

  // for reload from parent
  useEffect(() => {
    // only when component is loaded and parent refresh is true
    if (!componentCfg.isLoaded || !isCalledToReload) { return }
    // call to reload from segmentation
    handlerReloadComponent()
  }, [isCalledToReload])

  // for load when source id changes...
  useEffect(() => {
    // only when component is loaded...
    if (!componentCfg.isLoaded) { return }
    // call to reload from segmentation
    handlerReloadComponent()
  }, [sourceId])

  // loading component from first load:
  // - only if set source id and is not loading
  useEffect(() => {
    // clean up controller --> prevent crash with async function
    let isMounted = true
    // set observers
    propagateIsLoaded(false)
    // not reload if component is loaded...
    if (componentCfg.isLoaded) { return }
    // if not has permissions
    if (!hasReadEnergySupplyLiquidationPeriodsPromotionPermissions) {
      setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, compensable: [], total: 0 })
    } else {
      // get data promotion energy sources
      getPromotionEnergySourceLiquidationPendingCompensationPeriods(promotionId, sourceId, (result) => {
        // prevent async crash
        if (!isMounted) return null
        // fix delete last item on a page
        if (_.isEmpty(result.data) && result.pagination.total_records > 0) {
          // call to load previous page
          handlerChangePage(result.pagination.total_pages, true)
        } else {
          // set data to config
          setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, isChangingPage: false, isReloading: false, compensable: result.data, total: result.pagination.total_records, isForbidden: false })
        }
      }, (err) => {
        // prevent async crash
        if (!isMounted) return null
        if (err === 403) {
          setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, isChangingPage: false, isReloading: false, compensable: [], total: 0, isForbidden: true })
        } else {
          setComponentCfg({ ...componentCfg, isError: true, isLoaded: true, isChangingPage: false, isReloading: false, compensable: [], total: 0, isForbidden: false })
        }
      }, componentCfg.limit, componentCfg.offset)
    } // prevent crash with async function
    return () => { isMounted = false }
  }, [componentCfg.isLoaded, componentCfg.page])

  // format data for table
  const dataTable = useMemo(() => {
    // getCompensationData -> data, index, empty result, is component loaded required
    const { compensationsDataCalculated } = getCompensationData(componentCfg, 'compensable', true, true, setCompensationsInterfaceData, setCompensationsSummaryInterfaceData, setInterfaceDataToCalculatedPeriod, setInterfaceDataToTotalCalculatedPeriod)
    // to save a data table
    const dataTable = []
    // for each calculated data...
    _.each(compensationsDataCalculated, ({ estimatedData }) => {
      // get compensation data
      _.each(estimatedData, (compensation) => { dataTable.push(compensation) })
    })
    // return data table
    return dataTable
  }, [componentCfg.compensable])

  const paginationRangeText = useMemo(() => {
    return getPaginationText(componentCfg)
  }, [componentCfg.page, componentCfg.limit, componentCfg.total])

  const showComponentLoader = !componentCfg.isLoaded && !componentCfg.isChangingPage && !componentCfg.isReloading
  const showTableLoader = !componentCfg.isLoaded || componentCfg.isChangingPage || componentCfg.isReloading

  return (<>
    <LoaderBackDrop active={showComponentLoader} loader background>
      {(() => {
        if (!hasReadEnergySupplyLiquidationPeriodsPromotionPermissions) {
          return <ForbiddenResult compact={true} />
        } else if (componentCfg.isError) {
          return <ErrorResult compact={true} />
        } else { /* return components to render */
          return (<>
            <Table loading={showTableLoader} hover={false} fillHeight={false} autoHeight={false} height={262} data={dataTable} cellBordered rowHeight={30} headerHeight={30}>
              <Column width={115} align={'right'}>
                <CompactHeaderCell>{t('dashboard.date')}</CompactHeaderCell>
                <CompactCell dataKey={'created'} />
              </Column>
              <Column width={180} align={'center'}>
                <CompactHeaderCell>{t('dashboard.period')}</CompactHeaderCell>
                <CompactCell dataKey={'period_full_date'} />
              </Column>
              <Column width={45} align={'center'}>
                <CompactHeaderCell>{t('dashboard.days')}</CompactHeaderCell>
                <CompactCell dataKey={'period_days'} />
              </Column>
              <Column width={200} align={'center'}>
                <CompactHeaderCell>{t('dashboard.reference')}</CompactHeaderCell>
                <CompactCell dataKey={'period_id'} />
              </Column>
              <Column width={205} align={'right'}>
                <CompactHeaderCell>{t('fields:fields.labels.consumption')}</CompactHeaderCell>
                <CompactCell dataKey={'period_consumption'} />
              </Column>
              <Column width={100} align={'right'}>
                <CompactHeaderCell>{t('fields:fields.labels.cost_variable')}</CompactHeaderCell>
                <CompactCell dataKey={'period_cost_variable'} />
              </Column>
              <Column width={100} align={'right'}>
                <CompactHeaderCell>{t('fields:fields.labels.cost_fix')}</CompactHeaderCell>
                <CompactCell dataKey={'period_cost_fix'} />
              </Column>
              <Column width={100} align={'right'}>
                <CompactHeaderCell>{t('fields:fields.labels.tax')} %</CompactHeaderCell>
                <CompactCell dataKey={'period_cost_tax_percent'} />
              </Column>
              <Column width={100} align={'right'}>
                <CompactHeaderCell>{t('fields:fields.labels.tax')} €</CompactHeaderCell>
                <CompactCell dataKey={'period_cost_tax_eur'} />
              </Column>
              <Column minWidth={150} flexGrow={1} align={'right'}>
                <CompactHeaderCell>{t('fields:fields.labels.total')}</CompactHeaderCell>
                <CompactCell dataKey={'period_total'} />
              </Column>
              <Column width={90} align={'center'}>
                <CompactHeaderCell>{t('dashboard.created_at')}</CompactHeaderCell>
                <CompactCell dataKey="created" />
              </Column>
            </Table>
            { /* pagination */ }
            <div style={{ padding: '5px 10px', backgroundColor: '#fafafa', borderTop: '1px #f2f2f5 solid' }}>
              <Pagination layout={
                  [paginationRangeText, '-', 'pager', <Button key={_.uniqueId()} onClick={handlerPageReload} type="text" shape="circle" icon={<IoMdRefresh size={18} style={{ marginTop: '2px' }}/>} size={'small'}/>]
                }
                size={'xs'} prev={true} next={true} first={true} last={true}
                maxButtons={5} boundaryLinks={true} ellipsis={true} limit={componentCfg.limit}
                total={componentCfg.total} activePage={componentCfg.page} onChangePage={handlerChangePage}
              />
            </div>
          </>)
        }
      })()}
    </LoaderBackDrop>
  </>)
}

PromotionEnergySupplyLiquidationCompensationPeriodsTable.propTypes = {
  sourceId: PropTypes.number.isRequired,
  reload: PropTypes.bool,
  isCalledToReload: PropTypes.bool,
  propagateIsLoaded: PropTypes.func
}
