// for print person information
import { Panel } from 'rsuite'
import PropTypes from 'prop-types'
import React from 'react'
import { BlockGeneralDataTable } from './tables/BlockGeneralDataTable'

export const BlockGeneralPanel = (props) => {
  // get data to set
  const { data } = props

  return (<>
    <Panel className={'filled-table-panel'} style={{ borderRadius: '4px' }} bordered bodyFill>
      <BlockGeneralDataTable data={data} />
    </Panel>
  </>)
}

BlockGeneralPanel.propTypes = {
  data: PropTypes.object.isRequired
}
