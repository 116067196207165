// diver with central element
import React from 'react'
import { Result } from 'antd'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Loader } from 'rsuite'
import ViewsUnauthorizeIcon from '@rsuite/icons/ViewsUnauthorize'
import InfoOutlineIcon from '@rsuite/icons/InfoOutline'
import StorageIcon from '@rsuite/icons/Storage'
import DashboardIcon from '@rsuite/icons/Dashboard'
import { TbFaceIdError } from 'react-icons/tb'

// define locales for this page
const transFiles = ['dashboard']

const feedBackIconStyle = {
  color: '#8080802e',
  fontSize: '50px'
}

const feedBackTextStyle = {
  ...feedBackIconStyle,
  fontSize: '20px'
}

const feedBackSubTextStyle = {
  ...feedBackIconStyle,
  fontSize: '15px'
}

const ResultLayout = function (props) {
  const { t } = useTranslation(transFiles)
  const { compact, title, icon, subTitle } = props

  return (
    <div className={'dashboard-feedback'}>
      <Result
        icon={compact ? null : icon}
        title={<span style={compact ? { ...feedBackTextStyle, fontSize: '17px' } : feedBackTextStyle}>{t(title)}</span>}
        subTitle={<span style={feedBackSubTextStyle}>{t(subTitle)}</span>}
      />
    </div>
  )
}

ResultLayout.propTypes = {
  compact: PropTypes.bool,
  icon: PropTypes.node,
  title: PropTypes.string,
  subTitle: PropTypes.string
}

export function EmptyResult (props) {
  return (<ResultLayout {...props} icon={<StorageIcon style={feedBackIconStyle} />} title={'dashboard.no_data'} />)
}

export function ForbiddenResult (props) {
  return (<ResultLayout {...props} icon={<ViewsUnauthorizeIcon style={feedBackIconStyle} />} title={'dashboard.not_authorized'} />)
}

export function ErrorResult (props) {
  // for translation
  return (<ResultLayout {...props} icon={<InfoOutlineIcon style={feedBackIconStyle} />} title={'Error'} subTitle={'dashboard.not_controlled_case'} />)
}

ErrorResult.propTypes = {
  isTableError: PropTypes.bool,
  height: PropTypes.number
}

export function SelectResult (props) {
  // get form parent to prevent multiple clicks on menu
  const { text } = props
  return (<ResultLayout {...props} icon={<DashboardIcon style={feedBackIconStyle} />} title={text} />)
}

SelectResult.propTypes = {
  text: PropTypes.string.isRequired
}

export function LoaderBackDrop (props) {
  // get props
  const { active, children, loader, bordered, background, top, height, error } = props

  const borderCls = !bordered ? 'rs-back-drop-container-no-border' : ''
  const backgroundCls = !background ? 'rs-back-drop-container-no-background' : `rs-back-drop-container-background-${background}`
  const loaderCls = loader ? '' : 'rs-back-drop-container-without-loader'
  const topCls = top ? 'rs-back-drop-container-top' : ''
  const activeCls = active ? 'rs-back-drop-container-active' : ''
  const heightStyle = { height } || { }

  return (
    <div className='rs-back-dop-container-custom' style={{ position: 'relative', height: '100%' }}>
      <div className={`${activeCls} ${borderCls} ${backgroundCls} ${loaderCls} ${topCls}`} style={{ ...heightStyle }}>
        {children}
        { active && <Loader backdrop content={error ? <TbFaceIdError size={25}/> : '' } /> }
      </div>
    </div>
  )
}

LoaderBackDrop.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  top: PropTypes.bool,
  error: PropTypes.bool,
  bordered: PropTypes.bool,
  background: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  loader: PropTypes.bool,
  height: PropTypes.string
}
