import React, { useEffect, useMemo, useState } from 'react'
import { Panel } from 'rsuite'
import { Segmented, Space } from 'antd'
import _ from 'lodash'
import { EmptyResult, ErrorResult, ForbiddenResult, LoaderBackDrop } from 'components/feedbacks'
import { PromotionEnergySupplyAnalysisLiquidationTable } from './tables/PromotionEnergySupplyAnalysisLiquidationTable'
import { PanelHeader } from '../panels'
import { AiOutlineCalendar } from 'react-icons/ai'
import { useRequestsEvents } from '../../hooks/useRequestsEvents'
import { useEnvironment } from '../../hooks/useEnvironment'
import { useParams } from 'react-router-dom'
import useDidMountEffect from '../../hooks/useDidMountEffect'
import { DrawerForm } from '../forms'
import { InvoicesIcon } from '../icons'
import { PromotionEnergySupplyLiquidateForm } from './forms/PromotionEnergySupplyLiquidateForm'
import { useTranslation } from 'react-i18next'

// create segment on options when segment is rendered
const getSegmentOptions = (data) => {
  return data.map((src) => {
    let name = `(${src.promotion_energy_source_id}) ${src.promotion_energy_source_name}`
    // to display text
    if (src.promotion_energy_source_desc) {
      name = `${name} - ${src.promotion_energy_source_desc}`
    }
    return { label: name, value: _.get(src, 'promotion_energy_source_id', '') }
  })
}

export const PromotionEnergySupplyAnalysisLiquidationPanel = (props) => {
  // for translation
  const { t } = useTranslation(['dashboard', 'fields'])
  // get current promotion id
  const { promotionId } = useParams()
  // get methods to send data
  const { getPromotionEnergySourcesWithLiquidationPeriods, updatePromotionEnergySupplyLiquidationPeriodicity, deleteEnergySourceLiquidatedPeriod } = useRequestsEvents()
  // get for check permissions
  const { hasPromotionPermissions } = useEnvironment()
  // component config
  const [componentCfg, setComponentCfg] = useState({
    sources: [],
    periods: [],
    sourceSelected: {},
    defaultMenuSelectedId: null,
    menuSelectedId: null,
    isLoaded: false,
    isReloading: false,
    isError: false,
    isForbidden: false,
    period: 0,
    // for liquidate a period via form
    liquidateEditableTableShow: false,
    liquidateEditableTableData: undefined,
    liquidateEditableTableAction: undefined
  }) // permissions
  const hasReadEnergySupplyLiquidationPeriodsPromotionPermissions = hasPromotionPermissions(promotionId, 'read:promotion:energy:liquidation:periods') && !componentCfg.isForbidden

  // flag to disable element
  const disabled = !componentCfg.isLoaded || componentCfg.isReloading

  const handlerChangeReferenceDate = (action) => {
    // not change if component is not loaded....
    if (!componentCfg.isLoaded) { return }
    // to move next
    if (action === '+') {
      // set new selected id
      setComponentCfg({ ...componentCfg, period: componentCfg.period + 1, isLoaded: false, isReloading: true })
    // to move previous
    } else if (action === '-') {
      // set new selected id
      setComponentCfg({ ...componentCfg, period: componentCfg.period - 1, isLoaded: false, isReloading: true })
    }
  }

  const handlerChangePeriodicity = (sourceId, values, callback = () => {}) => {
    // not change if component is not loaded....
    if (!componentCfg.isLoaded) { return }
    // set is reloading component
    setComponentCfg({ ...componentCfg, isReloading: true })
    // get data promotion energy sources
    updatePromotionEnergySupplyLiquidationPeriodicity(promotionId, sourceId, values, (result) => {
      // call to callback
      callback()
      // reload component
      handlerReloadComponent()
    }, () => {
      // set is reloading component
      setComponentCfg({ ...componentCfg, isReloading: false })
    })
  }

  // on change segmentation
  const handlerChangeSelection = (id) => {
    // not change if component is not loaded....
    if (!componentCfg.isLoaded) { return }
    // set new selected id
    setComponentCfg({ ...componentCfg, menuSelectedId: id })
  }

  // reload all component (segmentation and table)
  const handlerReloadComponent = () => {
    // not reload if component is loaded...
    if (!componentCfg.isLoaded) { return }
    // not reload if component is not loaded
    setComponentCfg({ ...componentCfg, isError: false, isLoaded: false })
  }

  // when close liquidate form
  const handlerDrawerClose = () => {
    // not reload if component is loaded...
    if (!componentCfg.isLoaded) { return }
    // close form
    setComponentCfg({ ...componentCfg, liquidateEditableTableShow: false, liquidateEditableTableAction: undefined, liquidateEditableTableData: undefined })
  }

  // when go to liquidate form
  const handlerLiquidate = (liquidationId, liquidation, action = 'create') => {
    // not reload if component is loaded...
    if (!componentCfg.isLoaded) { return }
    // show form
    setComponentCfg({ ...componentCfg, liquidateEditableTableShow: true, liquidateEditableTableAction: action, liquidateEditableTableData: liquidation })
  }

  // when delete liquidation
  const handlerDeleteLiquidation = (liquidationId) => {
    // not reload if component is loaded...
    if (!componentCfg.isLoaded) { return }
    // clean up controller --> prevent crash with async function
    const isMounted = true
    // request to delete invoice
    deleteEnergySourceLiquidatedPeriod(promotionId, componentCfg.sourceSelected.promotion_energy_source_id, liquidationId, () => {
      if (!isMounted) return null
      handlerReloadComponent()
    }, () => {
      if (!isMounted) return null
      setComponentCfg({ ...componentCfg, isError: true, isLoaded: true, isReloading: false })
    })
  }

  const handlerOnLiquidate = () => {
    // not reload if component is loaded...
    if (!componentCfg.isLoaded) { return }
    // close form
    setComponentCfg({ ...componentCfg, liquidateEditableTableShow: false, liquidateEditableTableAction: undefined, liquidateEditableTableData: undefined, isError: false, isLoaded: false })
  }

  // method to change invoices to show
  useDidMountEffect(() => {
    // not reload if component is loaded...
    if (!componentCfg.isLoaded) { return }
    // get source invoices
    const sourceSelected = _.find(componentCfg.sources, { promotion_energy_source_id: componentCfg.menuSelectedId })
    const sourcePeriods = _.get(sourceSelected, 'promotion_energy_source_liquidation_periods', [])
    // load invoices
    setComponentCfg({ ...componentCfg, periods: sourcePeriods, sourceSelected })
  }, [componentCfg.sources, componentCfg.menuSelectedId])

  // loading component from first load
  useEffect(() => {
    // clean up controller --> prevent crash with async function
    let isMounted = true
    // not reload if component is loaded...
    if (componentCfg.isLoaded) { return }
    // if not has permissions
    if (!hasReadEnergySupplyLiquidationPeriodsPromotionPermissions) {
      setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, sources: [], periods: [], sourceSelected: {} })
    } else {
      // get data promotion energy sources
      getPromotionEnergySourcesWithLiquidationPeriods(promotionId, componentCfg.period, (result) => {
        // prevent async crash
        if (!isMounted) return null
        // get default source
        const defaultMenuSelectedId = componentCfg.defaultMenuSelectedId || _.get(result.data, '[0].promotion_energy_source_id', 0)
        const menuSelectedId = componentCfg.menuSelectedId || defaultMenuSelectedId
        // set data to config
        setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, isReloading: false, sources: result.data, defaultMenuSelectedId, menuSelectedId, isForbidden: false })
      }, (err) => {
        // prevent async crash
        if (!isMounted) return null
        if (err === 403) {
          setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, isReloading: false, sources: [], periods: [], isForbidden: true })
        } else {
          setComponentCfg({ ...componentCfg, isError: true, isLoaded: true, isReloading: false, sources: [], periods: [], isForbidden: false })
        }
      })
    } // prevent crash with async function
    return () => { isMounted = false }
  }, [componentCfg.isLoaded])

  // create a source tables
  const periodsTablesPanel = useMemo(() => {
    // return a component
    return (
      <Panel className={'filled-table-panel'} style={{ borderRadius: '4px' }} bordered bodyFill>
        <PromotionEnergySupplyAnalysisLiquidationTable
          isCalledToReload={componentCfg.isReloading} handlerChangeReferenceDate={handlerChangeReferenceDate}
          handlerChangePeriodicity={handlerChangePeriodicity}
          handlerLiquidate={handlerLiquidate} handlerDeleteLiquidation={handlerDeleteLiquidation}
          data={{ source: componentCfg.sourceSelected, periods: componentCfg.periods }} />
      </Panel>
    )
  }, [componentCfg.periods, componentCfg.sourceSelected, componentCfg.isReloading])

  // to set border to first panel
  const isPanelBordered = (!hasReadEnergySupplyLiquidationPeriodsPromotionPermissions || !componentCfg.isLoaded || componentCfg.isError || _.isEmpty(componentCfg.sources)) && !componentCfg.isReloading

  return (<>
    <Panel bodyFill header={ /* Panel header */
      <PanelHeader icon={<AiOutlineCalendar />} title={'dashboard.promotion.data_title_review_and_liquidation_periods'}
        /* create permissions and config */
        hasCreate={false}
        /* edit permissions and config */
        hasEdit={false}
        /* delete permissions and config */
        hasDelete={false}
        /* for reload data */
        hasReload={true}
        /* set reload config data */
        hasReloadConfig={{
          method: handlerReloadComponent,
          isDisabled: disabled
        }}
      />
    }>
      <LoaderBackDrop active={!componentCfg.isLoaded && !componentCfg.isReloading} loader background bordered>
        <Panel className={'filled-table-panel'} style={{ borderRadius: '4px' }} bordered={isPanelBordered} bodyFill>
          {(() => {
            if (!hasReadEnergySupplyLiquidationPeriodsPromotionPermissions) {
              return <ForbiddenResult compact={true} />
            } else if (componentCfg.isError) {
              return <ErrorResult compact={true} />
            } else if (_.isEmpty(componentCfg.sources)) {
              return <EmptyResult compact={true} />
            } else { /* return components to render */
              return (<>
                <Space className="block-information" direction="vertical" size="middle" style={{ display: 'flex' }}>
                  <Segmented defaultValue={componentCfg.defaultMenuSelectedId} value={componentCfg.menuSelectedId} onChange={handlerChangeSelection} options={getSegmentOptions(componentCfg.sources)} />
                  <div>{ periodsTablesPanel }</div>
                  { /* drawer */ }
                  { componentCfg.liquidateEditableTableShow &&
                    <DrawerForm width={1065} destroyOnClose={true} title={`${componentCfg.liquidateEditableTableAction === 'liquidation.view' ? `${t('dashboard.promotion.data_title_view_liquidation')}: ${componentCfg.liquidateEditableTableData.liquidation_id}` : t('dashboard.promotion.data_title_create_liquidation')}`} open={componentCfg.liquidateEditableTableShow}
                      content={<PromotionEnergySupplyLiquidateForm onLiquidate={handlerOnLiquidate} promotionId={parseInt(promotionId)} data={componentCfg.liquidateEditableTableData} action={componentCfg.liquidateEditableTableAction} /> }
                      extra={<InvoicesIcon size={25} />} onClose={handlerDrawerClose} />
                  }
                </Space>
              </>)
            }
          })()}
        </Panel>
      </LoaderBackDrop>
    </Panel>
  </>)
}
