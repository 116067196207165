import _, { uniqueId } from 'lodash'
import React, { useMemo, useState } from 'react'
import { Col, Row, Typography } from 'antd'
import PropTypes, { array, object } from 'prop-types'
import { useTranslation } from 'react-i18next'
import Dragger from 'antd/es/upload/Dragger'
import { BsFiletypeJson } from 'react-icons/bs'
import { useRequestsEvents } from '../../../hooks/useRequestsEvents'
import { Panel, Table } from 'rsuite'
import { BiMessageAltX, BiMessageAltCheck } from 'react-icons/bi'
import { DrawerForm } from '../../forms'
import GearIcon from '@rsuite/icons/Gear'

const { Title } = Typography
const { Column, HeaderCell, Cell } = Table
const CompactCell = props => <Cell {...props} style={{ padding: 6 }} />
const CompactHeaderCell = props => <HeaderCell {...props} style={{ padding: 4 }} />

// for show errors table
const ResultsTable = (props) => {
  // get data to print
  const { messages } = props.data
  // get messages
  const errors = _.get(messages, 'errors', [])
  const success = _.get(messages, 'success', [])
  // return table to render
  return (<> { /* show title for success messages */}
    { errors && errors.length > 0 && <Title style={{ marginBottom: '15px' }} level={5}>{'Errors'}</Title> }
    { <span style={{ marginBottom: '15px' }}> {/* and data to show */
      _.map(errors, (data) => {
        // for render # in bold
        const message = data.message.split('#')
        return (
          <Row key={uniqueId()} style={{
            marginBottom: '10px',
            wordBreak: 'break-all'
          }}>
            <Col span={2} style={{ fontSize: '12px' }}>{data.code}</Col>
            <Col span={22} style={{ fontSize: '12px' }}>{
              message.map((parte, index) => (
                <React.Fragment key={uniqueId()}>
                  {index < message.length - 1 ? <>{parte} <b>#</b></> : parte}
                </React.Fragment>
              ))

            }</Col>
          </Row>)
      })
    } </span> } { /* show title for success messages */}
  { success && success.length > 0 && <Title style={{ marginBottom: '15px' }} level={5}>Success</Title> }
  { <span style={{ marginBottom: '15px' }}> { success && /* and data to show */
    _.map(success, (data) => {
      // HOW TO SET BOLD?? ARRAY MAP BEFORE SETTINGS OBJECTS?
      data.message = data.message.replace('#', <><b>#</b></>)
      return (
        <Row key={uniqueId()} style={{ marginBottom: '10px', wordBreak: 'break-all' }}>
          <Col span={2} style={{ fontSize: '12px' }}>{data.code}</Col>
          <Col span={22} style={{ fontSize: '12px' }}>{data.message}</Col>
        </Row>)
    })
   } </span> } </>)
}

ResultsTable.propTypes = {
  data: object.isRequired
}

const UploadsFilesTable = (props) => {
  // for translation
  const { t } = useTranslation(['dashboard'])
  // return table to render
  return (<>
    <Panel className={'filled-table-panel'} style={{ borderRadius: '4px', marginTop: '10px' }} bordered={true} bodyFill>
      <Table hover={false} autoHeight={true} data={props.data} cellBordered={true} rowHeight={30} headerHeight={30}>
        <Column width={28} align="left">
          <CompactHeaderCell></CompactHeaderCell>
          <CompactCell dataKey="progress_component" />
        </Column>
        <Column flexGrow={1} align="left">
          <CompactHeaderCell>{t('dashboard.file')}</CompactHeaderCell>
          <CompactCell dataKey="name" />
        </Column>
      </Table>
    </Panel>
  </>)
}

UploadsFilesTable.propTypes = {
  data: array.isRequired
}

export const UploadDataPanel = (/* props */) => {
  // for translation
  const { t } = useTranslation(['dashboard'])
  // for get promotions
  const { uploadFile } = useRequestsEvents()
  // upload progress
  const [componentCfg, setComponentCfg] = useState({
    uploading: false,
    filesList: []
  })
  // for display errors
  const [errors, setErrors] = useState({
    show: false,
    title: undefined,
    component: <></>
  })

  const handlerUpdateProgress = (file, value) => {
    // set progress to file
    file.progress = value
    // set progress tu current file
    setComponentCfg({ ...componentCfg, filesList: _.clone(componentCfg.filesList) })
  }

  // create a source tables
  const draggerProps = {
    name: 'file',
    multiple: true,
    method: 'post',
    accept: 'application/json',
    itemRender: false,
    showUploadList: false,
    withCredentials: true,
    customRequest: (options) => {
      // get options to pass request
      const { onSuccess, onError, file, onProgress } = options
      // caller to upload a file
      return uploadFile(file, onSuccess, onError, onProgress, handlerUpdateProgress)
    },
    onChange (info) {
      // get status of file
      const { status } = info.file
      // if status is uploading
      if (status === 'uploading') { return }
      // get file
      const file = _.find(componentCfg.filesList, function (o) { return o.uid === info.file.uid }) || {}
      // set status to file
      if (status === 'done') {
        file.status = 'done'
      } else if (status === 'error') {
        file.status = 'error'
      } // set progress tu current file
      file.messages = info.file.response.data.messages
      setComponentCfg({ ...componentCfg, filesList: _.clone(componentCfg.filesList) })
    },
    onDrop (e) { /* console.log('Dropped files', e.dataTransfer.files) */ },
    beforeUpload (file, fileList) {
      // set file progress uploading
      file.progress = 0
      // save to files uploading to render list
      setComponentCfg({ ...componentCfg, filesList: fileList })
    }
  }

  // show drawer with errors
  const handlerHideErrors = () => {
    setErrors({ ...errors, show: false, errors: [] })
  }

  const handlerShowErrors = (file) => {
    // show drawer with errors
    setErrors({ ...errors, show: true, title: file.name, component: <ResultsTable key={uniqueId()} data={file} /> })
  }

  // format cells of table data
  const tableData = useMemo(() => {
    // for each field list create your date to display
    return _.map(componentCfg.filesList, (file) => {
      if (file.status === 'error') {
        file.progress_component = <BiMessageAltX style={{ cursor: 'pointer' }} color={'#620000'} size={20} onClick={() => { handlerShowErrors(file) }}/>
      } else if (file.status === 'done') {
        file.progress_component = <BiMessageAltCheck style={{ cursor: 'pointer' }} color={'#006633'} size={20} onClick={() => { handlerShowErrors(file) }}/>
      } else {
        file.progress_component = <GearIcon spin style={{ fontSize: '1.4em' }} />
      } // return file
      return file
    })
  }, [componentCfg.filesList])

  return (<>
    <Dragger {...draggerProps} height={300}>
      <p className="ant-upload-drag-icon">
        <BsFiletypeJson size={60} />
      </p>
      <p className="ant-upload-text">{t('dashboard.upload_text_dragger')}</p>
    </Dragger>
    { /* print table with summary data */ }
    { componentCfg.filesList.length > 0 && <>
      <UploadsFilesTable data={tableData} />
      { errors.show &&
        <DrawerForm width={700} destroyOnClose={true} title={errors.title} open={errors.show}
                    content={errors.component}
                    extra={<BsFiletypeJson size={25} />} onClose={handlerHideErrors} />
      }
    </> }
  </>)
}

UploadDataPanel.propTypes = {
  onSuccessSubmit: PropTypes.func
}
