import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Space } from 'antd'
import PropTypes from 'prop-types'
import {
  ButtonFormReset,
  ButtonFormSubmit,
  FormAlerts,
  ApplicationTypesField,
  DescriptionSmallField,
  YesNoField, MonthDayField
} from '../../forms'
import { useTranslation } from 'react-i18next'
import { useRequestsEvents } from '../../../hooks/useRequestsEvents'
import dayjs from 'dayjs'
import _ from 'lodash'

// set field labels for print errors from API
const fieldsConfig = {
  energy_application_id: {
    label: 'fields.labels.application',
    name: 'energy_application_id'
  },
  promotion_energy_application_desc: {
    label: 'fields.labels.description',
    name: 'promotion_energy_application_desc'
  },
  promotion_energy_application_start: {
    label: 'fields.labels.start',
    name: 'promotion_energy_application_start'
  },
  promotion_energy_application_end: {
    label: 'fields.labels.end',
    name: 'promotion_energy_application_end'
  },
  promotion_energy_application_is_active: {
    label: 'fields.labels.is_active_f',
    name: 'promotion_energy_application_is_active'
  }
}

const initValues = { }

export const PromotionEnergySourceApplicationForm = (props) => {
  // for translation
  const { t } = useTranslation(['dashboard'])
  // for re-rerender form when change locale
  const [form] = Form.useForm()
  // for validate on input change after first submit
  const [validateOnChange, setValidateOnChange] = useState([])
  const [validateHasFeedback, setValidateHasFeedback] = useState(false)
  // get props data
  const { promotionId, sourceId, applicationId, onSuccessSubmit } = props
  // get methods to send data
  const { updatePromotionEnergySourceApplication, getPromotionEnergySourceApplication } = useRequestsEvents()
  // component config
  const [componentCfg, setComponentCfg] = useState({
    isLoaded: false,
    isSubmitting: false,
    errors: [],
    defaultValues: { },
    renderPeriod: false,
    applicationTypeDisabled: _.isEmpty(applicationId)
  })
  // flag for buttons disable
  const areButtonsDisabled = !componentCfg.isLoaded || componentCfg.isSubmitting
  // for data range picker to get a days in period
  // use application periods
  // const applicationPeriod = Form.useWatch(fieldsConfig.promotion_energy_application_start.name, form)
  // use application type selected
  const applicationTypeId = Form.useWatch(fieldsConfig.energy_application_id.name, form)

  // change validateOnChange when finish is not successfully
  const handlerFinishFailed = () => {
    setValidateOnChange('onChange')
    setValidateHasFeedback(true)
  }

  // reset form function
  const handlerResetForm = () => {
    // reset fields
    form.resetFields()
    // update config in function of caller of this function
    setComponentCfg({ ...componentCfg, isSubmitting: false, isLoaded: true, errors: [] })
  }

  // call to log in when finish successfully
  const handlerFinish = (values) => {
    // update component config
    setComponentCfg({ ...componentCfg, isSubmitting: true })
    // set values to send
    const valuesToSend = { ...values } // delete application period
    delete valuesToSend.application_period
    // if not edit case...
    if (!applicationId) { return }
    // delete energy application id (not editable, only create)
    delete valuesToSend.energy_application_id
    // update application
    updatePromotionEnergySourceApplication(promotionId, sourceId, applicationId, valuesToSend, () => {
      setComponentCfg({ ...componentCfg, defaultValues: values })
      if (onSuccessSubmit) { onSuccessSubmit(applicationId) }
    }, (status, response) => {
      setComponentCfg({ ...componentCfg, isSubmitting: false, errors: _.get(response, 'data.errors', []) })
    })
  }

  // for load all promotions data when component is mounted
  useEffect(() => {
    // clean up controller --> prevent crash with async function
    let isMounted = true
    // nothing to do if is loaded
    if (componentCfg.isLoaded) { return }
    // if not set invoice id
    if (!applicationId) { // is created case
      setComponentCfg({ ...componentCfg, isLoaded: true, defaultValues: _.cloneDeep(initValues) })
    } else { // is edit case
      getPromotionEnergySourceApplication(promotionId, sourceId, applicationId, (result) => {
        // prevent async crash
        if (!isMounted) return null
        // get first returned
        const values = result.data
        // if applications is clima, heating or clima + heating...
        if ([2, 3, 4].indexOf(values.energy_application_id) > -1 && values.promotion_energy_application_start) {
          values.promotion_energy_application_start = dayjs(`${values.promotion_energy_application_start}`, 'YYYYMMDD').format('MM-DD')
          values.promotion_energy_application_end = dayjs(`${values.promotion_energy_application_end}`, 'YYYYMMDD').format('MM-DD')
        } else {
          values.promotion_energy_application_start = null
          values.promotion_energy_application_end = null
        }
        // update component cfg
        setComponentCfg({ ...componentCfg, isLoaded: true, defaultValues: values })
      })
    } // prevent crash with async function
    return () => { isMounted = false }
  }, [componentCfg.isLoaded])

  // for load values when are set default values:
  // - on load component
  // - on submit data
  useEffect(() => {
    // skip first render
    if (!componentCfg.isLoaded) { return }
    // reset form if (!selected || !selected[0] || !selected[1]) { return 0 }
    handlerResetForm(true)
  }, [componentCfg.defaultValues])

  // for update component on change application type id
  useEffect(() => {
    // show or hide range data picker
    // if applications is clima, heating or clima + heating...
    if ([2, 3, 4].indexOf(applicationTypeId) > -1) {
      setComponentCfg({ ...componentCfg, renderPeriod: true })
    } else {
      setComponentCfg({ ...componentCfg, renderPeriod: false })
    }
  }, [componentCfg.isLoaded, applicationTypeId])

  return (<>
    <FormAlerts errors={componentCfg.errors} fieldsConfig={fieldsConfig} />
    <Form form={form} disabled={!componentCfg.isLoaded} validateTrigger={validateOnChange} layout="vertical" name="promotion_application_form"
          autoComplete="off" onFinish={handlerFinish} onFinishFailed={handlerFinishFailed} requiredMark={true}
          initialValues={componentCfg.defaultValues}>
      { /* row 01 */ }
      <Row gutter={16}>
        <Col span={7}>
          <ApplicationTypesField
            form={form} promotionId={promotionId}
            hasFeedback={validateHasFeedback}
            label={fieldsConfig.energy_application_id.label}
            name={fieldsConfig.energy_application_id.name}
            disabled={componentCfg.applicationTypeDisabled}
          />
        </Col>
        <Col span={17}>
          <DescriptionSmallField
            form={form}
            hasFeedback={validateHasFeedback}
            label={fieldsConfig.promotion_energy_application_desc.label}
            name={fieldsConfig.promotion_energy_application_desc.name}
          />
        </Col>
      </Row>
      { /* row 02 */}
      <Row gutter={16}>
        <Col span={7}>
          <YesNoField
            form={form}
            hasFeedback={validateHasFeedback}
            label={fieldsConfig.promotion_energy_application_is_active.label}
            name={fieldsConfig.promotion_energy_application_is_active.name}
          />
        </Col>
        <Col span={17}>
          {
            componentCfg.renderPeriod && <>
            <Row gutter={16}>
              <Col span={12}>
                <MonthDayField
                  form={form}
                  required={false}
                  hasFeedback={validateHasFeedback}
                  label={fieldsConfig.promotion_energy_application_start.label}
                  name={fieldsConfig.promotion_energy_application_start.name}
                />
              </Col>
              <Col span={12}>
                <MonthDayField
                  form={form}
                  required={false}
                  hasFeedback={validateHasFeedback}
                  label={fieldsConfig.promotion_energy_application_end.label}
                  name={fieldsConfig.promotion_energy_application_end.name}
                />
              </Col>
            </Row>
            </>
          }
        </Col>
      </Row>
      { /* buttons row */ }
      <Row justify="end">
        <Col>
          <Space>
            {!applicationId && <ButtonFormReset disabled={areButtonsDisabled} form={form} callback={() => handlerResetForm() } /> }
            <ButtonFormSubmit disabled={areButtonsDisabled} form={form} text={applicationId ? t('dashboard.update') : t('dashboard.add')}/>
          </Space>
        </Col>
      </Row>
    </Form>
  </>)
}

PromotionEnergySourceApplicationForm.propTypes = {
  promotionId: PropTypes.number.isRequired,
  sourceId: PropTypes.number.isRequired,
  applicationId: PropTypes.number,
  onSuccessSubmit: PropTypes.func
}
