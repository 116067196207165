import React, { useEffect, useMemo, useState } from 'react'
import { Pagination, Table } from 'rsuite'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment/moment'
import { useRequestsEvents } from '../../../hooks/useRequestsEvents'
import _ from 'lodash'
import { ErrorResult, ForbiddenResult, LoaderBackDrop } from '../../feedbacks'
import { useEnvironment } from '../../../hooks/useEnvironment'
import { useParams } from 'react-router-dom'
import { Button } from 'antd'
import { IoMdRefresh } from 'react-icons/io'
import { getPaginationText } from '../../../helpers/paginations'
import SpinnerIcon from '@rsuite/icons/legacy/Spinner'
import { HiOutlineEye } from 'react-icons/hi'
import { DrawerForm } from '../../forms'
import { PromotionEnergySupplyLiquidateForm } from '../forms/PromotionEnergySupplyLiquidateForm'
import { InvoicesIcon } from '../../icons'
import { useTable } from '../../../hooks/useTable'
import { BiDownload } from 'react-icons/bi'
import { setCompensationsInterfaceData, setCompensationsSummaryInterfaceData, setInterfaceDataToCalculatedPeriod, setInterfaceDataToTotalCalculatedPeriod } from '../../../helpers/liquidations'

const { getCalculatedPeriods, getCompensationData, getPercent } = window.helpersLiquidations
const { Column, HeaderCell, Cell } = Table
const CompactCell = props => <Cell {...props} style={{ padding: 6 }} />
const CompactHeaderCell = props => <HeaderCell {...props} style={{ padding: 4 }} />

// define locales for this page
const transFiles = ['dashboard', 'fields']

export const PromotionEnergySupplyLiquidatedPeriodsTable = (props) => {
  // for translation
  const { t } = useTranslation(transFiles)
  // get current promotion id
  const { promotionId } = useParams()
  // get methods to send data
  const { getPromotionEnergySourceLiquidatedPeriods, deleteEnergySourceLiquidatedPeriod, downloadEnergySourceLiquidatedPeriod } = useRequestsEvents()
  // get actions from use table
  const { DeleteAction } = useTable()
  // get for check permissions
  const { hasPromotionPermissions } = useEnvironment()
  // get data from props
  const { sourceId, isCalledToReload, propagateIsLoaded } = props
  // component config
  const [componentCfg, setComponentCfg] = useState({
    liquidated: [],
    isLoaded: false,
    isChangingPage: false,
    isReloading: false,
    isError: false,
    isForbidden: false,
    // drawer config
    drawer: false,
    selected: null,
    // pagination config
    page: 1,
    total: 0,
    offset: 0,
    limit: 25,
    // drawer
    liquidationViewShow: false,
    liquidationViewAction: undefined,
    liquidationViewData: undefined
  }) // get permissions
  const hasReadEnergySupplyLiquidationPeriodsPromotionPermissions = hasPromotionPermissions(promotionId, 'read:promotion:energy:liquidation:periods') && !componentCfg.isForbidden
  const hasDeleteEnergySupplyLiquidationPeriodsPromotionPermissions = hasPromotionPermissions(promotionId, 'delete:promotion:energy:liquidation:periods') && !componentCfg.isForbidden
  const hasDownloadEnergySupplyLiquidationPeriodsPromotionPermissions = hasPromotionPermissions(promotionId, 'download:promotion:energy:liquidation:periods') && !componentCfg.isForbidden

  // reload component data
  const handlerReloadComponent = () => {
    // call to reload from segmentation
    setComponentCfg({ ...componentCfg, isError: false, isLoaded: false, isReloading: true })
  }

  // on change page
  const handlerChangePage = (page, skipIsLoading = false) => {
    // not change if component is not loaded....
    if (!componentCfg.isLoaded && !skipIsLoading) { return }
    // set to reload a component
    setComponentCfg({ ...componentCfg, page, offset: (componentCfg.limit * page) - componentCfg.limit, isError: false, isLoaded: false, isChangingPage: true })
  }

  const handlerPageReload = (skipIsLoading = false) => {
    // not change if component is not loaded....
    if (!componentCfg.isLoaded && !skipIsLoading) { return }
    // set to reload a component
    setComponentCfg({ ...componentCfg, isError: false, isLoaded: false, isChangingPage: true })
  }

  // for reload from parent
  useEffect(() => {
    // only when component is loaded and parent refresh is true
    if (!componentCfg.isLoaded || !isCalledToReload) { return }
    // call to reload from segmentation
    handlerReloadComponent()
  }, [isCalledToReload])

  // for load when source id changes...
  useEffect(() => {
    // only when component is loaded...
    if (!componentCfg.isLoaded) { return }
    // call to reload from segmentation
    handlerReloadComponent()
  }, [sourceId])

  // loading component from first load:
  // - only if set source id and is not loading
  useEffect(() => {
    // clean up controller --> prevent crash with async function
    let isMounted = true
    // set observers
    propagateIsLoaded(false)
    // not reload if component is loaded...
    if (componentCfg.isLoaded) { return }
    // if not has permissions
    if (!hasReadEnergySupplyLiquidationPeriodsPromotionPermissions) {
      setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, liquidated: [] })
    } else {
      // get data promotion energy sources
      getPromotionEnergySourceLiquidatedPeriods(promotionId, sourceId, (result) => {
        // prevent async crash
        if (!isMounted) return null
        // fix delete last item on a page
        if (_.isEmpty(result.data) && result.pagination.total_records > 0) {
          // call to load previous page
          handlerChangePage(result.pagination.total_pages, true)
        } else {
          // set data to config
          setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, isChangingPage: false, isReloading: false, liquidated: result.data, total: result.pagination.total_records, isForbidden: false })
        }
      }, (err) => {
        // prevent async crash
        if (!isMounted) return null
        if (err === 403) {
          setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, isChangingPage: false, isReloading: false, liquidated: [], total: 0, isForbidden: true })
        } else {
          setComponentCfg({ ...componentCfg, isError: true, isLoaded: true, isChangingPage: false, isReloading: false, liquidated: [], total: 0, isForbidden: false })
        }
      }, componentCfg.limit, componentCfg.offset)
    } // prevent crash with async function
    return () => { isMounted = false }
  }, [componentCfg.isLoaded, componentCfg.page])

  // when go to liquidate form
  const handlerViewLiquidation = (liquidationId, liquidation) => {
    // not reload if component is loaded...
    if (!componentCfg.isLoaded) { return }
    // show form
    setComponentCfg({ ...componentCfg, liquidationViewShow: true, liquidationViewAction: 'liquidation.view', liquidationViewData: liquidation })
  }

  // download a liquidation xlsx
  const handlerDownload = (liquidationId, liquidation) => {
    // not reload if component is loaded...
    if (!componentCfg.isLoaded) { return }
    // clean up controller --> prevent crash with async function
    const isMounted = true
    // request to delete invoice
    downloadEnergySourceLiquidatedPeriod(promotionId, sourceId, liquidation.liquidation_id, (result, response) => {
      if (!isMounted) return null
      const url = window.URL.createObjectURL(new Blob([result],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
      const link = document.createElement('a')
      link.href = url
      link.download = `${liquidationId}.xlsx`
      document.body.appendChild(link)
      link.click()
      link.remove()
    }, () => {
      if (!isMounted) return null
      setComponentCfg({ ...componentCfg, isError: true, isLoaded: true, isReloading: false })
    })
  }

  // when delete liquidation
  const handlerDeleteLiquidation = (liquidation) => {
    // not reload if component is loaded...
    if (!componentCfg.isLoaded) { return }
    // clean up controller --> prevent crash with async function
    const isMounted = true
    // request to delete invoice
    deleteEnergySourceLiquidatedPeriod(promotionId, sourceId, liquidation.liquidation_id, () => {
      if (!isMounted) return null
      props.propagateIsLoaded(true)
    }, (/* status, response */) => {
      if (!isMounted) return null
      setComponentCfg({ ...componentCfg, isError: true, isLoaded: true, isReloading: false })
    })
  }

  // when close liquidate form
  const handlerDrawerClose = () => {
    // not reload if component is loaded...
    if (!componentCfg.isLoaded) { return }
    // close form
    setComponentCfg({ ...componentCfg, liquidationViewShow: false, liquidationViewAction: undefined, liquidationViewData: undefined })
  }

  // format data for table
  const tableData = useMemo(() => {
    // if not data set...
    if (_.isEmpty(componentCfg.liquidated)) { return [] }
    // format data
    return _.map(componentCfg.liquidated, (liquidation) => {
      // get liquidation data
      const liquidationData = getCalculatedPeriods(liquidation.liquidation_periods, setInterfaceDataToCalculatedPeriod, setInterfaceDataToTotalCalculatedPeriod).slice(-1)[0]
      // get other concepts
      const otherConcepts = _.get(liquidation, 'liquidation_other_concepts', [])
      // set data to table
      const tableData = {
        key: _.uniqueId(),
        period_days: liquidationData.period_days,
        period_full_date: `${moment(liquidation.liquidation_date_start, 'YYYYMMDD').format('YYYY-MM-DD')} - ${moment(liquidation.liquidation_date_end, 'YYYYMMDD').format('YYYY-MM-DD')}`,
        period_date: liquidation.liquidation_date,
        period_reference: liquidation.liquidation_id,
        period_consumption: liquidationData.consumption,
        period_cost_variable: liquidationData.cost_variable,
        period_cost_fix: liquidationData.cost_fix,
        period_cost_tax_percent: liquidationData.cost_tax_percent,
        period_cost_tax_eur: liquidationData.cost_tax,
        period_total: liquidationData.total,
        created_at: moment(liquidation.created_at, 'YYYY-MM-DD').locale(window.user.user_language_id).format('YYYY.MM.DD'),
        edit_action: <HiOutlineEye size={15} style={{ cursor: 'pointer', marginLeft: '-1px', marginTop: '1px' }} onClick={() => handlerViewLiquidation(liquidation.liquidation_id, liquidation)} />,
        delete_action: <DeleteAction data={liquidation} disabled={!liquidation.liquidation_is_deletable || !hasDeleteEnergySupplyLiquidationPeriodsPromotionPermissions} handler={handlerDeleteLiquidation} />,
        download_action: !hasDownloadEnergySupplyLiquidationPeriodsPromotionPermissions ? <></> : <BiDownload size={15} style={{ cursor: 'pointer', marginLeft: '-1px', marginTop: '1px' }} onClick={() => handlerDownload(liquidation.liquidation_id, liquidation)} />,
        children: [{
          ...liquidationData,
          key: _.uniqueId(),
          period_full_date: <span style={{ float: 'right', marginRight: '6px', color: 'grey' }}>{t('dashboard.liquidation')}</span>,
          period_reference: '-',
          created_at: moment(liquidation.created_at, 'YYYY-MM-DD').locale(window.user.user_language_id).format('YYYY.MM.DD')
        }]
      }

      // getCompensationData -> data, index, empty result, is component loaded required
      const compensations = getCompensationData({ ...liquidation, isLoaded: true }, 'liquidation_compensations', true, true, setCompensationsInterfaceData, setCompensationsSummaryInterfaceData, setInterfaceDataToCalculatedPeriod, setInterfaceDataToTotalCalculatedPeriod)
      // get each compensation data
      const compensationsData = _.get(compensations, 'compensationDataCalculatedSummary')

      if (_.isEmpty(compensationsData) && _.isEmpty(otherConcepts)) {
        tableData.children = undefined
      } else { // only if exits one of two...
        // set compensation data as child
        if (!_.isEmpty(compensationsData)) {
          // add to table data
          tableData.children.push({
            ...compensationsData,
            period_full_date: <span style={{ float: 'right', marginRight: '6px', color: 'grey' }}>{t('dashboard.compensations')}</span>,
            period_reference: '-',
            created_at: moment(liquidation.created_at, 'YYYY-MM-DD').locale(window.user.user_language_id).format('YYYY.MM.DD')
          }) // update total liquidation
          tableData.period_total += compensationsData.total
          tableData.period_consumption += compensationsData.consumption
          tableData.period_cost_variable += compensationsData.cost_variable
          tableData.period_cost_fix += compensationsData.cost_fix
          tableData.period_cost_tax_eur += compensationsData.cost_tax_eur
        }
        // set other concepts as child
        if (!_.isEmpty(otherConcepts)) {
          // get total others
          const totalOthers = _.sumBy(otherConcepts, 'value')
          // add to table data
          tableData.children.push({
            period_total: `${totalOthers.toFixed(2)} €`,
            period_full_date: <span style={{ float: 'right', marginRight: '6px', color: 'grey' }}>{t('dashboard.others')}</span>,
            period_reference: '-',
            period_cost_tax_eur: '-',
            period_cost_tax_percent: '-',
            period_cost_fix: '-',
            period_cost_variable: '-',
            period_consumption: '-',
            period_days: '-',
            created_at: moment(liquidation.created_at, 'YYYY-MM-DD').locale(window.user.user_language_id).format('YYYY.MM.DD')
          })
          tableData.period_total += totalOthers
        }
      }

      // set string format
      tableData.period_cost_tax_percent = `${(getPercent(tableData.period_cost_tax_eur, (tableData.period_cost_variable + tableData.period_cost_fix))).toFixed(2)} %`
      tableData.period_total = `${tableData.period_total.toFixed(2)} €`
      tableData.period_consumption = `${tableData.period_consumption.toFixed(2)} kWh`
      tableData.period_cost_variable = `${tableData.period_cost_variable.toFixed(2)} €`
      tableData.period_cost_fix = `${tableData.period_cost_fix.toFixed(2)} €`
      tableData.period_cost_tax_eur = `${tableData.period_cost_tax_eur.toFixed(2)} €`
      // return table data
      return tableData
    })
  }, [componentCfg.liquidated])

  const paginationRangeText = useMemo(() => {
    return getPaginationText(componentCfg)
  }, [componentCfg.page, componentCfg.limit, componentCfg.total])

  const showComponentLoader = !componentCfg.isLoaded && !componentCfg.isChangingPage && !componentCfg.isReloading
  const showTableLoader = !componentCfg.isLoaded || componentCfg.isChangingPage || componentCfg.isReloading

  return (<>
    <LoaderBackDrop active={showComponentLoader} loader background>
      {(() => {
        if (!hasReadEnergySupplyLiquidationPeriodsPromotionPermissions) {
          return <ForbiddenResult compact={true} />
        } else if (componentCfg.isError) {
          return <ErrorResult compact={true} />
        } else { /* return components to render */
          return (<>
            <Table isTree defaultExpandAllRows rowKey="key" shouldUpdateScroll={false}
                   onExpandChange={(isOpen, rowData) => { }}
                   renderTreeToggle={(icon, rowData) => { return (rowData.children && rowData.children.length === 0) ? <SpinnerIcon spin /> : icon }}
                   loading={showTableLoader} hover={false} fillHeight={false} autoHeight={false} height={262} data={tableData} cellBordered rowHeight={30} headerHeight={30}>
              <Column width={115} align={'right'}>
                <CompactHeaderCell>{t('dashboard.date')}</CompactHeaderCell>
                <CompactCell dataKey={'period_date'} />
              </Column>
              <Column width={180} align={'center'}>
                <CompactHeaderCell>{t('dashboard.period')}</CompactHeaderCell>
                <CompactCell dataKey={'period_full_date'} />
              </Column>
              <Column width={45} align={'center'}>
                <CompactHeaderCell>{t('dashboard.days')}</CompactHeaderCell>
                <CompactCell dataKey={'period_days'} />
              </Column>
              <Column width={200} align={'center'}>
                <CompactHeaderCell>{t('dashboard.reference')}</CompactHeaderCell>
                <CompactCell dataKey={'period_reference'} />
              </Column>
              <Column width={150} flexGrow={1} align={'right'}>
                <CompactHeaderCell>{t('fields:fields.labels.consumption')}</CompactHeaderCell>
                <CompactCell dataKey={'period_consumption'} />
              </Column>
              <Column width={100} align={'right'}>
                <CompactHeaderCell>{t('fields:fields.labels.cost_variable')}</CompactHeaderCell>
                <CompactCell dataKey={'period_cost_variable'} />
              </Column>
              <Column width={100} align={'right'}>
                <CompactHeaderCell>{t('fields:fields.labels.cost_fix')}</CompactHeaderCell>
                <CompactCell dataKey={'period_cost_fix'} />
              </Column>
              <Column width={100} align={'right'}>
                <CompactHeaderCell>{t('fields:fields.labels.tax')} %</CompactHeaderCell>
                <CompactCell dataKey={'period_cost_tax_percent'} />
              </Column>
              <Column width={100} align={'right'}>
                <CompactHeaderCell>{t('fields:fields.labels.tax')} €</CompactHeaderCell>
                <CompactCell dataKey={'period_cost_tax_eur'} />
              </Column>
              <Column width={150} flexGrow={1} align={'right'}>
                <CompactHeaderCell>{t('fields:fields.labels.total')}</CompactHeaderCell>
                <CompactCell dataKey={'period_total'} />
              </Column>
              { /* ACTIONS */ }
              <Column width={26} align="center">
                <CompactHeaderCell></CompactHeaderCell>
                <CompactCell dataKey="edit_action" />
              </Column>
              <Column width={26} align="center">
                <CompactHeaderCell></CompactHeaderCell>
                <CompactCell dataKey="delete_action" />
              </Column>
              { hasDownloadEnergySupplyLiquidationPeriodsPromotionPermissions && <Column width={26} align="center">
                <CompactHeaderCell></CompactHeaderCell>
                <CompactCell dataKey="download_action" />
              </Column> }
              { /* CREATED AT */ }
              <Column width={90} align={'center'}>
                <CompactHeaderCell>{t('dashboard.created_at')}</CompactHeaderCell>
                <CompactCell dataKey="created_at" />
              </Column>
            </Table>
            { /* pagination */ }
            <div style={{ padding: '5px 10px', backgroundColor: '#fafafa', borderTop: '1px #f2f2f5 solid' }}>
              <Pagination layout={
                  [paginationRangeText, '-', 'pager', <Button key={_.uniqueId()} onClick={handlerPageReload} type="text" shape="circle" icon={<IoMdRefresh size={18} style={{ marginTop: '2px' }}/>} size={'small'}/>]
                }
                size={'xs'} prev={true} next={true} first={true} last={true}
                maxButtons={5} boundaryLinks={true} ellipsis={true} limit={componentCfg.limit}
                total={componentCfg.total} activePage={componentCfg.page} onChangePage={handlerChangePage}
              />
            </div>
            { /* drawer */ }
            { componentCfg.liquidationViewShow &&
              <DrawerForm width={1065} destroyOnClose={true} title={`${t('dashboard.promotion.data_title_view_liquidation')}: ${componentCfg.liquidationViewData.liquidation_id}`} open={componentCfg.liquidationViewShow}
                          content={<PromotionEnergySupplyLiquidateForm onLiquidate={() => {}} promotionId={parseInt(promotionId)} data={componentCfg.liquidationViewData} action={componentCfg.liquidationViewAction} /> }
                          extra={<InvoicesIcon size={25} />} onClose={handlerDrawerClose} />
            }
          </>)
        }
      })()}
    </LoaderBackDrop>
  </>)
}

PromotionEnergySupplyLiquidatedPeriodsTable.propTypes = {
  sourceId: PropTypes.number.isRequired,
  reload: PropTypes.bool,
  isCalledToReload: PropTypes.bool,
  propagateIsLoaded: PropTypes.func
}
