import React from 'react'
import { useEnvironment } from 'hooks/useEnvironment'
import { useParams } from 'react-router-dom'
import 'rsuite/dist/rsuite.min.css'
import { PromotionPersonHolderPanel } from '../../../../../components/promotion/PromotionPersonHolderPanel'
import { PromotionPersonPresidentPanel } from '../../../../../components/promotion/PromotionPersonPresidentPanel'
import { PromotionPersonAdministratorPanel } from '../../../../../components/promotion/PromotionPersonAdministratorPanel'
import { PromotionPersonContactPanel } from '../../../../../components/promotion/PromotionPersonContactPanel'
import { ForbiddenResult } from '../../../../../components/feedbacks'
import { PromotionPanel } from '../../../../../components/promotion/PromotionPanel'

// diver with central element
export function PromotionProfile (props) {
  // get current promotion id
  const { promotionId } = useParams()
  // get for check permissions
  const { hasPromotionPermissions } = useEnvironment()
  // promotion permission
  const hasReadPromotionPermission = hasPromotionPermissions(promotionId, 'read:promotion')
  const hasReadHolderPromotionPermission = hasPromotionPermissions(promotionId, 'read:promotion:holder')
  const hasReadPresidentPromotionPermission = hasPromotionPermissions(promotionId, 'read:promotion:president')
  const hasReadAdminPromotionPermission = hasPromotionPermissions(promotionId, 'read:promotion:admin')
  const hasReadClientPromotionPermission = hasPromotionPermissions(promotionId, 'read:promotion:client')

  return (<>
      {(() => {
        if (!hasReadPromotionPermission) {
          return <ForbiddenResult />
        } else { /* return components to render */
          return (<>
            { /* promotion data */ }
            <PromotionPanel />
            { /* render holder */ }
            { hasReadHolderPromotionPermission && <PromotionPersonHolderPanel /> }
            { /* render president */ }
            { hasReadPresidentPromotionPermission && <PromotionPersonPresidentPanel /> }
            { /* render administrator */ }
            { hasReadAdminPromotionPermission && <PromotionPersonAdministratorPanel /> }
            { /* render client */ }
            { hasReadClientPromotionPermission && <PromotionPersonContactPanel /> }
          </>)
        }
      })()}
    </>
  )
}
