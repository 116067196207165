import React from 'react'
import { DatePicker, Input, InputNumber, Space } from 'antd'
import { CloseOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons'
import { ButtonGroup, IconButton } from 'rsuite'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const styleInputCellContainer = { paddingLeft: '2px', paddingRight: '3px' }
const styleInputCell = { fontFamily: 'Inter', fontSize: '12px' }

export const HeaderTextWithEditionIcons = (props) => {
  // for translation
  const { t } = useTranslation(['dashboard', 'fields'])
  return (<Space>
    { t(props.text) }
    { props.isEditing
      ? <ButtonGroup style={{ marginTop: '-1px' }} size="xs">
        <IconButton icon={<SaveOutlined />} size="xs" onClick={props.onSave} />
        <IconButton style={{ borderLeft: '1px #d1d1d1 solid' }} icon={<CloseOutlined />} size="xs" onClick={props.onCancel} />
      </ButtonGroup>
      : <IconButton style={{ marginTop: '-1px' }} circle icon={<EditOutlined />} size="xs" onClick={props.onEdit}/>
    }
  </Space>)
}

HeaderTextWithEditionIcons.propTypes = {
  text: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  isEditing: PropTypes.bool
}

// data picker on cell
export const DataPickerCell = (props) => {
  // get value for create a data picker
  const { name, value, minDate, onChange, align } = props
  // set dayjs object
  const valueDayJs = dayjs(value, 'YYYY-MM-DD')
  const minDateJs = dayjs(minDate, 'YYYY-MM-DD')
  // set picker align
  const pickerAlign = align || 'center'
  // funct for disabled dates
  const disabledDate = (current) => {
    // Can not select days before today and today
    return parseInt(current.format('YYYYMMDD')) <= parseInt(minDateJs.format('YYYYMMDD'))
  } // on change field call to change parent
  const handlerChange = (value) => {
    onChange(name, value.format('YYYYMMDD'))
  }
  return (
    <div className={`input-data-cell-align-${pickerAlign}`} style={{ paddingLeft: '2px', paddingRight: '3px', width: '100%' }}>
      <DatePicker disabledDate={disabledDate} allowClear={false} onChange={handlerChange} className='data-picker-cell'
                  style={{ ...{ paddingLeft: '2px', paddingRight: '3px' }, width: '100%', textAlign: pickerAlign }}
                  defaultValue={valueDayJs} size="small" />
    </div>
  )
}

DataPickerCell.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  minDate: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  align: PropTypes.string
}

// Number input cell
export const InputNumberCell = (props) => {
  const { name, value, defaultValue, onChange, after, min, align, dataId } = props
  const handlerChange = (value) => { onChange(name, value, dataId) }
  // set picker align
  const inputAlign = align || 'right'
  return (
    <div className={`input-data-cell-align-${inputAlign}`} style={{ ...styleInputCellContainer, paddingTop: '2px', width: '100%' }}>
      <InputNumber controls={false} defaultValue={defaultValue} value={value} min={min} addonAfter={after || undefined} className={'input-number-cell'} onChange={handlerChange}
                   style={{ ...styleInputCell, width: '100%' }} size="small" />
    </div>
  )
}

InputNumberCell.propTypes = {
  name: PropTypes.string.isRequired,
  min: PropTypes.number,
  value: PropTypes.number,
  align: PropTypes.string,
  defaultValue: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  dataId: PropTypes.string,
  after: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ])
}

// Text input cell
export const InputTextCell = (props) => {
  const { name, value, defaultValue, onChange, after, align, dataId } = props
  const handlerChange = (e) => { onChange(name, e.target.value, dataId) }
  // set picker align
  const inputAlign = align || 'right'

  return (
    <div className={`input-data-cell-align-${inputAlign}`} style={{ ...styleInputCellContainer, paddingTop: '2px', width: '100%' }}>
      <Input defaultValue={defaultValue} value={value} addonAfter={after || undefined} className={'input-text-cell'} onChange={handlerChange}
             style={{ ...styleInputCell, width: '100%', textAlign: align || 'left' }} size="small" />
    </div>
  )
}

InputTextCell.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  align: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  dataId: PropTypes.string,
  after: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ])
}
