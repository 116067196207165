import React from 'react'
import { useParams } from 'react-router-dom'
import { useEnvironment } from '../../../../../hooks/useEnvironment'
import { PromotionEnergySupplyAnalysisLiquidationPanel } from '../../../../../components/promotion/PromotionEnergySupplyAnalysisLiquidationPanel'
import { ForbiddenResult } from '../../../../../components/feedbacks'

// diver with central element
export function PromotionAnalysis (props) {
  // get current promotion id
  const { promotionId } = useParams()
  // get for check permissions
  const { hasPromotionPermissions } = useEnvironment()
  // read energy data
  const hasReadSupplyInvoicesEnergyPromotionPermission = hasPromotionPermissions(promotionId, 'read:promotion:energy:supply:invoices')
  const hasReadEnergySupplyLiquidationPeriodsPromotionPermissions = hasPromotionPermissions(promotionId, 'read:promotion:energy:liquidation:periods')
  const hasReadEnergyData = (hasReadSupplyInvoicesEnergyPromotionPermission || hasReadEnergySupplyLiquidationPeriodsPromotionPermissions)

  return (<>
    {(() => {
      if (!hasReadEnergyData) {
        return <ForbiddenResult />
      } else { /* return components to render */
        return <PromotionEnergySupplyAnalysisLiquidationPanel />
      }
    })()}
  </>)
}
