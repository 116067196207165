import { useParams } from 'react-router-dom'
import { useEnvironment } from '../../hooks/useEnvironment'
import _ from 'lodash'
import { EmptyResult, ForbiddenResult } from '../feedbacks'
import PropTypes from 'prop-types'
import React from 'react'
import { BlockApartmentTable } from './tables/BlockApartmentTable'

export const BlockApartmentPanel = (props) => {
  // get data to set
  const { apartments } = props
  // get current promotion id
  const { promotionId } = useParams()
  // get for check permissions
  const { hasPromotionPermissions } = useEnvironment()
  // block permissions
  const hasReadApartmentPermission = hasPromotionPermissions(promotionId, 'read:promotion:block:floors:apartments')

  return (
    <>
      {(() => {
        if (!hasReadApartmentPermission) {
          return <ForbiddenResult compact={true} />
        } else if (_.isEmpty(apartments)) {
          return <EmptyResult compact={true} />
        } else { /* return components to render */
          return <BlockApartmentTable data={apartments} />
        }
      })()}
    </>)
}

BlockApartmentPanel.propTypes = {
  apartments: PropTypes.array
}
