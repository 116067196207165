import React from 'react'
import { Button, Col, Input, Row, Space } from 'antd'
import PropTypes from 'prop-types'
import uniqid from 'uniqid'
import { useTranslation } from 'react-i18next'
import { PlusOutlined } from '@ant-design/icons'
import { Tooltip } from 'react-tooltip'
import { MdOutlineFileUpload } from 'react-icons/md'
import { TbRefresh } from 'react-icons/tb'

import 'react-tooltip/dist/react-tooltip.css'

const transFiles = ['dashboard']

// css style
const menuOptionsTextStyle = {
  paddingTop: '1px',
  marginLeft: '5px'
}

// components

export const MenuHeaderAddButton = (props) => {
  // for translation
  const { t } = useTranslation(transFiles)
  const tooltipId = uniqid('tooltip-id-')
  const icon = <PlusOutlined style={{ fontSize: '13px' }}/>

  return (
    <>
      <Button onClick={props.onClick} key={uniqid()} data-tooltip-id={tooltipId} data-tooltip-content={t('dashboard.add')}
              data-tooltip-place="bottom" block size={'small'} icon={icon} disabled={props.disabled} />
      <Tooltip key={uniqid()} id={tooltipId} />
    </>
  )
}

MenuHeaderAddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

export const MenuHeaderImportButton = (props) => {
  // for translation
  const { t } = useTranslation(transFiles)
  const tooltipId = uniqid('tooltip-id-')
  const icon = <MdOutlineFileUpload style={{ paddingTop: '3px' }} size={19}/>

  return (
    <>
      <Button onClick={props.onClick} key={uniqid()} data-tooltip-id={tooltipId} data-tooltip-content={t('dashboard.import')}
              data-tooltip-place="bottom" block size={'small'} icon={icon} disabled={props.disabled} />
      <Tooltip key={uniqid()} id={tooltipId} />
    </>
  )
}

MenuHeaderImportButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

export const MenuHeaderReloadButton = (props) => {
  // for translation
  const { t } = useTranslation(transFiles)
  const tooltipId = uniqid('tooltip-id-')
  const icon = <TbRefresh style={{ paddingTop: '3px' }} size={19}/>

  return (
    <>
      <Button onClick={props.onClick} key={uniqid()} data-tooltip-id={tooltipId} data-tooltip-content={t('dashboard.import')}
              data-tooltip-place="bottom" block size={'small'} icon={icon} disabled={props.disabled} />
      <Tooltip key={uniqid()} id={tooltipId} />
    </>
  )
}

MenuHeaderReloadButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

// get search input
const { Search } = Input

export const MenuHeaderSearch = (props) => {
  // for translation
  const { t } = useTranslation(transFiles)
  const { onSearch, defaultValue } = props

  return (
    <>
      { /* Header */ }
      <Row className={'dashboard-side-nav-toolbar'} align={'middle'}>
        { /* Header :: menu left */ }
        <Col span={24}>
          <Search defaultValue={defaultValue} placeholder={t('dashboard.search')}
                  size={'small'} allowClear onSearch={onSearch}
                  style={{ fontSize: '5px' }} disabled={props.disabled}
          />
        </Col>
      </Row>
    </>
  )
}

MenuHeaderSearch.propTypes = {
  onSearch: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool
}

export const HeaderTitleLayout = (props) => {
  const { title, buttons, icon } = props
  return (
    <>
      { /* header for menu */ }
      <Row className={'dashboard-side-nav-toolbar'} align={'middle'}>
        <Col span={12}>
          <Row> { /* icon */ }
            <Col align={'middle'} flex={'20px'}>
              {icon}
            </Col> { /* title */ }
            <Col style={menuOptionsTextStyle} flex={'auto'}>
              {title}
            </Col>
          </Row>
        </Col> { /* buttons */ }
        <Col flex={'auto'}>
          <Row align={'end'}>
            <Space size={5}>{buttons}</Space>
          </Row>
        </Col>
      </Row>
    </>
  )
}

HeaderTitleLayout.propTypes = {
  title: PropTypes.string,
  buttons: PropTypes.node,
  icon: PropTypes.node
}
