import { CloseOutlined, DeleteOutlined, EditOutlined, LoadingOutlined, SaveOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import { Popconfirm } from 'antd'
import { useTranslation } from 'react-i18next'

// define locales for this page
const transFiles = ['dashboard']

export const useTable = () => {
  // for translation
  const { t } = useTranslation(transFiles)
  // create state for disable and enable ajax actions
  const [isAnyActionRunning, setIsAnyActionRunning] = useState(false)
  // set invoice actions
  const commonColor = isAnyActionRunning ? 'grey' : 'black'
  // set action running state for all components
  const setActionRunningStateForAll = (state, setActionCallerIsRunning) => {
    setIsAnyActionRunning(state)
    setActionCallerIsRunning(state)
  }

  const DeleteAction = (props) => {
    // eslint-disable-next-line react/prop-types
    const { data, handler, disabled } = props
    // create state for disable and enable ajax actions
    const [isActionRunning, setIsActionRunning] = useState(false)
    // method to set done action state
    const setIsActionRunningForComponent = (state) => {
      setActionRunningStateForAll(state, setIsActionRunning)
    } // to call when item is clicked
    const deleteAction = () => {
      // nothing to do if other action is running
      if (isAnyActionRunning || disabled) return
      // call to do action
      handler(data, setIsActionRunningForComponent)
    }

    return (<>{
      isActionRunning
        ? <LoadingOutlined />
        : <Popconfirm disabled={isAnyActionRunning || disabled} placement="left" title={t('dashboard.delete')} description={t('dashboard.questions.delete_item')}
                      onConfirm={isAnyActionRunning || disabled ? undefined : () => deleteAction() } okText={t('dashboard.yes')} cancelText={t('dashboard.no')}>
            <DeleteOutlined className={`rd-table-edit${disabled ? '-disabled' : ''}`} style={{ color: commonColor }} />
          </Popconfirm>
    }</>)
  }

  const EditAction = (props) => {
    // eslint-disable-next-line react/prop-types
    const { data, handler, disabled } = props
    // create state for disable and enable ajax actions
    const [isActionRunning, setIsActionRunning] = useState(false)
    // method to set done action state
    const setIsActionRunningForComponent = (state) => {
      setActionRunningStateForAll(state, setIsActionRunning)
    }// to call when item is clicked
    const editAction = () => {
      // nothing to do if other action is working
      if (isAnyActionRunning || disabled) return
      // call to do action
      handler(data, setIsActionRunningForComponent)
    }

    return (<>{
      isActionRunning
        ? <LoadingOutlined />
        : <EditOutlined className={`rd-table-edit${disabled ? '-disabled' : ''}`} style={{ color: commonColor }} onClick={ isAnyActionRunning || disabled ? undefined : () => editAction() } />
    }</>)
  }

  const CancelAction = (props) => {
    // eslint-disable-next-line react/prop-types
    const { data, handler, disabled } = props
    // create state for disable and enable ajax actions
    const [isActionRunning, setIsActionRunning] = useState(false)
    // method to set done action state
    const setIsActionRunningForComponent = (state) => {
      setActionRunningStateForAll(state, setIsActionRunning)
    }// to call when item is clicked
    const editAction = () => {
      // nothing to do if other action is working
      if (isAnyActionRunning || disabled) return
      // call to do action
      handler(data, setIsActionRunningForComponent)
    }

    return (<>{
      isActionRunning
        ? <LoadingOutlined />
        : <CloseOutlined className={`rd-table-edit${disabled ? '-disabled' : ''}`} style={{ color: commonColor }} onClick={ isAnyActionRunning || disabled ? undefined : () => editAction() } />
    }</>)
  }

  const SaveAction = (props) => {
    // eslint-disable-next-line react/prop-types
    const { data, handler, disabled } = props
    // create state for disable and enable ajax actions
    const [isActionRunning, setIsActionRunning] = useState(false)
    // method to set done action state
    const setIsActionRunningForComponent = (state) => {
      setActionRunningStateForAll(state, setIsActionRunning)
    }// to call when item is clicked
    const editAction = () => {
      // nothing to do if other action is working
      if (isAnyActionRunning || disabled) return
      // call to do action
      handler(data, setIsActionRunningForComponent)
    }

    return (<>{
      isActionRunning
        ? <LoadingOutlined />
        : <SaveOutlined className={`rd-table-edit${disabled ? '-disabled' : ''}`} style={{ color: commonColor }} onClick={ isAnyActionRunning || disabled ? undefined : () => editAction() } />
    }</>)
  }

  return {
    DeleteAction,
    EditAction,
    CancelAction,
    SaveAction
  }
}
