import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Col, Layout, Menu, Row } from 'antd'
import { DashboardHeader } from 'pages/dashboard/DashboardHeader'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AppSettingsIcon, PeopleIcon, PromotionsIcon } from 'components/icons'
import { useEnvironment } from 'hooks/useEnvironment'
import useDidMountEffect from '../../hooks/useDidMountEffect'
import { ErrorResult, ForbiddenResult, LoaderBackDrop } from '../../components/feedbacks'
import { useRequestsEvents } from '../../hooks/useRequestsEvents'

// define locales for this page
const transFiles = ['dashboard', 'fields']

function getItem (label, key, icon, disabled, children, type) {
  return { key, icon, children, label, type, disabled }
}

export const DashboardLayout = () => {
  // for translation
  const { t } = useTranslation(transFiles)
  // for work with context
  const { getUserPermissionsByProfile } = useRequestsEvents()
  // get use environment for check permissions
  const { hasPermissions } = useEnvironment()
  // for redirect
  const navigate = useNavigate()
  // for location reference
  const location = useLocation()
  // component config
  const [componentCfg, setComponentCfg] = useState({
    menu: [],
    menuSelectedKey: [],
    isLoaded: false,
    isForbidden: false,
    isError: false
  }) // navigate config
  const [navigationConfig, setNavigationConfig] = useState({
    link: [],
    navigate: false
  })

  // when any item on menu is clicked...
  const handlerMenuItemClick = (e) => {
    // if current location is the same...
    if (location.pathname.includes(e.key)) { return }
    // set config to navigate
    setNavigationConfig({ ...navigationConfig, link: e.key, navigate: true })
  }

  // on change promotion id call to reload component
  useDidMountEffect(() => {
    // if not navigate...
    if (!navigationConfig.navigate) { return }
    // set navigate link
    navigate(navigationConfig.link)
    // set navigated
    setNavigationConfig({ ...navigationConfig, navigate: false })
  }, [navigationConfig])

  // select item on menu when load window
  useEffect(() => {
    _.each(componentCfg.menu, (val, idx) => {
      // if current location is the same...
      if (location.pathname.includes(val.key)) {
        // set selected key
        return setComponentCfg({ ...componentCfg, menuSelectedKey: [val.key] })
      }
    })
  }, [location.pathname, componentCfg.menu])

  // load menu on load component
  useEffect(() => {
    // if is loaded nothing to do...
    if (componentCfg.isLoaded) { return }
    // get user permissions by profile
    getUserPermissionsByProfile((result) => {
      // set user permissions to windows
      window.user.user_permissions = result.user_permissions
      // create menu in function of permissions
      const menu = []
      // promotion menu item
      if (hasPermissions('item:promotions')) {
        menu.push(getItem(t('dashboard.promotions'), 'promotions', <PromotionsIcon />, false))
      }
      // edyna menu item
      if (hasPermissions('item:edyna')) {
        menu.push(getItem(t('dashboard.edyna'), 'edyna/users', <AppSettingsIcon size={18} />, true))
      }
      // persons menu item
      if (hasPermissions('item:persons')) {
        menu.push({ type: 'divider' })
        menu.push(getItem(t('dashboard.people'), 'people', <PeopleIcon size={19} />, true))
      }

      // set ne config to component
      setComponentCfg({ ...componentCfg, isLoaded: true, menu, isForbidden: false, isError: false })
    }, (re) => {
      if (re === 403) {
        setComponentCfg({ ...componentCfg, isLoaded: true, menu: [], isForbidden: true, isError: false })
      } else {
        setComponentCfg({ ...componentCfg, isLoaded: true, menu: [], isForbidden: false, isError: true })
      }
    })
  }, [componentCfg.isLoaded, location.pathname])

  return (<>
        {(() => {
          if (!componentCfg.isLoaded) {
            return <LoaderBackDrop active={true} loader={true} top/>
          } else if (componentCfg.isForbidden) {
            return <ForbiddenResult/>
          } else if (componentCfg.isError) {
            return <ErrorResult />
          } else {
            return <Layout id={'dashboard-layout'}>
              <Row>
                <Col className={'dashboard-header'} span={24}>
                  <DashboardHeader />
                </Col>
                <div className={'dashboard-body'}>
                  <Row>
                    <Col className={'dashboard-side-nav'} flex="0 0 96px">
                      <LoaderBackDrop active={navigationConfig.navigate}>
                        <Menu onClick={handlerMenuItemClick} selectedKeys={componentCfg.menuSelectedKey} style={{ width: '100%' }} mode="inline" items={componentCfg.menu} />
                      </LoaderBackDrop>
                    </Col>
                    <Col className={'dashboard-data-with-side-nav dashboard-data'} flex="1 1 auto">
                      { /* Render the selected item */ }
                      { !navigationConfig.navigate && <Outlet /> }
                    </Col>
                  </Row>
                </div>
              </Row>
              <div id="global_mask"></div>
            </Layout>
          }
        })()}
    </>
  )
}
