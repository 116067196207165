import React, { useState } from 'react'
import { Button, Col /*, Dropdown */, Row } from 'antd'
import { TbDoorExit } from 'react-icons/tb'
import { useEnvironment } from '../../hooks/useEnvironment'
import { LuHardDriveUpload } from 'react-icons/lu'
import { DrawerForm } from '../../components/forms'
import { UploadDataPanel } from '../../components/promotion/panel/UploadDataPanel'
import { CgProfile } from 'react-icons/cg'
import { useNavigate } from 'react-router-dom'
import { isUserOverall } from '../../helpers/roles'

export const DashboardHeader = () => {
  // for work with context
  const { deleteAuthUserToken, hasPermissions } = useEnvironment()
  // for redirect
  const navigate = useNavigate()
  // component config
  const [componentCfg, setComponentCfg] = useState({
    // drawer
    uploadDrawerOpen: false
  })

  const handlerDrawerOpen = () => {
    // close form
    setComponentCfg({ ...componentCfg, uploadDrawerOpen: true })
  }

  const handlerDrawerClose = () => {
    // close form
    setComponentCfg({ ...componentCfg, uploadDrawerOpen: false })
  }

  const handlerShowProfiles = () => {
    navigate('/profiles')
  }

  return (
    <>
      { /* Header */ }
      <Row align="middle">
        { /* Header :: menu left */ }
        <Col flex="250px">
          Workspace <sub style={{ fontSize: '7px', color: 'grey' }}>2024-08-29-22:21:44</sub>
        </Col>
        { /* Header :: menu right */ }
        <Col className={'dashboard-header-buttons-right'} flex="auto">
          <Row justify="end">
            { hasPermissions('item:upload:data:promotions') && <Button shape="circle" icon={<LuHardDriveUpload />} onClick={handlerDrawerOpen} /> }
            {/* <Button shape="circle" icon={<SettingsIcon />} disabled={!hasPermissions('item:settings')} /> */}
            {/* <Button shape="circle" icon={<UserIcon />} disabled={!hasPermissions('item:user')} /> */}
            { !isUserOverall() && <Button shape="circle" icon={<CgProfile style={{ fontSize: '19px', marginTop: '1px', marginLeft: '1px' }}/>} onClick={ handlerShowProfiles }/> }
            <Button shape="circle" icon={<TbDoorExit style={{ fontSize: '17px', marginTop: '2px', marginLeft: '1px' }}/>} onClick={ deleteAuthUserToken }/>
          </Row>
        </Col>
      </Row>
      { componentCfg.uploadDrawerOpen &&
        <DrawerForm width={650} destroyOnClose={true} title={'dashboard.upload_readings' } open={componentCfg.uploadDrawerOpen}
                    content={<UploadDataPanel onSuccessSubmit={handlerDrawerClose} /> }
                    extra={<LuHardDriveUpload size={25} />} onClose={handlerDrawerClose} />
      }
    </>
  )
}
