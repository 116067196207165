import axios from 'axios'
import { message } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEnvironment } from 'hooks/useEnvironment'
import _ from 'lodash'
import qs from 'qs'

// for save cookies and send in each requests
axios.defaults.withCredentials = true

// get api base url
const getApiBaseUrl = () => {
  return `https://${process.env.REACT_APP_RD_APP_DOMAIN_API}/api/v1/administration`
}
// get auth base url for all requests
const getAuthBaseUrl = () => {
  return `${getApiBaseUrl()}/auth`
}

// get app base url for all requests
const getAppBaseUrl = () => {
  return `${getApiBaseUrl()}/overall`
}

// serialize data to send for requests
const getURLSearchParamsFromObject = (obj) => {
  return qs.stringify(obj, { encode: true })
}

export const useRequestsEvents = () => {
  // for redirect
  const navigate = useNavigate()
  const { profile } = useParams()
  // for translation
  const { t } = useTranslation('notifications')
  // get environment events
  const { getCurrentLocale, getAuthUserToken, setAuthUserTokens, getAuthUserRefreshToken, deleteAuthUserToken } = useEnvironment()

  // get header for all request
  const getHeaders = (cfg = {}) => {
    return {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept-Language': getCurrentLocale(),
        Authorization: `Bearer ${getAuthUserToken()}`,
        'X-Tenant-Profile': profile,
        ...cfg
      }
    }
  }
  // on start requests
  const onStartRequest = async (caller) => {
    // get global mask before request
    const globalMask = document.getElementById('global_mask')
    // if exist global mask
    if (globalMask) { globalMask.style.visibility = 'visible' }
    // if caller include progress...
    if (!caller.includes(':progress')) { return }
    // fix antd warning
    const title = t('notification.in_progress', { ns: 'notifications' })
    // show loading message with 0 (not dismiss)
    message.loading(title, 0)
  }
  // on finish requests
  const onFinishRequest = async (caller) => {
    // get global mask before request
    const globalMask = document.getElementById('global_mask')
    // if exist global mask
    if (globalMask) { globalMask.style.visibility = 'hidden' }
    // if exists windows...
    if (!caller.includes(':progress')) { return }
    // hide current message not close if login, more action use the same message
    message.destroy()
  }

  // parse failure requests
  const parseFailureRequests = (error, callBack = () => {}, caller = undefined) => {
    // call to common actions on finish request
    onFinishRequest(caller).then(async () => {
      // check errors
      if (error.response && error.response.status) {
        callBack(error.response.status, error.response)

        // check if caller has a custom errors form api
        if (caller.includes(':custom_errors') && _.has(error, 'response.data.errors')) {
          // get config for error to show...
          const errorConfig = error.response.data.errors[0]
          // if error has message to show, show a message
          if (_.has(errorConfig, 'msg')) { return message.error(errorConfig.msg) }
        }

        if (error.response.status === 400) {
          return message.error(t('notification.failure.bad_request', { ns: 'notifications' }))
        } else if (error.response.status === 401) { // no session
          if (!caller.includes(':no_401_msg')) message.warning(t('notification.failure.no_session', { ns: 'notifications' }))
          return deleteAuthUserToken()
        } else if (error.response.status === 429) {
          return message.warning(t('notification.failure.max_requests', { ns: 'notifications' }))
        } else if (error.response.status === 404) {
          return message.warning(t('notification.failure.not_found', { ns: 'notifications' }))
        } else if (error.response.status === 403) {
          return message.warning(t('notification.failure.forbidden', { ns: 'notifications' }))
        }
      }

      // for catch a 500 error if caller is a form...
      if (caller.includes(':form') || caller.includes(':table')) {
        return message.error(t('notification.failure.unknown', { ns: 'notifications' }))
      } // default fail error or 500
      return navigate('/500', { state: 500, status: 500 })
    })
  }
  // parse success requests
  const parseSuccessRequests = (response, callBack = () => {}, caller = undefined, file = false) => {
    // call to common actions on finish request
    onFinishRequest(caller).then(() => {
      // if call is successfully
      if (response.data.success || (file && response.status === 200)) {
        if ((caller.includes(':form') || caller.includes(':table')) && !caller.includes(':no_success_msg')) {
          message.success(t('notification.success.form', { ns: 'notifications' }))
        } // call to callback
        callBack(response.data, response)
        // skip this caller because app is not loaded when this request is performed
      } else if (caller !== 'authentication@getAuthNewToken' && caller.includes(':progress')) {
        message.error(t('notification.failure.unknown', { ns: 'notifications' }))
      }
    })
  }

  // METHODS -> AUTH
  // get a new JWT token for the user and set into local storage
  const getAuthNewToken = (successCallback = () => {}, errorCallback = () => {}, msg = 'no_401_msg') => {
    // name for this method
    const name = `authentication@getAuthNewToken:${msg}`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.post(
        `${getAuthBaseUrl()}/user_refresh_token`,
        getURLSearchParamsFromObject({ token_refresh: getAuthUserRefreshToken() }),
        getHeaders()
      ).then(function (response) {
        setAuthUserTokens({ ...response.data, user_refresh_token: getAuthUserRefreshToken() })
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }
  // authenticate user into api
  const authUser = (formData, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = 'authentication@authuser:progress:no_401_msg'
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.post(
        `${getAuthBaseUrl()}/user_login`,
        getURLSearchParamsFromObject(formData),
        getHeaders()
      ).then(function (response) {
        setAuthUserTokens(response.data)
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  const getUserProfiles = (successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = 'authentication@authuser:getUserProfiles'
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getAuthBaseUrl()}/user_profiles/index`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  const getUserPermissionsByProfile = (successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = 'authentication@authuser:getUserPermissionsByProfile'
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getAuthBaseUrl()}/user_profiles/permissions/index`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // APPLICATIONS TYPES
  // APPLICATIONS TYPES :: GET
  const getApplicationTypes = (successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = 'applicationsTypes@getApplicationTypes'
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getApiBaseUrl()}/applications/types/index`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // METHODS MEASUREMENTS UNITS
  // METHODS MEASUREMENTS UNITS :: GET
  const getMeasurementsUnits = (successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = 'measurementsUnits@getMeasurementsUnits'
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getApiBaseUrl()}/measurements/units/index`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // METHODS SUPPLY COMPANIES
  // METHODS SUPPLY COMPANIES :: GET
  const getSupplyCompanies = (successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = 'supplyCompanies@getSupplyCompanies'
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getApiBaseUrl()}/supply/companies/index`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // METHODS RESIDENTIAL (EDYNA)
  const getResidentialUsers = (successCallback = () => {}, errorCallback = () => {}, limit = 30, offset = 0) => {
    // name for this method
    const name = 'residential@getResidentialUsers'
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getApiBaseUrl()}/residential/users/index?limit=${limit}&offset=${offset}`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTIONS :: INDEX
  const getPromotions = (successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = 'promotions@getPromotions'
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getAppBaseUrl()}/promotions/index`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTIONS :: PROMOTION DATA
  const getPromotion = (promotionId, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}@getPromotion`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getAppBaseUrl()}/promotions/${promotionId}/index`,
        getHeaders()
      ).then(function (response) {
        // save user promotion permissions
        _.set(window, `promotion-${promotionId}.promotion_permissions`, _.get(response, 'data.data.promotion_permissions', {}))
        // parse success request
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTIONS :: BLOCKS
  const getPromotionBlocks = (promotionId, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}@getPromotionBlocks`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getAppBaseUrl()}/promotions/${promotionId}/blocks/index`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTIONS :: PROMOTION HOLDER
  const getPromotionHolder = (promotionId, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}@getPromotionHolder`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getAppBaseUrl()}/promotions/${promotionId}/person/holder/index`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTIONS :: PROMOTION BLOCK HOLDER
  const getPromotionBlockHolder = (promotionId, blockId, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}-${blockId}@getPromotionBlockHolder`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getAppBaseUrl()}/promotions/${promotionId}/block/${blockId}/holder/index`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTIONS :: PROMOTION BLOCK PRESIDENT
  const getPromotionBlockPresident = (promotionId, blockId, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}-${blockId}@getPromotionBlockPresident`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getAppBaseUrl()}/promotions/${promotionId}/block/${blockId}/president/index`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTIONS :: PROMOTION BLOCK ADMINISTRATOR
  const getPromotionBlockAdministrator = (promotionId, blockId, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}-${blockId}@getPromotionBlockAdministrator`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getAppBaseUrl()}/promotions/${promotionId}/block/${blockId}/administrator/index`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTIONS :: PROMOTION PRESIDENT
  const getPromotionPresident = (promotionId, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}@getPromotionPresident`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getAppBaseUrl()}/promotions/${promotionId}/person/president/index`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTIONS :: PROMOTION PRESIDENT
  const getPromotionAdministrator = (promotionId, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}@getPromotionAdministrator`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getAppBaseUrl()}/promotions/${promotionId}/person/administrator/index`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTIONS :: PROMOTION CLIENT
  const getPromotionContact = (promotionId, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}@getPromotionContact`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getAppBaseUrl()}/promotions/${promotionId}/person/client/index`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTIONS :: ENERGY SOURCES
  const getPromotionEnergySource = (promotionId, sourceId, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}@getPromotionEnergySource`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getAppBaseUrl()}/promotions/${promotionId}/energy/source/${sourceId}/index`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  const getPromotionEnergySources = (promotionId, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}@getPromotionEnergySources`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getAppBaseUrl()}/promotions/${promotionId}/energy/sources/index`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTIONS :: ENERGY SOURCES SUPPLY INVOICES
  const getPromotionEnergySourcesWithSupplyInvoices = (promotionId, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}@getPromotionEnergySourcesWithSupplyInvoices`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests /:promotion_id/supply/invoices/energy/source/:source_id
      axios.get(
        `${getAppBaseUrl()}/promotions/${promotionId}/energy/sources/index?e_supply_invoices`,
        getHeaders()
      ).then(function (response) {
        // call to callback
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTIONS :: ENERGY SOURCES APPLICATIONS
  const getPromotionEnergySourcesWithApplications = (promotionId, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}@getPromotionEnergySourcesWithApplications`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      axios.get(
        `${getAppBaseUrl()}/promotions/${promotionId}/energy/sources/index?e_source_application&e_source_application_pipes&e_source_application_pipes_sensors`,
        getHeaders()
      ).then(function (response) {
        // call to callback
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTIONS :: ENERGY SOURCES WITH LIQUIDATIONS
  const getPromotionEnergySourcesWithLiquidatedPeriods = (promotionId, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}@getPromotionEnergySourcesWithLiquidations`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      axios.get(
        `${getAppBaseUrl()}/promotions/${promotionId}/energy/sources/index?e_source_liquidated_periods`,
        getHeaders()
      ).then(function (response) {
        // call to callback
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTIONS :: ENERGY SOURCES LIQUIDATION PERIODS
  const getPromotionEnergySourcesWithLiquidationPeriods = (promotionId, period, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}@getPromotionEnergySourcesWithLiquidationPeriods`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      axios.get(
        `${getAppBaseUrl()}/promotions/${promotionId}/energy/sources/index?e_source_liquidation_periods&period=${period}`,
        getHeaders()
      ).then(function (response) {
        // call to callback
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTIONS :: ENERGY SOURCE SUPPLY INVOICES
  // the specified supply invoices for a promotion
  const getPromotionEnergySupplyInvoice = (promotionId, invoiceId, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}-${invoiceId}@getPromotionEnergySupplyInvoice:no_success_msg`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getAppBaseUrl()}/promotions/${promotionId}/supply/invoice/${invoiceId}/index`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTIONS :: ENERGY SOURCE APPLICATION
  // the specified application for a promotion
  const getPromotionEnergySourceApplication = (promotionId, sourceId, applicationId, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}-${sourceId}-${applicationId}@getPromotionEnergySourceApplication:no_success_msg`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getAppBaseUrl()}/promotions/${promotionId}/energy/source/${sourceId}/applications/${applicationId}/index`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTIONS :: SENSORS
  // the sensors for this promotion
  const getPromotionsApplicationsPipesSensors = (promotionId, formData, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}@getPromotionsApplicationsPipesSensors:no_success_msg`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getAppBaseUrl()}/promotions/${promotionId}/applications/pipes/sensors/index`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // the readings of sensor in function of application and pipe
  const getPromotionApplicationPipeSensorReadings = (promotionId, applicationId, pipeId, sensorId, successCallback = () => {}, errorCallback = () => {}, bodyData = null, queryData = {}, limit = 30, offset = 0) => {
    // name for this method
    const name = `promotion-${promotionId}-${applicationId}-${pipeId}-${sensorId}@getPromotionApplicationPipeSensorReadings:no_success_msg`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.post(
        `${getAppBaseUrl()}/promotions/${promotionId}/application/${applicationId}/pipe/${pipeId}/sensor/${sensorId}/readings/searches?limit=${limit}&offset=${offset}`,
        bodyData,
        { ...getHeaders(), params: queryData }
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTIONS :: ENERGY SOURCE SUPPLY INVOICES
  // the supply invoices for a source id
  const getPromotionEnergySourceSupplyInvoices = (promotionId, sourceId, successCallback = () => {}, errorCallback = () => {}, limit = 5, offset = 0) => {
    // name for this method
    const name = `promotion-${promotionId}-${sourceId}@getPromotionEnergySourceSupplyInvoices:table:no_success_msg`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getAppBaseUrl()}/promotions/${promotionId}/energy/source/${sourceId}/supply/invoices/index?limit=${limit}&offset=${offset}`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTIONS :: ENERGY SOURCE LIQUIDATED PERIODS
  // the liquidated periods for a source id
  const getPromotionEnergySourceLiquidatedPeriods = (promotionId, sourceId, successCallback = () => {}, errorCallback = () => {}, limit = 30, offset = 0) => {
    // name for this method
    const name = `promotion-${promotionId}-${sourceId}@getPromotionEnergySourceLiquidatedPeriods:table:no_success_msg`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getAppBaseUrl()}/promotions/${promotionId}/energy/source/${sourceId}/liquidation/index?limit=${limit}&offset=${offset}`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // the liquidated periods for compensable
  const getPromotionEnergySourceLiquidationCompensablePeriods = (promotionId, sourceId, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}-${sourceId}@getPromotionEnergySourceLiquidatedPeriods:table:no_success_msg`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getAppBaseUrl()}/promotions/${promotionId}/energy/source/${sourceId}/liquidation/compensable/now/index`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // the liquidated periods for compensable
  const getPromotionEnergySourceLiquidationPendingCompensationPeriods = (promotionId, sourceId, successCallback = () => {}, errorCallback = () => {}, limit = 30, offset = 0) => {
    // name for this method
    const name = `promotion-${promotionId}-${sourceId}@getPromotionEnergySourceLiquidationPendingCompensationPeriods:table:no_success_msg`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getAppBaseUrl()}/promotions/${promotionId}/energy/source/${sourceId}/liquidation/compensable/pending/index?limit=${limit}&offset=${offset}`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // get the liquidation summary for energy source
  const getPromotionEnergySourceLiquidationSummary = (promotionId, sourceId, start, end, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}-${sourceId}@getPromotionEnergySourceLiquidationSummary:table:no_success_msg`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getAppBaseUrl()}/promotions/${promotionId}/energy/source/${sourceId}/liquidation/summary/index?start=${start.format('YYYYMMDD')}&end=${end.format('YYYYMMDD')}`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTIONS :: BLOCK WITH APARTMENTS
  const getPromotionBlockFloorsWithApartments = (promotionId, blockId, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}-${blockId}@getPromotionBlockFloorsWithApartments`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getAppBaseUrl()}/promotions/${promotionId}/block/${blockId}/floors/index?apartments&persons`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTION :: CREATE ENERGY SUPPLY INVOICE
  const createPromotionEnergySupplyInvoice = (promotionId, formData, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}@createPromotionEnergySupplyInvoice:form:progress`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.post(
        `${getAppBaseUrl()}/promotions/${promotionId}/supply/invoice/create`,
        { data: JSON.stringify(formData) },
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTION :: UPDATE PROMOTION ENERGY SOURCE
  const updatePromotionEnergySource = (promotionId, sourceId, payload, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}-${sourceId}@updatePromotionEnergySource:form:progress`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.put(
        `${getAppBaseUrl()}/promotions/${promotionId}/energy/source/${sourceId}/update`,
        { data: JSON.stringify(payload) },
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTION :: UPDATE PROMOTION APPLICATION
  const updatePromotionEnergySourceApplication = (promotionId, sourceId, applicationId, payload, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}-${sourceId}-${applicationId}@updatePromotionEnergySourceApplication:form:progress`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.put(
        `${getAppBaseUrl()}/promotions/${promotionId}/energy/source/${sourceId}/applications/${applicationId}/update`,
        { data: JSON.stringify(payload) },
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTION :: UPDATE ENERGY SUPPLY INVOICE
  const updatePromotionEnergySupplyInvoice = (promotionId, invoiceId, payload, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}-${invoiceId}@updatePromotionEnergySupplyInvoice:form:progress`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.put(
        `${getAppBaseUrl()}/promotions/${promotionId}/supply/invoice/${invoiceId}/update`,
        { data: JSON.stringify(payload) },
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTION :: DELETE ENERGY SUPPLY INVOICE
  const deletePromotionEnergySupplyInvoice = (promotionId, invoiceId, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}-${invoiceId}@deletePromotionEnergySupplyInvoice:table:progress`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.delete(
        `${getAppBaseUrl()}/promotions/${promotionId}/supply/invoice/${invoiceId}/delete`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTION :: CREATE A LIQUIDATION PERIOD
  const createEnergySourceLiquidatedPeriod = (promotionId, sourceId, payload, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}-${sourceId}@createEnergySourceLiquidatedPeriod:table:progress:custom_errors`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.post(
        `${getAppBaseUrl()}/promotions/${promotionId}/energy/source/${sourceId}/liquidation/create`,
        { data: JSON.stringify(payload) },
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTION :: DELETE A LIQUIDATION PERIOD
  const deleteEnergySourceLiquidatedPeriod = (promotionId, sourceId, liquidationId, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}-${sourceId}-${liquidationId}@deleteEnergySourceLiquidatedPeriod:table:progress:custom_errors`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.delete(
        `${getAppBaseUrl()}/promotions/${promotionId}/energy/source/${sourceId}/liquidation/${liquidationId}/delete`,
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  // PROMOTION :: DOWNLOAD A LIQUIDATION PERIOD
  const downloadEnergySourceLiquidatedPeriod = (promotionId, sourceId, liquidationId, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}-${sourceId}-${liquidationId}@downloadEnergySourceLiquidatedPeriod:table:progress`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.get(
        `${getAppBaseUrl()}/promotions/${promotionId}/energy/source/${sourceId}/liquidation/${liquidationId}/download`,
        { ...getHeaders(), responseType: 'blob' }
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name, true)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name, true)
      })
    })
  }

  // PROMOTION :: UPDATED A ENERGY SUPPLY LIQUIDATION PERIODICITY
  const updatePromotionEnergySupplyLiquidationPeriodicity = (promotionId, sourceId, payload, successCallback = () => {}, errorCallback = () => {}) => {
    // name for this method
    const name = `promotion-${promotionId}-${sourceId}@updatePromotionEnergySupplyLiquidationPeriodicity:table:progress:custom_errors`
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.put(
        `${getAppBaseUrl()}/promotions/${promotionId}/energy/source/${sourceId}/periodicity/update`,
        { data: JSON.stringify(payload) },
        getHeaders()
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  const uploadFile = async (file, successCallback = () => {}, errorCallback = () => {}, onProgress = () => {}, handlerUpdateProgress = () => {}) => {
    // name for this method
    const name = 'dashboard@uploadFile:progress:custom_errors'
    const fmData = new FormData()
    fmData.append('json', file)
    // call to common actions on start request
    onStartRequest(name).then(() => {
      // call requests
      axios.post(
        `${getAppBaseUrl()}/upload/json`,
        fmData,
        {
          ...getHeaders({ 'content-type': 'multipart/form-data' }),
          onUploadProgress: event => {
            const percent = Math.floor((event.loaded / event.total) * 100)
            handlerUpdateProgress(file, percent)
            onProgress({ percent: (event.loaded / event.total) * 100 })
          }
        }
      ).then(function (response) {
        parseSuccessRequests(response, successCallback, name)
      }).catch(function (error) {
        parseFailureRequests(error, errorCallback, name)
      })
    })
  }

  return {
    getAuthNewToken,
    authUser,
    getUserProfiles,
    getUserPermissionsByProfile,
    getPromotion,
    getPromotions,
    getPromotionBlocks,
    getPromotionHolder,
    getPromotionPresident,
    getPromotionContact,
    getPromotionAdministrator,
    getPromotionEnergySource,
    getPromotionEnergySources,
    getPromotionEnergySupplyInvoice,
    getPromotionEnergySourceApplication,
    getPromotionEnergySourceSupplyInvoices,
    getPromotionEnergySourceLiquidatedPeriods,
    getPromotionEnergySourceLiquidationCompensablePeriods,
    getPromotionEnergySourceLiquidationPendingCompensationPeriods,
    getPromotionEnergySourceLiquidationSummary,
    getPromotionEnergySourcesWithApplications,
    getPromotionEnergySourcesWithSupplyInvoices,
    getPromotionEnergySourcesWithLiquidatedPeriods,
    getPromotionEnergySourcesWithLiquidationPeriods,
    getPromotionsApplicationsPipesSensors,
    getPromotionApplicationPipeSensorReadings,
    getPromotionBlockHolder,
    getPromotionBlockPresident,
    getPromotionBlockAdministrator,
    getPromotionBlockFloorsWithApartments,
    getApplicationTypes,
    getMeasurementsUnits,
    getSupplyCompanies,
    getResidentialUsers,
    updatePromotionEnergySourceApplication,
    updatePromotionEnergySource,
    createPromotionEnergySupplyInvoice,
    updatePromotionEnergySupplyInvoice,
    updatePromotionEnergySupplyLiquidationPeriodicity,
    deletePromotionEnergySupplyInvoice,
    createEnergySourceLiquidatedPeriod,
    deleteEnergySourceLiquidatedPeriod,
    downloadEnergySourceLiquidatedPeriod,
    uploadFile
  }
}
