import React from 'react'
import { useEnvironment } from 'hooks/useEnvironment'
import 'rsuite/dist/rsuite.min.css'
import { useParams } from 'react-router-dom'

import { ForbiddenResult } from '../../../../../components/feedbacks'
import { PromotionBlocks } from '../../../../../components/promotion/PromotionBlocks'

// diver with central element
export function PromotionHousingSchema (props) {
  // get current promotion id
  const { promotionId } = useParams()
  // get for check permissions
  const { hasPromotionPermissions } = useEnvironment()
  // get permissions for block
  const hasReadBlockPermission = hasPromotionPermissions(promotionId, 'read:promotion:block')

  return (<>
    {(() => {
      if (!hasReadBlockPermission) {
        return <ForbiddenResult />
      } else { /* return components to render */
        return <PromotionBlocks />
      }
    })()}
  </>)
}
