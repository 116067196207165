import React from 'react'
import { ForbiddenResult } from '../../../components/feedbacks'
import { Col, Menu, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useEnvironment } from '../../../hooks/useEnvironment'

// define locales for this page
const transFiles = ['dashboard', 'fields']


export const PeopleLayout = (props) => {
  // for translation
  const { t } = useTranslation(transFiles)
  // get use environment for check permissions
  const { hasPermissions } = useEnvironment()


  return (<>
    {'JOJOJ'}
      {/* {(() => { */}
      {/*   // return if not permissions to read user edyna */}
      {/*   if (!hasRead) { return <ForbiddenResult /> } */}
      {/*   return ( // return a layout with menu */}
      {/*     <div className={'dashboard-data-section'}> */}
      {/*       <Row> */}
      {/*         <Col className={'dashboard-side-nav'} flex="0 0 96px"> */}
      {/*           <Menu onClick={handlerMenuItemClick} selectedKeys={navigationConfig.menuSelectedKey} style={{ width: '100%' }} mode="inline" items={navigationConfig.menu} /> */}
      {/*         </Col> */}
              { /* Render the selected item */ }
      {/*         <Col className={'dashboard-data'} flex="1 1 auto"> */}
      {/*           { (!navigationConfig.navigate && hasRead) && <Outlet /> } */}
      {/*         </Col> */}
      {/*       </Row> */}
      {/*     </div> */}
      {/*   ) */}
      {/* })()} */}
    </>
  )
}
