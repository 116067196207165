// define locales for this page
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import moment from 'moment'
import { Table } from 'rsuite'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

// for react-suite tables with compact cells
const {
  Column,
  HeaderCell,
  Cell
} = Table

const CompactCell = props => <Cell {...props} style={{ padding: 6 }}/>
const CompactHeaderCell = props => <HeaderCell {...props} style={{ padding: 4 }}/>

const transFiles = ['dashboard']

export const BlockApartmentTable = (props) => {
  // for translation
  const { t } = useTranslation(transFiles)
  // get data
  const data = _.get(props, 'data', [])

  // return data for this table
  const tableData = useMemo(() => {
    // if not data set...
    if (_.isEmpty(data)) { return [] }
    // get date information
    const locale = window.user.user_language_id
    // return formatted data
    return data.map((apart) => {
      // eslint-disable-next-line camelcase
      const { created_at, updated_at } = apart
      // get person names
      return {
        ...apart,
        neighbour_name: _.get(apart, 'apartment_tenant.person_name', ''),
        holder_name: _.get(apart, 'apartment_holder.person_name', ''),
        updated: moment(updated_at, 'YYYY-MM-DD').locale(locale).format('YYYY.MM.DD'),
        created: moment(created_at, 'YYYY-MM-DD').locale(locale).format('YYYY.MM.DD')
      }
    })
  }, [data])

  return (
    <>
      <Table hover={false} autoHeight={true} data={tableData} cellBordered rowHeight={30} headerHeight={30}>
        <Column width={50} align="center" fixed>
          <CompactHeaderCell>Id</CompactHeaderCell>
          <CompactCell dataKey="apartment_id"/>
        </Column>
        <Column width={50} align="center" fixed>
          <CompactHeaderCell>{t('dashboard.floor.door')}</CompactHeaderCell>
          <CompactCell dataKey="apartment_name"/>
        </Column>
        <Column minWidth={150} flexGrow={1} align="left">
          <CompactHeaderCell>{t('dashboard.floor.neighbor')}</CompactHeaderCell>
          <CompactCell dataKey="neighbour_name"/>
        </Column>
        <Column minWidth={150} flexGrow={1} align="left">
          <CompactHeaderCell>{t('dashboard.floor.holder')}</CompactHeaderCell>
          <CompactCell dataKey="holder_name"/>
        </Column>
        <Column width={190} align="center" fixed fullText>
          <CompactHeaderCell>{t('dashboard.apartment.cadastral')}</CompactHeaderCell>
          <CompactCell dataKey="apartment_cadastral_reference"/>
        </Column>
        <Column width={60} align="center" fixed>
          <CompactHeaderCell>m²</CompactHeaderCell>
          <CompactCell dataKey="apartment_m_2"/>
        </Column>
        <Column width={85} align="center" fixed>
          <CompactHeaderCell>{t('dashboard.apartment.property_coefficient_abbr')}</CompactHeaderCell>
          <CompactCell dataKey="apartment_property_coefficient"/>
        </Column>
        <Column width={85} align="center" fixed>
          <CompactHeaderCell>{t('dashboard.apartment.rooms')}</CompactHeaderCell>
          <CompactCell dataKey="apartment_rooms"/>
        </Column>
        <Column width={85} align="center" fixed>
          <CompactHeaderCell>kWh/m² {t('dashboard.year')}</CompactHeaderCell>
          <CompactCell dataKey="apartment_kwh_m2_year"/>
        </Column>
        <Column width={100} align="center" fixed>
          <CompactHeaderCell>KgCO₂/m² {t('dashboard.year')}</CompactHeaderCell>
          <CompactCell dataKey="apartment_equivalent_emissions_kwh_m2_year"/>
        </Column>
        <Column width={90} align={'center'}>
          <CompactHeaderCell>{t('dashboard.updated_at')}</CompactHeaderCell>
          <CompactCell dataKey="updated"/>
        </Column>
        <Column width={90} align={'center'}>
          <CompactHeaderCell>{t('dashboard.created_at')}</CompactHeaderCell>
          <CompactCell dataKey="created"/>
        </Column>
      </Table>
    </>)
}

BlockApartmentTable.propTypes = {
  data: PropTypes.array
}
