import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { Panel } from 'rsuite'
import { Segmented, Space } from 'antd'
import { EmptyResult, ErrorResult, ForbiddenResult, LoaderBackDrop } from 'components/feedbacks'
import { useParams } from 'react-router-dom'
import { useEnvironment } from '../../hooks/useEnvironment'
import { PanelHeader } from '../panels'
import { useRequestsEvents } from '../../hooks/useRequestsEvents'
import { PromotionEnergySupplyLiquidatedPeriodsTable } from './tables/PromotionEnergySupplyLiquidatedPeriodsTable'
import { AiOutlineCalendar } from 'react-icons/ai'
import { MdBalance } from 'react-icons/md'
import { PromotionEnergySupplyLiquidationCompensationPeriodsTable } from './tables/PromotionEnergySupplyLiquidationCompensationPeriodsTable'
import { DrawerForm } from '../forms'
import { BarChartOutlined } from '@ant-design/icons'
import { PromotionEnergySourceLiquidationAnalysisPanel } from './panel/PromotionEnergySourceLiquidationAnalysisPanel'

// create segment on options when segment is rendered
const getSegmentOptions = (data) => {
  return data.map((src) => {
    let name = `(${src.promotion_energy_source_id}) ${src.promotion_energy_source_name}`
    // to display text
    if (src.promotion_energy_source_desc) {
      name = `${name} - ${src.promotion_energy_source_desc}`
    }
    return { label: name, value: _.get(src, 'promotion_energy_source_id', '') }
  })
}

export const PromotionEnergySupplyLiquidationPanel = (props) => {
  // get current promotion id
  const { promotionId } = useParams()
  // get methods to send data
  const { getPromotionEnergySources } = useRequestsEvents()
  // get for check permissions
  const { hasPromotionPermissions } = useEnvironment()
  // component config
  const [componentCfg, setComponentCfg] = useState({
    sources: [],
    defaultMenuSelectedId: null,
    menuSelectedId: null,
    isLoaded: false,
    isError: false,
    isForbidden: false,
    /* analysis drawer control */
    analysisDrawerOpen: false
  }) // get permissions
  const hasReadPermissions = hasPromotionPermissions(promotionId, 'read:promotion:energy:sources') && !componentCfg.isForbidden

  // for child loader process
  const [reloadTable, setReloadTable] = useState(false)
  // disabled flag for component
  const disabled = reloadTable || !componentCfg.isLoaded

  // reload all component (segmentation and table)
  const handlerReloadComponent = () => {
    // not reload if component is loaded...
    if (!componentCfg.isLoaded) { return }
    // not reload if component is not loaded
    setComponentCfg({ ...componentCfg, isError: false, isLoaded: false })
  }

  // on change segmentation, change saved menu selected for refresh a child
  const handlerChangeSegment = (id) => {
    // not change if component is not loaded....
    if (!componentCfg.isLoaded) { return }
    // set new selected id
    setComponentCfg({ ...componentCfg, menuSelectedId: id })
  }

  const handlerDrawerOpen = () => {
    // not reload if component is loaded...
    if (!componentCfg.isLoaded) { return }
    // close form
    setComponentCfg({ ...componentCfg, analysisDrawerOpen: true })
  }

  const handlerDrawerClose = () => {
    // not reload if component is loaded...
    if (!componentCfg.isLoaded) { return }
    // close form
    setComponentCfg({ ...componentCfg, analysisDrawerOpen: false })
  }

  // loading component from first load
  useEffect(() => {
    // clean up controller --> prevent crash with async function
    let isMounted = true
    // not reload if component is loaded...
    if (componentCfg.isLoaded) { return }
    // if not has permissions
    if (!hasReadPermissions) {
      setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, sources: [] })
    } else {
      // get data promotion energy sources
      getPromotionEnergySources(promotionId, (result) => {
        // prevent async crash
        if (!isMounted) return null
        // get default source
        const defaultMenuSelectedId = componentCfg.defaultMenuSelectedId || _.get(result.data, '[0].promotion_energy_source_id', 0)
        const menuSelectedId = componentCfg.menuSelectedId || defaultMenuSelectedId
        // set data to config
        setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, sources: result.data, defaultMenuSelectedId, menuSelectedId, isForbidden: false })
      }, (err) => {
        // prevent async crash
        if (!isMounted) return null
        if (err === 403) {
          setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, sources: [], isForbidden: true })
        } else {
          setComponentCfg({ ...componentCfg, isError: true, isLoaded: true, sources: [], isForbidden: false })
        }
      })
    } // prevent crash with async function
    return () => { isMounted = false }
  }, [componentCfg.isLoaded])

  // create a source tables
  const liquidatedPeriodsTablesPanel = useMemo(() => {
    // if is empty sources
    if (_.isEmpty(componentCfg.sources)) return []
    // parse and return table for each source
    return componentCfg.sources.map((source) => {
      // if hidden component
      const isHidden = componentCfg.menuSelectedId !== source.promotion_energy_source_id
      // return each table for each source
      return (
        <Panel key={source.promotion_energy_source_id} hidden={isHidden} className={'filled-table-panel'} style={{ borderRadius: '4px' }} bordered bodyFill>
          { <PromotionEnergySupplyLiquidatedPeriodsTable isCalledToReload={reloadTable} propagateIsLoaded={setReloadTable} sourceId={source.promotion_energy_source_id} /> }
        </Panel>
      )
    })
  }, [componentCfg.sources, componentCfg.menuSelectedId, reloadTable])

  // create a source tables
  const compensationsPeriodsTablesPanel = useMemo(() => {
    // if is empty sources
    if (_.isEmpty(componentCfg.sources)) return []
    // parse and return table for each source
    return componentCfg.sources.map((source) => {
      // if hidden component
      const isHidden = componentCfg.menuSelectedId !== source.promotion_energy_source_id
      // return each table for each source
      return (
        <Panel key={source.promotion_energy_source_id} hidden={isHidden} className={'filled-table-panel'} style={{ borderRadius: '4px' }} bordered bodyFill>
          { <PromotionEnergySupplyLiquidationCompensationPeriodsTable isCalledToReload={reloadTable} propagateIsLoaded={setReloadTable} sourceId={source.promotion_energy_source_id} /> }
        </Panel>
      )
    })
  }, [componentCfg.sources, componentCfg.menuSelectedId, reloadTable])

  // to set border to first panel
  const isPanelBordered = !hasReadPermissions || !componentCfg.isLoaded || componentCfg.isError || _.isEmpty(componentCfg.sources)

  return (<>
    { /* liquidations panel */ }
    <Panel bodyFill header={ /* Panel header */
      <PanelHeader icon={<AiOutlineCalendar />} title={'dashboard.promotion.liquidation'}
        hasCreate={false}
        /* edit permissions and config */
        hasEdit={false}
        /* delete permissions and config */
        hasDelete={false}
        /* for reload data */
        hasReload={true}
        /* set reload config data */
        hasReloadConfig={{
          method: handlerReloadComponent,
          isDisabled: disabled
        }}
        /* set drawer */
        hasDrawer={true}
        // set drawer
        hasDrawerConfig={[{
          title: 'dashboard.analysis',
          method: handlerDrawerOpen
        }]}
      />
    }>
      <LoaderBackDrop active={!componentCfg.isLoaded} loader background bordered>
        <Panel className={'filled-table-panel'} style={{ borderRadius: '4px' }} bordered={isPanelBordered} bodyFill>
          {(() => { /* load component */
            if (!hasReadPermissions) {
              return <ForbiddenResult compact={true} />
            } else if (componentCfg.isError) {
              return <ErrorResult compact={true} />
            } else if (_.isEmpty(componentCfg.sources)) {
              return <EmptyResult compact={true} />
            } else { /* return components to render */
              return (<>
                <Space className="block-information" direction="vertical" size="middle" style={{ display: 'flex' }}>
                  <Segmented disabled={disabled} defaultValue={componentCfg.defaultMenuSelectedId} value={componentCfg.menuSelectedId} onChange={handlerChangeSegment} options={getSegmentOptions(componentCfg.sources)} />
                  <div>{ liquidatedPeriodsTablesPanel }</div>
                </Space>
              </>)
            }
          })()}
        </Panel>
      </LoaderBackDrop>
    </Panel>
    { /* compensations panel */ }
    <Panel bodyFill header={ /* Panel header */
      <PanelHeader icon={<MdBalance />} title={'dashboard.promotion.pending_compensation'}
        hasCreate={false}
        /* edit permissions and config */
        hasEdit={false}
        /* delete permissions and config */
        hasDelete={false}
        /* for reload data */
        hasReload={true}
        /* set reload config data */
        hasReloadConfig={{
          method: handlerReloadComponent,
          isDisabled: disabled
        }}
        /* set drawer */
        hasDrawer={true}
        // set drawer
        hasDrawerConfig={[{
          method: handlerDrawerOpen
        }]}
      />
    }>
      <LoaderBackDrop active={!componentCfg.isLoaded} loader background bordered>
        <Panel className={'filled-table-panel'} style={{ borderRadius: '4px' }} bordered={isPanelBordered} bodyFill>
          {(() => { /* load component */
            if (!hasReadPermissions) {
              return <ForbiddenResult compact={true} />
            } else if (componentCfg.isError) {
              return <ErrorResult compact={true} />
            } else if (_.isEmpty(componentCfg.sources)) {
              return <EmptyResult compact={true} />
            } else { /* return components to render */
              return (<>
                <Space className="block-information" direction="vertical" size="middle" style={{ display: 'flex' }}>
                  <Segmented disabled={disabled} defaultValue={componentCfg.defaultMenuSelectedId} value={componentCfg.menuSelectedId} onChange={handlerChangeSegment} options={getSegmentOptions(componentCfg.sources)} />
                  <div>{ compensationsPeriodsTablesPanel }</div>
                </Space>
              </>)
            }
          })()}
        </Panel>
      </LoaderBackDrop>
      { componentCfg.analysisDrawerOpen &&
        <DrawerForm width={1065} destroyOnClose={true} title={'dashboard.analysis' } open={componentCfg.analysisDrawerOpen}
                    content={<PromotionEnergySourceLiquidationAnalysisPanel promotionId={parseInt(promotionId)} sourceId={parseInt(componentCfg.menuSelectedId)} /> }
                    extra={<BarChartOutlined style={{ fontSize: '25px' }} />} onClose={handlerDrawerClose} />
      }
    </Panel>
  </>)
}
