import React, { useState } from 'react'
import { ForbiddenResult, LoaderBackDrop } from '../../../../components/feedbacks'
import { PanelHeader, SectionTitle } from '../../../../components/panels'
import { AdministratorIcon, InvoicesIcon } from '../../../../components/icons'
import { useTranslation } from 'react-i18next'
import { useEnvironment } from '../../../../hooks/useEnvironment'
import { Panel } from 'rsuite'
import { EdynaAdministrationsUsersTable } from '../../../../components/promotion/tables/EdynaAdministrationsUsersTable'
import { Space } from 'antd'

// define locales for this page
const transFiles = ['dashboard', 'fields']

export const EdynaUsers = (props) => {
  // for translation
  const { t } = useTranslation(transFiles)
  // get use environment for check permissions
  const { hasPermissions } = useEnvironment()
  // TODO PERMISSIONS
  const hasReadEdynaAdmins = true
  // for child loader process
  const [reloadTable, setReloadTable] = useState(false)
  // disabled flag for component
  const disabled = reloadTable

  // reload all component (segmentation and table)
  const handlerReloadComponent = () => {
    // not reload if component is loaded...
    if (reloadTable) { return }
    // not reload if component is not loaded
    setReloadTable(true)
  }

  const handlerCreateEdynaAdministrator = () => {
    console.info('createEdynaAdministrator')
  }

  return (
    <>
      {(() => {
        if (!hasReadEdynaAdmins) {
          return <ForbiddenResult />
        } else {
          /* render component if user has permissions */
          return (
            <Panel bodyFill header={ /* Panel header */
              <PanelHeader icon={''} title={''}
                /* create permissions and config */
                hasCreate={handlerCreateEdynaAdministrator}
                hasCreateConfig={{
                  title: 'dashboard.promotion.data_title_create_invoice',
                  extra: <InvoicesIcon size={25}/>,
                  content: <>EDYNA ADMIN USERS FORMS</>,
                  isDisabled: disabled
                }}
                /* edit permissions and config */
                hasEdit={false}
                /* delete permissions and config */
                hasDelete={false}
                /* for reload data */
                hasReload={true}
                /* set reload config data */
                hasReloadConfig={{
                  method: handlerReloadComponent,
                  isDisabled: disabled
                }}
              />
            }>
              <LoaderBackDrop active={reloadTable} loader background bordered>
                {(() => { /* load component */
                  if (!hasReadEdynaAdmins) {
                    return <ForbiddenResult compact={true}/>
                  } else { /* return components to render */
                    return (
                      <Panel className={'filled-table-panel'} style={{ borderRadius: '4px' }} bordered bodyFill>
                        <EdynaAdministrationsUsersTable isCalledToReload={reloadTable} propagateIsLoaded={setReloadTable}/>
                      </Panel>
                    )
                  }
                })()}
              </LoaderBackDrop>
            </Panel>
          )
        }
      })()}
    </>
  )
}
