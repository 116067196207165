import React, { useEffect, useMemo, useState } from 'react'
import { Pagination, Table } from 'rsuite'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment/moment'
import { useTable } from '../../../hooks/useTable'
import { useRequestsEvents } from '../../../hooks/useRequestsEvents'
import { InvoicesIcon } from '../../icons'
import { PromotionEnergySupplyInvoicesForm } from '../forms/PromotionEnergySupplyInvoicesForm'
import { DrawerForm } from '../../forms'
import _ from 'lodash'
import { ErrorResult, ForbiddenResult, LoaderBackDrop } from '../../feedbacks'
import { useEnvironment } from '../../../hooks/useEnvironment'
import { useParams } from 'react-router-dom'
import { Button } from 'antd'
import { IoMdRefresh } from 'react-icons/io'
import { getPaginationText } from '../../../helpers/paginations'

const { Column, HeaderCell, Cell } = Table
const CompactCell = props => <Cell {...props} style={{ padding: 6 }} />
const CompactHeaderCell = props => <HeaderCell {...props} style={{ padding: 4 }} />

// define locales for this page
const transFiles = ['dashboard', 'fields']

export const PromotionEnergySupplyInvoicesTable = (props) => {
  // for translation
  const { t } = useTranslation(transFiles)
  // get current promotion id
  const { promotionId } = useParams()
  // get methods to send data
  const { getPromotionEnergySourceSupplyInvoices, deletePromotionEnergySupplyInvoice } = useRequestsEvents()
  // get for check permissions
  const { hasPromotionPermissions } = useEnvironment()
  // get actions from use table
  const { EditAction, DeleteAction } = useTable()
  // get data from props
  const { sourceId, isCalledToReload, propagateIsLoaded } = props
  // component config
  const [componentCfg, setComponentCfg] = useState({
    invoices: [],
    isLoaded: false,
    isChangingPage: false,
    isReloading: false,
    isError: false,
    isForbidden: false,
    // drawer config
    drawer: false,
    selected: null,
    selectedInvoicePeriodIsEditable: false,
    // pagination config
    page: 1,
    total: 0,
    offset: 0,
    limit: 25
  }) // get permissions
  const hasReadSupplyInvoicesEnergyPromotionPermission = hasPromotionPermissions(promotionId, 'read:promotion:energy:supply:invoices') && !componentCfg.isForbidden
  const hasEditSupplyInvoicesEnergyPromotionPermission = hasPromotionPermissions(promotionId, 'update:promotion:energy:supply:invoices') && !componentCfg.isForbidden
  const hasDeleteSupplyInvoicesEnergyPromotionPermission = hasPromotionPermissions(promotionId, 'delete:promotion:energy:supply:invoices') && !componentCfg.isForbidden

  // when end edit invoice (on close drawer)
  const handlerDrawerClose = () => {
    setComponentCfg({ ...componentCfg, drawer: false, selected: null })
  }

  // reload component data
  const handlerReloadComponent = () => {
    // call to reload from segmentation
    setComponentCfg({ ...componentCfg, isError: false, isLoaded: false, isReloading: true })
  }

  // edit invoice action
  const handlerEdit = (data, setActionRunning) => {
    // set state to action working
    setActionRunning(true)
    setComponentCfg({ ...componentCfg, drawer: true, selected: data.promotion_supply_invoice_id, selectedInvoicePeriodIsEditable: Boolean(data.promotion_supply_invoice_is_deletable_max_date) })
    setActionRunning(false)
  }

  // delete invoice action
  const handlerDelete = (data, setActionRunning) => {
    // clean up controller --> prevent crash with async function
    const isMounted = true
    // set state to action working
    setActionRunning(true)
    // request to delete invoice
    deletePromotionEnergySupplyInvoice(data.promotion_id, data.promotion_supply_invoice_id, () => {
      if (!isMounted) return null
      handlerReloadComponent()
      setActionRunning(false)
    }, () => {
      if (!isMounted) return null
      setComponentCfg({ ...componentCfg, isError: true, isLoaded: true, isReloading: false })
      setActionRunning(false)
    })
  }

  // on change page
  const handlerChangePage = (page, skipIsLoading = false) => {
    // not change if component is not loaded....
    if (!componentCfg.isLoaded && !skipIsLoading) { return }
    // set to reload a component
    setComponentCfg({ ...componentCfg, page, offset: (componentCfg.limit * page) - componentCfg.limit, isError: false, isLoaded: false, isChangingPage: true })
  }

  const handlerPageReload = (skipIsLoading = false) => {
    // not change if component is not loaded....
    if (!componentCfg.isLoaded && !skipIsLoading) { return }
    // set to reload a component
    setComponentCfg({ ...componentCfg, isError: false, isLoaded: false, isChangingPage: true })
  }

  // for reload from parent
  useEffect(() => {
    // only when component is loaded and parent refresh is true
    if (!componentCfg.isLoaded || !isCalledToReload) { return }
    // call to reload from segmentation
    handlerReloadComponent()
  }, [isCalledToReload])

  // for load when source id changes...
  useEffect(() => {
    // only when component is loaded...
    if (!componentCfg.isLoaded) { return }
    // call to reload from segmentation
    handlerReloadComponent()
  }, [sourceId])

  // loading component from first load:
  // - only if set source id and is not loading
  useEffect(() => {
    // clean up controller --> prevent crash with async function
    let isMounted = true
    // set observers
    propagateIsLoaded(false)
    // not reload if component is loaded...
    if (componentCfg.isLoaded) { return }
    // if not has permissions
    if (!hasReadSupplyInvoicesEnergyPromotionPermission) {
      setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, invoices: [] })
    } else {
      // get data promotion energy sources
      getPromotionEnergySourceSupplyInvoices(promotionId, sourceId, (result) => {
        // prevent async crash
        if (!isMounted) return null
        // fix delete last item on a page
        if (_.isEmpty(result.data) && result.pagination.total_records > 0) {
          // call to load previous page
          handlerChangePage(result.pagination.total_pages, true)
        } else {
          // set data to config
          setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, isChangingPage: false, isReloading: false, invoices: result.data, total: result.pagination.total_records, isForbidden: false })
        }
      }, (err) => {
        // prevent async crash
        if (!isMounted) return null
        if (err === 403) {
          setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, isChangingPage: false, isReloading: false, invoices: [], total: 0, isForbidden: true })
        } else {
          setComponentCfg({ ...componentCfg, isError: true, isLoaded: true, isChangingPage: false, isReloading: false, invoices: [], total: 0, isForbidden: false })
        }
      }, componentCfg.limit, componentCfg.offset)
    } // prevent crash with async function
    return () => { isMounted = false }
  }, [componentCfg.isLoaded, componentCfg.page])

  // format data for table
  const tableData = useMemo(() => {
    // if not data set...
    if (_.isEmpty(componentCfg.invoices)) { return [] }
    // format data
    return componentCfg.invoices.map((invoice) => {
      // get date information
      const locale = window.user.user_language_id
      // set timestamps
      invoice.updated = moment(invoice.updated_at, 'YYYY-MM-DD').locale(locale).format('YYYY.MM.DD')
      invoice.created = moment(invoice.created_at, 'YYYY-MM-DD').locale(locale).format('YYYY.MM.DD')
      // date and period
      invoice.date_invoice = moment(invoice.promotion_supply_invoice_date, 'YYYY-MM-DD').locale(locale).format('YYYY.MM.DD')
      invoice.date_end = moment(invoice.promotion_supply_invoice_end, 'YYYY-MM-DD').locale(locale)
      invoice.date_start = moment(invoice.promotion_supply_invoice_start, 'YYYY-MM-DD').locale(locale)
      invoice.period_string = `${invoice.date_start.format('YYYY.MM.DD')} - ${invoice.date_end.format('YYYY.MM.DD')}`
      invoice.period_days_string = `${invoice.date_end.add(1, 'days').diff(invoice.date_start, 'days')}`
      // set consumption string and costs string
      invoice.consumption_string = `${invoice.promotion_supply_invoice_consumption.toFixed(2)} ${invoice.promotion_supply_invoice_measurement_unit_symbol}`
      invoice.fix_cost_string = `${invoice.promotion_supply_invoice_fix_cost.toFixed(2)} €`
      invoice.variable_cost_string = `${invoice.promotion_supply_invoice_variable_cost.toFixed(2)} €`
      invoice.tax_string = `${invoice.promotion_supply_invoice_tax}%`
      // set total tax
      invoice.total_string = ((invoice.promotion_supply_invoice_fix_cost + invoice.promotion_supply_invoice_variable_cost) * (1 + (invoice.promotion_supply_invoice_tax / 100))).toFixed(2)
      invoice.total_string = `${invoice.total_string} €`
      // set actions
      invoice.delete_action = <DeleteAction data={invoice} disabled={!hasDeleteSupplyInvoicesEnergyPromotionPermission || !invoice.promotion_supply_invoice_is_deletable_liquidated || !invoice.promotion_supply_invoice_is_deletable_max_date} handler={handlerDelete} />
      invoice.edit_action = <EditAction data={invoice} disabled={!hasEditSupplyInvoicesEnergyPromotionPermission || !invoice.promotion_supply_invoice_is_editable_liquidated} handler={handlerEdit} />
      // return application
      return invoice
    })
  }, [componentCfg.invoices])

  const paginationRangeText = useMemo(() => {
    return getPaginationText(componentCfg)
  }, [componentCfg.page, componentCfg.limit, componentCfg.total])

  const showComponentLoader = !componentCfg.isLoaded && !componentCfg.isChangingPage && !componentCfg.isReloading
  const showTableLoader = !componentCfg.isLoaded || componentCfg.isChangingPage || componentCfg.isReloading

  return (<>
    <LoaderBackDrop active={showComponentLoader} loader background>
      {(() => {
        if (!hasReadSupplyInvoicesEnergyPromotionPermission) {
          return <ForbiddenResult compact={true} />
        } else if (componentCfg.isError) {
          return <ErrorResult compact={true} />
        } else { /* return components to render */
          return (<>
            <Table loading={showTableLoader} hover={false} fillHeight={false} autoHeight={false} height={670} data={tableData} cellBordered rowHeight={30} headerHeight={30}>
              <Column width={50} align="center" fixed>
                <CompactHeaderCell>Id</CompactHeaderCell>
                <CompactCell dataKey="promotion_supply_invoice_id" />
              </Column>
              { /* INVOICE DATES AND NUMBER */ }
              <Column width={90} align="center">
                <CompactHeaderCell>{t('dashboard.date')}</CompactHeaderCell>
                <CompactCell dataKey="date_invoice" />
              </Column>
              <Column width={160} align="center">
                <CompactHeaderCell>{t('dashboard.period')}</CompactHeaderCell>
                <CompactCell dataKey="period_string" />
              </Column>
              <Column width={50} align="center">
                <CompactHeaderCell>{t('dashboard.days')}</CompactHeaderCell>
                <CompactCell dataKey="period_days_string" />
              </Column>
              <Column minWidth={90} flexGrow={1} align="left">
                <CompactHeaderCell>{t('dashboard.number')}</CompactHeaderCell>
                <CompactCell dataKey="promotion_supply_invoice_number" />
              </Column>
              { /* INVOICE MARKETER */ }
              <Column minWidth={90} flexGrow={1} align="left">
                <CompactHeaderCell>{t('fields.labels.supply_marketer', { ns: 'fields' })}</CompactHeaderCell>
                <CompactCell dataKey="promotion_supply_invoice_marketer_name" />
              </Column>
              { /* INVOICE CONSUMPTION */ }
              <Column width={120} align="right">
                <CompactHeaderCell>{t('fields.labels.consumption', { ns: 'fields' })}</CompactHeaderCell>
                <CompactCell dataKey="consumption_string" />
              </Column>
              { /* INVOICE COSTS */ }
              <Column width={90} align="right">
                <CompactHeaderCell>{t('fields.labels.cost_variable', { ns: 'fields' })}</CompactHeaderCell>
                <CompactCell dataKey="variable_cost_string" />
              </Column>
              <Column width={105} align="right">
                <CompactHeaderCell>{t('fields.labels.cost_fix', { ns: 'fields' })}</CompactHeaderCell>
                <CompactCell dataKey="fix_cost_string" />
              </Column>
              <Column width={75} align="right">
                <CompactHeaderCell>{t('fields.labels.tax', { ns: 'fields' })}</CompactHeaderCell>
                <CompactCell dataKey="tax_string" />
              </Column>
              <Column width={100} align="right">
                <CompactHeaderCell>{t('dashboard.total')}</CompactHeaderCell>
                <CompactCell dataKey="total_string" />
              </Column>
              <Column width={25} align="center">
                <CompactHeaderCell></CompactHeaderCell>
                <CompactCell dataKey="edit_action" />
              </Column>
              <Column width={25} align="center">
                <CompactHeaderCell></CompactHeaderCell>
                <CompactCell dataKey="delete_action" />
              </Column>
              { /* INVOICE ACTIONS */ }
              { /* INVOICE DATES CREATED AND UPDATED */ }
              <Column width={90} align={'center'}>
                <CompactHeaderCell>{t('dashboard.created_at')}</CompactHeaderCell>
                <CompactCell dataKey="created" />
              </Column>
            </Table>
            { /* pagination */ }
            <div style={{ padding: '5px 10px', backgroundColor: '#fafafa', borderTop: '1px #f2f2f5 solid' }}>
              <Pagination layout={
                  [paginationRangeText, '-', 'pager', <Button key={_.uniqueId()} onClick={handlerPageReload} type="text" shape="circle" icon={<IoMdRefresh size={18} style={{ marginTop: '2px' }}/>} size={'small'}/>]
                }
                size={'xs'} prev={true} next={true} first={true} last={true}
                maxButtons={5} boundaryLinks={true} ellipsis={true} limit={componentCfg.limit}
                total={componentCfg.total} activePage={componentCfg.page} onChangePage={handlerChangePage}
              />
            </div>
            { /* drawer */ }
            { componentCfg.drawer && <DrawerForm destroyOnClose={true} title={'dashboard.promotion.data_title_edit_invoice'} open={componentCfg.drawer}
              content={
                <PromotionEnergySupplyInvoicesForm promotionId={parseInt(promotionId)} invoiceId={componentCfg.selected}
                 isPeriodEditable={componentCfg.selectedInvoicePeriodIsEditable}
                 onSuccessSubmit={handlerReloadComponent} /> } extra={<InvoicesIcon size={25} />} onClose={handlerDrawerClose} />
            }
          </>)
        }
      })()}
    </LoaderBackDrop>
  </>)
}

PromotionEnergySupplyInvoicesTable.propTypes = {
  sourceId: PropTypes.number.isRequired,
  reload: PropTypes.bool,
  isCalledToReload: PropTypes.bool,
  propagateIsLoaded: PropTypes.func
}
