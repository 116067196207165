import React, { useEffect, useMemo, useState } from 'react'
import { Pagination, Table, Tooltip, Whisper } from 'rsuite'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { ErrorResult, ForbiddenResult, LoaderBackDrop } from '../../feedbacks'
import { useParams } from 'react-router-dom'
import { useRequestsEvents } from '../../../hooks/useRequestsEvents'
import { useEnvironment } from '../../../hooks/useEnvironment'
import { getPaginationText } from '../../../helpers/paginations'
import { Button, Tag } from 'antd'
import { IoMdRefresh } from 'react-icons/io'
import moment from 'moment'
import { MdClose, MdOutlineDone, MdWifiTetheringError, MdWifiTetheringOff } from 'react-icons/md'
import { TbBuildingBroadcastTower, TbCodeAsterisk, TbCodeMinus, TbCodePlus } from 'react-icons/tb'
import { RiBuilding4Line } from 'react-icons/ri'
import { LuCalendarClock } from 'react-icons/lu'

const { Column, HeaderCell, Cell } = Table
const CompactCell = props => <Cell {...props} style={{ padding: 6 }} />
const CompactHeaderCell = props => <HeaderCell {...props} style={{ padding: 4 }} />

// define locales for this page
const transFiles = ['dashboard']

const getNullValue = (reading, key, notEmptyCallback = (value) => { return value }, emptyCallback = (value) => { return value }) => {
  if (reading[key] || reading[key] === 0) {
    return notEmptyCallback(reading[key])
  }
  return emptyCallback('-')
}

const getIconCell = (icon, color, text) => {
  return <Whisper placement="left" speaker={<Tooltip>{text}</Tooltip>}>
    <Tag style={{ margin: 0 }} color={color}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {icon}
        <div style={{ fontSize: 0 }}>{'.'}</div>
      </div>
    </Tag>
  </Whisper>
}

export const PromotionEnergyReadingsTable = (props) => {
  // for translation
  const { t } = useTranslation(transFiles)
  // get current promotion id
  const { promotionId } = useParams()
  // get methods to send data
  const { getPromotionApplicationPipeSensorReadings } = useRequestsEvents()
  // get for check permissions
  const { hasPromotionPermissions } = useEnvironment()
  // get data from props
  const { sensor, date, isCalledToReload, propagateIsLoaded } = props
  // sensor id
  const sensorId = _.get(sensor, 'promotion_energy_application_pipe_sensor_combined_id', -1)
  // component config
  const [componentCfg, setComponentCfg] = useState({
    readings: [],
    isLoaded: false,
    isChangingPage: false,
    isReloading: false,
    isError: false,
    isForbidden: false,
    // pagination config
    page: 1,
    total: 0,
    offset: 0,
    limit: 25
  }) // permissions
  const hasReadEnergyReadingsPromotionPermission = hasPromotionPermissions(promotionId, 'read:promotion:energy:applications:pipes:sensors:readings') && !componentCfg.isForbidden
  // const hasCreateEnergyReadingsPromotionPermission = hasPromotionPermissions(promotionId, 'create:promotion:energy:applications:pipes:sensors:readings')

  // reload component data
  const handlerReloadComponent = () => {
    // call to reload from segmentation
    setComponentCfg({ ...componentCfg, isError: false, isLoaded: false, isReloading: true })
  }

  // on change page
  const handlerChangePage = (page, skipIsLoading = false) => {
    // not change if component is not loaded....
    if (!componentCfg.isLoaded && !skipIsLoading) { return }
    // set to reload a component
    setComponentCfg({ ...componentCfg, page, offset: (componentCfg.limit * page) - componentCfg.limit, isError: false, isLoaded: false, isChangingPage: true })
  }

  const handlerPageReload = (skipIsLoading = false) => {
    // not change if component is not loaded....
    if (!componentCfg.isLoaded && !skipIsLoading) { return }
    // set to reload a component
    setComponentCfg({ ...componentCfg, isError: false, isLoaded: false, isChangingPage: true })
  }

  // for reload from parent
  useEffect(() => {
    // only when component is loaded and parent refresh is true
    if (!componentCfg.isLoaded || !isCalledToReload) { return }
    // call to reload from segmentation
    handlerReloadComponent()
  }, [isCalledToReload])

  // for load when sensor id changes...
  useEffect(() => {
    // only when component is loaded...
    if (!componentCfg.isLoaded) { return }
    // call to reload from segmentation
    handlerReloadComponent()
  }, [sensorId])

  // for load when sensor id changes...
  useEffect(() => {
    // only when component is loaded...
    if (!componentCfg.isLoaded) { return }
    // call to reload from segmentation
    handlerReloadComponent()
  }, [date])

  // loading component from first load:
  // - only if set source id and is not loading
  useEffect(() => {
    // clean up controller --> prevent crash with async function
    let isMounted = true
    // set observers
    propagateIsLoaded(false)
    // not reload if component is loaded...
    if (componentCfg.isLoaded) { return }
    // if not has permissions
    if (!hasReadEnergyReadingsPromotionPermission) {
      setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, readings: [] })
    } else {
      // get url params
      // sensor id
      const applicationId = _.get(sensor, 'promotion_energy_application_id', -1)
      // sensor id
      const pipeId = _.get(sensor, 'promotion_energy_application_pipe_id', -1)
      // sensor id
      const sensorId = _.get(sensor, 'promotion_energy_application_pipe_sensor_id', -1)
      // get data promotion energy sources
      getPromotionApplicationPipeSensorReadings(promotionId, applicationId, pipeId, sensorId, (result) => {
        // prevent async crash
        if (!isMounted) return null
        // fix for delete item in last page
        if (_.isEmpty(result.data) && result.pagination.total_records > 0) {
          // call to load previous page
          handlerChangePage(result.pagination.total_pages, true)
        } else {
          // set data to config
          setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, isChangingPage: false, isReloading: false, readings: result.data, total: result.pagination.total_records, isForbidden: false })
        }
      }, (err) => {
        // prevent async crash
        if (!isMounted) return null
        if (err === 403) {
          setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, isChangingPage: false, isReloading: false, readings: [], total: 0, isForbidden: true })
        } else {
          setComponentCfg({ ...componentCfg, isError: true, isLoaded: true, isChangingPage: false, isReloading: false, readings: [], total: 0, isForbidden: false })
        }
      }, null, {
        date: date.format('YYYYMMDD')
      }, componentCfg.limit, componentCfg.offset)
    } // prevent crash with async function
    return () => { isMounted = false }
  }, [componentCfg.isLoaded, componentCfg.page])

  // format data for table
  const tableData = useMemo(() => {
    // if not data set...
    if (_.isEmpty(componentCfg.readings)) { return [] }
    // format data
    return componentCfg.readings.map((reading) => {
      reading.block_string = reading.block_name
      reading.floor_string = reading.floor_name
      reading.apartment_name_string = reading.apartment_name
      reading.apartments_reading_id_string = getNullValue(reading, 'apartments_reading_id')
      reading.apartments_reading_days_string = getNullValue(reading, 'apartments_reading_days')
      // get locale
      const locale = window.user.user_language_id
      // set dates
      reading.date_end = getNullValue(reading, 'apartments_reading_date_end', (value) => {
        return moment(value, 'YYYYMMDD').locale(locale).format('YYYY.MM.DD')
      })
      reading.date_start = getNullValue(reading, 'apartments_reading_date_start', (value) => {
        return moment(value, 'YYYYMMDD').locale(locale).format('YYYY.MM.DD')
      })
      reading.period_string = `${reading.date_start} - ${reading.date_end}`
      // set numbers
      reading.apartments_reading_consumption_start_string = getNullValue(reading, 'apartments_reading_consumption_start', (value) => {
        return `${(value).toFixed(2)} ${reading.apartments_reading_consumption_unit}`
      })
      reading.apartments_reading_consumption_end_string = getNullValue(reading, 'apartments_reading_consumption_end', (value) => {
        return `${(value).toFixed(2)} ${reading.apartments_reading_consumption_unit}`
      })
      reading.apartments_reading_consumption_total_string = getNullValue(reading, 'apartments_reading_consumption_total', (value) => {
        return `${(value).toFixed(2)} ${reading.apartments_reading_consumption_unit}`
      })
      reading.convert_factor_string = getNullValue(reading, 'promotion_energy_application_pipe_sensor_convert_factor', (value) => {
        return (value).toFixed(2)
      })
      reading.apartments_reading_consumption_converted_string = getNullValue(reading, 'apartments_reading_consumption_converted', (value) => {
        return `${(value).toFixed(2)} ${reading.apartments_reading_consumption_unit_converted}`
      })
      // set types
      reading.apartments_reading_status_component = getNullValue(reading, 'apartments_reading_status', (value) => {
        if (value === -1) { // -1 tot ok
          return getIconCell(<span style={{ height: 16, width: 16, paddingLeft: 3 }}><MdOutlineDone size={10}/></span>, 'default', 'Ok')
        } else if (value === 1) { // 1 No sensor / No data sensor
          return getIconCell(<MdWifiTetheringOff size={16}/>, 'volcano', t('dashboard.sensor_status_1'))
        } else if (value === 2) { // 2 Communication error
          return getIconCell(<MdWifiTetheringError size={16}/>, 'volcano', t('dashboard.sensor_status_2'))
        } else if (value === 3 || value === 6) { // high consumption
          return getIconCell(<TbCodePlus size={16}/>, 'gold', t('dashboard.sensor_status_3_6'))
        } else if (value === 4 || value === 7) { // 0 consumption
          return getIconCell(<TbCodeAsterisk size={16}/>, 'gold', t('dashboard.sensor_status_4_7'))
        } else if (value === 5 || value === 8) { // negative consumption
          return getIconCell(<TbCodeMinus size={16}/>, 'gold', t('dashboard.sensor_status_5_8'))
        }
      })
      // set types
      reading.apartments_reading_type_component = getNullValue(reading, 'apartments_reading_type', (value) => {
        /* 1 on-site -  2 remote - 3 estimated */
        if (value === 1) {
          return getIconCell(<RiBuilding4Line size={15}/>, 'blue', t('dashboard.on_site'))
        } else if (value === 2) {
          return getIconCell(<TbBuildingBroadcastTower size={15}/>, 'default', t('dashboard.remote_measurement'))
        } else if (value === 3) {
          return getIconCell(<LuCalendarClock size={15}/>, 'green', t('dashboard.estimated'))
        }
      })
      // created updated
      reading.updated = getNullValue(reading, 'updated_at', (value) => {
        return moment(value, 'YYYYMMDD').locale(locale).format('YYYY.MM.DD')
      })
      reading.created = getNullValue(reading, 'created_at', (value) => {
        return moment(value, 'YYYYMMDD').locale(locale).format('YYYY.MM.DD')
      })
      // is fake component
      reading.apartments_reading_is_updatable_component = getNullValue(reading, 'apartments_reading_is_updatable', (value) => {
        return value === 1 ? <MdClose /> : <MdOutlineDone/>
      })
      return reading
    })
  }, [componentCfg.readings])

  const paginationRangeText = useMemo(() => {
    return getPaginationText(componentCfg)
  }, [componentCfg.page, componentCfg.limit, componentCfg.total])

  const showComponentLoader = !componentCfg.isLoaded && !componentCfg.isChangingPage && !componentCfg.isReloading
  const showTableLoader = !componentCfg.isLoaded || componentCfg.isChangingPage || componentCfg.isReloading

  return (<>
    <LoaderBackDrop active={showComponentLoader} loader background>
      {(() => {
        if (!hasReadEnergyReadingsPromotionPermission) {
          return <ForbiddenResult compact={false} />
        } else if (componentCfg.isError) {
          return <ErrorResult compact={true} />
        } else { /* return components to render */
          return (<>
            <Table loading={showTableLoader} hover={false} fillHeight={false} autoHeight={false} height={670} data={tableData} cellBordered rowHeight={30} headerHeight={30}>
              <Column width={35} align="center" fixed>
                <CompactHeaderCell>
                  <Whisper speaker={<Tooltip>{t('dashboard.is_definitive')}</Tooltip>}><span>D.*</span></Whisper>
                </CompactHeaderCell>
                <CompactCell dataKey="apartments_reading_is_updatable_component" />
              </Column>
              <Column width={180} align="center" fixed>
                <CompactHeaderCell>Id</CompactHeaderCell>
                <CompactCell dataKey="apartments_reading_id_string" />
              </Column>
              <Column width={160} align="center">
                <CompactHeaderCell>{t('dashboard.period')}</CompactHeaderCell>
                <CompactCell dataKey="period_string" />
              </Column>
              <Column width={50} align="center">
                <CompactHeaderCell>{t('dashboard.days')}</CompactHeaderCell>
                <CompactCell dataKey="apartments_reading_days_string" />
              </Column>
              <Column width={80} /* flexGrow={1} */ align="center">
                <CompactHeaderCell>{t('dashboard.block.name')}</CompactHeaderCell>
                <CompactCell dataKey="block_name" />
              </Column>
              <Column width={65} /* flexGrow={1} */ align="center">
                <CompactHeaderCell>{t('dashboard.floor.name')}</CompactHeaderCell>
                <CompactCell dataKey="floor_name" />
              </Column>
              <Column width={65} /* flexGrow={1} */ align="center">
                <CompactHeaderCell>{t('dashboard.floor.door')}</CompactHeaderCell>
                <CompactCell dataKey="apartment_name_string" />
              </Column>
              <Column width={100} flexGrow={1} align="right">
                <CompactHeaderCell>{t('dashboard.promotion.energy_reading_start')}</CompactHeaderCell>
                <CompactCell dataKey="apartments_reading_consumption_start_string" />
              </Column>
              <Column width={100} flexGrow={1} align="right">
                <CompactHeaderCell>{t('dashboard.promotion.energy_reading_end')}</CompactHeaderCell>
                <CompactCell dataKey="apartments_reading_consumption_end_string" />
              </Column>
              <Column width={90} flexGrow={1} align="right">
                <CompactHeaderCell>{t('dashboard.consumption')}</CompactHeaderCell>
                <CompactCell dataKey="apartments_reading_consumption_total_string" />
              </Column>
              <Column width={70} align="center">
                <CompactHeaderCell>{t('dashboard.promotion.conversion_factor')}</CompactHeaderCell>
                <CompactCell dataKey="convert_factor_string" />
              </Column>
              <Column width={90} flexGrow={1} align="right">
                <CompactHeaderCell>{t('dashboard.consumption')}</CompactHeaderCell>
                <CompactCell dataKey="apartments_reading_consumption_converted_string" />
              </Column>
              <Column width={50} align="center">
                <CompactHeaderCell>{t('dashboard.state')}</CompactHeaderCell>
                <CompactCell dataKey="apartments_reading_status_component" />
              </Column>
              <Column width={50} align="center">
                <CompactHeaderCell>{t('dashboard.type')}</CompactHeaderCell>
                <CompactCell dataKey="apartments_reading_type_component" />
              </Column>
              { /* INVOICE DATES CREATED AND UPDATED */ }
              <Column width={90} align={'center'}>
                <CompactHeaderCell>{t('dashboard.updated_at')}</CompactHeaderCell>
                <CompactCell dataKey="updated" />
              </Column>
            </Table>
            { /* pagination */ }
            <div style={{ padding: '5px 10px', backgroundColor: '#fafafa', borderTop: '1px #f2f2f5 solid' }}>
              <Pagination layout={
                  [paginationRangeText, '-', 'pager', <Button key={_.uniqueId()} onClick={handlerPageReload} type="text" shape="circle" icon={<IoMdRefresh size={18} style={{ marginTop: '2px' }}/>} size={'small'}/>]
                }
                size={'xs'} prev={true} next={true} first={true} last={true}
                maxButtons={5} boundaryLinks={true} ellipsis={true} limit={componentCfg.limit}
                total={componentCfg.total} activePage={componentCfg.page} onChangePage={handlerChangePage}
              />
            </div>
          </>)
        }
      })()}
    </LoaderBackDrop>
  </>)
}

PromotionEnergyReadingsTable.propTypes = {
  date: PropTypes.object.isRequired,
  sensor: PropTypes.object.isRequired,
  reload: PropTypes.bool,
  isCalledToReload: PropTypes.bool,
  propagateIsLoaded: PropTypes.func
}
