import React from 'react'
import { useParams } from 'react-router-dom'
import { useEnvironment } from '../../../../../hooks/useEnvironment'
import { PromotionEnergyReadingsPanel } from '../../../../../components/promotion/PromotionEnergyReadingsPanel'
import { ForbiddenResult } from '../../../../../components/feedbacks'

// diver with central element
export function PromotionReadings () {
  // get current promotion id
  const { promotionId } = useParams()
  // get for check permissions
  const { hasPromotionPermissions } = useEnvironment()
  // read energy data
  const hasReadEnergyReadingsPromotionPermission = hasPromotionPermissions(promotionId, 'read:promotion:energy:applications:pipes:sensors:readings')

  return (<>
      {(() => {
        if (!hasReadEnergyReadingsPromotionPermission) {
          return <ForbiddenResult />
        } else { /* return components to render */
          return <PromotionEnergyReadingsPanel />
        }
      })()}
  </>)
}
