// import fonts
import '@fontsource/montserrat'
import '@fontsource/teko'
import '@fontsource/inter'
import '@fontsource/open-sans'
// import styles
import 'styles/globals.css'
import 'styles/loader.scss'
import 'styles/divider.css'
import 'styles/fields.css'
import 'styles/login.css'
import 'styles/dashboard.scss'
// import moment locales
import 'moment/locale/en-gb'
import 'moment/locale/es'
import 'moment/locale/ca'
// get components
import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useEnvironment } from 'hooks/useEnvironment'
import { useRequestsEvents } from 'hooks/useRequestsEvents'
import _ from 'lodash'
// https://github.com/rsuite/rsuite
// https://rsuitejs.com/components/sidenav/

const MainLoaderComponent = () => {
  return (<div style={{ paddingTop: '12px', paddingLeft: '12px' }}>Loading...</div>)
}

let tokenIntervalRenewId

// create a main app
export const Main = () => {
  // for redirect
  const navigate = useNavigate()
  // for redirect to requested location
  const location = useLocation()
  // get function to see a current locale
  const { getCurrentLocale, setCurrentLocale, isDevelopmentContext, isUserTokens } = useEnvironment()
  // get for check a user authentication
  const { getAuthNewToken } = useRequestsEvents()
  // component config
  const [componentCfg, setComponentCfg] = useState({
    isRedirectingByLanguage: true,
    isCheckedRemoteTokens: false,
    isCheckedLocalTokens: false,
    isUserAuth: false,
    isLoadedTokenRenew: false
  }) // component to render
  const [component, setComponent] = useState(false)
  // get is protected path flag
  const isProtectedPath = (location.pathname.indexOf('dashboard') > -1 || location.pathname.indexOf('profiles') > -1)

  // action when app is loading...
  // - load language from local storage if is needed
  useEffect(() => {
    // not load if not redirecting...
    if (!componentCfg.isRedirectingByLanguage) { return }
    // set current locale
    setCurrentLocale(getCurrentLocale())
    // set off redirecting by language
    setComponentCfg({ ...componentCfg, isRedirectingByLanguage: false })
  }, [componentCfg.isRedirectingByLanguage, getCurrentLocale, setCurrentLocale])

  // only execute if isRedirectingByLanguage change to false and is loading
  // execute only when component is mounted at firs time
  // check the current tokens are valid on first load
  // - load credentials from database if tokens exists
  useEffect(() => {
    // only execute is not redirecting or not is loading auth
    if (componentCfg.isRedirectingByLanguage || componentCfg.isCheckedRemoteTokens) { return }
    // -- load dashboard is user is logged in
    getAuthNewToken(() => {
      setComponentCfg({ ...componentCfg, isCheckedRemoteTokens: true, isUserAuth: true })
    }, () => {
      setComponentCfg({ ...componentCfg, isCheckedRemoteTokens: true, isUserAuth: false })
    })
  }, [componentCfg.isRedirectingByLanguage])

  // when change path: navigates, redirects, history...
  // check the current tokens are valid after first load
  useEffect(() => {
    // only execute is not redirecting or not is loading auth
    if (componentCfg.isRedirectingByLanguage || !componentCfg.isCheckedRemoteTokens) { return }
    // check if user tokens are set
    if (isUserTokens()) {
      setComponentCfg({ ...componentCfg, isCheckedLocalTokens: true, isUserAuth: true })
    } else {
      setComponentCfg({ ...componentCfg, isCheckedLocalTokens: true, isUserAuth: false })
    }
  }, [location.pathname, componentCfg.isCheckedRemoteTokens])

  // for redirects in special cases
  useEffect(() => {
    // redirect on local path name is not set
    if (location.pathname === '/' || !location.pathname || location.pathname === '') {
      navigate('/auth/login')
    // if checked not checked tokens
    } if (!componentCfg.isCheckedLocalTokens || !componentCfg.isCheckedRemoteTokens) {
      setComponent(<MainLoaderComponent />)
      // if user is auth and pathname is a login
    } else if (componentCfg.isUserAuth && location.pathname === '/auth/login') {
      setComponent(<MainLoaderComponent />)
      if (_.get(window, 'user.user_role', '-1').includes('overall')) {
        navigate('/dashboard/0')
      } else {
        navigate('/profiles')
      }
      // if not is protected path, return url requested
    } else if (!isProtectedPath) {
      setComponent(<Outlet></Outlet>)
      // if is protected path and is auth, return url requested
    } else if (componentCfg.isUserAuth) {
      setComponent(<Outlet></Outlet>)
    // if is protected path but is not auth, return login
    } else if (location.pathname !== '/auth/login') {
      setComponent(<MainLoaderComponent />)
      navigate('/auth/login')
    }
  }, [location.pathname, componentCfg.isCheckedLocalTokens, componentCfg.isUserAuth])

  // for redirects in special cases
  useEffect(() => {
    // remove refresh interval loop
    if (!componentCfg.isCheckedLocalTokens || !componentCfg.isCheckedRemoteTokens || componentCfg.isLoadedTokenRenew || !componentCfg.isUserAuth) {
      if (tokenIntervalRenewId) clearInterval(tokenIntervalRenewId)
    // set interval loop to refresh
    } else {
      tokenIntervalRenewId = setInterval(() => {
        getAuthNewToken(() => {}, () => {}, '401_msg')
      }, 2700000) // each 45 min, expires at 1 hour
    }
    return () => { if (tokenIntervalRenewId) clearInterval(tokenIntervalRenewId) }
  }, [componentCfg.isLoadedTokenRenew, componentCfg.isCheckedLocalTokens, componentCfg.isCheckedRemoteTokens, componentCfg.isUserAuth])

  return (
    <>
    { !component ? <MainLoaderComponent /> : component }
    { isDevelopmentContext && <div className="devel-banner">Development</div> }
    </>
  )
}
