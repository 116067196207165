import { useParams } from 'react-router-dom'
import { useRequestsEvents } from '../../hooks/useRequestsEvents'
import { useEnvironment } from '../../hooks/useEnvironment'
import React, { useEffect, useState } from 'react'
import { EmptyResult, ErrorResult, ForbiddenResult, LoaderBackDrop } from '../feedbacks'
import { PromotionTable } from './tables/PromotionTable'
import _ from 'lodash'
import { Panel } from 'rsuite'
import { PanelHeader } from '../panels'
import { RiProfileLine } from 'react-icons/ri'

export function PromotionPanel (props) {
  // get current promotion id
  const { promotionId } = useParams()
  // for get promotions
  const { getPromotion } = useRequestsEvents()
  // get for check permissions
  const { hasPromotionPermissions } = useEnvironment()
  // promotion permission
  const hasReadPromotionPermission = hasPromotionPermissions(promotionId, 'read:promotion')
  // component config
  const [componentCfg, setComponentCfg] = useState({
    data: {},
    isLoaded: false,
    isError: false
  })

  // for load all promotions data when component is mounted
  useEffect(() => {
    // clean up controller --> prevent crash with async function
    let isMounted = true
    // not reload if component is loaded...
    if (componentCfg.isLoaded) { return }
    // get promotion needed
    getPromotion(promotionId, (result) => {
      // prevent async crash
      if (!isMounted) return null
      setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, data: result.data })
    }, () => {
      // prevent async crash
      if (!isMounted) return null
      setComponentCfg({ ...componentCfg, isError: true, isLoaded: true, data: {} })
    }) // prevent crash with async function
    return () => { isMounted = false }
  }, [componentCfg.isLoaded])

  return (<>
    <Panel bodyFill header={
      <PanelHeader icon={<RiProfileLine />} title={'dashboard.description'} hasDelete={false} hasEdit={false} hasCreate={false} hasReload={false} />
    }>
      <LoaderBackDrop active={!componentCfg.isLoaded} loader background={'white'} top>
        <Panel className={'filled-table-panel'} style={{ borderRadius: '4px' }} bodyFill>
            {(() => {
              if (!hasReadPromotionPermission) {
                return <ForbiddenResult compact={true} />
              } else if (componentCfg.isError) {
                return <ErrorResult compact={true} />
              } else if (_.isEmpty(componentCfg.data)) {
                return <EmptyResult compact={true} />
              } else { /* return components to render */
                return <PromotionTable data={componentCfg.data} />
              }
            })()}
        </Panel>
      </LoaderBackDrop>
    </Panel>
  </>)
}
