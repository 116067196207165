import React, { useEffect, useState } from 'react'
import { Panel } from 'rsuite'
import 'rsuite/dist/rsuite.min.css'
import { PanelHeader } from '../panels'
import { AdministratorIcon } from '../icons'
import { useParams } from 'react-router-dom'
import { useEnvironment } from '../../hooks/useEnvironment'
import { useRequestsEvents } from '../../hooks/useRequestsEvents'
import PropTypes from 'prop-types'
import { EmptyResult, ErrorResult, LoaderBackDrop } from '../feedbacks'
import _ from 'lodash'
import { PromotionPersonTable } from './tables/PromotionPersonTable'

export const PromotionPersonBlockAdministratorPanel = (props) => {
  // get current promotion id
  const { promotionId } = useParams()
  // get block id
  const { blockId } = props
  // for get promotions
  const { getPromotionBlockAdministrator } = useRequestsEvents()
  // component config
  const [componentCfg, setComponentCfg] = useState({
    data: {},
    show: true,
    isLoaded: false,
    isError: false,
    isForbidden: false
  }) // get permissions
  const { hasPromotionPermissions } = useEnvironment()
  const hasReadAdminBlockPermission = hasPromotionPermissions(promotionId, 'read:promotion:block:admin') && !componentCfg.isForbidden
  const hasUpdateAdminBlockPermission = hasPromotionPermissions(promotionId, 'update:promotion:block:admin') && !componentCfg.isForbidden
  const hasDeleteAdminBlockPermission = hasPromotionPermissions(promotionId, 'delete:promotion:block:admin') && !componentCfg.isForbidden

  // for load all promotions data when component is mounted
  useEffect(() => {
    // clean up controller --> prevent crash with async function
    let isMounted = true
    // not reload if component is loaded...
    if (componentCfg.isLoaded) { return }
    // if not has permissions
    if (!hasReadAdminBlockPermission) {
      setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, show: false, data: {} })
    } else {
      // get promotion holder data
      getPromotionBlockAdministrator(promotionId, blockId, (result) => {
        // prevent async crash
        if (!isMounted) return null
        // get holder data
        const data = result.data
        // set holder data
        setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, show: !!(data), data, isForbidden: false })
      }, (err) => {
        // prevent async crash
        if (!isMounted) return null
        if (err === 403) {
          setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, show: true, data: {}, isForbidden: true })
        } else {
          setComponentCfg({ ...componentCfg, isError: true, isLoaded: true, show: true, data: {}, isForbidden: false })
        }
      })
    } // prevent crash with async function
    return () => { isMounted = false }
  }, [componentCfg.isLoaded])

  return (<>
    {(() => { /* check permissions */
      if (!hasReadAdminBlockPermission || _.isEmpty(componentCfg.data)) {
        return <></>
      } else { /* render a panel */
        return (
          <Panel bodyFill header={
            <PanelHeader icon={<AdministratorIcon />} title={'dashboard.block.data_title_administrator'} hasEdit={hasUpdateAdminBlockPermission} hasDelete={hasDeleteAdminBlockPermission} />
          }>
            <LoaderBackDrop active={!componentCfg.isLoaded} loader background bordered>
              <Panel className={'filled-table-panel'} style={{ borderRadius: '4px' }} bordered bodyFill>
                  { /* load component */ }
                  {(() => {
                    if (componentCfg.isError) {
                      return <ErrorResult compact={true}/>
                    } else if (_.isEmpty(componentCfg.data)) {
                      return <EmptyResult compact={true} />
                    } else { /* return components to render */
                      return <PromotionPersonTable data={componentCfg.data}/>
                    }
                  })()}
              </Panel>
            </LoaderBackDrop>
          </Panel>
        )
      }
    })()}
  </>)
}

PromotionPersonBlockAdministratorPanel.propTypes = {
  blockId: PropTypes.number.isRequired
}
