import React, { useMemo, useState } from 'react'
import { ForbiddenResult } from '../../../components/feedbacks'
import { AppSettingsIcon } from '../../../components/icons'
import { useTranslation } from 'react-i18next'
import { useEnvironment } from '../../../hooks/useEnvironment'
import { SectionContent, SectionMenu, SectionTitle } from '../../../components/panels'
import { useNavigate, useParams } from 'react-router-dom'
import useDidMountEffect from '../../../hooks/useDidMountEffect'
import { EdynaUsers } from './sections/EdynaUsers'

// define locales for this page
const transFiles = ['dashboard', 'fields']

const tabMenuItems = [{
  label: 'dashboard.users',
  key: 'users'
}]

export const EdynaLayout = (props) => {
  // for translation
  const { t } = useTranslation(transFiles)
  // get use environment for check permissions
  const { hasPermissions } = useEnvironment()
  // for redirect
  const navigate = useNavigate()
  // get current promotion id
  const { resourceId } = useParams()
  // TODO PERMISSIONS
  const hasReadEdynaUsers = true
  const hasReadEdynaAdmins = true
  const hasRead = hasReadEdynaUsers && hasReadEdynaAdmins

  // navigate config
  const [navigationConfig, setNavigationConfig] = useState({
    link: null,
    navigate: false
  })

  // when any item on menu is clicked...
  const handlerMenuItemClick = (key) => {
    // if current location is the same of selected key...
    if (resourceId === key) { return }
    // set config to navigate
    setNavigationConfig({ ...navigationConfig, key, navigate: true })
  }

  // on change promotion id call to reload component
  useDidMountEffect(() => {
    // if not navigate...
    if (!navigationConfig.navigate) { return }
    // go to
    navigate(`/dashboard/edyna/${navigationConfig.key}`)
    // set navigated
    setNavigationConfig({ ...navigationConfig, navigate: false })
  }, [navigationConfig])

  // only when change resource id
  const component = useMemo(() => {
    /* return component in function of resource in url */
    if (resourceId === 'users') {
      /* promotion data */
      return <EdynaUsers />
    }
  }, [resourceId])

  return (<>
      {(() => {
        // return if not permissions to read user edyna
        if (!hasRead) {
          return <ForbiddenResult />
        } else {
          return ( // return a layout with menu
            <div className={'dashboard-data-section'}>
              <SectionTitle title={'Edyna'} icon={<AppSettingsIcon size={18} />} />
              { /* menu */ }
              <SectionMenu activeKey={resourceId} handler={handlerMenuItemClick} items={tabMenuItems}/>
              {/* content */}
              <SectionContent component={component} />
            </div>)
        }
      })()}
    </>
  )
}
