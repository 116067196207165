import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { BiSubdirectoryRight } from 'react-icons/bi'
import moment from 'moment/moment'
import { MdOutlineDone, MdClose } from 'react-icons/md'
import { Table } from 'rsuite'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

const { Column, HeaderCell, Cell } = Table
const CompactCell = props => <Cell {...props} style={{ padding: 6 }} />
const CompactHeaderCell = props => <HeaderCell {...props} style={{ padding: 4 }} />

// define locales for this page
const transFiles = ['dashboard']

export const PromotionEnergySourceApplicationPipesSensorsTable = (props) => {
  // for translation
  const { t } = useTranslation(transFiles)
  // get data
  const data = _.get(props, 'data', [])

  // format data for table
  const tableData = useMemo(() => {
    // if not data set...
    if (_.isEmpty(data)) { return [] }
    // format data
    return data.map((sensor) => {
      sensor.icon = <BiSubdirectoryRight size={20}/>
      // get date information
      const locale = window.user.user_language_id
      // set timestamps
      sensor.updated = moment(sensor.updated_at, 'YYYY-MM-DD').locale(locale).format('YYYY.MM.DD')
      sensor.created = moment(sensor.created_at, 'YYYY-MM-DD').locale(locale).format('YYYY.MM.DD')
      // get flag is active
      const isActive = _.get(sensor, 'promotion_energy_application_pipe_sensor_is_active')
      // eslint-disable-next-line no-constant-condition
      sensor.is_active = isActive === 1 ? <MdOutlineDone /> : <MdClose />
      // return sensor formatted
      return sensor
    })
  }, [data])

  return (<>
    <Table hover={false} autoHeight={true} data={tableData} cellBordered rowHeight={30} headerHeight={30} >
      <Column width={50} align="center" fixed>
        <CompactHeaderCell></CompactHeaderCell>
        <CompactCell dataKey="no_data_1" />
      </Column>
      <Column width={50} align="center" fixed>
        <CompactHeaderCell></CompactHeaderCell>
        <CompactCell dataKey="no_data_2" />
      </Column>
      <Column width={50} align="center" fixed>
        <CompactHeaderCell>-</CompactHeaderCell>
        <CompactCell dataKey="icon" />
      </Column>
      <Column width={50} align="center" fixed>
        <CompactHeaderCell>S. Id</CompactHeaderCell>
        <CompactCell dataKey="promotion_energy_application_pipe_sensor_id" />
      </Column>
      <Column width={120} align="left">
        <CompactHeaderCell>{t('dashboard.sensor')}</CompactHeaderCell>
        <CompactCell dataKey="promotion_energy_application_pipe_sensor_model" />
      </Column>
      <Column minWidth={125} flexGrow={1} align="left">
        <CompactHeaderCell>{t('dashboard.description')}</CompactHeaderCell>
        <CompactCell dataKey="promotion_energy_application_pipe_sensor_name" />
      </Column>
      <Column width={70} align="center">
        <CompactHeaderCell>{t('dashboard.units')}</CompactHeaderCell>
        <CompactCell dataKey="promotion_energy_application_pipe_sensor_units_symbol" />
      </Column>
      <Column width={50} align="center">
        <CompactHeaderCell>{t('dashboard.active')}</CompactHeaderCell>
        <CompactCell dataKey="is_active" />
      </Column>
      <Column width={90} align={'center'}>
        <CompactHeaderCell>{t('dashboard.updated_at')}</CompactHeaderCell>
        <CompactCell dataKey="updated" />
      </Column>
      <Column width={90} align={'center'}>
        <CompactHeaderCell>{t('dashboard.created_at')}</CompactHeaderCell>
        <CompactCell dataKey="created" />
      </Column>
    </Table>
  </>)
}

PromotionEnergySourceApplicationPipesSensorsTable.propTypes = {
  data: PropTypes.array.isRequired
}
