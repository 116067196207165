import moment from 'moment/moment'
import { Tooltip, Whisper } from 'rsuite'
import { Trans } from 'react-i18next'
import React from 'react'
import { MdClose, MdOutlineDone } from 'react-icons/md'
// from library get getLiquidationValues
const { getPercent } = window.helpersLiquidations

export const compensationDataCalculatedSummary = {
  compensationsDataCalculated: [], compensationDataCalculatedSummary: {}
}

export const setInterfaceDataToTotalCalculatedPeriod = (totals, text = 'dashboard.total') => {
  totals.period_full_date =
    <span style={{ float: 'right', marginRight: '6px', color: 'grey' }}>
      <Trans i18nKey={text} ns="dashboard" />
    </span>
  totals.period_consumption = `${totals.consumption.toFixed(2)} kWh`
  totals.period_cost_variable = `${totals.cost_variable.toFixed(2)} €`
  totals.period_cost_fix = `${totals.cost_fix.toFixed(2)} €`
  totals.period_cost_tax = `${totals.cost_tax.toFixed(2)} €`
  totals.period_cost_tax_percent = `${getPercent(totals.cost_tax, totals.cost_variable + totals.cost_fix).toFixed(2)} %`
  totals.period_cost_tax_eur = `${totals.cost_tax.toFixed(2)} €`
  totals.period_total = `${totals.total.toFixed(2)} €`
}

export const setInterfaceDataToCalculatedPeriod = (period, periodConsumption, periodCostVariable, periodCostFix, periodCostTax, periodTotal) => {
  period.period_full_date = `${moment(period.period_date_start, 'YYYYMMDD').format('YYYY-MM-DD')} - ${moment(period.period_date_end, 'YYYYMMDD').format('YYYY-MM-DD')}`
  period.period_is_estimated_icon = period.period_is_estimated ? <MdOutlineDone /> : <MdClose/>
  period.period_consumption = `${periodConsumption.toFixed(2)} kWh`
  period.period_cost_variable = `${periodCostVariable.toFixed(2)} €`
  period.period_cost_fix = `${periodCostFix.toFixed(2)} €`
  period.period_cost_tax_percent = `${period.period_price_tax_percent.toFixed(2)} %`
  period.period_cost_tax_eur = `${periodCostTax.toFixed(2)} €`
  period.period_total = `${periodTotal.toFixed(2)} €`
  if (period.period_invoice_id) {
    period.period_invoice_id =
      <Whisper speaker={ <Tooltip>{ <Trans i18nKey={'dashboard.invoice'} ns="dashboard" /> } <br /> </Tooltip>}>
        <span>{period.period_invoice_id}*</span>
      </Whisper>
  } else {
    period.period_invoice_id = '-'
  }

  if (period.created_at) {
    period.created = moment(period.created_at, 'YYYYMMDD').format('YYYY-MM-DD')
  }
}

export const setCompensationsInterfaceData = (compensableData, compensationDataBalance, compensable, deleteActionCfg) => {
  // set compensable data
  compensableData.period_days = compensableData.days
  compensableData.period_consumption = `${compensableData.consumption.toFixed(2)} kWh`
  compensableData.period_cost_fix = `${compensableData.cost_fix.toFixed(2)} €`
  compensableData.period_cost_variable = `${compensableData.cost_variable.toFixed(2)} €`
  compensableData.period_cost_tax_eur = `${compensableData.cost_tax_eur.toFixed(2)} €`
  compensableData.period_cost_tax_percent = `${compensableData.cost_tax_percent.toFixed(2)} %`
  compensableData.period_total = `${compensableData.total.toFixed(2)} €`
  compensableData.period_full_date =
    <span style={{ float: 'right', marginRight: '6px', color: 'grey' }}>
      <Trans i18nKey={'dashboard.compensable'} ns="dashboard" />
    </span>

  compensationDataBalance.period_full_date =
    <span style={{ float: 'right', marginRight: '6px', color: 'grey' }}>
      <Trans i18nKey={'dashboard.balance'} ns="dashboard"/>
    :</span>
  compensationDataBalance.created_at = moment(compensable.created_at, 'YYYY-MM-DD').locale(window.user.user_language_id).format('YYYY.MM.DD')

  if (deleteActionCfg) { // set delete action if this is set
    compensationDataBalance.period_invoice_id = deleteActionCfg && <deleteActionCfg.component data={compensable} handler={deleteActionCfg.handler} />
  }

  compensationDataBalance.period_days = compensationDataBalance.days
  compensationDataBalance.period_consumption = `${compensationDataBalance.consumption.toFixed(2)} kWh`
  compensationDataBalance.period_cost_fix = `${compensationDataBalance.cost_fix.toFixed(2)} €`
  compensationDataBalance.period_cost_variable = `${compensationDataBalance.cost_variable.toFixed(2)} €`
  compensationDataBalance.period_cost_tax_eur = `${compensationDataBalance.cost_tax_eur.toFixed(2)} €`
  compensationDataBalance.period_cost_tax_percent = `${compensationDataBalance.cost_tax_percent.toFixed(2)} %`
  compensationDataBalance.period_total = `${compensationDataBalance.total.toFixed(2)} €`
}

export const setCompensationsSummaryInterfaceData = (compensationDataCalculatedSummary) => {
  // calculate period tax
  const summaryTaxPercent = getPercent(compensationDataCalculatedSummary.cost_tax_eur, compensationDataCalculatedSummary.cost_variable + compensationDataCalculatedSummary.cost_fix)
  // format summary data
  compensationDataCalculatedSummary.period_consumption = `${compensationDataCalculatedSummary.consumption.toFixed(2)} kWh`
  compensationDataCalculatedSummary.period_cost_fix = `${compensationDataCalculatedSummary.cost_fix.toFixed(2)} €`
  compensationDataCalculatedSummary.period_cost_variable = `${compensationDataCalculatedSummary.cost_variable.toFixed(2)} €`
  compensationDataCalculatedSummary.period_cost_tax_eur = `${compensationDataCalculatedSummary.cost_tax_eur.toFixed(2)} €`
  compensationDataCalculatedSummary.period_cost_tax_percent = `${summaryTaxPercent.toFixed(2)} %`
  compensationDataCalculatedSummary.period_total = `${compensationDataCalculatedSummary.total.toFixed(2)} €`
  compensationDataCalculatedSummary.period_full_date = <span style={{ float: 'right', marginRight: '6px', color: 'grey' }}>{<Trans i18nKey={'dashboard.total'} ns="dashboard"/>}:</span>
}
