import * as Sentry from '@sentry/react'

// https://docs.sentry.io/platforms/javascript/guides/react/
Sentry.init({
  dsn: (function () {
    if (process.env.NODE_ENV === 'development') {
      return null
    } else {
      return 'https://e30dc20ff7dd4542822a80bf8186e390@o1218844.ingest.sentry.io/4504694502653952'
    }
  })(),
  integrations: [new Sentry.BrowserTracing()],
  environment: process.env.NODE_ENV === 'development',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1
})
