import { useEnvironment } from 'hooks/useEnvironment'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { EmptyResult, ErrorResult, ForbiddenResult, LoaderBackDrop } from 'components/feedbacks'
import { Panel, Divider } from 'rsuite'
import { PromotionEnergySourceTable } from './tables/PromotionEnergySourceTable'
import { PromotionEnergySourceApplicationTable } from './tables/PromotionEnergySourceApplicationTable'
import React, { useEffect, useState } from 'react'
import { useRequestsEvents } from '../../hooks/useRequestsEvents'
import { PanelHeader } from '../panels'
import AppSelectIcon from '@rsuite/icons/AppSelect'
import { PromotionEnergySourceApplicationPipesTable } from './tables/PromotionEnergySourceApplicationPipesTable'
import { PromotionEnergySourceApplicationPipesSensorsTable } from './tables/PromotionEnergySourceApplicationPipesSensorsTable'
import { Col, Row } from 'antd'
import { GrAppsRounded } from 'react-icons/gr'
import { useTranslation } from 'react-i18next'

// function to get pipes with sensor
const getPipes = (componentCfg, setComponentCfg, pipes) => {
  return pipes.map((pipe) => {
    // get source applications
    const sensors = _.get(pipe, 'promotion_energy_application_pipe_sensors', [])

    return (
      <div key={_.uniqueId()}>
        <div style={{ borderTop: '1px dashed rgb(183 183 183)' }}>
          <PromotionEnergySourceApplicationPipesTable data={[pipe]} />
          {/* render sensors table */}
          { !_.isEmpty(sensors) && <PromotionEnergySourceApplicationPipesSensorsTable data={sensors} /> }
        </div>
      </div>
    )
  })
}

// function to get applications with sensor
const getApplications = (componentCfg, setComponentCfg, applications) => {
  // for get a unique object for each app id
  const uniqueAppObject = {}

  // reload application
  const applicationHandlerReload = (applicationId) => { // call to reload application
    setComponentCfg({ ...componentCfg, applicationReloadId: applicationId })
  }

  // for each application create component once and render if is duplicated of more one source
  return applications.map((app) => {
    // get source applications
    const pipes = _.get(app, 'promotion_energy_application_pipes', [])
    // for get a unique object for each app id
    // on edit object this object edit in all places
    const applicationId = _.get(app, 'promotion_energy_application_id', [])
    // save a unique objet for each app to render later
    if (!_.has(uniqueAppObject, applicationId)) { uniqueAppObject[applicationId] = app }

    return (
      <div key={_.uniqueId()}>
        {/* render application table */}
        <div style={{ borderTop: '1px dashed rgb(183 183 183)' }}>
          <PromotionEnergySourceApplicationTable data={uniqueAppObject[applicationId]} parentCfg={{
            config: componentCfg,
            setter: setComponentCfg
          }} handlerReload={applicationHandlerReload}/>
          {/* render sensors table */}
          {!_.isEmpty(pipes) && getPipes(componentCfg, setComponentCfg, pipes)}
        </div>
      </div>
    )
  })
}

// function to get source with applications and sensor
const getSources = (componentCfg, setComponentCfg, data, t) => {
  // get total num sources
  const numSources = data.length

  return data.map((source, index) => {
    // get source applications
    const sourceApplications = _.get(source, 'promotion_energy_source_applications', [])
    // return a source energy
    return (<div key={_.uniqueId()}>
        <Panel className={'filled-table-panel'} style={{ borderRadius: '4px' }} bordered={true} bodyFill>
          {/* render source table */}
          <PromotionEnergySourceTable data={[source]}/>
          {/* render applications title */}
          {!_.isEmpty(sourceApplications) &&
            <div className={'rs-between-tables-row-title'}>
              <Row>
                <Col flex="20px"><GrAppsRounded size={'16px'}/></Col>
                <Col flex="auto" className={'rs-table-cell-title-text'}>{t('dashboard.promotion.data_title_energy_applications')}</Col>
              </Row>
            </div>
          }
          {/* render applications tables */}
          { !_.isEmpty(sourceApplications) && getApplications(componentCfg, setComponentCfg, sourceApplications)}
        </Panel>
        { /* not set divider for last source */}
        {(numSources - 1) !== index && <Divider/>}
      </div>
    )
  })
}

export const PromotionEnergySourceApplicationPipesSensorsPanel = (props) => {
  // get current promotion id
  const { promotionId } = useParams()
  // get for check permissions
  const { hasPromotionPermissions } = useEnvironment()
  // for translation
  const { t } = useTranslation('dashboard')
  // get methods to send data
  const { getPromotionEnergySourcesWithApplications } = useRequestsEvents()
  // component config
  const [componentCfg, setComponentCfg] = useState({
    data: [],
    isLoaded: false,
    isError: false,
    isForbidden: false,
    // application manipulations
    applicationReloadId: -1
  }) // permissions
  const hasReadEnergyConfigPromotionPermission = hasPromotionPermissions(promotionId, 'read:promotion:energy:sources') && !componentCfg.isForbidden
  // const hasCreateEnergyConfigPromotionPermission = hasPromotionPermissions(promotionId, 'create:promotion:energy:config')

  // loading component from first load
  useEffect(() => {
    // clean up controller --> prevent crash with async function
    let isMounted = true
    // not reload if component is loaded...
    if (componentCfg.isLoaded) { return }
    // if not has permissions
    if (!hasReadEnergyConfigPromotionPermission) {
      setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, data: [] })
    } else {
      // get data promotion energy sources
      getPromotionEnergySourcesWithApplications(promotionId, (result) => {
        // prevent async crash
        if (!isMounted) return null
        // set holder data
        setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, data: result.data, isForbidden: false })
      }, (err) => {
        // prevent async crash
        if (!isMounted) return null
        if (err === 403) {
          setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, data: [], isForbidden: true })
        } else {
          setComponentCfg({ ...componentCfg, isError: true, isLoaded: true, data: [], isForbidden: false })
        }
      })
    } // prevent crash with async function
    return () => { isMounted = false }
  }, [componentCfg.isLoaded])

  // to set border to first panel
  const isPanelBordered = !hasReadEnergyConfigPromotionPermission || !componentCfg.isLoaded || componentCfg.isError || _.isEmpty(componentCfg.data)

  return (<>
    <Panel bodyFill header={
      <PanelHeader icon={<AppSelectIcon />} title={'dashboard.promotion.energy_schema'} hasDelete={false} hasEdit={false} hasCreate={false} hasReload={false} />
    }>
      <LoaderBackDrop active={!componentCfg.isLoaded} loader background={'white'} top>
        <Panel className={'filled-table-panel'} style={{ borderRadius: '4px' }} bordered={isPanelBordered} bodyFill>
          {(() => {
            if (!hasReadEnergyConfigPromotionPermission) {
              return <ForbiddenResult compact={false} />
            } else if (componentCfg.isError) {
              return <ErrorResult compact={true} />
            } else if (_.isEmpty(componentCfg.data)) {
              return <EmptyResult compact={true} />
            } else { /* return components to render */
              return (<>{ getSources(componentCfg, setComponentCfg, componentCfg.data, t) }</>)
            }
          })()}
        </Panel>
      </LoaderBackDrop>
    </Panel>
  </>)
}
