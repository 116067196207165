import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { Panel } from 'rsuite'
import { Segmented, Space } from 'antd'
import { EmptyResult, ErrorResult, ForbiddenResult, LoaderBackDrop } from 'components/feedbacks'
import { PromotionEnergySupplyInvoicesTable } from './tables/PromotionEnergySupplyInvoicesTable'
import { InvoicesIcon } from '../icons'
import { PromotionEnergySupplyInvoicesForm } from './forms/PromotionEnergySupplyInvoicesForm'
import { useParams } from 'react-router-dom'
import { useEnvironment } from '../../hooks/useEnvironment'
import { PanelHeader } from '../panels'
import { useRequestsEvents } from '../../hooks/useRequestsEvents'

// create segment on options when segment is rendered
const getSegmentOptions = (data) => {
  return data.map((src) => {
    let name = `(${src.promotion_energy_source_id}) ${src.promotion_energy_source_name}`
    // to display text
    if (src.promotion_energy_source_desc) {
      name = `${name} - ${src.promotion_energy_source_desc}`
    }
    return { label: name, value: _.get(src, 'promotion_energy_source_id', '') }
  })
}

export const PromotionEnergySupplyInvoicesPanel = (props) => {
  // get current promotion id
  const { promotionId } = useParams()
  // get methods to send data
  const { getPromotionEnergySources } = useRequestsEvents()
  // get for check permissions
  const { hasPromotionPermissions } = useEnvironment()
  // component config
  const [componentCfg, setComponentCfg] = useState({
    sources: [],
    defaultMenuSelectedId: null,
    menuSelectedId: null,
    isLoaded: false,
    isError: false,
    isForbidden: false
  })// get permissions
  const hasReadSupplyInvoicesEnergyPromotionPermission = hasPromotionPermissions(promotionId, 'read:promotion:energy:supply:invoices') && !componentCfg.isForbidden
  const hasCreateSupplyInvoicesEnergyPromotionPermission = hasPromotionPermissions(promotionId, 'create:promotion:energy:supply:invoices') && !componentCfg.isForbidden

  // for child loader process
  const [reloadTable, setReloadTable] = useState(false)
  // disabled flag for component
  const disabled = reloadTable || !componentCfg.isLoaded

  // reload all component (segmentation and table)
  const handlerReloadComponent = () => {
    // not reload if component is loaded...
    if (!componentCfg.isLoaded) { return }
    // not reload if component is not loaded
    setComponentCfg({ ...componentCfg, isError: false, isLoaded: false })
  }

  // only reload table
  const handlerReloadTable = () => {
    // not reload if component is loaded...
    if (!componentCfg.isLoaded) { return }
    // set child loaded to false
    setReloadTable(true)
  }

  // on change segmentation, change saved menu selected for refresh a child
  const handlerChangeSegment = (id) => {
    // not change if component is not loaded....
    if (!componentCfg.isLoaded) { return }
    // set new selected id
    setComponentCfg({ ...componentCfg, menuSelectedId: id })
  }

  // loading component from first load
  useEffect(() => {
    // clean up controller --> prevent crash with async function
    let isMounted = true
    // not reload if component is loaded...
    if (componentCfg.isLoaded) { return }
    // if not has permissions
    if (!hasReadSupplyInvoicesEnergyPromotionPermission) {
      setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, sources: [] })
    } else {
      // get data promotion energy sources
      getPromotionEnergySources(promotionId, (result) => {
        // prevent async crash
        if (!isMounted) return null
        // get default source
        const defaultMenuSelectedId = componentCfg.defaultMenuSelectedId || _.get(result.data, '[0].promotion_energy_source_id', 0)
        const menuSelectedId = componentCfg.menuSelectedId || defaultMenuSelectedId
        // set data to config
        setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, sources: result.data, defaultMenuSelectedId, menuSelectedId, isForbidden: false })
      }, (err) => {
        // prevent async crash
        if (!isMounted) return null
        if (err === 403) {
          setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, sources: [], isForbidden: true })
        } else {
          setComponentCfg({ ...componentCfg, isError: true, isLoaded: true, sources: [], isForbidden: false })
        }
      })
    } // prevent crash with async function
    return () => { isMounted = false }
  }, [componentCfg.isLoaded])

  // create a source tables
  const invoiceTablesPanel = useMemo(() => {
    // if is empty sources
    if (_.isEmpty(componentCfg.sources)) return []
    // parse and return table for each source
    return componentCfg.sources.map((source) => {
      // if hidden component
      const isHidden = componentCfg.menuSelectedId !== source.promotion_energy_source_id
      // return each table for each source
      return (
          <Panel key={source.promotion_energy_source_id} hidden={isHidden} className={'filled-table-panel'} style={{ borderRadius: '4px' }} bordered bodyFill>
            <PromotionEnergySupplyInvoicesTable isCalledToReload={reloadTable} propagateIsLoaded={setReloadTable} sourceId={source.promotion_energy_source_id} />
          </Panel>
      )
    })
  }, [componentCfg.sources, componentCfg.menuSelectedId, reloadTable])

  // to set border to first panel
  const isPanelBordered = !hasReadSupplyInvoicesEnergyPromotionPermission || !componentCfg.isLoaded || componentCfg.isError || _.isEmpty(componentCfg.sources)

  return (<>
    <Panel bodyFill header={ /* Panel header */
      <PanelHeader icon={<InvoicesIcon/>} title={'dashboard.promotion.data_title_invoices'}
        /* create permissions and config */
        hasCreate={hasCreateSupplyInvoicesEnergyPromotionPermission}
        hasCreateConfig={{
          title: 'dashboard.promotion.data_title_create_invoice',
          extra: <InvoicesIcon size={25}/>,
          content: <PromotionEnergySupplyInvoicesForm promotionId={parseInt(promotionId)} onSuccessSubmit={handlerReloadTable} isPeriodEditable={true} />,
          isDisabled: disabled
        }}
        /* edit permissions and config */
        hasEdit={false}
        /* delete permissions and config */
        hasDelete={false}
        /* for reload data */
        hasReload={true}
        /* set reload config data */
        hasReloadConfig={{
          method: handlerReloadComponent,
          isDisabled: disabled
        }}
      />
    }>
      <LoaderBackDrop active={!componentCfg.isLoaded} loader background bordered>
        <Panel className={'filled-table-panel'} style={{ borderRadius: '4px' }} bordered={isPanelBordered} bodyFill>
          {(() => { /* load component */
            if (!hasReadSupplyInvoicesEnergyPromotionPermission) {
              return <ForbiddenResult compact={true} />
            } else if (componentCfg.isError) {
              return <ErrorResult compact={true} />
            } else if (_.isEmpty(componentCfg.sources)) {
              return <EmptyResult compact={true} />
            } else { /* return components to render */
              return (<>
                <Space className="block-information" direction="vertical" size="middle" style={{ display: 'flex' }}>
                  <Segmented disabled={disabled} defaultValue={componentCfg.defaultMenuSelectedId} value={componentCfg.menuSelectedId} onChange={handlerChangeSegment} options={getSegmentOptions(componentCfg.sources)} />
                  <div>{ invoiceTablesPanel }</div>
                </Space>
              </>)
            }
          })()}
        </Panel>
      </LoaderBackDrop>
    </Panel>
  </>)
}
