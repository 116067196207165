import React from 'react'
import { PromotionEnergySupplyInvoicesPanel } from '../../../../../components/promotion/PromotionEnergySupplyInvoicesPanel'
import { useParams } from 'react-router-dom'
import { useEnvironment } from '../../../../../hooks/useEnvironment'
import { ForbiddenResult } from '../../../../../components/feedbacks'

// diver with central element
export function PromotionBilling (props) {
  // get current promotion id
  const { promotionId } = useParams()
  // get for check permissions
  const { hasPromotionPermissions } = useEnvironment()
  // read energy data
  const hasReadSupplyInvoicesEnergyPromotionPermission = hasPromotionPermissions(promotionId, 'read:promotion:energy:supply:invoices')

  return (<>
    {(() => {
      if (!hasReadSupplyInvoicesEnergyPromotionPermission) {
        return <ForbiddenResult />
      } else { /* return components to render */
        return <PromotionEnergySupplyInvoicesPanel />
      }
    })()}
  </>)
}
