// for react-suite tables with compact cells
import { IconButton, Table } from 'rsuite'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { BiSubdirectoryRight } from 'react-icons/bi'
import _ from 'lodash'
import { MdClose, MdOutlineDone } from 'react-icons/md'
import moment from 'moment'
import { useTable } from '../../../hooks/useTable'
import { DrawerForm } from '../../forms'
import { PromotionEnergySourceApplicationForm } from '../forms/PromotionEnergySourceApplicationForm'
import { useParams } from 'react-router-dom'
import { ErrorResult, ForbiddenResult, LoaderBackDrop } from '../../feedbacks'
import { useRequestsEvents } from '../../../hooks/useRequestsEvents'
import { GrAppsRounded } from 'react-icons/gr'
import { useEnvironment } from '../../../hooks/useEnvironment'
import { Col, Row } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { TbCalendarEvent } from 'react-icons/tb'
import { sentenceCase } from 'change-case'
import { PromotionEnergySourceForm } from '../forms/PromotionEnergySourceForm'

const { Column, HeaderCell, Cell } = Table
const CompactCell = props => <Cell {...props} style={{ padding: 6 }} />
const CompactHeaderCell = props => <HeaderCell {...props} style={{ padding: 4 }} />

// define locales for this page
const transFiles = ['dashboard']

export const PromotionEnergySourceApplicationTable = (props) => {
  // for translation
  const { t } = useTranslation(transFiles)
  // get actions from use table
  const { EditAction, DeleteAction } = useTable()
  // get current promotion id
  const { promotionId } = useParams()
  // get for check permissions
  const { hasPromotionPermissions } = useEnvironment()
  // get methods to send data
  const { getPromotionEnergySourceApplication } = useRequestsEvents()
  // get config from parent
  const { config: parentCfg } = _.get(props, 'parentCfg', { config: {}, setter: () => {} })
  // get source id
  const sourceId = _.get(props, 'data.promotion_energy_source_id', -1)
  // component config
  const [componentCfg, setComponentCfg] = useState({
    applicationId: _.get(props, 'data.promotion_energy_application_id', -1),
    application: _.get(props, 'data', {}),
    isLoaded: true,
    isReloading: false,
    isError: false,
    isForbidden: false,
    // drawer config
    drawer: false
  }) // permissions
  const hasReadPermissions = hasPromotionPermissions(promotionId, 'read:promotion:energy:applications') && !componentCfg.isForbidden

  // edit invoice action
  const handlerEdit = (data, setActionRunning) => {
    // set state to action working
    setActionRunning(true)
    setComponentCfg({ ...componentCfg, drawer: true })
    setActionRunning(false)
  }

  // delete invoice action
  const handlerDelete = (data, setActionRunning) => { }

  // when end edit application (on close drawer)
  const handlerDrawerClose = () => { setComponentCfg({ ...componentCfg, drawer: false }) }
  // when is edit component call to reload from drawer
  const handlerReloadComponent = _.get(props, 'handlerReload', (applicationId) => { /* setIsReloading... */ })

  const handlerGetApplication = (isMounted, load) => {
    // get promotion application data
    getPromotionEnergySourceApplication(promotionId, sourceId, componentCfg.applicationId, (result) => {
      // prevent async crash
      if (!isMounted) return null
      // get first returned
      const values = result.data
      // update application data on source
      Object.assign(componentCfg.application, values)
      // update component cfg with application data by new object
      setComponentCfg({ ...componentCfg, isReloading: false, isLoaded: true, application: { ...componentCfg.application }, isError: false, isForbidden: false })
    }, (err) => {
      // prevent async crash
      if (!isMounted) return null
      if (err === 403) {
        setComponentCfg({ ...componentCfg, isReloading: false, isLoaded: true, application: { ...componentCfg.application }, isError: false, isForbidden: true })
      } else {
        setComponentCfg({ ...componentCfg, isReloading: false, isLoaded: true, application: { ...componentCfg.application }, isError: true, isForbidden: false })
      }
    })
  }

  // for first load component
  useEffect(() => {
    // clean up controller --> prevent crash with async function
    let isMounted = true
    // nothing to do if is loaded
    if (componentCfg.isLoaded) { return } // reload data from this application edited
    // call to get application
    handlerGetApplication(isMounted)
    // prevent crash with async function
    return () => { isMounted = false }
  }, [componentCfg.isLoaded])

  // for reloading component from parent
  useEffect(() => {
    // any application to update
    if (_.get(parentCfg, 'applicationReloadId', -1) === -1) { return }
    // get application Id
    const applicationId = _.get(componentCfg, 'application.promotion_energy_application_id', -1)
    // if this is not updatable...
    if (_.get(parentCfg, 'applicationReloadId', -1) !== applicationId) { return }
    // reload component
    setComponentCfg({ ...componentCfg, isReloading: true, isLoaded: false })
  }, [parentCfg.applicationReloadId])

  // format data for table
  const tableData = useMemo(() => {
    const app = componentCfg.application
    // if not data set...
    if (_.isEmpty(app)) { return [] }
    // for empty cells
    app.icon = <BiSubdirectoryRight size={20}/>
    // get date information
    const locale = window.user.user_language_id
    // get flag is active
    const isActive = _.get(app, 'promotion_energy_application_is_active')
    // eslint-disable-next-line no-constant-condition
    app.is_active = isActive === 1 ? <MdOutlineDone/> : <MdClose />
    // set timestamps
    app.updated = moment(app.updated_at, 'YYYY-MM-DD').locale(locale).format('YYYY.MM.DD')
    app.created = moment(app.created_at, 'YYYY-MM-DD').locale(locale).format('YYYY.MM.DD')
    // interval application
    app.promotion_energy_application_end_string = app.promotion_energy_application_end ? moment(app.promotion_energy_application_end, 'YYYYMMDD').locale(locale).format('DD-MM-YYYY') : '-'
    app.promotion_energy_application_start_string = app.promotion_energy_application_start ? moment(app.promotion_energy_application_start, 'YYYYMMDD').locale(locale).format('DD-MM-YYYY') : '-'
    // set actions
    app.delete_action = <DeleteAction data={app} disabled={true} handler={handlerDelete} />
    app.edit_action = <EditAction data={app} disabled={!hasPromotionPermissions(promotionId, 'update:promotion:energy:applications')} handler={handlerEdit} />
    // return application
    return [app]
  }, [componentCfg.application])

  return (<>
    <LoaderBackDrop active={!componentCfg.isLoaded || componentCfg.isReloading} loader background={'white'} top>
      {(() => {
        if (!hasReadPermissions) {
          return <ForbiddenResult compact={false} />
        } else if (componentCfg.isError) {
          return <ErrorResult compact={true}/>
        } else { /* return components to render */
          return (<>
            <Table hover={false} autoHeight={true} data={tableData} cellBordered rowHeight={30} headerHeight={30}>
              <Column width={50} align="center" fixed>
                <CompactHeaderCell>-</CompactHeaderCell>
                <CompactCell dataKey="icon"/>
              </Column>
              <Column width={50} align="center" fixed>
                <CompactHeaderCell>A. Id</CompactHeaderCell>
                <CompactCell dataKey="promotion_energy_application_id"/>
              </Column>
              <Column width={170} align="left">
                <CompactHeaderCell>{t('dashboard.promotion.data_title_energy_application')}</CompactHeaderCell>
                <CompactCell dataKey="promotion_energy_application_name"/>
              </Column>
              <Column minWidth={300} flexGrow={1} align="left">
                <CompactHeaderCell>{t('dashboard.description')}</CompactHeaderCell>
                <CompactCell dataKey="promotion_energy_application_desc"/>
              </Column>
              <Column width={140} align="center">
                <CompactHeaderCell>{t('dashboard.start')}</CompactHeaderCell>
                <CompactCell dataKey={'promotion_energy_application_start_string'}/>
              </Column>
              <Column width={140} align="center">
                <CompactHeaderCell>{t('dashboard.end')}</CompactHeaderCell>
                <CompactCell dataKey="promotion_energy_application_end_string"/>
              </Column>
              <Column width={50} align="center">
                <CompactHeaderCell>{t('dashboard.active')}</CompactHeaderCell>
                <CompactCell dataKey="is_active"/>
              </Column>
              <Column width={25} align="center">
                <CompactHeaderCell></CompactHeaderCell>
                <CompactCell dataKey="edit_action"/>
              </Column>
              <Column width={25} align="center">
                <CompactHeaderCell></CompactHeaderCell>
                <CompactCell dataKey="delete_action"/>
              </Column>
              <Column width={90} align={'center'}>
                <CompactHeaderCell>{t('dashboard.updated_at')}</CompactHeaderCell>
                <CompactCell dataKey="updated"/>
              </Column>
              <Column width={90} align={'center'}>
                <CompactHeaderCell>{t('dashboard.created_at')}</CompactHeaderCell>
                <CompactCell dataKey="created"/>
              </Column>
            </Table>
            { /* drawer */}
            {componentCfg.drawer &&
              <DrawerForm destroyOnClose={true} title={'dashboard.promotion.data_title_energy_edit_application'} open={componentCfg.drawer}
                          extra={<GrAppsRounded size={25}/>} onClose={handlerDrawerClose}
                          content={<PromotionEnergySourceApplicationForm promotionId={parseInt(promotionId)} sourceId={parseInt(sourceId)} applicationId={parseInt(componentCfg.application.promotion_energy_application_id)} onSuccessSubmit={handlerReloadComponent} /> }
              />
            }
          </>)
        }
      })()}
    </LoaderBackDrop>
  </>)
}

PromotionEnergySourceApplicationTable.propTypes = {
  data: PropTypes.object.isRequired
}
