import React, { useEffect, useMemo, useState } from 'react'
import { Divider, Space } from 'antd'
import { Panel } from 'rsuite'
import _ from 'lodash'
import { DividerButtons, DividerTag } from 'components/divider'
import { useEnvironment } from 'hooks/useEnvironment'
import 'rsuite/dist/rsuite.min.css'
import { useParams } from 'react-router-dom'
import { BlockGeneralPanel } from 'components/block/BlockGeneralPanel'
import { useRequestsEvents } from '../../hooks/useRequestsEvents'
import { PromotionPersonBlockHolderPanel } from './PromotionPersonBlockHolderPanel'
import { PromotionPersonBlockPresidentPanel } from './PromotionPersonBlockPresidentPanel'
import { PromotionPersonBlockAdministratorPanel } from './PromotionPersonBlockAdministratorPanel'
import { BlockFloorsPanel } from '../block/BlockFloorsPanel'
import { EmptyResult, ForbiddenResult, LoaderBackDrop } from '../feedbacks'

// diver with central element
export function PromotionBlocks (props) {
  // get current promotion id
  const { promotionId } = useParams()
  // for get promotions
  const { getPromotionBlocks } = useRequestsEvents()
  // get for check permissions
  const { hasPromotionPermissions } = useEnvironment()
  // get permissions for block
  const hasReadBlockPermission = hasPromotionPermissions(promotionId, 'read:promotion:block')
  // component config
  const [componentCfg, setComponentCfg] = useState({
    data: [],
    isLoaded: false,
    isError: false,
    isForbidden: false
  })
  // block permissions
  const hasUpdateBlockPermission = hasPromotionPermissions(promotionId, 'update:promotion:block') && !componentCfg.isForbidden
  const hasDeleteBlockPermission = hasPromotionPermissions(promotionId, 'delete:promotion:block') && !componentCfg.isForbidden
  const hasReadHolderBlockPermission = hasPromotionPermissions(promotionId, 'read:promotion:block:holder')
  const hasReadPresidentBlockPermission = hasPromotionPermissions(promotionId, 'read:promotion:block:president')
  const hasReadAdminBlockPermission = hasPromotionPermissions(promotionId, 'read:promotion:block:admin')
  const hasReadFloorBlockPermission = hasPromotionPermissions(promotionId, 'read:promotion:block:floors')

  // for load all promotions data when component is mounted
  useEffect(() => {
    // clean up controller --> prevent crash with async function
    let isMounted = true
    // not load permissions
    if (!componentCfg.isLoaded && !hasReadBlockPermission) {
      // set blank promotions
      setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, data: [] })
      // if is already component loaded, not load again
    } else if (componentCfg.isLoaded) { // do nothing
      // other cases
    } else {
      // get promotion needed
      getPromotionBlocks(promotionId, (result) => {
        // prevent async crash
        if (!isMounted) return null
        setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, data: result.data, isForbidden: false })
      }, (err) => { // no permissions or other error
        // prevent async crash
        if (!isMounted) return null
        if (err === 403) {
          setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, data: [], isForbidden: true })
        } else {
          setComponentCfg({ ...componentCfg, isError: true, isLoaded: true, data: [], isForbidden: false })
        }
      })
    } // prevent crash with async function
    return () => { isMounted = false }
  }, [componentCfg.isLoaded])

  const components = useMemo(() => {
    // return data
    return componentCfg.data.map((blc) => {
      return (
        <div key={_.uniqueId()}>
          <Divider orientation="left">
            <DividerTag tagColor={'cyan'} tagTitle={blc.block_name} title={_.get(blc, 'block_name')} />
            {/* only if user has permissions for edit */}
            <DividerButtons permission={[hasUpdateBlockPermission, hasDeleteBlockPermission]} />
          </Divider>
          { /* block information */ }
          { /* header={<PanelHeader icon={<IdInfoIcon />} title={'dashboard.block.data_title_general'} />} */ }
          <Panel style={{ marginTop: '20px' }} bodyFill>
            <BlockGeneralPanel data={blc} />
          </Panel>
          { /* block holder */ }
          { hasReadHolderBlockPermission && <PromotionPersonBlockHolderPanel blockId={blc.block_id} /> }
          { /* block president */ }
          { hasReadPresidentBlockPermission && <PromotionPersonBlockPresidentPanel blockId={blc.block_id} /> }
          { /* block administrator */ }
          { hasReadAdminBlockPermission && <PromotionPersonBlockAdministratorPanel blockId={blc.block_id} /> }
          { /* block floors with apartments */ }
          { hasReadFloorBlockPermission && <BlockFloorsPanel blockId={blc.block_id} /> }
        </div>)
    })
  }, [componentCfg.data])

  // to set border to first panel
  const isPanelBordered = !hasReadBlockPermission || !componentCfg.isLoaded || componentCfg.isError || _.isEmpty(componentCfg.data)

  return (<>
    <LoaderBackDrop active={!componentCfg.isLoaded} loader background={'white'} top>
      <Panel className={'filled-table-panel'} style={{ borderRadius: '4px' }} bordered={isPanelBordered} bodyFill>
          {(() => {
            if (!hasReadBlockPermission) {
              return <ForbiddenResult compact={true} />
            } else if (componentCfg.data && componentCfg.data.length < 1) {
              return <EmptyResult compact={true} />
            } else { /* return components to render */
              return (<>
              <Space className="block-information" direction="vertical" size="middle" style={{ display: 'flex', padding: '0' }}>
                { components }
              </Space>
              <div style={{ height: '15px' }}></div>
            </>)
            }
          })()}
      </Panel>
    </LoaderBackDrop>
  </>)
}
