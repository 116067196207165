import React, { useEffect, useMemo, useState } from 'react'
import { Panel } from 'rsuite'
import { DatePicker, Segmented, Space } from 'antd'
import _ from 'lodash'
import { EmptyResult, ErrorResult, ForbiddenResult, LoaderBackDrop } from 'components/feedbacks'
import { PromotionEnergyReadingsTable } from './tables/PromotionEnergyReadingsTable'
import { PanelHeader } from '../panels'
import { ReadingsIcon } from '../icons'
import { useEnvironment } from '../../hooks/useEnvironment'
import { useParams } from 'react-router-dom'
import { useRequestsEvents } from '../../hooks/useRequestsEvents'
import dayjs from 'dayjs'

// create segment on options when segment is rendered
const getSegmentOptions = (data) => {
  return data.map((sensor) => {
    const application = `(${sensor.promotion_energy_application_id}) ${sensor.promotion_energy_application_name}`
    const pipe = `(${sensor.promotion_energy_application_pipe_id}) ${sensor.promotion_energy_application_pipe_name}`
    const name = `(${sensor.promotion_energy_application_pipe_sensor_id}) ${sensor.promotion_energy_application_pipe_sensor_name}: ${sensor.promotion_energy_application_pipe_sensor_model}`
    return { label: `${application} - ${pipe} - ${name}`, value: sensor.promotion_energy_application_pipe_sensor_combined_id }
  })
}

export const PromotionEnergyReadingsPanel = (props) => {
  // get current promotion id
  const { promotionId } = useParams()
  // get methods to send data
  const { getPromotionsApplicationsPipesSensors } = useRequestsEvents()
  // get for check permissions
  const { hasPromotionPermissions } = useEnvironment()
  // component config
  const [componentCfg, setComponentCfg] = useState({
    sensors: [],
    readings: [],
    dataPickerDate: dayjs(),
    // sensors config
    defaultSensorSelectedId: null,
    sensorSelectedId: null,
    isSensorLoaded: false,
    isSensorLoadedError: false,
    isForbidden: false
  }) // permissions
  const hasReadEnergyReadingsPromotionPermission = hasPromotionPermissions(promotionId, 'read:promotion:energy:applications:pipes:sensors') && !componentCfg.isForbidden
  // const hasCreateEnergyReadingsPromotionPermission = hasPromotionPermissions(promotionId, 'create:promotion:energy:applications:pipes:sensors:readings') && !componentCfg.isForbidden

  // for child loader process
  const [reloadTable, setReloadTable] = useState(false)
  const disabled = reloadTable || !componentCfg.isSensorLoaded
  // for first panel border
  const isPanelBordered = !hasReadEnergyReadingsPromotionPermission || !componentCfg.isSensorLoaded || componentCfg.isSensorLoadedError || _.isEmpty(componentCfg.sensors)

  // reload all component (segmentation and table)
  const handlerReloadComponent = () => {
    // not reload if component is loaded...
    if (!componentCfg.isSensorLoaded) { return }
    // not reload if component is not loaded
    setComponentCfg({ ...componentCfg, isSensorLoadedError: false, isSensorLoaded: false })
  }

  // on change segmentation, change saved menu selected for refresh a child
  const handlerChangeSegment = (id) => {
    // not change if component is not loaded....
    if (!componentCfg.isSensorLoaded) { return }
    // set new selected id
    setComponentCfg({ ...componentCfg, sensorSelectedId: id })
  }

  const handlerDatePickerChange = (dayjs) => {
    // not change if component is not loaded....
    if (!componentCfg.isSensorLoaded) { return }
    // set new selected id
    setComponentCfg({ ...componentCfg, dataPickerDate: dayjs })
  }

  // loading component from first load
  useEffect(() => {
    // clean up controller --> prevent crash with async function
    let isMounted = true
    // not reload if component is loaded...
    if (componentCfg.isSensorLoaded) { return }
    // if not has permissions
    if (!hasReadEnergyReadingsPromotionPermission) {
      setComponentCfg({ ...componentCfg, isSensorLoadedError: false, isSensorLoaded: true, sensors: [] })
    } else {
      getPromotionsApplicationsPipesSensors(promotionId, {}, (result) => {
        // prevent async crash
        if (!isMounted) return null
        // get default source
        const defaultSensorSelectedId = componentCfg.defaultSensorSelectedId || _.get(result.data, '[0].promotion_energy_application_pipe_sensor_combined_id', 0)
        const sensorSelectedId = componentCfg.sensorSelectedId || defaultSensorSelectedId
        // set data to config
        setComponentCfg({ ...componentCfg, isSensorLoadedError: false, isSensorLoaded: true, sensors: result.data, defaultSensorSelectedId, sensorSelectedId, isForbidden: false })
      }, (err) => {
        // prevent async crash
        if (!isMounted) return null
        if (err === 403) {
          setComponentCfg({ ...componentCfg, isSensorLoadedError: false, isSensorLoaded: true, sensors: [], isForbidden: true })
        } else {
          setComponentCfg({ ...componentCfg, isSensorLoadedError: true, isSensorLoaded: true, sensors: [], isForbidden: false })
        }
      })
    } // prevent crash with async function
    return () => { isMounted = false }
  }, [componentCfg.isSensorLoaded])

  // create readings table
  const readingsTablePanel = useMemo(() => {
    // not change if component is not loaded....
    if (!componentCfg.isSensorLoaded) { return [] }
    // if is empty sources
    if (_.isEmpty(componentCfg.sensors)) return []
    // parse and return table for each source
    return componentCfg.sensors.map((sensor) => {
      // if hidden component
      const isHidden = componentCfg.sensorSelectedId !== sensor.promotion_energy_application_pipe_sensor_combined_id
      // return each table for each source
      return (
        <Panel key={sensor.promotion_energy_application_pipe_sensor_combined_id} className={'filled-table-panel'} hidden={isHidden} style={{ borderRadius: '4px' }} bordered bodyFill>
          <PromotionEnergyReadingsTable isCalledToReload={reloadTable} propagateIsLoaded={setReloadTable} date={componentCfg.dataPickerDate} sensor={sensor} />
        </Panel>
      )
    })
  }, [componentCfg.sensors, componentCfg.sensorSelectedId, componentCfg.dataPickerDate, reloadTable])

  return (<>
    <Panel bodyFill header={ /* Panel header */
      <PanelHeader icon={<ReadingsIcon />} title={'dashboard.promotion.data_title_readings'}
          hasCreate={false}
          /* edit permissions and config */
          hasEdit={false}
          /* delete permissions and config */
          hasDelete={false}
          /* for reload data */
          hasReload={true}
          /* set reload config data */
          hasReloadConfig={{
            method: handlerReloadComponent,
            isDisabled: disabled
          }} /* set drawer */
          hasDrawer={false}
      />
    }>
      <LoaderBackDrop active={!componentCfg.isSensorLoaded} loader bordered background>
        <Panel className={'filled-table-panel'} style={{ borderRadius: '4px' }} bordered={isPanelBordered} bodyFill>
          {(() => {
            if (!hasReadEnergyReadingsPromotionPermission) {
              return <ForbiddenResult compact={true} />
            } else if (componentCfg.isSensorLoadedError) {
              return <ErrorResult compact={true} />
            } else if (_.isEmpty(componentCfg.sensors)) {
              return <EmptyResult compact={true} />
            } else { /* return components to render */
              return (<>
                <Space className="block-information" direction="vertical" size="middle" style={{ display: 'flex' }}>
                  <Space size={'small'}>
                    <DatePicker className='data-picker-segment' defaultValue={componentCfg.dataPickerDate} onChange={handlerDatePickerChange} allowClear={false} picker="month" size={'middle'} />
                    <Segmented disabled={disabled} defaultValue={componentCfg.defaultSensorIdSelected} value={componentCfg.sensorSelectedId} onChange={handlerChangeSegment}
                               options={getSegmentOptions(componentCfg.sensors)}/>
                  </Space>
                  <div>{readingsTablePanel}</div>
                </Space>
              </>)
            }
          })()}
        </Panel>
      </LoaderBackDrop>
    </Panel>
  </>)
}
