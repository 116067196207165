import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CloseSquareOutlined, FormOutlined, ImportOutlined, RightOutlined } from '@ant-design/icons'
import { Tag } from 'antd'
import { Button, ButtonGroup } from 'rsuite'
import { DrawerForm } from './forms'
import _ from 'lodash'

// define locales for this page
const transFiles = ['dashboard']

// diver with central element
export function DividerCenterIcon (props) {
  // import element to render
  const { children } = props

  return (
    <>
      <div className="divider my-2">
        <div className="divider-text">{children}</div>
      </div>
    </>
  )
}

DividerCenterIcon.propTypes = {
  children: PropTypes.node.isRequired
}

// for person title
export const DividerTag = (props) => {
  // for translation
  const { t } = useTranslation(transFiles)
  // get properties
  const { /* title, */ tagTitle, tagColor } = props

  return (
    <>
      <RightOutlined />&nbsp;&nbsp;
      <Tag className={'no-margin-right'} color={tagColor}>{t(tagTitle)}</Tag>
    </>
  )
}

DividerTag.propTypes = {
  title: PropTypes.string.isRequired,
  permission: PropTypes.bool,
  tagTitle: PropTypes.string.isRequired,
  tagColor: PropTypes.string.isRequired
}

export const DividerButtons = (props) => {
  // for translation
  const { t } = useTranslation(transFiles)
  // get permissions props
  const { hasEdit, hasDelete, hasCreate, hasImport, hasReload, hasDrawer } = props
  // get config permissions props
  const { hasEditConfig, hasDeleteConfig, hasCreateConfig, hasImportConfig, hasReloadConfig, hasDrawerConfig } = props

  // create drawer
  const [openCreateDrawer, setOpenCreateDrawer] = useState(false)
  // create drawer events
  const showCreateDrawer = () => { setOpenCreateDrawer(true) }
  const closeCreateDrawer = () => {
    if (hasCreateConfig.onCreateFinish) { hasCreateConfig.onCreateFinish() }
    setOpenCreateDrawer(false)
  }

  // edit drawer
  const [openEditDrawer, setOpenEditDrawer] = useState(false)
  // edit drawer events
  const showEditDrawer = () => { setOpenEditDrawer(true) }
  const closeEditDrawer = () => { setOpenEditDrawer(false) }

  return (<>
    <div>
      <ButtonGroup size="xs">
        { (hasCreate && hasCreateConfig) && <>
          <Button onClick={showCreateDrawer} disabled={hasCreateConfig.isDisabled}>{t('dashboard.add')}</Button>
          { openCreateDrawer && <DrawerForm destroyOnClose={true} title={hasCreateConfig.title} content={hasCreateConfig.content} extra={hasCreateConfig.extra} onClose={closeCreateDrawer} open={openCreateDrawer} /> }
        </> }
        { (hasImport && hasImportConfig) && <>
          <Button icon={<ImportOutlined />} type='text'>{t('dashboard.import')}</Button>
        </> }
        { (hasEdit && hasEditConfig) && <>
          <Button icon={<FormOutlined />} onClick={showEditDrawer} type='text'>{t('dashboard.edit')}</Button>
          { openCreateDrawer && <DrawerForm destroyOnClose={true} title={hasEditConfig.title} content={hasEditConfig.content} extra={hasCreateConfig.extra} onClose={closeEditDrawer} open={openEditDrawer} /> }
        </> }
        { (hasDelete && hasDeleteConfig) && <>
          <Button icon={<CloseSquareOutlined />} type='text'>{t('dashboard.delete')}</Button>
        </> }
        { (hasReload && hasReloadConfig) && <>
          <Button onClick={hasReloadConfig.method} disabled={hasReloadConfig.isDisabled}>{t('dashboard.reload')}</Button>
        </> }
        { (hasDrawer && hasDrawerConfig) && <>
          { hasDrawerConfig.map((drawer) => {
            const icon = (drawer.icon) ? <span style={{ height: '20px', paddingTop: '3px' }}>{drawer.icon}</span> : <></>
            return <Button disabled={drawer.isDisabled} key={_.uniqueId()} onClick={drawer.method}>{icon} {t(drawer.title)}</Button>
          })}
        </>}
      </ButtonGroup>
    </div>
  </>)
}

DividerButtons.propTypes = {
  hasImport: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  hasEdit: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  hasReload: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  hasDelete: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  hasCreate: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  hasDrawer: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  hasImportConfig: PropTypes.object,
  hasEditConfig: PropTypes.object,
  hasDeleteConfig: PropTypes.object,
  hasCreateConfig: PropTypes.object,
  hasReloadConfig: PropTypes.object,
  hasDrawerConfig: PropTypes.array
}
