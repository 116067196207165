import React, { useEffect, useMemo, useState } from 'react'
import { IconButton, Pagination, Panel, Table } from 'rsuite'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import moment from 'moment'
import { Button, ConfigProvider, Tag } from 'antd'
import { ErrorResult, LoaderBackDrop } from '../../feedbacks'
import { getPaginationText } from '../../../helpers/paginations'
import { IoMdRefresh } from 'react-icons/io'
import { useEnvironment } from '../../../hooks/useEnvironment'
import { useTable } from '../../../hooks/useTable'
import { DrawerForm } from '../../forms'
import { useRequestsEvents } from '../../../hooks/useRequestsEvents'
import SpinnerIcon from '@rsuite/icons/legacy/Spinner'
import { HiPlusSm } from "react-icons/hi"
import { HiChevronRight } from "react-icons/hi"
import { HiChevronDown } from "react-icons/hi"
import { Table as AntdTable } from 'antd';

const { Column, HeaderCell, Cell } = Table
const CompactCell = props => <Cell {...props} style={{ padding: 6 }} />
const CompactHeaderCell = props => <HeaderCell {...props} style={{ padding: 4 }} />
// eslint-disable-next-line react/prop-types
const CompactExpandCell = ({ rowData, dataKey, expandedRowKeys, setExpandedRowKeys, onChange,  ...props }) => (
  <Cell {...props} style={{ padding: 6 }}>
    <HiPlusSm style={{ cursor: 'pointer' }} size={18} onClick={() => { /* onChange(rowData) */ }}/>
    { _.some(expandedRowKeys, key => key === rowData[rowKey])
      ? (<HiChevronDown style={{ cursor: 'pointer' }} size={18} onClick={() => { onChange(rowData, dataKey, expandedRowKeys, setExpandedRowKeys) }}/>)
      : (<HiChevronRight style={{ cursor: 'pointer' }} size={18} onClick={() => { onChange(rowData, dataKey, expandedRowKeys, setExpandedRowKeys) }}/>) }
  </Cell>
)
// handler expand row
const handleExpanded = (rowData, dataKey, expandedRowKeys, setExpandedRowKeys) => {
  const isExpanded = expandedRowKeys.includes(rowData[dataKey])
  const nextExpandedRowKeys = isExpanded ? expandedRowKeys.filter(key => key !== rowData[dataKey]) : [...expandedRowKeys, rowData[dataKey]]
  setExpandedRowKeys(nextExpandedRowKeys)
}

const columns = [
  {
    title: 'e-Id',
    dataIndex: 'user_id',
    key: 'user_id',
    width: 50,
    align: 'center'
  },
  {
    title: 'Id',
    dataIndex: 'user_eanalysis_person_id',
    key: 'user_eanalysis_person_id',
    width: 50,
    align: 'center'
  },
  {
    title: 'Name',
    dataIndex: 'user_name',
    key: 'user_name'
  },
  {
    title: 'Email',
    dataIndex: 'user_email',
    key: 'user_email'
  },{
    title: 'created_by',
    dataIndex: 'created_by',
    key: 'created_by',
    width: 120,
    align: 'center'
  },{
    title: 'last_login',
    dataIndex: 'last_login',
    key: 'last_login',
    width: 120,
    align: 'center'
  },{
    title: 'edit_action',
    dataIndex: 'edit_action',
    key: 'edit_action',
    width: 25,
    align: 'center'
  },{
    title: 'delete_action',
    dataIndex: 'delete_action',
    key: 'delete_action',
    width: 25,
    align: 'center'
  },{
    title: 'updated',
    dataIndex: 'updated',
    key: 'updated',
    width: 90,
    align: 'center'
  },{
    title: 'created',
    dataIndex: 'created',
    key: 'created',
    width: 90,
    align: 'center'
  }
]

// define locales for this page
const transFiles = ['dashboard']
// for expand
const rowKey = 'user_id'

export const EdynaAdministrationsUsersTable = (props) => {
  // for translation
  const { t } = useTranslation(transFiles)
  // get for check permissions
  const { hasPromotionPermissions } = useEnvironment()
  // get methods to send data
  const { getResidentialUsers } = useRequestsEvents()
  // get actions from use table
  const { EditAction, DeleteAction } = useTable()
  // get data from props
  // eslint-disable-next-line react/prop-types
  const { isCalledToReload, propagateIsLoaded } = props
  // TODO PERMISSIONS
  const hasReadEdynaAdmins = true
  // row expand status
  const [userExpandedRowKeys, setUserExpandedRowKeys] = React.useState([])
  // component config
  const [componentCfg, setComponentCfg] = useState({
    users: [],
    isLoaded: false,
    isChangingPage: false,
    isReloading: false,
    isError: false,
    // drawer config
    drawer: false,
    selected: null,
    selectedIUserEditable: false,
    // pagination config
    page: 1,
    total: 0,
    offset: 0,
    limit: 25
  })

  // render row expanded...
  const renderRowExpanded = rowData => {
    //console.info(rowData)
    return (<>
      <div>{'TABLA'}</div>
      <div>{'TABLA'}</div>
      <div>{'TABLA'}</div>
      <div>{'TABLA'}</div>
    </>)
  }

  // when end edit user (on close drawer)
  const handlerDrawerClose = () => {
    setComponentCfg({ ...componentCfg, drawer: false, selected: null })
  }

  // reload component data
  const handlerReloadComponent = () => {
    // call to reload from segmentation
    setComponentCfg({ ...componentCfg, isError: false, isLoaded: false, isReloading: true })
  }

  // when handler edit user action
  const handlerEdit = (data, setActionRunning) => {
    // set state to action working
    setActionRunning(true)
    // setComponentCfg({ ...componentCfg, drawer: true, selected: data.promotion_supply_invoice_id, selectedIUserEditable: Boolean(data.promotion_supply_invoice_is_deletable_max_date) })
    setActionRunning(false)
  }

  // when handler delete user action
  const handlerDelete = (data, setActionRunning) => {
    // clean up controller --> prevent crash with async function
    const isMounted = true
    // set state to action working
    setActionRunning(true)
    // // request to delete user
    // deletePromotionEnergySupplyInvoice(data.promotion_id, data.promotion_supply_invoice_id, () => {
    //   if (!isMounted) return null
    //   handlerReloadComponent()
    //   setActionRunning(false)
    // }, () => {
    //   if (!isMounted) return null
    //   setComponentCfg({ ...componentCfg, isError: true, isLoaded: true, isReloading: false })
    //   setActionRunning(false)
    // })
  }

  // on change page
  const handlerChangePage = (page, skipIsLoading = false) => {
    // not change if component is not loaded....
    if (!componentCfg.isLoaded && !skipIsLoading) { return }
    // set to reload a component
    setComponentCfg({ ...componentCfg, page, offset: (componentCfg.limit * page) - componentCfg.limit, isError: false, isLoaded: false, isChangingPage: true })
  }

  const handlerPageReload = (skipIsLoading = false) => {
    // not change if component is not loaded....
    if (!componentCfg.isLoaded && !skipIsLoading) { return }
    // set to reload a component
    setComponentCfg({ ...componentCfg, isError: false, isLoaded: false, isChangingPage: true })
  }

  // for reload from parent
  useEffect(() => {
    // only when component is loaded and parent refresh is true
    if (!componentCfg.isLoaded || !isCalledToReload) { return }
    // call to reload from segmentation
    handlerReloadComponent()
  }, [isCalledToReload])

  // loading component from first load:
  // - only if set source id and is not loading
  useEffect(() => {
    // clean up controller --> prevent crash with async function
    let isMounted = true
    // set observers
    propagateIsLoaded(false)
    // not reload if component is loaded...
    if (componentCfg.isLoaded) { return }
    // if not has permissions
    if (!hasReadEdynaAdmins) {
      setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, users: [] })
    } else {
      // TODO SET FLAG WITH PROMOTIONS AND APARTMENTS
      // get data promotion energy sources
      getResidentialUsers((result) => {
        // prevent async crash
        if (!isMounted) return null
        // fix delete last item on a page
        if (_.isEmpty(result.data) && result.pagination.total_records > 0) {
          // call to load previous page
          handlerChangePage(result.pagination.total_pages, true)
        } else {
          // set data to config
          setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, isChangingPage: false, isReloading: false, users: result.data, total: result.pagination.total_records })
        }
      }, () => {
        // prevent async crash
        if (!isMounted) return null
        setComponentCfg({ ...componentCfg, isError: true, isLoaded: true, isChangingPage: false, isReloading: false, users: [], total: 0 })
      }, componentCfg.limit, componentCfg.offset)
    } // prevent crash with async function
    return () => { isMounted = false }
  }, [componentCfg.isLoaded, componentCfg.page])

  // format data for table
  const tableData = useMemo(() => {
    // if not data set...
    if (_.isEmpty(componentCfg.users)) { return [] }
    // format data
    return componentCfg.users.map((user) => {
      user.key = user.user_id
      // get date information
      const locale = window.user.user_language_id
      user.updated = moment(user.user_updated_at, 'YYYY-MM-DD').locale(locale).format('YYYY.MM.DD')
      user.created = moment(user.user_created_at, 'YYYY-MM-DD').locale(locale).format('YYYY.MM.DD')
      user.last_login = moment(user.user_last_login, 'YYYY-MM-DD').locale(locale).format('YYYY.MM.DD')
      user.created_by = (<Tag color={user.user_created_by === 0 ? 'geekblue' : 'grey'}>{user.user_created_by === 0 ? 'e-analysis' : 'user'}</Tag>)
      // set actions
      user.delete_action = <DeleteAction data={user} disabled={false} handler={handlerDelete} />
      user.edit_action = <EditAction data={user} disabled={false} handler={handlerEdit} />
      return user
    })
  }, [componentCfg.users])

  const paginationRangeText = useMemo(() => {
    return getPaginationText(componentCfg)
  }, [componentCfg.page, componentCfg.limit, componentCfg.total])

  const showComponentLoader = !componentCfg.isLoaded && !componentCfg.isChangingPage && !componentCfg.isReloading
  const showTableLoader = !componentCfg.isLoaded || componentCfg.isChangingPage || componentCfg.isReloading

  return (<>
    <LoaderBackDrop active={showComponentLoader} loader background>
      {(() => {
        if (componentCfg.isError) {
          return <ErrorResult compact={true} />
        } else { /* return components to render */
          return (<>
            <div className={'antd-table-such-as-react-suite'} style={{ height: '718px' }}>
              <AntdTable
                bordered
                size={'small'}
                scroll={{ y: 690 }} /* 718 - footer */
                pagination={false}
                columns={columns}
                tableLayout={'fixed'}
                loading={showTableLoader}
                expandable={{
                  expandedRowRender: (record) => (
                    <p
                      style={{
                        margin: 0
                      }}
                    >
                      {record.description}
                    </p>
                  ),
                  rowExpandable: (record) => record.name !== 'Not Expandable'
                }}
                dataSource={tableData}
              />
            </div>
            { /* pagination */ }
            <div style={{ padding: '5px 10px', backgroundColor: '#fafafa', borderTop: '1px #f2f2f5 solid' }}>
              <Pagination layout={
                [paginationRangeText, '-', 'pager', <Button key={_.uniqueId()} onClick={handlerPageReload} type="text" shape="circle" icon={<IoMdRefresh size={18} style={{ marginTop: '2px' }}/>} size={'small'}/>]
              }
                          size={'xs'} prev={true} next={true} first={true} last={true}
                          maxButtons={5} boundaryLinks={true} ellipsis={true} limit={componentCfg.limit}
                          total={componentCfg.total} activePage={componentCfg.page} onChangePage={handlerChangePage}
              />
            </div>
            { /* drawer */ }
            { componentCfg.drawer && <DrawerForm destroyOnClose={true} title={'TITLE'} open={componentCfg.drawer}
                                                 content={<></>} onClose={() => {}}/>
            }
          </>)
        }
      })()}
    </LoaderBackDrop>
  </>)
}

EdynaAdministrationsUsersTable.propTypes = {
  data: PropTypes.array
}
