import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRequestsEvents } from 'hooks/useRequestsEvents'
import { ForbiddenResult, EmptyResult, LoaderBackDrop, ErrorResult } from 'components/feedbacks'
import { PromotionProfile } from 'pages/dashboard/promotions/promotion/sections/PromotionProfile'
import { PromotionHousingSchema } from 'pages/dashboard/promotions/promotion/sections/PromotionHousingSchema'
import useDidMountEffect from '../../../../hooks/useDidMountEffect'
import { PromotionsIcon } from '../../../../components/icons'
import { SectionContent, SectionMenu, SectionTitle } from '../../../../components/panels'
import { useTranslation } from 'react-i18next'
import { PromotionEnergySchema } from './sections/PromotionEnergySchema'
import { PromotionBilling } from './sections/PromotionBilling'
import { PromotionReadings } from './sections/PromotionReadings'
import { PromotionAnalysis } from './sections/PromotionAnalysis'
import { PromotionEnergySupplyLiquidationPanel } from '../../../../components/promotion/PromotionEnergySupplyLiquidationPanel'
import _ from 'lodash'
import { isUserOverall } from '../../../../helpers/roles'
import { useEnvironment } from '../../../../hooks/useEnvironment'

// define locales for this page
const transFiles = ['dashboard']

// create main promotion layout
export const PromotionLayout = (props) => {
  // for translation
  const { t } = useTranslation(transFiles)
  // get for check permissions
  const { hasPromotionPermissions } = useEnvironment()
  // get current promotion id
  const { promotionId, resourceId } = useParams()
  // for redirect
  const navigate = useNavigate()
  // for get promotions
  const { getPromotion } = useRequestsEvents()
  // component config
  const [componentCfg, setComponentCfg] = useState({
    data: [],
    isLoaded: false,
    isError: false,
    isForbidden: false
  })

  // navigate config
  const [navigationConfig, setNavigationConfig] = useState({
    link: null,
    navigate: false
  })

  // when any item on menu is clicked...
  const handlerMenuItemClick = (key) => {
    // if current location is the same of selected key...
    if (resourceId === key) { return }
    // set config to navigate
    setNavigationConfig({ ...navigationConfig, key, navigate: true })
  }

  // on change promotion id call to reload component
  useDidMountEffect(() => {
    // if not navigate...
    if (!navigationConfig.navigate) { return }
    // fix for tabs...
    const currentUrl = location.pathname
    const urlParts = currentUrl.split('/')
    // go to
    navigate(`${urlParts.slice(0, -1).join('/')}/${navigationConfig.key}`)
    // set navigated
    setNavigationConfig({ ...navigationConfig, navigate: false })
  }, [navigationConfig])

  // on change promotion id call to reload component
  useDidMountEffect(() => {
    // set blank promotions
    setComponentCfg({ ...componentCfg, isError: false, isLoaded: false, data: [] })
  }, [promotionId])

  // for load all promotions data when component is mounted
  useEffect(() => {
    // clean up controller --> prevent crash with async function
    let isMounted = true
    // if is loaded...
    if (componentCfg.isLoaded) { // do nothing
    // other cases
    } else {
      // get promotion needed
      getPromotion(promotionId, (result) => {
        // prevent async crash
        if (!isMounted) return null
        setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, data: result.data, label: `${result.data.promotion_reference} - ${result.data.promotion_name}`, isForbidden: false })
      }, (err) => { // no permissions or other error
        // prevent async crash
        if (!isMounted) return null
        if (err === 403) {
          setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, data: [], label: t('dashboard.promotion'), isForbidden: true })
        } else {
          setComponentCfg({ ...componentCfg, isError: true, isLoaded: true, data: [], label: t('dashboard.promotion'), isForbidden: false })
        }
      })
    } // prevent crash with async function
    return () => { isMounted = false }
  }, [componentCfg.isLoaded])

  // get permissions
  const permissions = _.get(window, `promotion-${promotionId}.promotion_permissions`)
  // only when change resource id
  const component = useMemo(() => {
    // if not set permissions
    if (!isUserOverall() && (!permissions || permissions.length === 0)) { return <ForbiddenResult /> }

    const resourceComponents = {
      profile: { component: <PromotionProfile />, permission: 'read:promotion' },
      energy: { component: <PromotionEnergySchema />, permission: 'read:promotion:energy:config' },
      energy_billing_data: { component: <PromotionBilling />, permission: 'read:promotion:energy:supply:invoices' },
      energy_readings: { component: <PromotionReadings />, permission: 'read:promotion:energy:applications:pipes:sensors:readings' },
      promotion_schema: { component: <PromotionHousingSchema />, permission: 'read:promotion:block' },
      energy_analysis: { component: <PromotionAnalysis />, permission: 'read:promotion:energy:supply:invoices' },
      energy_liquidations: { component: <PromotionEnergySupplyLiquidationPanel />, permission: 'read:promotion:energy:liquidation:periods' }
    }
    // get resource selected
    const resource = _.get(resourceComponents, resourceId, null)
    // if any selected, return empty
    if (!resource) return <EmptyResult />

    return !hasPromotionPermissions(promotionId, resource.permission)
      ? <ForbiddenResult />
      : resource.component
  }, [permissions, resourceId, promotionId])

  // construct menu in function of permissions
  const promotionMenuItems = useMemo(() => {
    // if not set permissions
    if (!isUserOverall() && (!permissions || permissions.length === 0)) { return [] }
    // base menu
    const menu = [{
      label: 'dashboard.profile',
      key: 'profile',
      permission: 'read:promotion'
    }, {
      label: 'dashboard.energy',
      key: 'energy',
      permission: 'read:promotion:energy:config'
    }, {
      label: 'dashboard.promotion.promotion_schema',
      key: 'promotion_schema',
      permission: 'read:promotion:block'
    }, {
      label: 'dashboard.promotion.energy_billing_data',
      key: 'energy_billing_data',
      permission: 'read:promotion:energy:supply:invoices'
    }, {
      label: 'dashboard.promotion.energy_readings',
      key: 'energy_readings',
      permission: 'read:promotion:energy:applications:pipes:sensors:readings'
    }, {
      label: 'dashboard.promotion.analysis_and_liquidation',
      key: 'energy_analysis',
      permission: 'read:promotion:energy:supply:invoices'
    }, {
      label: 'dashboard.promotion.liquidation',
      key: 'energy_liquidations',
      permission: 'read:promotion:energy:liquidation:periods'
    }]

    // return menu
    return _.filter(menu, (item) => {
      return hasPromotionPermissions(promotionId, item.permission)
    })
  }, [permissions, promotionId])

  // render component
  return (<>
    <LoaderBackDrop active={!componentCfg.isLoaded} loader background={'white'} top height={'100%'}>
      {(() => {
        if (componentCfg.isForbidden) {
          return <ForbiddenResult />
        } else if (componentCfg.isError) {
          return <ErrorResult />
        } else if (!componentCfg.data || _.isEmpty(componentCfg.data)) {
          return <EmptyResult />
        } else if (componentCfg.isLoaded) {
          return (
            <div className={'dashboard-data-section'}>
              {/* title */}
              <SectionTitle title={componentCfg.label} icon={<PromotionsIcon />} />
              { /* menu */ }
              <SectionMenu activeKey={resourceId} handler={handlerMenuItemClick} items={promotionMenuItems}/>
              {/* content */}
              <SectionContent component={component} />
            </div>
          )
        }
      })()}
    </LoaderBackDrop>
  </>)
}
