import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, message, Row } from 'antd'
import { UserEmailField, UserPasswordField, ButtonFormSubmit } from 'components/forms'
import { useTranslation } from 'react-i18next'
import { useEnvironment } from 'hooks/useEnvironment'
import sha256 from 'sha256'
import { Edit3, Globe } from 'react-feather'
import { DividerCenterIcon } from 'components/divider'
import { useRequestsEvents } from 'hooks/useRequestsEvents'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'

// define transFiles for this page
const transFiles = ['authentication']

// mini component for locale links
export const LocaleLinks = (props) => {
  // for work with context
  const { setCurrentLocale } = useEnvironment()
  // get locale configuration for generate link
  const { locale, currentLocale, triggerOnClick } = props
  // call on change language
  const changeLocale = (e) => {
    // get language selected
    const locale = e.currentTarget.getAttribute('arial-locale')
    // change locale
    setCurrentLocale(locale, () => { triggerOnClick(locale) })
  } // return links
  return (
        <>
            <Button type="text" onClick={changeLocale} arial-locale={locale} disabled={currentLocale === locale}>
                {locale.toUpperCase()}
            </Button>
        </>
  )
}

LocaleLinks.propTypes = {
  locale: PropTypes.string.isRequired,
  triggerOnClick: PropTypes.func.isRequired,
  currentLocale: PropTypes.string.isRequired
}

export const Login = (props) => {
  // for translation
  const { t } = useTranslation(transFiles)
  // for redirect
  const navigate = useNavigate()
  // for work with context
  const { getCurrentLocale } = useEnvironment()
  // for work with context
  const { authUser } = useRequestsEvents()
  // for link language and re-rerender when change locale
  const [locale, setLocale] = useState(getCurrentLocale(props))
  // for re-rerender form when change locale
  const [form] = Form.useForm()
  // for validate on input change after first submit
  const [validateOnChange, setValidateOnChange] = useState([])
  const [validateHasFeedback, setValidateHasFeedback] = useState(false)

  // call to log in when finish successfully
  const onFinish = (values) => {
    // call to log in
    authUser({ ...values, user_password: sha256(values.user_password) }, (r) => {
      if (_.get(r, 'user_role', '-1').includes('overall')) {
        navigate('/dashboard/0')
      } else {
        navigate('/profiles')
      }
    }, () => {
      message.error(t('notification.failure.login', { ns: 'notifications' }))
    })
  }

  // change validateOnChange when finish is not successfully
  const onFinishFailed = () => {
    setValidateOnChange('onChange')
    setValidateHasFeedback(true)
  }

  // effects when locale is changed
  // reset field to load with new locale
  useEffect(() => { form.resetFields() }, [locale])

  return (
    <>
      <div className={'center-vh'}>
        <Card bordered={true} style={{ width: 350 }} justify="center">
          <Row justify="center">
            <Col span={8}>
              <img width={'85px'} src={'/logo.svg'} alt={'RDmes logo'}/>
            </Col>
            <Col span={15}>
              <Row>
                <Col span={24} className={'teko-font teko-font-rdmes'}>
                  <span>RDmes</span>
                </Col>
                <Col span={24} className={'teko-font teko-font-rdmes-slogan'}>
                  {t('authentication.login.slogan', { ns: 'authentication' })}.
                </Col>
              </Row>
            </Col>
          </Row>
          <DividerCenterIcon><Edit3/></DividerCenterIcon>
          <Form form={form} validateTrigger={validateOnChange} layout="vertical" name="normal_login" autoComplete="off"
                className="login-form" onFinish={onFinish} onFinishFailed={onFinishFailed} requiredMark={false}>
            <UserEmailField hasFeedback={validateHasFeedback}/>
            <UserPasswordField hasFeedback={validateHasFeedback}/>
            <ButtonFormSubmit text={t('authentication.login.sign_in', { ns: 'authentication' })}/>
          </Form>
          <DividerCenterIcon><Globe/></DividerCenterIcon>
          <Row justify="center" align="middle">
            <Col span={4}>
              <LocaleLinks {...props} locale={'ca'} currentLocale={locale} triggerOnClick={setLocale}/>
            </Col> ·
            <Col span={4}>
              <LocaleLinks {...props} locale={'en'} currentLocale={locale} triggerOnClick={setLocale}/>
            </Col> ·
            <Col span={4}>
              <LocaleLinks {...props} locale={'es'} currentLocale={locale} triggerOnClick={setLocale}/>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  )
}
