import React, { useMemo } from 'react'
import { Table } from 'rsuite'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import moment from 'moment'
import { Tag } from 'antd'

const { Column, HeaderCell, Cell, ColumnGroup } = Table
const CompactCell = props => <Cell {...props} style={{ padding: 6 }} />
const CompactHeaderCell = props => <HeaderCell {...props} style={{ padding: 4 }} />

// define locales for this page
const transFiles = ['dashboard']

export const PromotionPersonTable = (props) => {
  // for translation
  const { t } = useTranslation(transFiles)
  // get person
  const data = _.get(props, 'data', {})
  // eslint-disable-next-line camelcase
  const person_fiscal_address_as_social = _.get(data, 'person_fiscal_address_as_social', true)
  // eslint-disable-next-line camelcase
  const socialAddressHeader = (person_fiscal_address_as_social) ? `${t('dashboard.social_address')} / ${t('dashboard.fiscal_address')}` : t('dashboard.social_address')

  // use memo to get a data
  const tableData = useMemo(() => {
    // if not data set...
    if (_.isEmpty(data)) { return [] }
    /* eslint-disable-next-line camelcase */
    const { person_type } = data
    /* eslint-disable-next-line camelcase */
    const { person_promotion_address } = data
    /* eslint-disable-next-line camelcase */
    const { person_social_address, person_social_address_postal_code, person_social_address_country_name, person_social_address_province_name, person_social_address_town_name } = data
    /* eslint-disable-next-line camelcase */
    const { person_fiscal_address, person_fiscal_address_postal_code, person_fiscal_address_country_name, person_fiscal_address_province_name, person_fiscal_address_town_name } = data
    // get social address full
    // eslint-disable-next-line camelcase
    data.social_address_full = [person_social_address, person_social_address_postal_code, person_social_address_country_name, person_social_address_province_name, person_social_address_town_name].filter(Boolean).join(', ')
    // eslint-disable-next-line camelcase
    data.fiscal_address_full = [person_fiscal_address, person_fiscal_address_postal_code, person_fiscal_address_country_name, person_fiscal_address_province_name, person_fiscal_address_town_name].filter(Boolean).join(', ')
    // eslint-disable-next-line camelcase
    if (_.isEmpty(data.social_address_full) && person_promotion_address) {
      /* eslint-disable-next-line camelcase */
      data.social_address_full = person_promotion_address
    }
    /* eslint-disable-next-line camelcase */
    if (_.isEmpty(data.fiscal_address_full) && person_promotion_address) {
      /* eslint-disable-next-line camelcase */
      data.social_address_full = person_promotion_address
    }

    /* eslint-disable-next-line camelcase */
    if (data.person_brand) {
      /* eslint-disable-next-line camelcase */
      data.peson_display_name = `${data.person_brand} (${data.person_name})`
    } else {
      /* eslint-disable-next-line camelcase */
      data.peson_display_name = data.person_name
    }

    // eslint-disable-next-line camelcase
    const { created_at, updated_at } = data
    // get date information
    const locale = window.user.user_language_id
    data.updated = moment(updated_at, 'YYYY-MM-DD').locale(locale).format('YYYY.MM.DD')
    data.created = moment(created_at, 'YYYY-MM-DD').locale(locale).format('YYYY.MM.DD')
    // eslint-disable-next-line camelcase
    const personColor = (person_type === 1) ? 'blue' : 'geekblue'
    // eslint-disable-next-line camelcase
    const personTypeName = data.person_type_name
    // eslint-disable-next-line camelcase
    data.person_tag = (<Tag color={(isNaN(person_type)) ? 'grey' : personColor}>{isNaN(person_type) ? person_type : personTypeName}</Tag>)
    // return data to render
    return [data]
  }, [data])

  return (<>
    {/* person data */ }
    <Table hover={false} autoHeight={true} data={tableData} cellBordered rowHeight={30} headerHeight={30}>
      <Column width={50} align="center" fixed>
        <CompactHeaderCell>Id</CompactHeaderCell>
        <CompactCell dataKey="person_id" />
      </Column>
      <Column minWidth={180} flexGrow={1} fullText fixed>
        <CompactHeaderCell>{t('dashboard.name')}</CompactHeaderCell>
        <CompactCell dataKey="peson_display_name" />
      </Column>
      <Column width={180} fullText>
        <CompactHeaderCell>{t('dashboard.email')}</CompactHeaderCell>
        <CompactCell dataKey="person_mail" />
      </Column>
      <Column width={140} fullText>
        <CompactHeaderCell>{t('dashboard.phone_1')}</CompactHeaderCell>
        <CompactCell dataKey="person_phone_1" />
      </Column>
      <Column width={140} fullText>
        <CompactHeaderCell>{t('dashboard.phone_2')}</CompactHeaderCell>
        <CompactCell dataKey="person_phone_2" />
      </Column>
      <Column width={90}>
        <CompactHeaderCell>{t('dashboard.type')}</CompactHeaderCell>
        <CompactCell dataKey="person_tag" />
      </Column>
      <ColumnGroup groupHeaderHeight={30} header={<span className="rsuite-table-group-inline">{t('dashboard.person.identity')}</span>}>
        <Column width={90}>
          <CompactHeaderCell></CompactHeaderCell>
          <CompactCell dataKey="person_number_type_name_abbr" />
        </Column>
        <Column width={90}>
          <CompactHeaderCell></CompactHeaderCell>
          <CompactCell dataKey="person_number" />
        </Column>
      </ColumnGroup>
      <Column width={90} align={'center'}>
        <CompactHeaderCell>{t('dashboard.updated_at')}</CompactHeaderCell>
        <CompactCell dataKey="updated" />
      </Column>
      <Column width={90} align={'center'}>
        <CompactHeaderCell>{t('dashboard.created_at')}</CompactHeaderCell>
        <CompactCell dataKey="created" />
      </Column>
    </Table>
    {/* social address */}
    <Table hover={false} autoHeight={true} data={[data]} cellBordered rowHeight={30} headerHeight={30}>
      <Column minWidth={180} flexGrow={1} width={90}>
        <CompactHeaderCell>{socialAddressHeader}</CompactHeaderCell>
        <CompactCell dataKey="social_address_full" />
      </Column>
    </Table>
    {/* fiscal address */}
    {/* eslint-disable-next-line camelcase */}
    { !person_fiscal_address_as_social &&
      <Table hover={false} autoHeight={true} data={[data]} cellBordered rowHeight={30} headerHeight={30}>
        <Column minWidth={180} flexGrow={1} width={90}>
          <CompactHeaderCell>{t('dashboard.fiscal_address')}</CompactHeaderCell>
          <CompactCell dataKey="fiscal_address_full" />
        </Column>
      </Table>
    }
  </>)
}

PromotionPersonTable.propTypes = {
  data: PropTypes.object
}
