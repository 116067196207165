// for print person information
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Table } from 'rsuite'
import PropTypes from 'prop-types'
import React from 'react'

// for react-suite tables with compact cells
const { Column, HeaderCell, Cell } = Table
const CompactCell = props => <Cell {...props} style={{ padding: 6 }} />
const CompactHeaderCell = props => <HeaderCell {...props} style={{ padding: 4 }} />

const transFiles = ['dashboard']

export const BlockGeneralDataTable = (props) => {
  // for translation
  const { t } = useTranslation(transFiles)
  // get data to set
  const { data } = props
  // eslint-disable-next-line camelcase
  const { created_at, updated_at } = data
  // get date information
  const locale = window.user.user_language_id
  data.updated = moment(updated_at, 'YYYY-MM-DD').locale(locale).format('YYYY.MM.DD')
  data.created = moment(created_at, 'YYYY-MM-DD').locale(locale).format('YYYY.MM.DD')

  return (<>
    {/* person data */ }
    <Table hover={false} autoHeight={true} data={[data]} cellBordered rowHeight={30} headerHeight={30}>
      <Column width={50} align="center" fixed>
        <CompactHeaderCell>Id</CompactHeaderCell>
        <CompactCell dataKey="block_id" />
      </Column>
      <Column width={180} fullText fixed>
        <CompactHeaderCell>{t('dashboard.reference')}</CompactHeaderCell>
        <CompactCell dataKey="block_reference" />
      </Column>
      { /* <Column width={180} fullText>
        <CompactHeaderCell>{t('dashboard.name')}</CompactHeaderCell>
        <CompactCell dataKey="block_name" />
      </Column> */ }
      <Column minWidth={300} flexGrow={1} fullText>
        <CompactHeaderCell>{t('dashboard.address')}</CompactHeaderCell>
        <CompactCell dataKey="block_address" />
      </Column>
      <Column width={90} align={'center'}>
        <CompactHeaderCell>{t('dashboard.updated_at')}</CompactHeaderCell>
        <CompactCell dataKey="updated" />
      </Column>
      <Column width={90} align={'center'}>
        <CompactHeaderCell>{t('dashboard.created_at')}</CompactHeaderCell>
        <CompactCell dataKey="created" />
      </Column>
    </Table>
  </>)
}

BlockGeneralDataTable.propTypes = {
  data: PropTypes.object.isRequired
}
