import React, { useEffect, useMemo, useState } from 'react'
import { Col, Row } from 'antd'
import { MenuHeaderAddButton, MenuHeaderImportButton, MenuHeaderReloadButton, MenuHeaderSearch, HeaderTitleLayout } from 'components/menus'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import uniqid from 'uniqid'
import { useTranslation } from 'react-i18next'
import { PromotionsIcon } from 'components/icons'
import { useRequestsEvents } from 'hooks/useRequestsEvents'
import { useEnvironment } from 'hooks/useEnvironment'
import { ForbiddenResult, EmptyResult, LoaderBackDrop, ErrorResult, SelectResult } from 'components/feedbacks'
import _ from 'lodash'
import useDidMountEffect from '../../../hooks/useDidMountEffect'
import { Sidenav, Nav } from 'rsuite'
import PropTypes from 'prop-types'
import { isUserOverall } from '../../../helpers/roles'

// define locales for this page
const transFiles = ['dashboard']

function getMenuItem (label, key, icon, disabled, children, type) {
  return { key, icon, children, label, type, disabled }
}

// side var for select a promotion
const PromotionsSideNav = (props) => {
  // get current promotion id
  const { promotionId } = useParams()
  // get props
  const { handler, options } = props
  // get items
  const items = useMemo(() => {
    return options.map((promotion) => {
      return <Nav.Item key={_.uniqueId()} eventKey={promotion.key} disabled={promotion.disabled}>{promotion.label}</Nav.Item>
    })
  }, [options])

  return (
    <Sidenav>
      <Sidenav.Body>
        <Nav onSelect={handler} activeKey={parseInt(promotionId)}>
          { items }
        </Nav>
      </Sidenav.Body>
    </Sidenav>
  )
}

PromotionsSideNav.propTypes = {
  handler: PropTypes.func.isRequired,
  promotionId: PropTypes.number,
  options: PropTypes.array.isRequired
}

// main component
export const PromotionsLayout = (props) => {
  // for translation
  const { t } = useTranslation(transFiles)
  // get current promotion id
  const { promotionId } = useParams()
  // for redirect
  const navigate = useNavigate()
  // for get promotions
  const { getPromotions } = useRequestsEvents()
  // get for check permissions
  const { hasPermissions } = useEnvironment()
  // component config
  const [componentCfg, setComponentCfg] = useState({
    menu: [],
    menuSelectedKey: [],
    isLoaded: false,
    isError: false,
    isForbidden: false,
    searchValue: null
  }) // navigate config
  const [navigationConfig, setNavigationConfig] = useState({
    link: null,
    navigate: false
  }) // get user permission (profile level)
  const hasListPermission = hasPermissions('item:promotions') && !componentCfg.isForbidden
  // const hasCreatePermission = (hasIndexPermission && hasPermissions('create:promotion'))

  // when any item on menu is clicked...
  const handlerMenuItemClick = (key) => {
    // if current location is the same of selected key...
    if (promotionId === key) { return }
    // set config to navigate
    setNavigationConfig({ ...navigationConfig, key, navigate: true })
  }

  // on change promotion id call to reload component
  useDidMountEffect(() => {
    // if not navigate...
    if (!navigationConfig.navigate) { return }
    // go to
    navigate(`${navigationConfig.key}/profile`)
    // set navigated
    setNavigationConfig({ ...navigationConfig, navigate: false })
  }, [navigationConfig])

  // when user click to refresh
  const handlerReload = (e) => {
    // not reload if not loaded
    if (!componentCfg.isLoaded) { return }
    // reload component via useEffect
    setComponentCfg({ ...componentCfg, isLoaded: false })
  }

  // method to change invoices to show
  const handlerSearch = (value) => {
    // not reload if not loaded
    if (!componentCfg.isLoaded) { return }
    // reload component via useEffect
    setComponentCfg({ ...componentCfg, isLoaded: false, searchValue: value })
  }

  // for load all promotions data when component is mounted
  useEffect(() => {
    // clean up controller --> prevent crash with async function
    let isMounted = true
    // if not has permissions...
    if (!componentCfg.isLoaded && !hasListPermission) {
      // set blank promotions
      setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, menu: [] })
      // if is already component loaded, not load again
    } else if (!componentCfg.isLoaded) {
      // get promotions from database
      getPromotions((result) => {
        // prevent async crash
        if (!isMounted) return null
        // if isset search value...
        if (componentCfg.searchValue) { // filter promotions by search value...
          result.data = _.filter(result.data, function (o) {
            return o.promotion_reference.indexOf(componentCfg.searchValue) > -1 || o.promotion_name.indexOf(componentCfg.searchValue) > -1
          })
        } // creating menu config
        result.data = result.data.map((p) => {
          // get promotion permissions
          const permissions = _.get(p, 'promotion_permissions', [])
          // get item menu config
          return getMenuItem(`${p.promotion_reference} - ${p.promotion_name}`, p.promotion_id, null, !isUserOverall() && !permissions.includes('read:promotion'))
        }) // set promotion to component to refresh component
        setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, menu: result.data, isForbidden: false })
      }, (err) => {
        // prevent async crash
        if (!isMounted) return null
        // for forbidden case...
        if (err === 403) {
          setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, menu: [], isForbidden: true })
        } else {
          setComponentCfg({ ...componentCfg, isError: true, isLoaded: true, menu: [], isForbidden: false })
        }
      })
    } // prevent crash with async function
    return () => { isMounted = false }
  }, [componentCfg.isLoaded])

  return (
    <Row>
      <Col className={'dashboard-data-side-nav'} flex="0 0 280px">
        { /* menu header */ }
        <Row className={'dashboard-data-side-nav-header'}>
          <Col span={24}>
            { /* title and buttons */}
            <HeaderTitleLayout icon={<PromotionsIcon />} title={t('dashboard.promotions')}
               // buttons={[
               //   <MenuHeaderAddButton onClick={() => {}} key={uniqid()} disabled={!hasCreatePermission} />,
               //    <MenuHeaderImportButton onClick={() => {}} key={uniqid()} disabled={!hasCreatePermission} />
               //  ]}
            />
          </Col>
          <Col span={24}>
            { /* search bar */}
            <MenuHeaderSearch defaultValue={componentCfg.searchValue} onSearch={handlerSearch} disabled={!hasListPermission}/>
          </Col>
        </Row>
        { /* menu content */ }
        <Row className={'dashboard-data-side-nav-data'}>
          <Col span={24}>
            <LoaderBackDrop active={!componentCfg.isLoaded || navigationConfig.navigate} loader={!componentCfg.isLoaded && !navigationConfig.navigate} top>
              { /* items to select */}
              {(() => {
                if (!componentCfg.isLoaded) {
                  return <LoaderBackDrop active={true} loader={true} top/>
                } else if (!hasListPermission) {
                  return <ForbiddenResult/>
                } else if (componentCfg.isError) {
                  return <ErrorResult />
                } else if (componentCfg.menu.length < 1) {
                  return <EmptyResult/>
                } else {
                  return <PromotionsSideNav handler={handlerMenuItemClick} options={componentCfg.menu} />
                }
              })()}
            </LoaderBackDrop>
          </Col>
        </Row>
        { /* menu footer */ }
        <Row className={'dashboard-data-side-nav-footer'}>
          <Col span={24}>
            <HeaderTitleLayout buttons={[
              <MenuHeaderReloadButton onClick={handlerReload} key={uniqid()} disabled={!hasListPermission} />
            ]}/>
          </Col>
        </Row>
      </Col>
      <Col className={'dashboard-data-with-side-nav'} flex="auto">
        {(() => {
          if (!promotionId) {
            return <SelectResult text={'dashboard.select_promotion'} />
          } else {
            return <Outlet />
          }
        })()}
      </Col>
    </Row>
  )
}
