import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import React, { useMemo } from 'react'
import moment from 'moment/moment'
import { Panel, Table } from 'rsuite'
import PropTypes from 'prop-types'
import { MdOutlineRoomPreferences } from 'react-icons/md'
import { Col, Row } from 'antd'

const { Column, HeaderCell, Cell } = Table
const CompactCell = props => <Cell {...props} style={{ padding: 6 }} />
const CompactHeaderCell = props => <HeaderCell {...props} style={{ padding: 4 }} />

// define locales for this page
const transFiles = ['dashboard']

export function PromotionTable (props) {
  // for translation
  const { t } = useTranslation(transFiles)
  // get person
  const data = _.get(props, 'data', {})
  // return data for this table
  const tableData = useMemo(() => {
    // if not data set...
    if (_.isEmpty(data)) { return [] }
    // get data
    // eslint-disable-next-line camelcase
    const { promotion_address, promotion_postal_code } = data
    // eslint-disable-next-line camelcase
    const { promotion_country_name, promotion_province_name, promotion_town_name } = data
    // eslint-disable-next-line camelcase
    const { created_at, updated_at } = data
    // set full address
    // eslint-disable-next-line camelcase
    data.promotion_address_full = `${promotion_address}, ${promotion_postal_code}, ${promotion_town_name}, ${promotion_province_name}, ${promotion_country_name}`
    // get date information
    const locale = window.user.user_language_id
    data.updated = moment(updated_at, 'YYYY-MM-DD').locale(locale).format('YYYY.MM.DD')
    data.created = moment(created_at, 'YYYY-MM-DD').locale(locale).format('YYYY.MM.DD')
    // set units
    data.promotion_apartment_type_m2_string = <>{data.promotion_apartment_type_m2}m<sup>2</sup></>
    data.promotion_apartment_type_u_string = <>{data.promotion_apartment_type_u}W/m<sup>2</sup>K</>
    data.promotion_apartment_comfort_temperature_string = <>{data.promotion_apartment_comfort_temperature}ºC</>
    data.promotion_apartment_DD_base_temperature_string = <>{data.promotion_apartment_DD_base_temperature}ºC</>
    // return data formatted
    return [data]
  }, [data])

  return (<div className={'filled-table-panel'}>
    <Panel style={{ borderRadius: '4px' }} bordered bodyFill>
      <Table hover={false} autoHeight={true} data={tableData} cellBordered rowHeight={30} headerHeight={30}>
        <Column width={50} align="center" fixed>
          <CompactHeaderCell>Id</CompactHeaderCell>
          <CompactCell dataKey="promotion_id" />
        </Column>
        <Column width={110} fixed fullText>
          <CompactHeaderCell>{t('dashboard.reference')}</CompactHeaderCell>
          <CompactCell dataKey="promotion_reference" />
        </Column>
        <Column width={90}>
          <CompactHeaderCell>Geo X</CompactHeaderCell>
          <CompactCell dataKey="promotion_geo_x" />
        </Column>
        <Column width={90}>
          <CompactHeaderCell>Geo Y</CompactHeaderCell>
          <CompactCell dataKey="promotion_geo_y" />
        </Column>
        <Column minWidth={300} flexGrow={1} fullText>
          <CompactHeaderCell>{t('dashboard.address')}</CompactHeaderCell>
          <CompactCell dataKey="promotion_address_full" />
        </Column>
        <Column width={90} align={'center'}>
          <CompactHeaderCell>{t('dashboard.updated_at')}</CompactHeaderCell>
          <CompactCell dataKey="updated" />
        </Column>
        <Column width={90} align={'center'}>
          <CompactHeaderCell>{t('dashboard.created_at')}</CompactHeaderCell>
          <CompactCell dataKey="created" />
        </Column>
      </Table>
    </Panel>
    <div className={'rs-between-tables-title'}>
      <Row>
        <Col flex="20px"><MdOutlineRoomPreferences size={'18px'}/></Col>
        <Col flex="auto" className={'rs-table-cell-title-text'}>{t('dashboard.promotion.holder_type')}</Col>
      </Row>
    </div>
    <Panel style={{ borderRadius: '4px' }} bordered bodyFill>
      <Table hover={false} autoHeight={true} data={tableData} cellBordered rowHeight={30} headerHeight={30}>
        <Column width={50} align="center">
          <CompactHeaderCell>Id</CompactHeaderCell>
          <CompactCell dataKey="promotion_id" />
        </Column>
        <Column width={110}>
          <CompactHeaderCell>{t('dashboard.apartment.rooms')}</CompactHeaderCell>
          <CompactCell dataKey="promotion_apartment_type_rooms" />
        </Column>
        <Column width={110}>
          <CompactHeaderCell>{t('dashboard.apartment.surface_area')}</CompactHeaderCell>
          <CompactCell dataKey="promotion_apartment_type_m2_string" />
        </Column>
        <Column width={110}>
          <CompactHeaderCell>U</CompactHeaderCell>
          <CompactCell dataKey="promotion_apartment_type_u_string" />
        </Column>
        <Column width={150}>
          <CompactHeaderCell>{t('dashboard.apartment.comfort_temperature')}</CompactHeaderCell>
          <CompactCell dataKey="promotion_apartment_comfort_temperature_string" />
        </Column>
        <Column minWidth={150} flexGrow={1}>
          <CompactHeaderCell>{t('dashboard.apartment.dd_base_temperature')}</CompactHeaderCell>
          <CompactCell dataKey="promotion_apartment_DD_base_temperature_string" />
        </Column>
      </Table>
    </Panel>
  </div>)
}

PromotionTable.propTypes = {
  data: PropTypes.object
}
