import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Layout, Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEnvironment } from 'hooks/useEnvironment'
import { Table } from 'rsuite'
import { TbDoorExit } from 'react-icons/tb'
import { CgProfile } from 'react-icons/cg'
import { useRequestsEvents } from '../../hooks/useRequestsEvents'

// define locales for this page
const transFiles = ['dashboard', 'fields']

const { Column, HeaderCell, Cell, ColumnGroup } = Table
const CompactCell = props => <Cell {...props} style={{ padding: 6 }} />
const CompactHeaderCell = props => <HeaderCell {...props} style={{ padding: 4 }} />

export const ProfilesLayout = () => {
  // for work with context
  const { deleteAuthUserToken } = useEnvironment()
  // for work with context
  const { getUserProfiles } = useRequestsEvents()
  // for translation
  const { t } = useTranslation(transFiles)
  // for redirect
  const navigate = useNavigate()
  // component config
  const [componentCfg, setComponentCfg] = useState({
    profiles: [],
    isLoaded: false,
    isForbidden: false,
    isError: false
  })

  const handlerRowClick = (rowData) => {
    navigate(`/dashboard/${rowData.profile_id}`)
  }

  // loading component from first load:
  // - only if set source id and is not loading
  useEffect(() => {
    // clean up controller --> prevent crash with async function
    let isMounted = true
    // not reload if component is loaded...
    if (componentCfg.isLoaded) { return }

    // get data promotion energy sources
    getUserProfiles((result) => {
      // prevent async crash
      if (!isMounted) return null
      // set result
      setComponentCfg({ ...componentCfg, profiles: _.get(result, 'user_profiles', []), isForbidden: false, isError: false, isLoaded: true })
    }, () => {
      // prevent async crash
      if (!isMounted) return null
      // set error
      setComponentCfg({ ...componentCfg, profiles: [], isForbidden: false, isError: true, isLoaded: true })
    })
    // prevent crash with async function
    return () => { isMounted = false }
  }, [componentCfg.isLoaded])

  return (
    <Layout id={'dashboard-layout'}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f0f2f5' }}>
        <div style={{ width: '612px', height: '484px', backgroundColor: 'white', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px', borderBottom: '1px solid #e0e0e0', backgroundColor: '#fafafa', alignItems: 'center' }} >
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <CgProfile size={22} /><span style={{ fontSize: '15px' }}>Profiles</span>
            </div>
            {/* <Button shape="circle" icon={<UserIcon />} disabled={!hasPermissions('item:user')} /> */}
            <Button shape="circle" icon={<TbDoorExit style={{ fontSize: '17px', marginTop: '2px', marginLeft: '1px' }}/>} onClick={ deleteAuthUserToken }/>
          </div> {/* 53px is a toolbar height */}
          <div style={{ padding: '10px', overflow: 'auto', backgroundColor: '#fafafa', height: 'calc(100% - 53px)' }}>
            <div style={{ backgroundColor: 'white' }}>
              <Table loading={!componentCfg.isLoaded} rowClassName="pointer-cursor" hover={true} fillHeight={false} autoHeight={false} height={411} data={componentCfg.profiles} cellBordered rowHeight={30} headerHeight={30} bordered onRowClick={handlerRowClick}>
                <Column width={50} align="center" fixed>
                  <CompactHeaderCell>Id</CompactHeaderCell>
                  <CompactCell dataKey="profile_id"/>
                </Column>
                <Column minWidth={180} flexGrow={1} fullText fixed>
                  <CompactHeaderCell>{t('dashboard.name')}</CompactHeaderCell>
                  <CompactCell dataKey="profile_name"/>
                </Column>
                <Column minWidth={180} flexGrow={1} fullText fixed>
                  <CompactHeaderCell>{t('dashboard.role')}</CompactHeaderCell>
                  <CompactCell dataKey="profile_user_role"/>
                </Column>
              </Table>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  )
}
