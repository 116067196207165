import React from 'react'
import { Link, useRouteError } from 'react-router-dom'
import { Button, Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { LoaderTriangle, LoaderCircle } from 'components/spinners'
import PropTypes from 'prop-types'

// define locales for this page
const transFiles = ['404', '500']
// error numbers
const notFount = 404

export const CustomErrors = (props) => {
  // get route error
  const error = props.status || useRouteError().status

  // for translation
  const { t } = useTranslation(transFiles)

  return (<>
    <div className={'center-div'}>
      <div>
        <Row justify="center" align="middle">
          <Col>
            { notFount === error ? <LoaderTriangle /> : <LoaderCircle /> }
          </Col>
          <Col>
            <span className={'teko-font teko-font-error-size'}>
              { notFount === error ? t('title', { ns: ['404'] }) : t('title', { ns: ['500'] }) }
            </span>
          </Col>
        </Row>
        <Row justify="center" align="middle">
          <p style={{ paddingLeft: '10px', paddingRight: '8px' }}>
            { notFount === error ? t('text', { ns: ['404'] }) : t('text', { ns: ['500'] }) }
          </p>
        </Row>
        <Row justify="center" align="middle">
          <Link to={'/'}><Button type="text">{t('button')}</Button></Link>
        </Row>
      </div>
    </div>
  </>)
}

CustomErrors.propTypes = {
  status: PropTypes.string
}
