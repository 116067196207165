import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Segmented, Space } from 'antd'
import { BlockApartmentPanel } from './BlockApartmentPanel'
import { Panel } from 'rsuite'
import { PanelHeader } from '../panels'
import { ApartmentIcon } from '../icons'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useRequestsEvents } from '../../hooks/useRequestsEvents'
import { useEnvironment } from '../../hooks/useEnvironment'
import useDidMountEffect from '../../hooks/useDidMountEffect'
import { EmptyResult, ErrorResult, LoaderBackDrop } from '../feedbacks'

// create segment on options when segment is rendered
const getSegmentOptions = (data) => {
  return data.map((fl) => {
    return { label: _.get(fl, 'floor_name', ''), value: _.get(fl, 'floor_id', '') }
  })
}

export const BlockFloorsPanel = (props) => {
  // get current promotion id
  const { promotionId } = useParams()
  // get block id
  const { blockId } = props
  // get methods to send data
  const { getPromotionBlockFloorsWithApartments } = useRequestsEvents()
  // get for check permissions
  const { hasPromotionPermissions } = useEnvironment()
  // component config
  const [componentCfg, setComponentCfg] = useState({
    floors: [],
    apartments: [],
    defaultMenuSelectedId: null,
    menuSelectedId: null,
    isLoaded: false,
    isError: false,
    isForbidden: false
  }) // floor permissions
  const hasReadFloorBlockPermission = hasPromotionPermissions(promotionId, 'read:promotion:block:floors') && !componentCfg.isForbidden
  const hasCreateFloorBlockPermission = hasPromotionPermissions(promotionId, 'create:promotion:block:floors') && !componentCfg.isForbidden
  const hasUpdateFloorBlockPermission = hasPromotionPermissions(promotionId, 'update:promotion:block:floors') && !componentCfg.isForbidden
  const hasDeleteFloorBlockPermission = hasPromotionPermissions(promotionId, 'delete:promotion:block:floors') && !componentCfg.isForbidden

  // on change segmentation
  const handlerChangeSelection = (id) => {
    // not change if component is not loaded....
    if (!componentCfg.isLoaded) { return }
    // set new selected id
    setComponentCfg({ ...componentCfg, menuSelectedId: id })
  }

  // method to change invoices to show
  useDidMountEffect(() => {
    // not reload if component is loaded...
    if (!componentCfg.isLoaded) { return }
    // get source invoices
    const floors = _.find(componentCfg.floors, { floor_id: componentCfg.menuSelectedId })
    const apartments = _.get(floors, 'floor_apartments', [])
    // load invoices
    setComponentCfg({ ...componentCfg, apartments })
  }, [componentCfg.floors, componentCfg.menuSelectedId])

  // loading component from first load
  useEffect(() => {
    // clean up controller --> prevent crash with async function
    let isMounted = true
    // not reload if component is loaded...
    if (componentCfg.isLoaded) { return }
    // if not has permissions
    if (!hasReadFloorBlockPermission) {
      setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, floors: [], apartments: [] })
    } else {
      // get data promotion energy sources
      getPromotionBlockFloorsWithApartments(promotionId, blockId, (result) => {
        // prevent async crash
        if (!isMounted) return null
        // get default source
        const defaultMenuSelectedId = componentCfg.defaultMenuSelectedId || _.get(result.data, '[0].floor_id', 0)
        const menuSelectedId = componentCfg.menuSelectedId || defaultMenuSelectedId
        // set data to config
        setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, floors: result.data, defaultMenuSelectedId, menuSelectedId, isForbidden: false })
      }, (err) => {
        // prevent async crash
        if (!isMounted) return null
        if (err === 403) {
          setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, floors: [], apartments: [], isForbidden: true })
        } else {
          setComponentCfg({ ...componentCfg, isError: true, isLoaded: true, floors: [], apartments: [], isForbidden: false })
        }
      })
    } // prevent crash with async function
    return () => { isMounted = false }
  }, [componentCfg.isLoaded])

  // for border first panel
  const isPanelBordered = !componentCfg.isLoaded || componentCfg.isError || _.isEmpty(componentCfg.floors)

  return (<>
    {(() => { /* check permissions */
      if (!hasReadFloorBlockPermission) {
        return <></>
      } else { /* render a panel */
        return (
          <Panel bodyFill header={ /* panel header */
            <PanelHeader icon={<ApartmentIcon />} title={'dashboard.block.data_title_floors'}
                         hasCreate={hasCreateFloorBlockPermission} hasEdit={hasUpdateFloorBlockPermission} hasDelete={hasDeleteFloorBlockPermission} />
          }>
            <LoaderBackDrop active={!componentCfg.isLoaded} bordered background loader>
              <Panel className={'filled-table-panel'} style={{ borderRadius: '4px' }} bordered={isPanelBordered} bodyFill>
                  {(() => { /* load component */
                    if (componentCfg.isError) {
                      return <ErrorResult compact={true}/>
                    } else if (_.isEmpty(componentCfg.floors)) {
                      return <EmptyResult compact={true} />
                    } else { /* return components to render */
                      return (<>
                        <Space className="block-information" direction="vertical" size="middle" style={{ display: 'flex' }}>
                          <Segmented defaultValue={componentCfg.defaultMenuSelectedId} value={componentCfg.menuSelectedId} onChange={handlerChangeSelection} options={getSegmentOptions(componentCfg.floors)} />
                          <Panel className={'filled-table-panel'} style={{ borderRadius: '4px', overflowY: 'auto' }} bordered bodyFill>
                            <BlockApartmentPanel apartments={componentCfg.apartments} />
                          </Panel>
                        </Space>
                      </>)
                    }
                  })()}

              </Panel>
            </LoaderBackDrop>
          </Panel>
        )
      }
    })()}
  </>)
}

BlockFloorsPanel.propTypes = {
  blockId: PropTypes.number.isRequired
}
