import React, { useEffect, useState } from 'react'
import { Panel } from 'rsuite'
import 'rsuite/dist/rsuite.min.css'
import { PanelHeader } from '../panels'
import { AdministratorIcon } from '../icons'
import { useParams } from 'react-router-dom'
import { useEnvironment } from '../../hooks/useEnvironment'
import { useRequestsEvents } from '../../hooks/useRequestsEvents'
import { EmptyResult, ErrorResult, LoaderBackDrop } from '../feedbacks'
import _ from 'lodash'
import { PromotionPersonTable } from './tables/PromotionPersonTable'

export const PromotionPersonAdministratorPanel = (props) => {
  // get current promotion id
  const { promotionId } = useParams()
  // for get promotions
  const { getPromotionAdministrator } = useRequestsEvents()
  // get permissions
  const { hasPromotionPermissions } = useEnvironment()
  // component config
  const [componentCfg, setComponentCfg] = useState({
    data: {},
    isLoaded: false,
    isError: false,
    isForbidden: false
  }) // permissions
  const hasReadAdminPromotionPermission = hasPromotionPermissions(promotionId, 'read:promotion:admin') && !componentCfg.isForbidden
  const hasUpdateAdminPromotionPermission = hasPromotionPermissions(promotionId, 'update:promotion:admin') && !componentCfg.isForbidden
  const hasDeleteAdminPromotionPermission = hasPromotionPermissions(promotionId, 'delete:promotion:admin') && !componentCfg.isForbidden

  // for load all promotions data when component is mounted
  useEffect(() => {
    // clean up controller --> prevent crash with async function
    let isMounted = true
    // not reload if component is loaded...
    if (componentCfg.isLoaded) { return }
    // if not has permissions
    if (!hasReadAdminPromotionPermission) {
      setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, data: {} })
    } else {
      // get promotion holder data
      getPromotionAdministrator(promotionId, (result) => {
        // prevent async crash
        if (!isMounted) return null
        // get holder data
        const data = result.data
        // set holder data
        setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, data, isForbidden: false })
      }, (err) => {
        // prevent async crash
        if (!isMounted) return null
        if (err === 403) {
          setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, data: {}, isForbidden: true })
        } else {
          setComponentCfg({ ...componentCfg, isError: true, isLoaded: true, data: {}, isForbidden: false })
        }
      })
    }// prevent crash with async function
    return () => { isMounted = false }
  }, [componentCfg.isLoaded])

  return (<>
    {(() => { /* check permissions */
      if (!hasReadAdminPromotionPermission || _.isEmpty(componentCfg.data)) {
        return <></>
      } else { /* render a panel */
        return (
          <Panel bodyFill header={
            <PanelHeader icon={<AdministratorIcon />} title={'dashboard.promotion.administrator'} hasEdit={hasUpdateAdminPromotionPermission} hasDelete={hasDeleteAdminPromotionPermission} />
          }>
            <LoaderBackDrop active={!componentCfg.isLoaded} loader background bordered>
              <Panel className={'filled-table-panel'} style={{ borderRadius: '4px' }} bordered bodyFill>
                  { /* load component */ }
                  {(() => {
                    if (componentCfg.isError) {
                      return <ErrorResult compact={true}/>
                    } else if (_.isEmpty(componentCfg.data)) {
                      return <EmptyResult compact={true} />
                    } else { /* return components to render */
                      return <PromotionPersonTable data={componentCfg.data}/>
                    }
                  })()}
              </Panel>
            </LoaderBackDrop>
          </Panel>
        )
      }
    })()}
  </>)
}
