import React, { useMemo, useState } from 'react'
import { IconButton, Table, Whisper, Tooltip, ButtonGroup } from 'rsuite'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import _ from 'lodash'
import { EmptyResult } from '../../feedbacks'
import ArrowLeftLineIcon from '@rsuite/icons/ArrowLeftLine'
import ArrowRightLineIcon from '@rsuite/icons/ArrowRightLine'
import { MdOutlineDone, MdClose } from 'react-icons/md'
import { RxOpenInNewWindow } from 'react-icons/rx'
import { Col, DatePicker, Popconfirm, Row, Select, Space, Tag } from 'antd'
import moment from 'moment'
import { CloseOutlined, DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons'
import { HiOutlineEye } from 'react-icons/hi'
import dayjs from 'dayjs'
import { daysSelectables, periodicityMonthlyOptions } from '../../forms'
import { useEnvironment } from '../../../hooks/useEnvironment'
import { useParams } from 'react-router-dom'

const { getApplicationsValues, getLiquidationValues } = window.helpersLiquidations
// from library get getLiquidationValues
const { Column, HeaderCell, Cell } = Table
const CompactCell = props => <Cell {...props} style={{ padding: 6 }} />
const CompactHeaderCell = props => <HeaderCell {...props} style={{ padding: 4 }} />

// define locales for this page
const transFiles = ['dashboard', 'fields']

const rowTextTitlesStyle = { textAlign: 'left', paddingLeft: '5px' }
const rowIconTitlesStyle = { fontSize: '18px', marginTop: '-2px' }

// set class to each row
const getRowClass = (obj, index) => {
  // get row name index
  const rowIndexName = _.get(obj, 'index', '')
  // if row is divider
  if (rowIndexName.indexOf('divider') > -1) { return 'rs-table-row-divider' }
}

const getRowHeight = (obj) => {
  // get row name index
  const rowIndexName = _.get(obj, 'index', '')
  // if row is divider
  if (rowIndexName.indexOf('divider') > -1) { return 1 }
  return 30
}

const getDataIndexed = (indexes, dataTable) => {
  const dataIndexed = []
  _.each(indexes, function (idx) {
    // 01: create a days object
    const dataObject = { index: idx }
    // 01: set to a days object
    _.each(dataTable, (period, index) => {
      dataObject[`data_${index}`] = period[idx]
    })
    dataIndexed.push(dataObject)
  })
  return dataIndexed
}

const setNewIndexes = (key, newIndexes, indexes, names, application, t, appType, color) => {
  // create a unique id for a row for each loop
  // - create a same key for the same data for each period. A row contains data of the same type
  let rowId = key
  // if is set application, create key for each application
  if (!_.isEmpty(application)) { rowId = `${rowId}_${application.promotion_energy_application_id}_${appType}` }
  // const tagColor = color
  const newRowsIdx = []

  for (let i = 0; i < newIndexes.length; i++) {
    // generate idx for each new row
    const rowIdx = `${rowId}_${newIndexes[i]}`
    newRowsIdx.push(rowIdx)
    // if is set on indexes data not set again
    if (_.indexOf(indexes, rowIdx) < 0) {
      // set tm_avg to array for print
      indexes.push(rowIdx)

      rowIdx.indexOf('estimated')
      // if index has a source_estimated_title...
      if (rowIdx.indexOf('source_liquidation_title_estimated') > -1) {
        // add to names column array for print name row
        names[0][rowIdx] = <div className={'rs-row-title-fix-parent'} style={rowTextTitlesStyle}>
          <ArrowRightLineIcon style={rowIconTitlesStyle}/>
          <Tag size="sm">{t('dashboard.liquidation')}</Tag>
          <Tag size="sm">{t('dashboard.promotion.data_title_energy_source')}</Tag>
          <Tag size="sm">{t('dashboard.estimated')}</Tag>
        </div>
      // if index has a source_estimated_title...
      } else if (rowIdx.indexOf('source_liquidation_title_invoice') > -1) {
        names[0][rowIdx] = <div className={'rs-row-title-fix-parent'} style={rowTextTitlesStyle}>
          <ArrowRightLineIcon style={rowIconTitlesStyle}/>
          <Tag size="sm">{t('dashboard.liquidation')}</Tag>
          <Tag size="sm">{t('dashboard.promotion.data_title_energy_source')}</Tag>
          <Tag size="sm">{t('dashboard.supply_company')}</Tag>
        </div>
      } else if (rowIdx.indexOf('source_liquidation_title_summary') > -1) {
        names[0][rowIdx] = <div className={'rs-row-title-fix-parent'} style={rowTextTitlesStyle}>
          <ArrowRightLineIcon style={rowIconTitlesStyle}/>
          <Tag size="sm">{t('dashboard.liquidation')}</Tag>
          <Tag size="sm">{t('dashboard.promotion.data_title_energy_source')}</Tag>
          <Tag size="sm">{t('dashboard.total')}</Tag>
        </div>
      // if index has a source_estimated_title...
      } else if (rowIdx.indexOf('source_estimated_title') > -1) {
        // add to names column array for print name row
        names[0][rowIdx] = <div className={'rs-row-title-fix-parent'} style={rowTextTitlesStyle}>
          <ArrowRightLineIcon style={rowIconTitlesStyle}/>
          <Tag color="blue" size="sm">{t('dashboard.estimation')}</Tag>
          <Tag size="sm" color={'cyan'}>{t('dashboard.promotion.data_title_energy_source')}</Tag>
        </div>
      // if index has a source_real_title...
      } else if (rowIdx.indexOf('source_liquidation_title') > -1) {
        // add to names column array for print name row
        names[0][rowIdx] = <div className={'rs-row-title-fix-parent'} style={rowTextTitlesStyle}>
          <ArrowRightLineIcon style={rowIconTitlesStyle}/>
          <Tag color="purple" size="sm">{t('dashboard.liquidation')}</Tag>
          <Tag size="sm" color={'cyan'}>{t('dashboard.promotion.data_title_energy_source')}</Tag>
        </div>
      } else if (rowIdx.indexOf('_working_') > -1) {
        // add to names column array for print name row
        names[0][rowIdx] = <>{t('dashboard.running')}</>
        // if index has a source_estimated_total_cost...
      } else if (rowIdx.indexOf('_days_') > -1) {
        // add to names column array for print name row
        names[0][rowIdx] = <>{t('dashboard.days')}</>
      // if index has a source_estimated_total_cost...
      } else if (rowIdx.indexOf('source_estimated_total_cost') > -1 || rowIdx.indexOf('source_liquidation_total_cost') > -1) {
        // add to names column array for print name row
        names[0][rowIdx] = <>{t('dashboard.total')} [€]</>
      // if index has a source_estimated_consumption...
      } else if (rowIdx.indexOf('source_estimated_consumption') > -1 || rowIdx.indexOf('source_liquidation_consumption') > -1) {
        // add to names column array for print name row
        names[0][rowIdx] = <>{t('dashboard.consumption')} [kWh]</>
      // if index has a source_estimated_consumption_price_unit...
      } if (rowIdx.indexOf('source_estimated_consumption_price_variable') > -1 || rowIdx.indexOf('source_liquidation_consumption_price_variable') > -1) {
        names[0][rowIdx] = <>{t('fields:fields.labels.cost_variable')} [€]</>
      // if index has a source_estimated_consumption_price_fix...
      } if (rowIdx.indexOf('source_estimated_consumption_price_fix') > -1 || rowIdx.indexOf('source_liquidation_consumption_price_fix') > -1) {
        names[0][rowIdx] = <>{t('fields:fields.labels.cost_fix')} [€]</>
      // if index has a promotion_energy_source_estimated_consumption_tax...
      } if (rowIdx.indexOf('source_estimated_tax') > -1) {
        names[0][rowIdx] = <>{t('fields:fields.labels.tax')} [%]</>
      // if index has a promotion_energy_source_estimated_consumption_tax...
      } if (rowIdx.indexOf('source_estimated_consumption_tax') > -1 || rowIdx.indexOf('source_liquidation_consumption_tax') > -1) {
        names[0][rowIdx] = <>{t('fields:fields.labels.tax')} [€]</>
      // if index has a distribution...
      } else if (rowIdx.indexOf('pipe_distribution') > -1) {
        // add to names column array for print name row
        names[0][rowIdx] = <><Whisper speaker={<Tooltip>{t('dashboard.distribution')}</Tooltip>}><span>A_i <sup>*</sup> [kWh]</span></Whisper></>
        // if index has a consumption kWh...
      } else if (rowIdx.indexOf('pipe_consumption_kWh') > -1) {
        // add to names column array for print name row
        names[0][rowIdx] = <>{t('dashboard.consumption')} [kWh]</>
        // if index has a consumption m3...
      } else if (rowIdx.indexOf('pipe_consumption_m3') > -1) {
        names[0][rowIdx] = <>{t('dashboard.consumption')} [m<span><sup>3</sup></span>]</>
        // if index has a tm...
      } else if (rowIdx.indexOf('pipe_tm_avg') > -1) {
        names[0][rowIdx] = <>{t('dashboard.promotion.tm')} [ºC]</>
        // if index has a ta...
      } else if (rowIdx.indexOf('pipe_ta_avg') > -1) {
        names[0][rowIdx] = <>{t('dashboard.promotion.ta')} [ºC]</>
        // if index has a dissipation min...
      } else if (rowIdx.indexOf('pipe_dissipation_kWh_min') > -1) {
        names[0][rowIdx] = <><Whisper speaker={<Tooltip>{t('dashboard.dissipation')}</Tooltip>}><span>B<sub>min</sub><sup>*</sup> [kWh]</span></Whisper></>
        // if index has a dissipation max...
      } else if (rowIdx.indexOf('pipe_dissipation_kWh_max') > -1) {
        names[0][rowIdx] = <><Whisper speaker={<Tooltip>{t('dashboard.dissipation')}</Tooltip>}><span>B<sub>max</sub><sup>*</sup> [kWh]</span></Whisper></>
      // if isset subtitle
      } else if (rowIdx.indexOf('application_subtitle') > -1) {
        // add to names column array for print name row
        names[0][rowIdx] = <div className={'rs-row-title-fix-parent'} style={rowTextTitlesStyle}>
          <Row>
            <Col flex="18px"><ArrowRightLineIcon style={rowIconTitlesStyle}/></Col>
            <Col flex="auto">
              <Tag size="sm">{t('dashboard.estimation')}</Tag>
              <Tag size="sm">{t('dashboard.channel_distribution')}</Tag>
              {/* <Tag size="sm">{t('dashboard.promotion.data_title_energy_application')}</Tag> */ }
              <Tag size="sm">
                {application.promotion_energy_application_id} {application.promotion_energy_application_name}
              </Tag>
              <Tag size="sm"> {application[key].pipe_id} {application[key].pipe_name} </Tag>
              <Tag size="sm">{ appType === 'dwh' ? t('dashboard.dwh') : t('dashboard.heating') } </Tag>
            </Col>
          </Row>
        </div>
      // if isset title
      } else if (rowIdx.indexOf('application_title') > -1) {
        // add to names column array for print name row
        names[0][rowIdx] = <div className={'rs-row-title-fix-parent'} style={rowTextTitlesStyle}>
          <ArrowRightLineIcon style={rowIconTitlesStyle}/>
          <Tag color="blue" size="sm">{t('dashboard.estimation')}</Tag>
          <Tag size="sm" color={'orange'}>{t('dashboard.channel_distribution')}</Tag>
          {/* <Tag size="sm">{t('dashboard.promotion.data_title_energy_application')}</Tag> */ }
          <Tag size="sm">
            {application.promotion_energy_application_id} {application.promotion_energy_application_name}
          </Tag>
          { /* <Tag size="sm">{t('dashboard.channel_distribution')}</Tag> */ }
          <Tag size="sm"> {application[key].pipe_id} {application[key].pipe_name} </Tag>
        </div>
      } else if (rowIdx.indexOf('divider') > -1) {
        names[0][rowIdx] = undefined
      }
    }
  } // return new rows idx
  return newRowsIdx
}

const setDWHData = (key, index, period, appData, indexes, names, application, t) => {
  // set new rows needed
  const newRowsNeeded = ['application_title', /* 'tm_avg', */ 'pipe_consumption_m3', 'pipe_consumption_kWh', 'pipe_distribution_kWh']
  // return in same order the new rows idx
  const newRowsIdx = setNewIndexes(key, newRowsNeeded, indexes, names, application, t, 'dwh', 'volcano')
  // add data to table for indexes
  period[newRowsIdx[0]] = undefined
  // period[newRowsIdx[1]] = `${application[key].tmAvg.toFixed(2)}ºC`
  period[newRowsIdx[1]] = <>{appData.consumption_m3.toFixed(2)} m<span><sup>3</sup></span></>
  period[newRowsIdx[2]] = `${appData.consumption_kWh.toFixed(2)} kWh`
  period[newRowsIdx[3]] = `${appData.distribution_kWh_i.toFixed(2)} kWh`
}

const setHeatingData = (key, index, period, appData, indexes, names, application, t) => {
  // set new rows needed
  const newRowsNeeded = ['application_title', /* 'ta_avg', */ '_working_', '_days_', 'pipe_consumption_kWh', 'pipe_distribution_kWh', 'pipe_dissipation_kWh_min', 'pipe_dissipation_kWh_max']
  // return in same order the new rows idx
  const newRowsIdx = setNewIndexes(key, newRowsNeeded, indexes, names, application, t, 'heating', 'magenta')
  // add data to table for indexes
  period[newRowsIdx[0]] = undefined
  // period[newRowsIdx[1]] = `${application[key].taAvg.toFixed(2)}ºC`
  period[newRowsIdx[1]] = (appData.start && appData.end) ? `${moment(appData.start, 'YYYYMMDD').format('YYYY.MM.DD')} - ${moment(appData.end, 'YYYYMMDD').format('YYYY.MM.DD')}` : '-'
  period[newRowsIdx[2]] = appData.days
  period[newRowsIdx[3]] = `${appData.consumption_kWh.toFixed(2)} kWh`
  period[newRowsIdx[4]] = `${appData.distribution_kWh_i.toFixed(2)} kWh`
  period[newRowsIdx[5]] = `${appData.dissipation_max_kWh.toFixed(2)} kWh`
  period[newRowsIdx[6]] = `${appData.dissipation_min_kWh.toFixed(2)} kWh`
}

const setClimaData = (key, index, period, appData, indexes, names, application, t) => {
  // set new rows needed
  const newRowsNeeded = ['application_title', /* 'ta_avg', */ '_working_', '_days_', 'pipe_consumption_kWh', 'pipe_distribution_kWh', 'pipe_dissipation_kWh_min', 'pipe_dissipation_kWh_max']
  // return in same order the new rows idx
  const newRowsIdx = setNewIndexes(key, newRowsNeeded, indexes, names, application, t, 'clime', 'geekblue')
  // IN EACH LOOP
  // add data to table for indexes
  period[newRowsIdx[0]] = undefined
  // period[newRowsIdx[1]] = `${application[key].taAvg.toFixed(2)}ºC`
  period[newRowsIdx[1]] = (appData.start && appData.end) ? `${moment(appData.start, 'YYYYMMDD').format('YYYY.MM.DD')} - ${moment(appData.end, 'YYYYMMDD').format('YYYY.MM.DD')}` : '-'
  period[newRowsIdx[2]] = appData.days
  period[newRowsIdx[3]] = `${appData.consumption_kWh.toFixed(2)} kWh`
  period[newRowsIdx[4]] = `${appData.distribution_kWh_i.toFixed(2)} kWh`
  period[newRowsIdx[5]] = `${appData.dissipation_max_kWh.toFixed(2)} kWh`
  period[newRowsIdx[6]] = `${appData.dissipation_min_kWh.toFixed(2)} kWh`
}

const setDWHeatingData = (key, index, period, appDataDwh, appDataHeat, indexes, names, application, t) => {
  // set new rows needed
  let newRowsNeeded = ['application_title']
  // return in same order the new rows idx
  let newRowsIdx = setNewIndexes(key, newRowsNeeded, indexes, names, application, t, 'dwh_heating', 'green')
  // add data to table for indexes
  period[newRowsIdx[0]] = undefined

  // appDataDwh, appDataHeat

  // set new rows needed
  newRowsNeeded = ['application_subtitle', /* 'tm_avg', */ 'pipe_consumption_m3', 'pipe_consumption_kWh', 'pipe_distribution_kWh']
  // return in same order the new rows idx
  newRowsIdx = setNewIndexes(key, newRowsNeeded, indexes, names, application, t, 'dwh', 'volcano')
  // add data to table for indexes
  period[newRowsIdx[0]] = undefined
  // period[newRowsIdx[1]] = `${application[key].dwh.tmAvg.toFixed(2)}ºC`
  period[newRowsIdx[1]] = <>{appDataDwh.consumption_m3.toFixed(2)} m<span><sup>3</sup></span></>
  period[newRowsIdx[2]] = `${appDataDwh.consumption_kWh.toFixed(2)} kWh`
  period[newRowsIdx[3]] = `${appDataDwh.distribution_kWh_i.toFixed(2)} kWh`

  // set new rows needed
  newRowsNeeded = ['application_subtitle', /* 'ta_avg', */ '_working_', '_days_', 'pipe_consumption_kWh', 'pipe_distribution_kWh', 'pipe_dissipation_kWh_min', 'pipe_dissipation_kWh_max']
  // return in same order the new rows idx
  newRowsIdx = setNewIndexes(key, newRowsNeeded, indexes, names, application, t, 'heating', 'magenta')
  // add data to table for indexes
  period[newRowsIdx[0]] = undefined
  // period[newRowsIdx[1]] = `${application[key].heating.taAvg.toFixed(2)}ºC`
  period[newRowsIdx[1]] = (appDataHeat.start && appDataHeat.end) ? `${moment(appDataHeat.start, 'YYYYMMDD').format('YYYY.MM.DD')} - ${moment(appDataHeat.end, 'YYYYMMDD').format('YYYY.MM.DD')}` : '-'
  period[newRowsIdx[2]] = appDataHeat.days
  period[newRowsIdx[3]] = `${appDataHeat.consumption_kWh.toFixed(2)} kWh`
  period[newRowsIdx[4]] = `${appDataHeat.distribution_kWh_i.toFixed(2)} kWh`
  period[newRowsIdx[5]] = `${appDataHeat.dissipation_max_kWh.toFixed(2)} kWh`
  period[newRowsIdx[6]] = `${appDataHeat.dissipation_min_kWh.toFixed(2)} kWh`
}

const setSourceEstimatedData = (key, index, liquidationPeriod, liquidationEstimatedValues, indexes, names, t) => {
  // set new rows needed
  const newRowsNeeded = [
    'source_estimated_title', 'source_estimated_consumption', 'source_divider_estimated_1', 'source_estimated_consumption_price_variable',
    'source_estimated_consumption_price_fix', 'source_divider_estimated_2', 'source_estimated_consumption_tax', 'source_divider_estimated_3',
    'source_estimated_total_cost'
  ]

  // return in same order the new rows idx
  const newRowsIdx = setNewIndexes(key, newRowsNeeded, indexes, names, null, t)
  // IN EACH LOOP
  // add data to table for indexes
  liquidationPeriod[newRowsIdx[0]] = undefined
  // period[newRowsIdx[1]] = `${application[key].taAvg.toFixed(2)}ºC`
  liquidationPeriod[newRowsIdx[1]] = `${liquidationEstimatedValues.total.consumption.toFixed(2)} kWh`
  liquidationPeriod[newRowsIdx[2]] = undefined
  liquidationPeriod[newRowsIdx[3]] = `${liquidationEstimatedValues.total.price_variable.toFixed(2)} €`
  liquidationPeriod[newRowsIdx[4]] = `${liquidationEstimatedValues.total.price_fix.toFixed(2)} €`
  liquidationPeriod[newRowsIdx[5]] = undefined
  liquidationPeriod[newRowsIdx[6]] = `${liquidationEstimatedValues.total.tax_percent.toFixed(2)} % (${liquidationEstimatedValues.total.tax.toFixed(2)} €)`
  liquidationPeriod[newRowsIdx[7]] = undefined
  liquidationPeriod[newRowsIdx[8]] = `${liquidationEstimatedValues.total.total.toFixed(2)} €`
}

const setSourceLiquidationData = (key, index, liquidationPeriod, liquidationsValues, indexes, names, t) => {
  // set new rows needed
  let newRowsNeeded = ['source_liquidation_title']
  // return in same order the new rows idx
  let newRowsIdx = setNewIndexes(key, newRowsNeeded, indexes, names, null, t)
  // IN EACH LOOP
  // add data to table for indexes
  liquidationPeriod[newRowsIdx[0]] = undefined

  // set new rows needed
  newRowsNeeded = ['source_liquidation_title_estimated']
  // return in same order the new rows idx
  newRowsIdx = setNewIndexes(key, newRowsNeeded, indexes, names, null, t)
  // IN EACH LOOP
  // add data to table for indexes
  liquidationPeriod[newRowsIdx[0]] = undefined

  // set new rows needed
  newRowsNeeded = [
    'source_liquidation_days_1',
    'source_divider_liquidation_1',
    'source_liquidation_consumption_1',
    'source_divider_liquidation_2',
    'source_liquidation_consumption_price_variable_1', 'source_liquidation_consumption_price_fix_1',
    'source_divider_liquidation_3',
    'source_liquidation_consumption_tax_1',
    'source_divider_liquidation_4',
    'source_liquidation_total_cost_1'
  ] // return in same order the new rows idx
  newRowsIdx = setNewIndexes(key, newRowsNeeded, indexes, names, null, t)
  // IN EACH LOOP
  // add data to table for indexes
  liquidationPeriod[newRowsIdx[0]] = liquidationsValues.estimated.days
  liquidationPeriod[newRowsIdx[1]] = undefined
  liquidationPeriod[newRowsIdx[2]] = `${liquidationsValues.estimated.consumption.toFixed(2)} kWh`
  liquidationPeriod[newRowsIdx[3]] = undefined
  liquidationPeriod[newRowsIdx[4]] = `${liquidationsValues.estimated.price_variable.toFixed(2)} €`
  liquidationPeriod[newRowsIdx[5]] = `${liquidationsValues.estimated.price_fix.toFixed(2)} €`
  liquidationPeriod[newRowsIdx[6]] = undefined
  liquidationPeriod[newRowsIdx[7]] = `${liquidationsValues.estimated.tax_percent.toFixed(2)} % (${liquidationsValues.estimated.tax.toFixed(2)} €)`
  liquidationPeriod[newRowsIdx[8]] = undefined
  liquidationPeriod[newRowsIdx[9]] = `${liquidationsValues.estimated.total.toFixed(2)} €`

  // set new rows needed
  newRowsNeeded = ['source_liquidation_title_invoice']
  // return in same order the new rows idx
  newRowsIdx = setNewIndexes(key, newRowsNeeded, indexes, names, null, t)
  // IN EACH LOOP
  // add data to table for indexes
  liquidationPeriod[newRowsIdx[0]] = undefined

  // set new rows needed
  newRowsNeeded = [
    'source_liquidation_days_2',
    'source_divider_liquidation_5',
    'source_liquidation_consumption_2',
    'source_divider_liquidation_6',
    'source_liquidation_consumption_price_variable_2', 'source_liquidation_consumption_price_fix_2',
    'source_divider_liquidation_7',
    'source_liquidation_consumption_tax_2',
    'source_divider_liquidation_8',
    'source_liquidation_total_cost_2'
  ] // return in same order the new rows idx
  newRowsIdx = setNewIndexes(key, newRowsNeeded, indexes, names, null, t)
  // IN EACH LOOP
  // add data to table for indexes
  liquidationPeriod[newRowsIdx[0]] = liquidationsValues.real.days
  liquidationPeriod[newRowsIdx[1]] = undefined
  liquidationPeriod[newRowsIdx[2]] = `${liquidationsValues.real.consumption.toFixed(2)} kWh`
  liquidationPeriod[newRowsIdx[3]] = undefined
  liquidationPeriod[newRowsIdx[4]] = `${liquidationsValues.real.price_variable.toFixed(2)} €`
  liquidationPeriod[newRowsIdx[5]] = `${liquidationsValues.real.price_fix.toFixed(2)} €`
  liquidationPeriod[newRowsIdx[6]] = undefined
  liquidationPeriod[newRowsIdx[7]] = `${liquidationsValues.real.tax_percent.toFixed(2)} % (${liquidationsValues.real.tax.toFixed(2)} €)`
  liquidationPeriod[newRowsIdx[8]] = undefined
  liquidationPeriod[newRowsIdx[9]] = `${liquidationsValues.real.total.toFixed(2)} €`

  // set new rows needed
  newRowsNeeded = ['source_liquidation_title_summary']
  // return in same order the new rows idx
  newRowsIdx = setNewIndexes(key, newRowsNeeded, indexes, names, null, t)
  // IN EACH LOOP
  // add data to table for indexes
  liquidationPeriod[newRowsIdx[0]] = undefined
  // set new rows needed
  newRowsNeeded = [
    'source_liquidation_days_3',
    'source_divider_liquidation_9',
    'source_liquidation_consumption_3',
    'source_divider_liquidation_10',
    'source_liquidation_consumption_price_variable_3', 'source_liquidation_consumption_price_fix_3',
    'source_divider_liquidation_11',
    'source_liquidation_consumption_tax_3',
    'source_divider_liquidation_12',
    'source_liquidation_total_cost_3'
  ] // return in same order the new rows idx
  newRowsIdx = setNewIndexes(key, newRowsNeeded, indexes, names, null, t)
  // IN EACH LOOP
  // add data to table for indexes
  liquidationPeriod[newRowsIdx[0]] = liquidationsValues.total.days
  liquidationPeriod[newRowsIdx[1]] = undefined
  liquidationPeriod[newRowsIdx[2]] = `${liquidationsValues.total.consumption.toFixed(2)} kWh`
  liquidationPeriod[newRowsIdx[3]] = undefined
  liquidationPeriod[newRowsIdx[4]] = `${liquidationsValues.total.price_variable.toFixed(2)} €`
  liquidationPeriod[newRowsIdx[5]] = `${liquidationsValues.total.price_fix.toFixed(2)} €`
  liquidationPeriod[newRowsIdx[6]] = undefined
  liquidationPeriod[newRowsIdx[7]] = `${liquidationsValues.total.tax_percent.toFixed(2)} % (${liquidationsValues.total.tax.toFixed(2)} €)`
  liquidationPeriod[newRowsIdx[8]] = undefined
  liquidationPeriod[newRowsIdx[9]] = `${liquidationsValues.total.total.toFixed(2)} €`
}

const styleIconsCell = { marginTop: '-4px' }
const styleIconsCellColor = { color: '#5c5c5c', fontSize: '13px' }

export const PromotionEnergySupplyAnalysisLiquidationTable = (props) => {
  // for translation
  const { t } = useTranslation(transFiles)
  const { data, isCalledToReload, handlerChangeReferenceDate, handlerChangePeriodicity, handlerLiquidate, handlerDeleteLiquidation } = props
  const { source, periods } = data
  // get current promotion id
  const { promotionId } = useParams()
  const [editingPeriodicityCfg, setEditingPeriodicityCfg] = useState({
    isEditing: false
  }) // get for check permissions
  const { hasPromotionPermissions } = useEnvironment()

  const hasCreateEnergyLiquidationPeriodsPromotionPermissions = hasPromotionPermissions(promotionId, 'create:promotion:energy:liquidation:periods')
  const hasUpdateEnergyLiquidationPeriodsPromotionPermissions = hasPromotionPermissions(promotionId, 'update:promotion:energy:liquidation:periods')
  const hasDeleteEnergyLiquidationPeriodsPromotionPermissions = hasPromotionPermissions(promotionId, 'delete:promotion:energy:liquidation:periods')

  // get is active flag
  // get data to source table
  const dataTableSource = useMemo(() => {
    // get date information
    const locale = window.user.user_language_id
    // init initial values
    let initialValues = {
      promotion_energy_source_liquidation_periodicity_id: source.promotion_energy_source_liquidation_periodicity_id,
      promotion_energy_source_liquidation_day: _.get(source, 'promotion_energy_source_liquidation_day', '..'),
      promotion_energy_source_liquidation_start_date: dayjs(source.promotion_energy_source_liquidation_start_date, 'YYYY-MM-DD').toString()
    }

    // when change periodicity field
    const handlerOnChange = (name, value) => {
      initialValues = { ...initialValues, [name]: value }
    } // when click to save
    const handlerOnSave = () => {
      initialValues = {
        ...initialValues,
        promotion_energy_source_liquidation_start_date: dayjs(initialValues.promotion_energy_source_liquidation_start_date).format('YYYY-MM-DD').toString()
      }
      handlerChangePeriodicity(source.promotion_energy_source_id, initialValues, () => {
        setEditingPeriodicityCfg({ ...editingPeriodicityCfg, isEditing: false })
      })
    } // when click cancel edit
    const handlerOnCancelEdit = () => {
      setEditingPeriodicityCfg({ ...editingPeriodicityCfg, isEditing: false })
    } // when click edit
    const handlerOnEdit = () => {
      setEditingPeriodicityCfg({ ...editingPeriodicityCfg, isEditing: true })
    }

    // render element in function is periodicity editing
    const Edit = <span className={'rd-cell-select-wrap'}> {
      editingPeriodicityCfg.isEditing
        ? <Space style={{ marginTop: '-7px' }}>
          { /* trans component with fields */
            <Trans
              i18nKey={ t('dashboard.promotion.liquidation_configuration_string_components') }
              components={{
                /* select days */
                day: <Select defaultValue={initialValues.promotion_energy_source_liquidation_day} className={'rd-select-cell'}
                             popupClassName={'rd-cell-select-dropdown'} size={'small'} style={{ width: 60 }} placeholder={t('dashboard.day')}
                             onChange={(value) => handlerOnChange('promotion_energy_source_liquidation_day', value)} options={daysSelectables} />,
                /* select periodicity */
                periodicity:
                  <Select defaultValue={initialValues.promotion_energy_source_liquidation_periodicity_id}
                          popupClassName={'rd-cell-select-dropdown'} size={'small'} style={{ width: 120 }} placeholder={t('dashboard.periodicity.name')}
                          onChange={(value) => handlerOnChange('promotion_energy_source_liquidation_periodicity_id', value)}
                          options={
                            _.map(periodicityMonthlyOptions, (opt) => {
                              return { ...opt, label: t(opt.label) }
                            })
                          }
                  />,
                /* start data picker */
                start:
                  <DatePicker className="data-picker-cell" allowClear={false} format={'YYYY.MM.DD'}
                              style={{ width: '105px' }} popupClassName={'rd-smaller-data-picker-style'}
                              onChange={(value) => handlerOnChange('promotion_energy_source_liquidation_start_date', value.format('YYYY-MM-DD').toString())}
                              defaultValue={dayjs(source.promotion_energy_source_liquidation_start_date, 'YYYY-MM-DD')} size="small"/>
              }}
            />
          }
          { /* select icons */ }
          <ButtonGroup size="xs">
            <IconButton appearance="subtle" style={styleIconsCellColor} icon={<SaveOutlined />} size="xs" onClick={handlerOnSave} />
            <IconButton appearance="subtle" style={{ ...styleIconsCellColor, borderLeft: '1px #d1d1d1 solid' }} icon={<CloseOutlined />} size="xs" onClick={handlerOnCancelEdit} />
          </ButtonGroup>
        </Space>
        : <Space>
          { t('dashboard.promotion.liquidation_configuration_string', {
            start: moment(source.promotion_energy_source_liquidation_start_date, 'YYYY-MM-DD').locale(locale).format('YYYY.MM.DD'),
            day: _.get(source, 'promotion_energy_source_liquidation_day', '..'),
            periodicity: t(`dashboard.periodicity.${source.promotion_energy_source_liquidation_periodicity_id}`).toLowerCase()
          }) }
          { hasUpdateEnergyLiquidationPeriodsPromotionPermissions && <IconButton appearance="subtle" style={{ ...styleIconsCell, ...styleIconsCellColor }} circle icon={<EditOutlined />} size="xs" onClick={handlerOnEdit}/> }
        </Space>
    }</span>

    // return data for a table
    return {
      liquidation_is_active: source.promotion_energy_source_liquidation_is_active === 0 ? <MdClose /> : <MdOutlineDone/>,
      liquidation_configuration_string: Edit
    }
  }, [source.promotion_energy_source_liquidation_periodicity_id, source.promotion_energy_source_liquidation_day, source.promotion_energy_source_liquidation_start_date, editingPeriodicityCfg.isEditing])

  const [tablePeriodsData, tablePeriodsComponents] = useMemo(() => {
    // nothing to do if not set periods
    if (_.isEmpty(periods)) return [[], []]
    // indexes to show and your order
    const indexes = ['rowDays', 'rowIsLiquidated']
    // clone data to work
    let data = _.cloneDeep(periods)
    // row names for (first column)
    const names = [{ // previous
      rowHeader: t('dashboard.periods'),
      rowDays: t('dashboard.days'),
      rowIsLiquidated: t('dashboard.is_liquidated'),
      rowIsCurrentPeriod: true, // fix grey on first column
      rowIsCurrentLiquidation: false
    }]

    // format data from period
    _.each(data, (liquidationPeriod, index) => {
      // save a original period before modify this
      const liquidationPeriodCopy = _.cloneDeep(liquidationPeriod)
      // get liquidations values
      const liquidationsValues = getLiquidationValues(_.get(liquidationPeriod, 'liquidation_periods', []))
      const liquidationEstimatedValues = getLiquidationValues(_.get(liquidationPeriod, 'liquidation_estimations.periods', []))

      // formant for periods such as row header
      const periodString = `${moment(liquidationPeriod.liquidation_date_start, 'YYYYMMDD').format('YYYY.MM.DD')} - ${moment(liquidationPeriod.liquidation_date_end, 'YYYYMMDD').format('YYYY.MM.DD')}`
      liquidationPeriod.rowHeader = !liquidationPeriod.liquidation_is_liquidable ? <span style={{ color: '#363636' }}>{periodString}</span> : periodString
      liquidationPeriod.rowHeader = liquidationPeriod.liquidation_is_reference
        ? <>
          <IconButton disabled={isCalledToReload} onClick={() => handlerChangeReferenceDate('-')} style={{ marginTop: '-2px' }} icon={<ArrowLeftLineIcon />} circle size="xs" />
          <span style={{ padding: '0 1px' }}><u>{liquidationPeriod.rowHeader}</u></span>
          <IconButton disabled={isCalledToReload} onClick={() => handlerChangeReferenceDate('+')} style={{ marginTop: '-2px' }} icon={<ArrowRightLineIcon />} circle size="xs" />
        </>
        : liquidationPeriod.rowHeader

      // set data for a row days
      liquidationPeriod.rowDays = liquidationsValues.total.days
      liquidationPeriod.rowIsLiquidated = !liquidationPeriod.liquidation_is_liquidated
        ? <><MdClose /> { liquidationPeriod.liquidation_is_liquidable && hasCreateEnergyLiquidationPeriodsPromotionPermissions && <RxOpenInNewWindow style={{ cursor: 'pointer' }} onClick={() => handlerLiquidate(liquidationPeriodCopy.liquidation_id, liquidationPeriodCopy)} /> }</>
        : <Space align="center">
          <div>
            <HiOutlineEye size={15} style={{ cursor: 'pointer' }} onClick={() => handlerLiquidate(liquidationPeriodCopy.liquidation_id, liquidationPeriodCopy, 'liquidation.view')} />
          </div>
          {
            liquidationPeriodCopy.liquidation_is_deletable && hasDeleteEnergyLiquidationPeriodsPromotionPermissions &&
              <div style={{ marginTop: '-5px', marginLeft: '-5px' }}>
                <Popconfirm disabled={false} placement="bottom" title={t('dashboard.delete')} description={t('dashboard.questions.delete_item')}
                          onConfirm={() => handlerDeleteLiquidation(liquidationPeriodCopy.liquidation_id) } okText={t('dashboard.yes')} cancelText={t('dashboard.no')}>
                  <DeleteOutlined style={{ color: 'black' }} />
                </Popconfirm>
              </div>
          }
        </Space>
      // set flags
      liquidationPeriod.rowIsPeriodReference = liquidationPeriod.liquidation_is_reference
      liquidationPeriod.rowIsLiquidable = liquidationPeriod.liquidation_is_liquidable
      liquidationPeriod.rowIsCurrentPeriod = liquidationPeriod.liquidation_is_current
      liquidationPeriod.rowIsCurrentLiquidation = liquidationPeriod.liquidation_is_current_liquidation

      // set source data to table
      setSourceLiquidationData(`sd_liq_${source.promotion_energy_source_id}`, index, liquidationPeriod, liquidationsValues, indexes, names, t)
      setSourceEstimatedData(`sd_est_${source.promotion_energy_source_id}`, index, liquidationPeriod, liquidationEstimatedValues, indexes, names, t)
      // get applications estimated data from applications
      const applications = _.get(liquidationPeriod, 'liquidation_estimations.applications', [])
      // for each application on period...
      _.each(applications, (application) => {
        // for each key value if set search application data
        _.each(application, (value, key) => {
          // get application id
          const applicationId = _.get(application, 'energy_application_id', -1)
          // if applications has dwh data...
          if (`${key}`.indexOf('promotion_energy_application_dwh_pipe_') > -1) {
            // get periods
            const periods = _.get(application, `${key}.periods`, [])
            // get app data -> application id, periods, override and text mode
            const appData = getApplicationsValues(applicationId, periods)
            // set DWH data to table
            setDWHData(key, index, liquidationPeriod, appData, indexes, names, application, t)
          }
          // if applications has heating data...
          if (`${key}`.indexOf('promotion_energy_application_heating_pipe_') > -1) {
            // get periods
            const periods = _.get(application, `${key}.periods`, [])
            // get app data -> application id, periods, override and text mode
            const appData = getApplicationsValues(applicationId, periods)
            // set head data to table
            setHeatingData(key, index, liquidationPeriod, appData, indexes, names, application, t)
          }
          // if applications has clima data...
          if (`${key}`.indexOf('promotion_energy_application_clima_pipe_') > -1) {
            // get periods
            const periods = _.get(application, `${key}.periods`, [])
            // get app data -> application id, periods, override and text mode
            const appData = getApplicationsValues(applicationId, periods)
            // set climate data to table
            setClimaData(key, index, liquidationPeriod, appData, indexes, names, application, t)
          }
          // if applications has clima data...
          if (`${key}`.indexOf('promotion_energy_application_dwh_heating_pipe_') > -1) {
            // get periods
            const periodsDwh = _.get(application, `${key}.dwh.periods`, [])
            const periodsHeat = _.get(application, `${key}.heating.periods`, [])
            // get app data -> application id, periods, override and text mode
            const appDataDwh = getApplicationsValues(1, periodsDwh)
            const appDataHeat = getApplicationsValues(2, periodsHeat)
            // set data
            setDWHeatingData(key, index, liquidationPeriod, appDataDwh, appDataHeat, indexes, names, application, t)
          }
        })
      })
    })

    // set names to data table
    data = [...names, ...data]
    // data to show
    const dataIndexed = getDataIndexed(indexes, data)

    return [dataIndexed, data.map((period, index) => {
      const dataKey = `data_${index}`
      const props = index !== 0 ? { minWidth: 200, flexGrow: 1 } : { width: 110 }
      const currentPeriodCls = period.rowIsCurrentPeriod ? 'rs-table-cell-grey' : ''
      const currentLiquidationCls = period.rowIsCurrentLiquidation ? 'rs-table-cell-green' : ''

      return (
        <Column colSpan={data.length} key={_.uniqueId()} {...props} align={index === 0 ? 'right' : 'center'} style={{ background: 'red' }}>
          <CompactHeaderCell>{period.rowHeader}</CompactHeaderCell>
          <CompactCell className={`${currentPeriodCls} ${currentLiquidationCls} rs-table-fix-top-border`} dataKey={dataKey} />
        </Column>
      )
    })]
  }, [periods, isCalledToReload])

  return (<>
    { /* periods configuration */ }
    <Table loading={isCalledToReload} hover={false} autoHeight={true} data={[dataTableSource]} cellBordered rowHeight={30} headerHeight={30}>
      <Column width={113} align="center">
        <CompactHeaderCell>{t('dashboard.promotion.liquidation_active_column')}</CompactHeaderCell>
        <CompactCell dataKey="liquidation_is_active" />
      </Column>
      <Column minWidth={300} flexGrow={1} align="left">
        <CompactHeaderCell>&nbsp;{t('dashboard.configuration')}</CompactHeaderCell>
        <CompactCell dataKey="liquidation_configuration_string" />
      </Column>
    </Table>
    {(() => {
      if (_.isEmpty(tablePeriodsData)) {
        return <EmptyResult compact={true} />
      } else { /* return components to render */
        return (
          <Table loading={isCalledToReload} rowClassName={getRowClass} rowHeight={getRowHeight} className={'promotion-energy-liquidation-periods-table'} hover={false} autoHeight={true} data={tablePeriodsData} cellBordered headerHeight={30}>
            { tablePeriodsComponents }
          </Table>
        )
      }
    })()}
  </>)
}

PromotionEnergySupplyAnalysisLiquidationTable.propTypes = {
  data: PropTypes.object.isRequired,
  isCalledToReload: PropTypes.bool,
  handlerChangeReferenceDate: PropTypes.func.isRequired,
  handlerChangePeriodicity: PropTypes.func.isRequired,
  handlerLiquidate: PropTypes.func.isRequired,
  handlerDeleteLiquidation: PropTypes.func.isRequired
}
