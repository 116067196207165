import 'config/global'
import 'config/i18n'
import 'config/sentry'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { Main } from 'Main'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { Login } from 'pages/auth/Login'
import { MainLoader } from 'components/spinners'
import { ConfigProvider, message } from 'antd'
import { CustomErrors } from 'pages/CustomErrors'
import { DashboardLayout } from 'pages/dashboard/DashboardLayout'
import { PromotionsLayout } from 'pages/dashboard/promotions/PromotionsLayout'
import { EdynaLayout } from 'pages/dashboard/edyna/EdynaLayout'
import { PromotionLayout } from 'pages/dashboard/promotions/promotion/PromotionLayout'
import { PeopleLayout } from './pages/dashboard/people/PeopleLayout'
import { ProfilesLayout } from './pages/profiles/ProfilesLayout'

// configure ant messages
message.config({ maxCount: 1 })
// configure ant style
const antdStyle = {
  colorTextBase: '#5e5873',
  fontFamily: 'Inter'
}

const andComponentsStyles = {
  Table: {
    fontSize: 12,
    headerBg: '#fafafa',
    cellColor: 'black',
    headerColor: '#979796'
  }
}

// configure routes
const routes = createBrowserRouter([{
  path: '/500',
  element: <CustomErrors status={'500'}/>
}, {
  path: '/',
  element: <Main />,
  errorElement: <CustomErrors />,
  children: [{
    path: 'auth/login',
    element: <Login />
  }, {
    path: 'profiles',
    element: <ProfilesLayout />
  }, {
    path: 'dashboard/:profile',
    element: <DashboardLayout />,
    children: [{
      path: 'promotions',
      element: <PromotionsLayout />,
      children: [{
        path: ':promotionId/:resourceId',
        element: <PromotionLayout />
      }]
    }, {
      path: 'edyna',
      element: <EdynaLayout />,
      children: [{
        path: ':resourceId',
        element: <EdynaLayout />
      }]
    }, {
      path: 'people',
      element: <PeopleLayout />
    }]
  }]
}])

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
      { /* ant design css fix for hashed css */ }
      <ConfigProvider theme={{ hashed: false, token: { ...antdStyle }, components: andComponentsStyles }}>
          <Suspense fallback={<MainLoader />}>
            <RouterProvider router={routes} />
          </Suspense>
      </ConfigProvider>
  </React.StrictMode>
)
