import React from 'react'
import { BiBuildings, BiSpreadsheet } from 'react-icons/bi'
import { RiUserLocationLine, RiUserSettingsLine } from 'react-icons/ri'
import { SettingOutlined, UserOutlined } from '@ant-design/icons'
import { GrFormAdd } from 'react-icons/gr'
import { FiKey } from 'react-icons/fi'
import { IoMegaphoneOutline } from 'react-icons/io5'
import { TbFileInvoice, TbTie } from 'react-icons/tb'
import { MdOutlinePersonPin, MdOutlineDoorFront } from 'react-icons/md'
import { MdOutlineAppSettingsAlt } from "react-icons/md"
import { BsPersonVideo2 } from 'react-icons/bs'

export const PromotionsIcon = (props) => {
  return (<BiBuildings size={20} {...props} />)
}

export const PersonIcon = (props) => {
  return (<RiUserLocationLine size={18} {...props} />)
}

export const SettingsIcon = (props) => {
  return (<SettingOutlined {...props} />)
}

export const UserIcon = (props) => {
  return (<UserOutlined {...props} />)
}

export const AlertAddIcon = (props) => {
  return (<GrFormAdd {...props} />)
}

export const AdministratorIcon = (props) => {
  return (<TbTie {...props} />)
}

export const HolderIcon = (props) => {
  return (<FiKey {...props} />)
}

export const PresidentIcon = (props) => {
  return (<IoMegaphoneOutline {...props} />)
}

export const OtherPersonIcon = (props) => {
  return (<MdOutlinePersonPin {...props} />)
}

export const InvoicesIcon = (props) => {
  return (<TbFileInvoice {...props} />)
}

export const ReadingsIcon = (props) => {
  return (<BiSpreadsheet {...props} />)
}

export const ApartmentIcon = (props) => {
  return (<MdOutlineDoorFront {...props} />)
}

export const UserConfigIcon = (props) => {
  return (<RiUserSettingsLine {...props} />)
}

export const AppSettingsIcon = (props) => {
  return (<MdOutlineAppSettingsAlt {...props} />)
}

export const PeopleIcon = (props) => {
  return (<BsPersonVideo2 {...props} />)
}
