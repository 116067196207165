import React from 'react'

export function LoaderCircle () {
  return (
        <div className="loader">
            <svg viewBox="0 0 80 80">
                <circle id="test" cx="40" cy="40" r="32"></circle>
            </svg>
        </div>
  )
}

export function LoaderSquare () {
  return (
    <div className="loader">
      <svg viewBox="0 0 80 80">
        <rect x="8" y="8" width="64" height="64"></rect>
      </svg>
    </div>
  )
}

export function LoaderTriangle () {
  return (
    <div className="loader triangle">
      <svg viewBox="0 0 86 80">
        <polygon points="43 8 79 72 7 72"></polygon>
      </svg>
    </div>
  )
}

export function MainLoader () {
  return (
    <main>
      <div id="full-screen-loader" className={'center-div'}>
        <LoaderCircle />
        <LoaderTriangle />
        <LoaderSquare />
      </div>
    </main>
  )
}
