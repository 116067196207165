import React from 'react'
import { useParams } from 'react-router-dom'
import { useEnvironment } from '../../../../../hooks/useEnvironment'
import { PromotionEnergySourceApplicationPipesSensorsPanel } from '../../../../../components/promotion/PromotionEnergySourceApplicationPipesSensorsPanel'
import { ForbiddenResult } from '../../../../../components/feedbacks'

export function PromotionEnergySchema (props) {
  // get current promotion id
  const { promotionId } = useParams()
  // get for check permissions
  const { hasPromotionPermissions } = useEnvironment()
  // read energy data
  const hasReadEnergyConfigPromotionPermission = hasPromotionPermissions(promotionId, 'read:promotion:energy:config')

  return (<>
      {(() => {
        if (!hasReadEnergyConfigPromotionPermission) {
          return <ForbiddenResult />
        } else { /* return components to render */
          return <PromotionEnergySourceApplicationPipesSensorsPanel />
        }
      })()}
    </>
  )
}
