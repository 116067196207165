// eslint-disable-next-line camelcase
import i18n from 'i18next'
import { useNavigate } from 'react-router-dom'
import CryptoJS from 'crypto-js'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { isUserOverall } from '../helpers/roles'

// name for locale in local storage
const userLocale = 'i18nextLng'
// save the current name of auth tokens
const applicationToken = 'application-id'
const applicationTokenRefresh = 'application-id-renew'

export const useEnvironment = () => {
  // - BROWSER CONTEXT
  const navigate = useNavigate()
  // check if development environment
  const isDevelopmentContext = process.env.NODE_ENV === 'development'
  // check if locals storage exists
  const existLocalStorageFunction = typeof localStorage !== 'undefined'
  // check if local storage exists
  const isExistsWindowObject = typeof window !== 'undefined'
  // for translation
  const { t } = useTranslation('notifications')

  // - LOCALE CONTEXT
  // return a current locale
  const getCurrentLocale = (props) => {
    // check locales in order
    if (localStorage.getItem(userLocale)) {
      return localStorage.getItem(userLocale)
    } else if (window.user && window.user.user_language_id) {
      return window.user.user_language_id
    } else if (props && props.locale) {
      return props.locale
    } else if (props && props.__NEXT_DATA__ && props.__NEXT_DATA__.locale) {
      return props.__NEXT_DATA__.locale
    } // return current locale
    return window.navigator.language.split('-')[0]
  } // set a current locale
  const setCurrentLocale = (locale, callback = () => {}) => {
    if (i18n.language !== locale) {
      localStorage.setItem(userLocale, locale)
      i18n.changeLanguage(locale)
    }
    callback()
  }

  // - USER AUTH CONTEXT
  // get current JWT user token from local storage
  const getAuthUserToken = () => {
    // get token
    const data = localStorage.getItem(applicationToken)
    // decrypt if exists...
    if (data) {
      try {
        const bytes = CryptoJS.AES.decrypt(data, global.config.key)
        return bytes.toString(CryptoJS.enc.Utf8)
      } catch {
        return undefined
      }
    } // default return
    return undefined
  }

  // get current JWT user token refresh from local storage
  const getAuthUserRefreshToken = () => {
    // get token
    const data = localStorage.getItem(applicationTokenRefresh)
    // decrypt if exists...
    if (data) {
      try {
        const bytes = CryptoJS.AES.decrypt(data, global.config.key)
        return bytes.toString(CryptoJS.enc.Utf8)
      } catch {
        return undefined
      }
    } // default return
    return undefined
  }

  // return if are set user tokens
  const isUserTokens = () => {
    return (getAuthUserToken() && getAuthUserRefreshToken())
  }

  // set JWT user token to local storage
  const setAuthUserTokens = (user) => {
    // save user data
    window.user = user
    // set current locale
    setCurrentLocale(window.user.user_language_id)
    // get tokens cypher
    const userTokenCypher = CryptoJS.AES.encrypt(user.user_token, global.config.key).toString()
    const userTokenRefreshCypher = CryptoJS.AES.encrypt(user.user_refresh_token, global.config.key).toString()
    // set to local storage
    localStorage.setItem(applicationToken, userTokenCypher)
    localStorage.setItem(applicationTokenRefresh, userTokenRefreshCypher)
  }

  // delete all JWT user tokens from local storage
  const deleteAuthUserToken = () => {
    localStorage.removeItem(applicationToken)
    localStorage.removeItem(applicationTokenRefresh)
    navigate('/auth/login')
  }

  // check permissions
  const hasPermissions = (permission = '') => {
    // get is item permission
    const isItem = permission.includes('item')
    // if is item permission check the permission -> overall user is affected
    // if not is overall, check permissions
    if (isItem || !isUserOverall()) { return window.user.user_permissions.includes(permission) }
    // is overall and not is item checking...
    return true
  }

  // check permissions
  const hasPromotionPermissions = (promotion, permission) => {
    // check if it has permissions
    return isUserOverall() || _.get(window, `promotion-${promotion}.promotion_permissions`, []).includes(permission)
  }

  // return results
  return {
    isDevelopmentContext,
    existLocalStorageFunction,
    isExistsWindowObject,
    isUserTokens,
    getCurrentLocale,
    setCurrentLocale,
    getAuthUserToken,
    getAuthUserRefreshToken,
    setAuthUserTokens,
    deleteAuthUserToken,
    hasPermissions,
    hasPromotionPermissions
  }
}
