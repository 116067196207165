// for react-suite tables with compact cells
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { Column as ColumnPlot } from '@ant-design/plots'
import { EmptyResult, ErrorResult, ForbiddenResult, LoaderBackDrop } from '../../feedbacks'
import _ from 'lodash'
import { useEnvironment } from '../../../hooks/useEnvironment'
import { useRequestsEvents } from '../../../hooks/useRequestsEvents'
import { Table } from 'rsuite'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { Alert, Button, Col, DatePicker, Row } from 'antd'
dayjs.extend(customParseFormat)
const { RangePicker } = DatePicker

const { Column, HeaderCell, Cell } = Table
// eslint-disable-next-line react/prop-types
const CompactCell = props => <Cell {...props} style={{ padding: 4, ...props.style }} />
// eslint-disable-next-line react/prop-types
const CompactHeaderCell = props => <HeaderCell {...props} style={{ padding: 4, ...props.style }} />

const data = [
  { liquidation: 'Liquidat', period: '2023-01-15 - 2023-03-14', value: 92.14 },
  { liquidation: 'Liquidat', period: '2022-11-15 - 2023-01-14', value: 90 },
  { liquidation: 'Liquidat', period: '2022-09-15 - 2022-11-14', value: 90 },
  { liquidation: 'Liquidat', period: '2022-07-15 - 2022-09-14', value: 90 },
  { liquidation: 'Liquidat', period: '2022-05-15 - 2022-07-14', value: 90 },
  { liquidation: 'Liquidat', period: '2022-03-15 - 2022-05-14', value: 90 },
  { liquidation: 'Liquidat', period: '2022-01-15 - 2022-03-14', value: 90 },
  { liquidation: 'Liquidat', period: '2023-03-15 - 2023-05-14', value: 93.04 },
  { liquidation: 'Pendent de compensar', period: '2023-05-01 - 2023-05-14', value: 21.31 },
  { liquidation: 'Pendent de compensar', period: '2023-02-15 - 2023-04-14', value: 27.81 },
  { liquidation: 'Pendent de compensar', period: '2023-01-15 - 2023-03-14', value: 25.87 }
]

// define locales for this page
const transFiles = ['dashboard']

export const PromotionEnergySourceLiquidationAnalysisPanel = (props) => {
  // for translation
  const { t } = useTranslation([transFiles])
  // get needed ids
  const { promotionId, sourceId } = props
  // get methods to send data
  const { getPromotionEnergySourceLiquidationSummary } = useRequestsEvents()
  // get for check permissions
  const { hasPromotionPermissions } = useEnvironment()
  // get permissions
  const hasReadEnergySupplyLiquidationPeriodsPromotionPermissions = hasPromotionPermissions(promotionId, 'read:promotion:energy:liquidation:periods')
  // component config
  const [componentCfg, setComponentCfg] = useState({
    isLoaded: false,
    start: moment().startOf('year'),
    end: moment().endOf('year'),
    comparative: undefined,
    rangeDateIsValid: true,
    chart: {
      data: undefined,
      isGroup: true,
      xField: 'period',
      yField: 'value',
      seriesField: 'liquidation',
      dodgePadding: 2,
      intervalPadding: 20,
      label: {
        position: 'middle',
        layout: [{
          type: 'interval-adjust-position'
        }, {
          type: 'interval-hide-overlap'
        }, {
          type: 'adjust-color'
        }]
      }
    }
  })

  // on change date range
  const handlerChangeDate = (dates, dateString) => {
    if (!componentCfg.isLoaded) return
    // get start and end data in moment object
    const start = moment(dateString[0], 'YYYY-MM-DD')
    const end = moment(dateString[1], 'YYYY-MM-DD')
    // set date to component cfg
    setComponentCfg({ ...componentCfg, start, end, rangeDateIsValid: start.isValid() && end.isValid() })
  }

  // on apply data to load
  const handlerApply = (dates, dateString) => {
    if (!componentCfg.isLoaded) return
    // set to reload
    setComponentCfg({ ...componentCfg, isError: false, isLoaded: false, chart: { ...componentCfg.chart, data: undefined } })
  }

  // loading component from first load
  useEffect(() => {
    // clean up controller --> prevent crash with async function
    let isMounted = true
    // not reload if component is loaded...
    if (componentCfg.isLoaded) return
    // if not has permissions
    if (!hasReadEnergySupplyLiquidationPeriodsPromotionPermissions) {
      setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, chart: { ...componentCfg.chart, data: undefined } })
    } else {
      // get promotion energy source liquidation summary
      getPromotionEnergySourceLiquidationSummary(promotionId, sourceId, componentCfg.start, componentCfg.end, (result) => {
        // prevent async crash
        if (!isMounted) return null
        // set data
        setComponentCfg({ ...componentCfg, isError: false, isLoaded: true, comparative: result.data, chart: { ...componentCfg.chart, data } })
      }, () => {
        // prevent async crash
        if (!isMounted) return null
        setComponentCfg({ ...componentCfg, isError: true, isLoaded: true, chart: { ...componentCfg.chart, data: undefined } })
      })
    } // prevent crash with async function
    return () => { isMounted = false }
  }, [componentCfg.isLoaded])

  // create a data table
  const dataTable = useMemo(() => {
    // if is empty return no data
    if (_.isEmpty(componentCfg.comparative)) return []

    // return table data
    return [{
      concept: t('dashboard.consumption'),
      value_01: `${_.get(componentCfg, 'comparative.real.consumption', 0).toFixed(2)} kWh`,
      versus: 'vs.',
      value_02: `${_.get(componentCfg, 'comparative.estimated.consumption', 0).toFixed(2)} kWh`,
      value_total: `${(_.get(componentCfg, 'comparative.estimated.consumption', 0) + _.get(componentCfg, 'comparative.real.consumption', 0)).toFixed(2)} kWh`
    }, {
      concept: t('dashboard.cost'),
      value_01: `${_.get(componentCfg, 'comparative.real.cost', 0).toFixed(2)} €`,
      versus: 'vs.',
      value_02: `${_.get(componentCfg, 'comparative.estimated.cost', 0).toFixed(2)} €`,
      value_total: `${(_.get(componentCfg, 'comparative.estimated.cost', 0) + _.get(componentCfg, 'comparative.real.cost', 0)).toFixed(2)} €`
    }]
  }, [componentCfg.comparative])

  return (
    <LoaderBackDrop active={!componentCfg.isLoaded} loader background bordered>
      <Alert type="error" message={'Working on it. In development'} style={{ marginBottom: '15px' }} banner />
      <Row gutter={[8, 16]}>
        <Col span={6}>
          <RangePicker size={'small'} style={{ marginBottom: '16px' }}
            onChange={handlerChangeDate}
            defaultValue={[
              dayjs(componentCfg.start.format('YYYYMMDD'), 'YYYYMMDD'),
              dayjs(componentCfg.end.format('YYYYMMDD'), 'YYYYMMDD')
            ]}
            format={'YYYY-MM-DD'}
          />
        </Col>
        <Col span={5}>
          <Button size={'small'} disabled={!componentCfg.rangeDateIsValid} onClick={handlerApply}>{t('dashboard.apply')}</Button>
        </Col>
      </Row>
      {(() => { /* load component */
        if (!hasReadEnergySupplyLiquidationPeriodsPromotionPermissions) {
          return <ForbiddenResult compact={false} />
        } else if (componentCfg.isError) {
          return <ErrorResult compact={false} />
        } else if (_.isEmpty(componentCfg.chart.data)) {
          return <EmptyResult compact={false} />
        } else { /* return components to render */
          return (<>
            <Row gutter={[8, 16]}>
              <Col span={24}>
                <Table loading={false} autoHeight={true} hover={false} fillHeight={false} showHeader={true} data={dataTable} bordered={true} cellBordered={true} headerHeight={30} rowHeight={29}>
                  <Column width={100} align={'center'}>
                    <CompactHeaderCell style={{ borderRight: '2px solid #cdcdcd' }} >&nbsp;</CompactHeaderCell>
                    <CompactCell style={{ borderRight: '2px solid #cdcdcd' }} dataKey={'concept'} />
                  </Column>
                  <Column minWidth={150} flexGrow={1} align={'center'}>
                    <CompactHeaderCell>{t('dashboard.real')}</CompactHeaderCell>
                    <CompactCell dataKey={'value_01'} />
                  </Column>
                  <Column width={50} align={'center'}>
                    <CompactHeaderCell>&nbsp;</CompactHeaderCell>
                    <CompactCell dataKey={'versus'} />
                  </Column>
                  <Column minWidth={150} flexGrow={1} align={'center'}>
                    <CompactHeaderCell>{t('dashboard.estimated')}</CompactHeaderCell>
                    <CompactCell dataKey={'value_02'} />
                  </Column>
                  <Column width={150} align={'center'}>
                    <CompactHeaderCell style={{ borderLeft: '1px solid #cdcdcd' }}>{t('dashboard.total')}</CompactHeaderCell>
                    <CompactCell style={{ borderLeft: '1px solid #cdcdcd' }} dataKey={'value_total'} />
                  </Column>
                </Table>
              </Col>
              <Col span={24} style={{ marginBottom: '16px' }}>
                <ColumnPlot {...componentCfg.chart} />
              </Col>
            </Row>
          </>)
        }
      })()}
    </LoaderBackDrop>
  )
}

PromotionEnergySourceLiquidationAnalysisPanel.propTypes = {
  promotionId: PropTypes.number.isRequired,
  sourceId: PropTypes.number.isRequired
}
