import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Space } from 'antd'
import PropTypes from 'prop-types'
import {
  ButtonFormReset, ButtonFormSubmit, FormAlerts,
  EnergyField, EnergySupplyCostsFields, DateField, SelectDay, PeriodicityMonthly, FormSubTitle, NumberPercentField
} from '../../forms'
import { useTranslation } from 'react-i18next'
import { useRequestsEvents } from '../../../hooks/useRequestsEvents'
import _ from 'lodash'
import moment from 'moment'
import { sentenceCase } from 'change-case'
import dayjs from 'dayjs'

// set locale to moments
moment.locale(_.get(window, 'user.user_language_id', 'ca'))
// get months in use lang
const months = moment.months()

// set field labels for print errors from API
const fieldsConfig = {
  promotion_energy_source_estimated_consumption_month_0: {
    label: sentenceCase(months[0]),
    name: 'promotion_energy_source_estimated_consumption_month_0'
  },
  promotion_energy_source_estimated_consumption_month_1: {
    label: sentenceCase(months[1]),
    name: 'promotion_energy_source_estimated_consumption_month_1'
  },
  promotion_energy_source_estimated_consumption_month_2: {
    label: sentenceCase(months[2]),
    name: 'promotion_energy_source_estimated_consumption_month_2'
  },
  promotion_energy_source_estimated_consumption_month_3: {
    label: sentenceCase(months[3]),
    name: 'promotion_energy_source_estimated_consumption_month_3'
  },
  promotion_energy_source_estimated_consumption_month_4: {
    label: sentenceCase(months[4]),
    name: 'promotion_energy_source_estimated_consumption_month_4'
  },
  promotion_energy_source_estimated_consumption_month_5: {
    label: sentenceCase(months[5]),
    name: 'promotion_energy_source_estimated_consumption_month_5'
  },
  promotion_energy_source_estimated_consumption_month_6: {
    label: sentenceCase(months[6]),
    name: 'promotion_energy_source_estimated_consumption_month_6'
  },
  promotion_energy_source_estimated_consumption_month_7: {
    label: sentenceCase(months[7]),
    name: 'promotion_energy_source_estimated_consumption_month_7'
  },
  promotion_energy_source_estimated_consumption_month_8: {
    label: sentenceCase(months[8]),
    name: 'promotion_energy_source_estimated_consumption_month_8'
  },
  promotion_energy_source_estimated_consumption_month_9: {
    label: sentenceCase(months[9]),
    name: 'promotion_energy_source_estimated_consumption_month_9'
  },
  promotion_energy_source_estimated_consumption_month_10: {
    label: sentenceCase(months[10]),
    name: 'promotion_energy_source_estimated_consumption_month_10'
  },
  promotion_energy_source_estimated_consumption_month_11: {
    label: sentenceCase(months[11]),
    name: 'promotion_energy_source_estimated_consumption_month_11'
  },
  promotion_energy_source_estimated_consumption_price_unit: {
    label: 'fields.labels.cost_unit',
    name: 'promotion_energy_source_estimated_consumption_price_unit'
  },
  promotion_energy_source_estimated_consumption_tax: {
    label: 'fields.labels.tax',
    name: 'promotion_energy_source_estimated_consumption_tax'
  },
  promotion_energy_source_estimated_consumption_price_fix: {
    label: 'fields.labels.cost_fix',
    name: 'promotion_energy_source_estimated_consumption_price_fix'
  },
  promotion_energy_source_liquidation_periodicity_id: {
    label: 'fields.labels.periodicity',
    name: 'promotion_energy_source_liquidation_periodicity_id'
  },
  promotion_energy_source_liquidation_day: {
    label: 'fields.labels.day',
    name: 'promotion_energy_source_liquidation_day'
  },
  promotion_energy_source_liquidation_start_date: {
    label: 'fields.labels.start',
    name: 'promotion_energy_source_liquidation_start_date'
  },
  promotion_energy_source_custom_efficiency: {
    label: 'fields.labels.efficiency',
    name: 'promotion_energy_source_custom_efficiency'
  }
}

const initValues = { }

export const PromotionEnergySourceForm = (props) => {
  // for translation
  const { t } = useTranslation(['dashboard'])
  // for re-rerender form when change locale
  const [form] = Form.useForm()
  // for validate on input change after first submit
  const [validateOnChange, setValidateOnChange] = useState([])
  const [validateHasFeedback, setValidateHasFeedback] = useState(false)
  // get props data
  const { promotionId, sourceId, onSuccessSubmit } = props
  // get methods for requests
  const { updatePromotionEnergySource, getPromotionEnergySource } = useRequestsEvents()
  // component config
  const [componentCfg, setComponentCfg] = useState({
    isLoaded: false,
    isSubmitting: false,
    errors: [],
    defaultValues: { },
    renderPeriod: false,
    applicationTypeDisabled: _.isEmpty(sourceId)
  }) // flag for buttons disable
  const areButtonsDisabled = !componentCfg.isLoaded || componentCfg.isSubmitting

  // change validateOnChange when finish is not successfully
  const handlerFinishFailed = () => {
    setValidateOnChange('onChange')
    setValidateHasFeedback(true)
  }

  // reset form function
  const handlerResetForm = () => {
    // reset fields
    form.resetFields()
    // update config in function of caller of this function
    setComponentCfg({ ...componentCfg, isSubmitting: false, isLoaded: true, errors: [] })
  }

  // call to log in when finish successfully
  const handlerFinish = (values) => {
    // update component config
    setComponentCfg({ ...componentCfg, isSubmitting: true })
    // set values to send
    const valuesToSend = {
      ...values,
      [fieldsConfig.promotion_energy_source_liquidation_start_date.name]: values[fieldsConfig.promotion_energy_source_liquidation_start_date.name].format('YYYY-MM-DD'),
      [fieldsConfig.promotion_energy_source_custom_efficiency.name]: values[fieldsConfig.promotion_energy_source_custom_efficiency.name] / 100
    } // if not edit case...
    if (!sourceId) { return }
    // update energy source
    updatePromotionEnergySource(promotionId, sourceId, valuesToSend, () => {
      setComponentCfg({ ...componentCfg, defaultValues: values })
      if (onSuccessSubmit) { onSuccessSubmit(sourceId) }
    }, (status, response) => {
      setComponentCfg({ ...componentCfg, isSubmitting: false, errors: _.get(response, 'data.errors', []) })
    })
  }

  // for load all promotions data when component is mounted
  useEffect(() => {
    // clean up controller --> prevent crash with async function
    let isMounted = true
    // nothing to do if is loaded
    if (componentCfg.isLoaded) { return }
    // if not set invoice id
    if (!sourceId) { // is created case
      setComponentCfg({ ...componentCfg, isLoaded: true, defaultValues: _.cloneDeep(initValues) })
    } else { // is edit case
      getPromotionEnergySource(promotionId, sourceId, (result) => {
        // prevent async crash
        if (!isMounted) return null
        // convert date value
        result.data[fieldsConfig.promotion_energy_source_liquidation_start_date.name] = dayjs(`${result.data[fieldsConfig.promotion_energy_source_liquidation_start_date.name]}`, 'YYYY-MM-DD')
        result.data[fieldsConfig.promotion_energy_source_custom_efficiency.name] = result.data[fieldsConfig.promotion_energy_source_custom_efficiency.name] * 100
        // update component cfg
        setComponentCfg({ ...componentCfg, isLoaded: true, defaultValues: result.data })
      })
    } // prevent crash with async function
    return () => { isMounted = false }
  }, [componentCfg.isLoaded])

  // for load values when are set default values:
  // - on load component
  // - on submit data
  useEffect(() => {
    // skip first render
    if (!componentCfg.isLoaded) { return }
    // reset form if (!selected || !selected[0] || !selected[1]) { return 0 }
    handlerResetForm(true)
  }, [componentCfg.defaultValues])

  return (<>
    <FormAlerts errors={componentCfg.errors} fieldsConfig={fieldsConfig} />
    <Form form={form} disabled={!componentCfg.isLoaded} validateTrigger={validateOnChange} layout="vertical" name="promotion_application_form"
          autoComplete="off" onFinish={handlerFinish} onFinishFailed={handlerFinishFailed} requiredMark={true}
          initialValues={componentCfg.defaultValues}>
      { /* row 01 - 04 :: Title */}
      <FormSubTitle text={t('dashboard.energy_profile')} />
      { /* row 01 - 04 :: Fields */}
      { /* row 01 */ }
      <Row gutter={16}>
        <Col span={8}>
          <EnergyField
            form={form} promotionId={promotionId}
            hasFeedback={validateHasFeedback}
            label={fieldsConfig.promotion_energy_source_estimated_consumption_month_0.label}
            name={fieldsConfig.promotion_energy_source_estimated_consumption_month_0.name}
          />
        </Col>
        <Col span={8}>
          <EnergyField
            form={form}
            hasFeedback={validateHasFeedback}
            label={fieldsConfig.promotion_energy_source_estimated_consumption_month_1.label}
            name={fieldsConfig.promotion_energy_source_estimated_consumption_month_1.name}
          />
        </Col>
        <Col span={8}>
          <EnergyField
            form={form}
            hasFeedback={validateHasFeedback}
            label={fieldsConfig.promotion_energy_source_estimated_consumption_month_2.label}
            name={fieldsConfig.promotion_energy_source_estimated_consumption_month_2.name}
          />
        </Col>
      </Row>
      { /* row 02 */}
      <Row gutter={16}>
        <Col span={8}>
          <EnergyField
            form={form} promotionId={promotionId}
            hasFeedback={validateHasFeedback}
            label={fieldsConfig.promotion_energy_source_estimated_consumption_month_3.label}
            name={fieldsConfig.promotion_energy_source_estimated_consumption_month_3.name}
          />
        </Col>
        <Col span={8}>
          <EnergyField
            form={form}
            hasFeedback={validateHasFeedback}
            label={fieldsConfig.promotion_energy_source_estimated_consumption_month_4.label}
            name={fieldsConfig.promotion_energy_source_estimated_consumption_month_4.name}
          />
        </Col>
        <Col span={8}>
          <EnergyField
            form={form}
            hasFeedback={validateHasFeedback}
            label={fieldsConfig.promotion_energy_source_estimated_consumption_month_5.label}
            name={fieldsConfig.promotion_energy_source_estimated_consumption_month_5.name}
          />
        </Col>
      </Row>
      { /* row 03 */}
      <Row gutter={16}>
        <Col span={8}>
          <EnergyField
            form={form} promotionId={promotionId}
            hasFeedback={validateHasFeedback}
            label={fieldsConfig.promotion_energy_source_estimated_consumption_month_6.label}
            name={fieldsConfig.promotion_energy_source_estimated_consumption_month_6.name}
          />
        </Col>
        <Col span={8}>
          <EnergyField
            form={form}
            hasFeedback={validateHasFeedback}
            label={fieldsConfig.promotion_energy_source_estimated_consumption_month_7.label}
            name={fieldsConfig.promotion_energy_source_estimated_consumption_month_7.name}
          />
        </Col>
        <Col span={8}>
          <EnergyField
            form={form}
            hasFeedback={validateHasFeedback}
            label={fieldsConfig.promotion_energy_source_estimated_consumption_month_8.label}
            name={fieldsConfig.promotion_energy_source_estimated_consumption_month_8.name}
          />
        </Col>
      </Row>
      { /* row 03 */}
      <Row gutter={16}>
        <Col span={8}>
          <EnergyField
            form={form} promotionId={promotionId}
            hasFeedback={validateHasFeedback}
            label={fieldsConfig.promotion_energy_source_estimated_consumption_month_9.label}
            name={fieldsConfig.promotion_energy_source_estimated_consumption_month_9.name}
          />
        </Col>
        <Col span={8}>
          <EnergyField
            form={form}
            hasFeedback={validateHasFeedback}
            label={fieldsConfig.promotion_energy_source_estimated_consumption_month_10.label}
            name={fieldsConfig.promotion_energy_source_estimated_consumption_month_10.name}
          />
        </Col>
        <Col span={8}>
          <EnergyField
            form={form}
            hasFeedback={validateHasFeedback}
            label={fieldsConfig.promotion_energy_source_estimated_consumption_month_11.label}
            name={fieldsConfig.promotion_energy_source_estimated_consumption_month_11.name}
          />
        </Col>
      </Row>
      { /* row 04 */}
      <Row gutter={16}>
        <Col span={24}>
          <EnergySupplyCostsFields
            form={form}
            hasFeedback={validateHasFeedback}
            label={[fieldsConfig.promotion_energy_source_estimated_consumption_price_fix.label, fieldsConfig.promotion_energy_source_estimated_consumption_price_unit.label, fieldsConfig.promotion_energy_source_estimated_consumption_tax.label]}
            name={[fieldsConfig.promotion_energy_source_estimated_consumption_price_fix.name, fieldsConfig.promotion_energy_source_estimated_consumption_price_unit.name, fieldsConfig.promotion_energy_source_estimated_consumption_tax.name]}
          />
        </Col>
      </Row>
      { /* row 05 :: Title */}
      <FormSubTitle text={`${t('dashboard.promotion.data_title_view_liquidation')}`} />
      { /* row 05 :: Fields */}
      <Row gutter={16}>
        <Col span={6}>
          <DateField
            form={form}
            hasFeedback={validateHasFeedback}
            label={fieldsConfig.promotion_energy_source_liquidation_start_date.label}
            name={fieldsConfig.promotion_energy_source_liquidation_start_date.name}
          />
        </Col>
        <Col span={6}>
          <PeriodicityMonthly
            form={form}
            hasFeedback={validateHasFeedback}
            required={true}
            label={fieldsConfig.promotion_energy_source_liquidation_periodicity_id.label}
            name={fieldsConfig.promotion_energy_source_liquidation_periodicity_id.name}
          />
        </Col>
        <Col span={6}>
          <SelectDay
            form={form}
            hasFeedback={validateHasFeedback}
            required={true}
            label={fieldsConfig.promotion_energy_source_liquidation_day.label}
            name={fieldsConfig.promotion_energy_source_liquidation_day.name}
          />
        </Col>
        <Col span={6}>
          <NumberPercentField
            form={form}
            hasFeedback={validateHasFeedback}
            required={true}
            label={fieldsConfig.promotion_energy_source_custom_efficiency.label}
            name={fieldsConfig.promotion_energy_source_custom_efficiency.name}
          />
        </Col>
      </Row>
      { /* buttons row */ }
      <Row justify="end">
        <Col>
          <Space>
            {!sourceId && <ButtonFormReset disabled={areButtonsDisabled} form={form} callback={() => handlerResetForm() } /> }
            <ButtonFormSubmit disabled={areButtonsDisabled} form={form} text={sourceId ? t('dashboard.update') : t('dashboard.add')}/>
          </Space>
        </Col>
      </Row>
    </Form>
  </>)
}

PromotionEnergySourceForm.propTypes = {
  promotionId: PropTypes.number.isRequired,
  sourceId: PropTypes.number,
  onSuccessSubmit: PropTypes.func
}
