import { useTranslation } from 'react-i18next'
import { Table, Tooltip, Whisper } from 'rsuite'
import React from 'react'
import PropTypes from 'prop-types'

const { Column, HeaderCell, Cell } = Table
const CompactCell = props => <Cell {...props} style={{ padding: 4 }} />
const CompactHeaderCell = props => <HeaderCell {...props} style={{ padding: 4 }} />

export const PromotionLiquidationGenericEnergyTable = (props) => {
  // for translation
  const { t } = useTranslation(['dashboard', 'fields'])
  // data to show
  const { data, style, showHeader, summary } = props

  return (<Table style={style || {}} loading={false} autoHeight={true} hover={false} fillHeight={false} showHeader={(showHeader !== false)} data={data} bordered={true} cellBordered={true} headerHeight={30} rowHeight={29}>
    { !summary &&
      <Column width={50} align={'center'}>
        <CompactHeaderCell>
          <Whisper speaker={<Tooltip>{t('dashboard.is_estimated_m')}</Tooltip>}><span>E.*</span></Whisper>
        </CompactHeaderCell>
        <CompactCell dataKey={'period_is_estimated_icon'} />
      </Column>
    }
    { !summary &&
      <Column width={60} align={'center'}>
        <CompactHeaderCell>
          <Whisper speaker={<Tooltip>{t('dashboard.invoice')}</Tooltip>}><span>{t('dashboard.invoice_abbr')} Id*</span></Whisper>
        </CompactHeaderCell>
        <CompactCell dataKey={'period_invoice_id'} />
      </Column>
    }
    <Column width={!summary ? 180 : 50 + 60 + 180 } align={'right'}>
      <CompactHeaderCell>{!summary ? t('dashboard.period') : ''}</CompactHeaderCell>
      <CompactCell dataKey={'period_full_date'} />
    </Column>
    <Column width={45} align={'center'}>
      <CompactHeaderCell>{t('dashboard.days')}</CompactHeaderCell>
      <CompactCell dataKey={'period_days'} />
    </Column>
    <Column width={150} flexGrow={1} align={'right'}>
      <CompactHeaderCell>{t('fields:fields.labels.consumption')}</CompactHeaderCell>
      <CompactCell dataKey={'period_consumption'} />
    </Column>
    <Column width={100} align={'right'}>
      <CompactHeaderCell>{t('fields:fields.labels.cost_variable')}</CompactHeaderCell>
      <CompactCell dataKey={'period_cost_variable'} />
    </Column>
    <Column width={100} align={'right'}>
      <CompactHeaderCell>{t('fields:fields.labels.cost_fix')}</CompactHeaderCell>
      <CompactCell dataKey={'period_cost_fix'} />
    </Column>
    <Column width={100} align={'right'}>
      <CompactHeaderCell>{t('fields:fields.labels.tax')} %</CompactHeaderCell>
      <CompactCell dataKey={'period_cost_tax_percent'} />
    </Column>
    <Column width={100} align={'right'}>
      <CompactHeaderCell>{t('fields:fields.labels.tax')} €</CompactHeaderCell>
      <CompactCell dataKey={'period_cost_tax_eur'} />
    </Column>
    <Column width={150} flexGrow={1} align={'right'}>
      <CompactHeaderCell>{t('fields:fields.labels.total')}</CompactHeaderCell>
      <CompactCell dataKey={'period_total'} />
    </Column>
  </Table>)
}

PromotionLiquidationGenericEnergyTable.propTypes = {
  data: PropTypes.array.isRequired,
  style: PropTypes.object,
  showHeader: PropTypes.bool,
  summary: PropTypes.bool
}
