import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Space } from 'antd'
import PropTypes from 'prop-types'
import {
  ButtonFormReset,
  ButtonFormSubmit,
  ConsumptionValueField, DateField,
  PromotionEnergySourceTypesField,
  ReferenceField,
  EnergySupplyCostsFields,
  PeriodDataPickerField,
  SupplyMarketerField, FormAlerts
} from '../../forms'
import { useTranslation } from 'react-i18next'
import { useRequestsEvents } from '../../../hooks/useRequestsEvents'
import dayjs from 'dayjs'
import _ from 'lodash'

// set field labels for print errors from API
const fieldsConfig = {
  promotion_energy_source_id: {
    label: 'fields.labels.source_energy',
    name: 'promotion_energy_source_id'
  },
  promotion_supply_invoice_marketer_id: {
    label: 'fields.labels.supply_marketer',
    name: 'promotion_supply_invoice_marketer_id'
  },
  promotion_supply_invoice_start: {
    label: 'fields.labels.period_days',
    errorLabel: 'fields.labels.period',
    name: 'invoice_period'
  },
  promotion_supply_invoice_end: {
    label: 'fields.labels.period_days',
    errorLabel: 'fields.labels.period',
    name: 'invoice_period'
  },
  promotion_supply_invoice_number: {
    label: 'fields.labels.invoice_number',
    name: 'promotion_supply_invoice_number'
  },
  promotion_supply_invoice_consumption: {
    label: 'fields.labels.consumption',
    name: 'promotion_supply_invoice_consumption'
  },
  promotion_supply_invoice_units_id: {
    label: 'fields.labels.units',
    name: 'promotion_supply_invoice_units_id'
  },
  promotion_supply_invoice_fix_cost: {
    label: 'fields.labels.cost_fix',
    name: 'promotion_supply_invoice_fix_cost'
  },
  promotion_supply_invoice_variable_cost: {
    label: 'fields.labels.cost_variable',
    name: 'promotion_supply_invoice_variable_cost'
  },
  promotion_supply_invoice_tax: {
    label: 'fields.labels.tax',
    name: 'promotion_supply_invoice_tax'
  },
  promotion_supply_invoice_date: {
    label: 'fields.labels.date',
    name: 'promotion_supply_invoice_date'
  }
}

const initValues = { }

export const PromotionEnergySupplyInvoicesForm = (props) => {
  // for translation
  const { t } = useTranslation(['dashboard'])
  // for re-rerender form when change locale
  const [form] = Form.useForm()
  // for data range picker to get a days in period
  const invoicePeriod = Form.useWatch(fieldsConfig.promotion_supply_invoice_start.name, form)
  const sourceId = Form.useWatch(fieldsConfig.promotion_energy_source_id.name, form)
  // for validate on input change after first submit
  const [validateOnChange, setValidateOnChange] = useState([])
  const [validateHasFeedback, setValidateHasFeedback] = useState(false)
  // for units filter
  const [permittedUnits, setPermittedUnits] = useState([])
  // for units filter
  const [sources, setSources] = useState([])
  // get props data
  const { invoiceId, promotionId, isPeriodEditable, onSuccessSubmit } = props
  // get methods to send data
  const { createPromotionEnergySupplyInvoice, updatePromotionEnergySupplyInvoice, getPromotionEnergySupplyInvoice } = useRequestsEvents()
  // component config
  const [componentCfg, setComponentCfg] = useState({
    isLoaded: false,
    isSubmitting: false,
    errors: [],
    defaultValues: { }
  })
  // flag for buttons disable
  const areButtonsDisabled = !componentCfg.isLoaded || componentCfg.isSubmitting

  // change validateOnChange when finish is not successfully
  const handlerFinishFailed = () => {
    setValidateOnChange('onChange')
    setValidateHasFeedback(true)
  }

  // reset form function
  const handlerResetForm = () => {
    // reset fields
    form.resetFields()
    // update config in function of caller of this function
    setComponentCfg({ ...componentCfg, isSubmitting: false, isLoaded: true, errors: [] })
  }

  // on combo sources is loaded
  const handlerOnLoadSources = (sources) => {
    // save sources loaded on this parent
    setSources(sources)
  }

  // call to log in when finish successfully
  const handlerFinish = (values) => {
    // update component config
    setComponentCfg({ ...componentCfg, isSubmitting: true })
    // set values to send
    const valuesToSend = {
      ...values,
      promotion_supply_invoice_start: values.invoice_period[0].format('YYYY-MM-DD'),
      promotion_supply_invoice_end: values.invoice_period[1].format('YYYY-MM-DD'),
      [fieldsConfig.promotion_supply_invoice_date.name]: values.promotion_supply_invoice_date.format('YYYY-MM-DD')
    } // delete not needed params
    delete valuesToSend.invoice_period

    // if edit case...
    if (invoiceId) {
      updatePromotionEnergySupplyInvoice(promotionId, invoiceId, valuesToSend, () => {
        setComponentCfg({ ...componentCfg, defaultValues: values })
        if (onSuccessSubmit) { onSuccessSubmit() }
      }, (status, response) => {
        setComponentCfg({ ...componentCfg, isSubmitting: false, errors: _.get(response, 'data.errors', []) })
      })
    } else { // if create case
      createPromotionEnergySupplyInvoice(promotionId, valuesToSend, () => {
        setComponentCfg({ ...componentCfg, defaultValues: _.cloneDeep(initValues) })
        if (onSuccessSubmit) { onSuccessSubmit() }
      }, (status, response) => {
        setComponentCfg({ ...componentCfg, isSubmitting: false, errors: _.get(response, 'data.errors', []) })
      })
    }
  }

  // for load all promotions data when component is mounted
  useEffect(() => {
    // clean up controller --> prevent crash with async function
    let isMounted = true
    // nothing to do if is loaded
    if (componentCfg.isLoaded) { return }
    // if not set invoice id
    if (!invoiceId) { // is created case
      setComponentCfg({ ...componentCfg, isLoaded: true, defaultValues: _.cloneDeep(initValues) })
    } else { // is edit case
      // request to delete invoice
      getPromotionEnergySupplyInvoice(promotionId, invoiceId, (result) => {
        // prevent async crash
        if (!isMounted) return null
        // fet values to form
        const values = {
          ...result.data,
          [fieldsConfig.promotion_supply_invoice_start.name]: [
            dayjs(result.data.promotion_supply_invoice_start, 'YYYY-MM-DD'),
            dayjs(result.data.promotion_supply_invoice_end, 'YYYY-MM-DD')
          ],
          [fieldsConfig.promotion_supply_invoice_date.name]: dayjs(result.data.promotion_supply_invoice_date, 'YYYY-MM-DD')
        } // update component cfg
        setComponentCfg({ ...componentCfg, isLoaded: true, defaultValues: values })
      })
    } // prevent crash with async function
    return () => { isMounted = false }
  }, [componentCfg.isLoaded])

  // for load values when are set default values:
  // - on load component
  // - on submit data
  useEffect(() => {
    // skip first render
    if (!componentCfg.isLoaded) { return }
    // reset formif (!selected || !selected[0] || !selected[1]) { return 0 }
    handlerResetForm(true)
  }, [componentCfg.defaultValues])

  // for update component on change source type id
  useEffect(() => {
    // skip is not loaded component
    if (!componentCfg.isLoaded) { return }
    // get source selected data
    const source = _.find(sources, { value: sourceId })
    const sourceTypeId = _.get(source, 'energy_source_id', -1)
    // ST, PV and electricity only kWh
    if ([1, 4, 5].includes(sourceTypeId)) {
      setPermittedUnits([1])
      // Gas propane and gas natural only kWh, m3
    } else if ([6, 3].includes(sourceTypeId)) {
      setPermittedUnits([1, 2])
    } else { // update config in function of caller of this function
      setPermittedUnits([1])
    }
  }, [componentCfg.isLoaded, sourceId, sources])

  return (<>
    <FormAlerts errors={componentCfg.errors} fieldsConfig={fieldsConfig} />
    <Form form={form} disabled={!componentCfg.isLoaded} validateTrigger={validateOnChange} layout="vertical" name="supply_energy_invoices_form"
          autoComplete="off" onFinish={handlerFinish} onFinishFailed={handlerFinishFailed} requiredMark={true}
          initialValues={componentCfg.defaultValues}>
      { /* row 01 */ }
      <Row gutter={16}>
        <Col span={12}>
          <PromotionEnergySourceTypesField
            form={form} promotionId={promotionId}
            hasFeedback={validateHasFeedback}
            onLoadCallback={handlerOnLoadSources}
            label={fieldsConfig.promotion_energy_source_id.label}
            name={fieldsConfig.promotion_energy_source_id.name}
          />
        </Col>
        <Col span={12}>
          <SupplyMarketerField
            form={form}
            hasFeedback={validateHasFeedback}
            label={fieldsConfig.promotion_supply_invoice_marketer_id.label}
            name={fieldsConfig.promotion_supply_invoice_marketer_id.name}
          />
        </Col>
      </Row>
      { /* row 02 */}
      <Row gutter={16}>
        <Col span={12}>
          <ReferenceField
            form={form}
            hasFeedback={validateHasFeedback}
            label={fieldsConfig.promotion_supply_invoice_number.label}
            name={fieldsConfig.promotion_supply_invoice_number.name}
          />
        </Col>
        <Col span={12}>
          <DateField
            form={form}
            hasFeedback={validateHasFeedback}
            label={fieldsConfig.promotion_supply_invoice_date.label}
            name={fieldsConfig.promotion_supply_invoice_date.name}
          />
        </Col>
      </Row>
      { /* row 03 */}
      <Row gutter={16}>
        <Col span={12}>
          <PeriodDataPickerField
            form={form}
            hasFeedback={validateHasFeedback}
            name={'invoice_period'}
            selected={invoicePeriod}
            disabled={invoiceId && !isPeriodEditable}
          />
        </Col>
        <Col span={12}>
          <ConsumptionValueField
            form={form}
            hasFeedback={validateHasFeedback}
            permittedUnits={permittedUnits}
            initialValue={componentCfg.defaultValues[fieldsConfig.promotion_supply_invoice_units_id.name]}
            label={[fieldsConfig.promotion_supply_invoice_consumption.label, fieldsConfig.promotion_supply_invoice_units_id.label]}
            name={[fieldsConfig.promotion_supply_invoice_consumption.name, fieldsConfig.promotion_supply_invoice_units_id.name]}
          />
        </Col>
      </Row>
      { /* row 04 */}
      <Row gutter={16}>
        <Col span={24}>
          <EnergySupplyCostsFields
            form={form}
            hasFeedback={validateHasFeedback}
            label={[fieldsConfig.promotion_supply_invoice_fix_cost.label, fieldsConfig.promotion_supply_invoice_variable_cost.label, fieldsConfig.promotion_supply_invoice_tax.label]}
            name={[fieldsConfig.promotion_supply_invoice_fix_cost.name, fieldsConfig.promotion_supply_invoice_variable_cost.name, fieldsConfig.promotion_supply_invoice_tax.name]}
          />
        </Col>
      </Row>
      { /* buttons row */ }
      <Row justify="end">
        <Col>
          <Space>
            {!invoiceId && <ButtonFormReset disabled={areButtonsDisabled} form={form} callback={() => handlerResetForm() } /> }
            <ButtonFormSubmit disabled={areButtonsDisabled} form={form} text={invoiceId ? t('dashboard.update') : t('dashboard.add')}/>
          </Space>
        </Col>
      </Row>
    </Form>
  </>)
}

PromotionEnergySupplyInvoicesForm.propTypes = {
  promotionId: PropTypes.number.isRequired,
  invoiceId: PropTypes.number,
  isPeriodEditable: PropTypes.bool.isRequired,
  onSuccessSubmit: PropTypes.func
}
