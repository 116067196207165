import React, { useEffect, useMemo, useState } from 'react'
import { Alert, Button, DatePicker, Drawer, Form, Input, InputNumber, Select, Space, Typography } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useRequestsEvents } from '../hooks/useRequestsEvents'
import _ from 'lodash'
import moment from 'moment'

// define locales for this page
const locales = ['fields']
const drawerWith = 720
const drawerStyle = { paddingBottom: 5 }

// selectable days
export const daysSelectables = _.range(1, 29).map(val => {
  return { value: val, label: val }
})

// field for email
export function UserEmailField (props) {
  // for translation
  const { t } = useTranslation(locales)
  // import element to render
  const { hasFeedback } = props

  const emailRules = [
    {
      required: true,
      message: t('fields.validations.not_empty')
    },
    {
      type: 'email',
      message: t('fields.validations.not_valid_email')
    }
  ]

  return (
    <>
      <Form.Item hasFeedback={hasFeedback ?? true} label={t('fields.labels.email')} name="user_email" rules={emailRules}>
        <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="john@example.com"/>
      </Form.Item>
    </>
  )
}

UserEmailField.propTypes = {
  hasFeedback: PropTypes.bool.isRequired
}

// field for password
export function UserPasswordField (props) {
  // for translation
  const { t } = useTranslation(locales)
  // import element to render
  const { hasFeedback } = props

  const passwordRules = [
    {
      validator: (rule, value) => {
        if (!value) { return Promise.reject(t('fields.validations.not_empty')) }
        const isValid = value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
        if (!isValid) { return Promise.reject(t('fields.helpers.password')) }
        return Promise.resolve()
      }
    }
  ]

  return (
    <>
      <Form.Item hasFeedback={hasFeedback ?? true} label={t('fields.labels.password')} name="user_password" rules={passwordRules}>
        <Input.Password prefix={<LockOutlined className="site-form-item-icon"/>} type="password" placeholder="Password"/>
      </Form.Item>
    </>
  )
}

UserPasswordField.propTypes = {
  hasFeedback: PropTypes.bool.isRequired
}

export function getDays (selected) {
  if (!selected || !selected[0] || !selected[1]) { return 0 }
  const start = moment(selected[0].format('YYYY-MM-DD'), 'YYYY-MM-DD')
  const end = moment(selected[1].format('YYYY-MM-DD'), 'YYYY-MM-DD').add(1, 'days')
  return end.diff(start, 'days')
}

// field for period data picker
export function PeriodDataPickerField (props) {
  // for translation
  const { t } = useTranslation(locales)
  // import element to render
  const { hasFeedback, name, selected, required, disabled, format } = props
  // set initial rules...
  const dataPickerRules = [{
    required: typeof required !== 'undefined' ? required : true,
    message: t('fields.validations.not_empty')
  }] // for set days selected
  const days = useMemo(() => { return (_.isEmpty(selected)) ? 0 : getDays(selected) }, [selected])

  return (
    <>
      <Form.Item hasFeedback={hasFeedback ?? true} name={name ?? 'period_values'} rules={dataPickerRules}
                 label={t('fields.labels.period_days', { days })}>
        <DatePicker.RangePicker style={{ width: '100%' }} disabled={disabled || false} format={format || 'YYYY/MM/DD'}/>
      </Form.Item>
    </>
  )
}

PeriodDataPickerField.propTypes = {
  hasFeedback: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  name: PropTypes.string,
  selected: PropTypes.array,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  format: PropTypes.string
}

// field for select date
export function DateField (props) {
  // for translation
  const { t } = useTranslation(locales)
  // import element to render
  const { hasFeedback, name, label } = props
  // set initial rules
  const dateRules = [{
    required: true,
    message: t('fields.validations.not_empty')
  }]

  return (
    <>
      <Form.Item hasFeedback={hasFeedback ?? true} label={t(label ?? 'fields.labels.date')} name={name ?? 'date'} rules={dateRules}>
        <DatePicker style={{ width: '100%' }}/>
      </Form.Item>
    </>
  )
}

DateField.propTypes = {
  hasFeedback: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string
}

// field for select supply company
export function SupplyMarketerField (props) {
  // for translation
  const { t } = useTranslation(locales)
  // import element to render
  const { hasFeedback, label, name } = props
  // for get measurements units
  const { getSupplyCompanies } = useRequestsEvents()
  // state for component loaded
  const [isLoaded, setLoaded] = useState(false)
  // state for companies
  const [companies, setCompanies] = useState(undefined)
  // set initial rules
  const requiredValidation = [{
    required: true,
    message: t('fields.validations.not_empty')
  }]

  // for load all promotions data when component is mounted
  useEffect(() => {
    // nothing to do if is loaded
    if (isLoaded) {
      return
    }
    // load units form database
    getSupplyCompanies((result) => {
      // format units
      const companiesFormatted = result.data.map(cmp => {
        return {
          value: parseInt(cmp.energy_marketer_company_id),
          label: cmp.energy_marketer_company_name
        }
      })

      // update units
      setCompanies(companiesFormatted)
      // set loaded component
      setLoaded(true)
    }, () => { // no permissions or other error
      // onLoadPromotions({ ...promotions, itemsRequested: [], itemLastSelected: undefined }, callback)
    })
  }, [isLoaded])

  return (
    <>
      <Form.Item hasFeedback={hasFeedback ?? true}
                 label={t(label ?? 'fields.labels.supply_marketer')}
                 name={name ?? 'supply_marketer_id'}
                 rules={requiredValidation}>
        <Select showSearch optionFilterProp="children" disabled={!isLoaded} loading={!isLoaded} options={companies}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
        />
      </Form.Item>
    </>
  )
}

SupplyMarketerField.propTypes = {
  hasFeedback: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string
}

// field for select yes or not
export function YesNoField (props) {
  // for translation
  const { t } = useTranslation(locales)
  // import element to render
  const { hasFeedback, label, name, required, disabled } = props
  // set initial rules
  const requiredValidation = [{
    required: typeof required !== 'undefined' ? required : true,
    message: t('fields.validations.not_empty')
  }]

  return (
    <>
      <Form.Item hasFeedback={hasFeedback ?? true} label={t(label ?? 'fields.labels.source_energy')} name={name ?? 'energy_source_id'} rules={requiredValidation}>
        <Select showSearch disabled={disabled || false} options={[
          { value: 0, label: t('fields.labels.no') },
          { value: 1, label: t('fields.labels.yes') }
        ]} optionFilterProp="children"
        />
      </Form.Item>
    </>
  )
}

YesNoField.propTypes = {
  hasFeedback: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  onLoadCallback: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool
}

// field for select application type
export function ApplicationTypesField (props) {
  // for translation
  const { t } = useTranslation(locales)
  // import element to render
  const { hasFeedback, label, name, onLoadCallback, required, disabled } = props
  // init component config
  const [componentCfg, setComponentCfg] = useState({
    isLoaded: false,
    isInit: false,
    sources: []
  })

  // for get measurements units
  const { getApplicationTypes } = useRequestsEvents()
  // set initial rules
  const requiredValidation = [{
    required: typeof required !== 'undefined' ? required : true,
    message: t('fields.validations.not_empty')
  }]

  // for load all promotions data when component is mounted
  useEffect(() => {
    // nothing to do if is loaded
    if (componentCfg.isLoaded) { return }
    // load units form database
    getApplicationTypes((result) => {
      // format units
      const sourcesFormatted = result.data.map(app => {
        return {
          value: app.energy_application_id,
          label: app.energy_application_name
        }
      })
      setComponentCfg({ ...componentCfg, isLoaded: true, sources: sourcesFormatted })
      if (onLoadCallback) onLoadCallback(sourcesFormatted)
    })
  }, [componentCfg.isLoaded])

  return (
    <>
      <Form.Item hasFeedback={hasFeedback ?? true} label={t(label ?? 'fields.labels.application')} name={name ?? 'energy_application_id'} rules={requiredValidation}>
        <Select showSearch disabled={!componentCfg.isLoaded || (disabled || false)} loading={!componentCfg.isLoaded} options={componentCfg.sources} optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
        />
      </Form.Item>
    </>
  )
}

ApplicationTypesField.propTypes = {
  hasFeedback: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  name: PropTypes.string,
  promotionId: PropTypes.number.isRequired,
  label: PropTypes.string,
  onLoadCallback: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool
}

// field for select energy source
export function PromotionEnergySourceTypesField (props) {
  // for translation
  const { t } = useTranslation(locales)
  // import element to render
  const { hasFeedback, promotionId, label, name, onLoadCallback, required, disabled } = props
  // init component config
  const [componentCfg, setComponentCfg] = useState({
    isLoaded: false,
    isInit: false,
    sources: []
  })

  // for get measurements units
  const { getPromotionEnergySources } = useRequestsEvents()
  // set initial rules
  const requiredValidation = [{
    required: typeof required !== 'undefined' ? required : true,
    message: t('fields.validations.not_empty')
  }]

  // for load all promotions data when component is mounted
  useEffect(() => {
    // nothing to do if is loaded
    if (componentCfg.isLoaded) { return }
    // load units form database
    getPromotionEnergySources(promotionId, (result) => {
      // format units
      const sourcesFormatted = result.data.map(src => {
        return {
          value: src.promotion_energy_source_id,
          label: (function () {
            const description = (src.promotion_energy_source_desc) ? ` - ${src.promotion_energy_source_desc}` : ''
            return `(${src.promotion_energy_source_id}) ${src.promotion_energy_source_name}${description}`
          })(),
          energy_source_id: src.energy_source_id
        }
      })
      setComponentCfg({ ...componentCfg, isLoaded: true, sources: sourcesFormatted })
      if (onLoadCallback) onLoadCallback(sourcesFormatted)
    })
  }, [componentCfg.isLoaded])

  return (
    <>
      <Form.Item hasFeedback={hasFeedback ?? true} label={t(label ?? 'fields.labels.source_energy')} name={name ?? 'energy_source_id'} rules={requiredValidation}>
        <Select showSearch disabled={!componentCfg.isLoaded || (disabled || false)} loading={!componentCfg.isLoaded} options={componentCfg.sources} optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>
    </>
  )
}

PromotionEnergySourceTypesField.propTypes = {
  hasFeedback: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  name: PropTypes.string,
  promotionId: PropTypes.number.isRequired,
  label: PropTypes.string,
  onLoadCallback: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool
}

// return consumption field
export function ConsumptionValueField (props) {
  // for translation
  const { t } = useTranslation(locales)
  // import element to render
  const { form, hasFeedback, name, label, permittedUnits, initialValue, required, disabled } = props
  // for get measurements units
  const { getMeasurementsUnits } = useRequestsEvents()
  // component configuration
  const [componentCfg, setComponentCfg] = useState({
    name: _.get(name, '[1]', null) ?? 'consumption_value_units',
    isLoaded: false,
    isNeededRender: false,
    units: undefined,
    options: undefined
  }) // set initial rules
  const requiredValidationUnits = [{
    required: typeof required === 'undefined' || !Array.isArray(required) ? true : required[0],
    message: t('fields.validations.not_empty')
  }]
  const requiredValidationConsumption = [{
    required: typeof required === 'undefined' || !Array.isArray(required) ? true : required[1],
    message: t('fields.validations.not_empty')
  }]

  // for load all promotions data when component is mounted
  useEffect(() => {
    // nothing to do if is loaded
    if (componentCfg.isLoaded) { return }
    // load units form database
    getMeasurementsUnits((result) => {
      // format units
      const unitsFormatted = result.data.map(units => {
        return {
          value: parseInt(units.measurement_unit_id),
          label: units.measurement_unit_symbol
        }
      }) // update component config
      setComponentCfg({ ...componentCfg, isLoaded: true, units: unitsFormatted })
    }, () => { // no permissions or other error
      // onLoadPromotions({ ...promotions, itemsRequested: [], itemLastSelected: undefined }, callback)
    })
  }, [componentCfg.isLoaded])

  useEffect(() => {
    // nothing to do if is loaded
    if (!componentCfg.isLoaded) { return }
    // reset component view
    setComponentCfg({ ...componentCfg, isNeededRender: false, options: undefined })
    // var for new options
    let options = componentCfg.units
    // if units filter is not and array
    if (permittedUnits && Array.isArray(permittedUnits)) {
      // filter options to show
      options = _.filter(componentCfg.units, (unit) => { return permittedUnits.includes(unit.value) })
    } // set options to component
    setComponentCfg({ ...componentCfg, isNeededRender: true, options })

    // FIX VIEW ON COMBO
    // get selected value (if not exists selected value get from initial value)
    const selected = form.getFieldValue(componentCfg.name) || initialValue
    // find if value selected exists on options
    const existentSelectedOption = _.find(options, { value: selected })
    // set default selected (if value not exists on options, set value selected on form to undefined, in other case, a current value selected)
    form.setFieldsValue({ [componentCfg.name]: existentSelectedOption ? selected : permittedUnits.length > 0 ? 1 : undefined })
  }, [componentCfg.isLoaded, permittedUnits])

  return (<>
    <div className={'site-space-compact-wrapper consumption-fields'}>
      <Space.Compact block>
        <Form.Item className={'consumption-fields-value'} hasFeedback={hasFeedback ?? true}
                   label={t(_.get(label, '[0]', null) ?? 'fields.labels.consumption')}
                   name={_.get(name, '[0]', null) ?? 'consumption_value'}
                   rules={requiredValidationConsumption}
                   style={{ width: '70%' }}>
          <InputNumber controls={false} min={0} disabled={Array.isArray(disabled) && disabled[0]}/>
        </Form.Item>
        <Form.Item className={'consumption-fields-units'} hasFeedback={hasFeedback ?? true}
                   label={t(_.get(label, '[1]', null) ?? 'fields.labels.units')}
                   name={componentCfg.name}
                   rules={requiredValidationUnits} style={{ width: '30%' }}>
          <Select disabled={true} loading={!componentCfg.isNeededRender} options={componentCfg.options}/>
        </Form.Item>
      </Space.Compact>
    </div>
  </>)
}

ConsumptionValueField.propTypes = {
  hasFeedback: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  name: PropTypes.array,
  label: PropTypes.array,
  permittedUnits: PropTypes.array,
  initialValue: PropTypes.number,
  required: PropTypes.array,
  disabled: PropTypes.array
}

// return consumption field
export function ReferenceField (props) {
  // for translation
  const { t } = useTranslation(locales)
  // import element to render
  const { hasFeedback, name, label } = props
  // set initial rules
  const requiredValidation = [{
    required: true,
    message: t('fields.validations.not_empty')
  }, {
    type: 'string',
    max: 45,
    message: t('fields.validations.not_be_longer', { max: 45 })
  }]

  return (<>
    <Form.Item hasFeedback={hasFeedback ?? true} label={t(label ?? 'fields.labels.reference')} name={name ?? 'reference'} rules={requiredValidation}>
      <Input/>
    </Form.Item>
  </>)
}

ReferenceField.propTypes = {
  hasFeedback: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string
}

// return consumption field
export function DescriptionSmallField (props) {
  // for translation
  const { t } = useTranslation(locales)
  // import element to render
  const { hasFeedback, name, label, max, placeholder } = props
  // set initial rules
  const requiredValidation = [{
    required: true,
    message: t('fields.validations.not_empty')
  }, {
    type: 'string',
    max: max || 100,
    message: t('fields.validations.not_be_longer', { max: max || 100 })
  }]

  return (<>
    <Form.Item hasFeedback={hasFeedback ?? true} label={t(label ?? 'fields.labels.description')} name={name ?? 'description'} rules={requiredValidation}>
      <Input placeholder={placeholder ? t(placeholder) : ''}/>
    </Form.Item>
  </>)
}

DescriptionSmallField.propTypes = {
  hasFeedback: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  max: PropTypes.number,
  placeholder: PropTypes.string
}

// return consumption field
export function MonthDayField (props) {
  // for translation
  const { t } = useTranslation(locales)
  // import element to render
  const { hasFeedback, name, label, required } = props
  // set initial rules
  const requiredValidation = [{
    required: typeof required === 'undefined' ? false : required,
    message: t('fields.validations.not_empty')
  }, {
    type: 'string',
    max: 5,
    message: t('fields.validations.not_be_longer', { max: 5 })
  }, {
    pattern: /(0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-9]|3[0-1])/,
    message: 'Format: MM-DD. [01-12]-[01-31]'
  }]

  return (<>
    <Form.Item hasFeedback={hasFeedback ?? true} label={t(label ?? 'fields.labels.description')} name={name ?? 'description'} rules={requiredValidation}>
      <Input placeholder={t('fields.placeholders.month_day')}/>
    </Form.Item>
  </>)
}

MonthDayField.propTypes = {
  hasFeedback: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool
}

export function MoneyField (props) {
  // for translation
  const { t } = useTranslation(locales)
  // import element to render
  const { hasFeedback, name, label, required, disabled } = props
  // set initial rules
  const requiredValidation = [{
    required: typeof required === 'undefined' || !Array.isArray(required) ? true : required[1],
    message: t('fields.validations.not_empty')
  }]

  return (<>
      <Form.Item className={'fix-with-input-number'}
                 hasFeedback={hasFeedback ?? true}
                 label={t(label ?? 'fields.labels.amount_variable')}
                 name={name ?? 'cost'}
                 rules={requiredValidation} style={{ width: '100%' }}>
        <InputNumber min={0} controls={false} addonAfter="€" disabled={Array.isArray(disabled) && disabled[1]} />
      </Form.Item>
  </>)
}

MoneyField.propTypes = {
  hasFeedback: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.array,
  required: PropTypes.array
}

export function SelectDay (props) {
  // for translation
  const { t } = useTranslation(locales)
  // import element to render
  const { hasFeedback, name, label, required, disabled } = props
  // set initial rules
  const requiredValidation = [{
    required: typeof required === 'undefined' ? false : required,
    message: t('fields.validations.not_empty')
  }]

  return (
    <Form.Item hasFeedback={hasFeedback ?? true} label={t(label ?? 'fields.labels.days')} name={name ?? 'days'} rules={requiredValidation}>
      <Select popupClassName={'rd-cell-select-dropdown'} placeholder={t('dashboard.day')}
              options={daysSelectables} disabled={disabled}
              name={name ?? 'cost'} rules={requiredValidation} style={{ width: '100%' }}
      />
    </Form.Item>
  )
}

SelectDay.propTypes = {
  hasFeedback: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool
}

export const periodicityMonthlyOptions = [{
  value: 'monthly',
  label: 'dashboard:dashboard.periodicity.monthly'
}, {
  value: 'bimonthly',
  label: 'dashboard:dashboard.periodicity.bimonthly'
}, {
  value: 'quarterly',
  label: 'dashboard:dashboard.periodicity.quarterly'
}, {
  value: 'semester',
  label: 'dashboard:dashboard.periodicity.semester'
}, {
  value: 'yearly',
  label: 'dashboard:dashboard.periodicity.yearly'
}]

export function PeriodicityMonthly (props) {
  // for translation
  const { t } = useTranslation(['fields', 'dashboard'])
  // import element to render
  const { hasFeedback, name, label, required, disabled } = props
  // set initial rules
  const requiredValidation = [{
    required: typeof required === 'undefined' ? false : required,
    message: t('fields.validations.not_empty')
  }]

  return (
    <Form.Item hasFeedback={hasFeedback ?? true} label={t(label ?? 'fields.labels.periodicity')} name={name ?? 'periodicity'} rules={requiredValidation}>
      <Select placeholder={t('dashboard:dashboard.periodicity.name')}
              name={name ?? 'periodicity-monthly'} disabled={disabled} rules={requiredValidation} style={{ width: '100%' }}
              options={
                _.map(periodicityMonthlyOptions, (opt) => {
                  return { ...opt, label: t(opt.label) }
                })
              }
      />
    </Form.Item>
  )
}

PeriodicityMonthly.propTypes = {
  hasFeedback: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool
}

// return invoice cost fields
export function EnergySupplyCostsFields (props) {
  // for translation
  const { t } = useTranslation(locales)
  // import element to render
  const { hasFeedback, name, label, required, disabled } = props
  // set initial rules
  const requiredValidationVariable = [{
    required: typeof required === 'undefined' || !Array.isArray(required) ? true : required[1],
    message: t('fields.validations.not_empty')
  }]
  // set initial rules
  const requiredValidationFix = [{
    required: typeof required === 'undefined' || !Array.isArray(required) ? true : required[0],
    message: t('fields.validations.not_empty')
  }]
  // set initial rules
  const requiredValidationTax = [{
    required: typeof required === 'undefined' || !Array.isArray(required) ? true : required[2],
    message: t('fields.validations.not_empty')
  }]

  return (<>
    <div className={'site-space-compact-wrapper supply-costs-fields'}>
      <Space.Compact block>
        <Form.Item className={'supply-cost-field supply-costs-fields-variable'}
                   hasFeedback={hasFeedback ?? true}
                   label={t(_.get(label, '[1]', null) ?? 'fields.labels.amount_variable')}
                   name={_.get(name, '[1]', null) ?? 'invoice_cost_variable'}
                   rules={requiredValidationVariable} style={{ width: '50%' }}>
          <InputNumber min={0} controls={false} addonAfter="€" disabled={Array.isArray(disabled) && disabled[1]} />
        </Form.Item>
        <Form.Item className={'supply-cost-field supply-costs-fields-fix'}
                   hasFeedback={hasFeedback ?? true}
                   label={t(_.get(label, '[0]', null) ?? 'fields.labels.amount_fix')}
                   name={_.get(name, '[0]', null) ?? 'invoice_cost_fix'}
                   rules={requiredValidationFix} style={{ width: '50%' }}>
          <InputNumber min={0} controls={false} addonAfter="€" disabled={Array.isArray(disabled) && disabled[0]}/>
        </Form.Item>
        <Form.Item className={'suppl-cost-field supply-costs-fields-vat'}
                   hasFeedback={hasFeedback ?? true}
                   label={t(_.get(label, '[2]', null) ?? 'fields.labels.amount_tax')}
                   name={_.get(name, '[2]', null) ?? 'invoice_tax'}
                   rules={requiredValidationTax} style={{ width: '50%' }}>
          <InputNumber min={0} controls={false} addonAfter="%" disabled={Array.isArray(disabled) && disabled[2]}/>
        </Form.Item>
      </Space.Compact>
    </div>
  </>)
}

EnergySupplyCostsFields.propTypes = {
  hasFeedback: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  name: PropTypes.array,
  label: PropTypes.array,
  disabled: PropTypes.array,
  required: PropTypes.array
}

// return field for percent
export function NumberPercentField (props) {
  // for translation
  const { t } = useTranslation(locales)
  // import element to render
  const { hasFeedback, name, label, required, disabled } = props
  // set initial rules
  const requiredValidation = [{
    required: typeof required === 'undefined' || !Array.isArray(required) ? true : required[1],
    message: t('fields.validations.not_empty')
  }]

  return (<>
    <Form.Item className={'fix-with-input-number'}
               hasFeedback={hasFeedback ?? true}
               label={t(label ?? 'fields.labels.percent')}
               name={name ?? 'percent'}
               rules={requiredValidation} style={{ width: '100%' }}>
      <InputNumber min={0} controls={false} addonAfter="%" disabled={Array.isArray(disabled) && disabled[1]} />
    </Form.Item>
  </>)
}

NumberPercentField.propTypes = {
  hasFeedback: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool
}

// return energy field
export function EnergyField (props) {
  // for translation
  const { t } = useTranslation(locales)
  // import element to render
  const { hasFeedback, name, label, required, disabled } = props
  // set initial rules
  const requiredValidation = [{
    required: typeof required === 'undefined' || !Array.isArray(required) ? true : required[1],
    message: t('fields.validations.not_empty')
  }]

  return (<>
    <Form.Item className={'fix-with-input-number'}
               hasFeedback={hasFeedback ?? true}
               label={t(label ?? 'fields.labels.energy')}
               name={name ?? 'energy'}
               rules={requiredValidation} style={{ width: '100%' }}>
      <InputNumber min={0} controls={false} addonAfter="kWh" disabled={Array.isArray(disabled) && disabled[1]} />
    </Form.Item>
  </>)
}

EnergyField.propTypes = {
  hasFeedback: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool
}
// BUTTONS

// for submit form
export function ButtonFormSubmit (props) {
  // import element to render
  const { text } = props
  // import element to render
  const { disabled } = props

  return (
    <>
      <Form.Item>
        <Button disabled={disabled} type="primary" htmlType="submit" className="login-form-button" style={{ width: '100%' }}>
          {text}
        </Button>
      </Form.Item>
    </>
  )
}

ButtonFormSubmit.propTypes = {
  text: PropTypes.node.isRequired,
  form: PropTypes.object,
  disabled: PropTypes.bool
}

// for reset form
export function ButtonFormReset (props) {
  // for translation
  const { t } = useTranslation(locales)
  // import element to render
  const { callback, disabled } = props

  return (
    <>
      <Form.Item>
        <Button disabled={disabled} onClick={callback} type="default" style={{ width: '100%' }}>
          {t('fields.reset')}
        </Button>
      </Form.Item>
    </>
  )
}

ButtonFormReset.propTypes = {
  callback: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

// CONTAINERS

// drawer with form
export function DrawerForm (props) {
  // for translation
  const { t } = useTranslation(['dashboard'])
  // import element to render
  const { title, content, onClose, open, extra, placement, width } = props

  return (
    <>
      <Drawer title={t(title)} placement={placement || 'right'} width={width || drawerWith} onClose={onClose} open={open} style={drawerStyle} extra={extra}>
        {content}
      </Drawer>
    </>
  )
}

DrawerForm.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  extra: PropTypes.node,
  placement: PropTypes.string,
  width: PropTypes.number
}

// drawer with form
export function FormAlerts (props) {
  // for translation
  const { t } = useTranslation(locales)
  const { errors, fieldsConfig } = props

  const alerts = errors.map(error => {
    const errorLabel = _.get(fieldsConfig, `${error.param}.errorLabel`, null) || _.get(fieldsConfig, `${error.param}.label`, '??')
    // get message
    const message = `${t(errorLabel)}: ${error.msg}`

    return (
      <div key={_.uniqueId('alert-w-')} style={{ marginBottom: '5px' }}>
        <Alert key={_.uniqueId('alert-')} type="error" message={message} banner closable/>
      </div>
    )
  })

  return (
    <>
      {!_.isEmpty(errors) && <> {alerts} <div style={{ marginBottom: '20px' }}/> </> }
    </>
  )
}

FormAlerts.propTypes = {
  errors: PropTypes.array.isRequired,
  fieldsConfig: PropTypes.object.isRequired
}

export function FormSubTitle (props) {
  /* <pre></pre> <blockquote></blockquote> */
  return (
    <Typography style={{ fontSize: '14px', margin: '0px', color: '#565353' }}>
      <pre style={{ marginTop: '0px', background: 'rgb(48 48 48 / 9%)', fontFamily: 'unset' }}>{props.text}</pre>
    </Typography>)
}

FormSubTitle.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired])
}
