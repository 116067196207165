import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DividerButtons } from 'components/divider'
import PropTypes from 'prop-types'
import { Col, Result, Row, Space } from 'antd'
import { Nav } from 'rsuite'
import _ from 'lodash'

// define locales for this page
const transFiles = ['dashboard']

export const PanelHeader = (props) => {
  // for translation
  const { t } = useTranslation(transFiles)
  // get header props
  const { title, icon } = props

  return (
    <div style={{ display: 'flex', alignItems: 'center', color: '#263256' }}>
      <Space>
        <span className={'icon-18'}>{icon}</span>
        {t(title)}
        <DividerButtons { ...props } />
      </Space>
    </div>
  )
}

PanelHeader.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  hasEdit: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  hasDelete: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  hasCreate: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  hasReload: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
}

export const PanelError = (props) => {
  return (
    <div className={'panel-error'}>
      <Result
        status="error"
        title="Request failed"
        subTitle="Please check and modify the following information before resubmitting."
      />
    </div>
  )
}

export const SectionTitle = (props) => {
  const { title, icon } = props

  return (
    <div className={'dashboard-data-title'}>
      <Row align="middle">
        <Col span={24}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {icon}&nbsp;&nbsp;{title}
          </div>
        </Col>
      </Row>
    </div>
  )
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node
}

export const SectionMenu = (props) => {
  // for translation
  const { t } = useTranslation(transFiles)
  // get props data
  const { handler, items, activeKey } = props
  // get items to render
  const elements = useMemo(() => {
    // get all item to render
    return items.map((item) => {
      // get data to render
      const key = _.get(item, 'key', 'unknown')
      const label = _.get(item, 'label', 'unknown')
      // get nav item
      return <Nav.Item key={_.uniqueId()} eventKey={key}>{t(label)}</Nav.Item>
    })
  }, [items])

  return (
    <div className={'dashboard-data-menu-toolbar'}>
      <Nav activeKey={activeKey} onSelect={handler} appearance="subtle">
        {elements}
      </Nav>
    </div>
  )
}

SectionMenu.propTypes = {
  handler: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  activeKey: PropTypes.string
}

export const SectionContent = (props) => {
  // get props data
  const { component } = props
  // return a component
  return <div className={'dashboard-data-content'}>{ component }</div>
}

SectionContent.propTypes = {
  component: PropTypes.node
}
